<template>
  <div class="tr">
    <div class="td">
      {{$formatDate(item.createdAt * 1000)}}
    </div>
    <div class="td">
      <div class="img-wrapper">
        <img :src="$getImgUrl(item.imageUrl)" />
      </div>
    </div>
    <div class="td">
      <router-link
        v-if="item.entryId"
        :to="{
          name: 'SingleMetadata',
          params: {
            type: item.daType,
            metadataId: item.entryId,
          },
        }"
      >
        {{ item.daName }}
      </router-link>
    </div>
    <div class="td">
      <router-link
        class="username"
        :to="{
          name: 'SingleCreator',
          params: {
            creatorId: item.creator.creatorId,
          },
        }"
      >
        {{ item.creator.nickname }}
        <br />
        ({{ item.creator.username }})
      </router-link>
    </div>
    <div class="td">
      <Badge :solid="true" :color="creatorGrade.color">
        {{ creatorGrade.label }}
      </Badge>
    </div>
    <div class="td">
      <template v-if="item.reviewer && item.reviewer.name">
        {{item.reviewer.name}}
      </template>
      <template v-else>
        -
      </template>
    </div>
    <div class="td">
      <div class="subset">
        <div class="subset-item category">
          <template v-if="item.categories && item.categories.length > 0">
            <div v-for="cat, i in item.categories" :key="i">
              {{cat.krName}}
            </div>
          </template>
          <template v-else>
            -
          </template>
        </div>
        <div class="subset-item quality">
          <template v-if="item.grade && item.grade !== 'NONE'">
            {{grade}}
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>
    </div>
    <div class="td right">
      <Badge color="black" :class="{
        fade: item.status === 'INIT'
      }">
        {{status}}
      </Badge>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";

import {
  getCreatorGradeDisplayData
} from "@/utils";

export default {
  extends: TableListRow,
  computed: {
    creatorGrade() {
      const level = getCreatorGradeDisplayData(this.item.creator.grade);
      return level ? level : {label: this.item.creator.grade}
    },
    status() {
      switch (this.item.status) {
        case 'INIT': 
          return '배정전'
        case 'ASSIGNED': 
          return '배정됨'
        case 'DONE': 
          return '입력완료'
        default: 
          return this.item.status
      }
    },
    grade() {
      switch (this.item.grade) {
        case 'LOW': 
          return '하'
        case 'NORMAL': 
          return '중'
        case 'PREMIUM': 
          return '상'
        default: 
          return this.item.status
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.subset {
  font-size: 11px;
  margin: 4px 0 0;
}
.subset-item {
  font-weight: 400;
  font-size: 12px;
}
.fade {
  opacity: 0.4;
}
</style>
