<template>
  <div class="tr">
    <div class="td">
      <Checkbox
        v-on:click="toggleCheck(item.noticeId)"
        :checked="checked"
        :disabled="disabled"
      />
    </div>
    <div class="td">
      {{ $formatDate(item.createdAt * 1000) }}
    </div>
    <div class="td">
      <router-link
        :to="{
          name: 'NoticeEdit',
          params: {
            noticeId: item.noticeId,
          },
        }"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td center">
      {{ item.writerName }}
    </div>
    <div class="td center">
      {{ $formatDate(item.publishedAt * 1000) }}
    </div>
    <div class="td center">
      <Badge :color="noticeStatus.color">
        {{ noticeStatus.label }}
      </Badge>
    </div>
    <div class="td center">
      {{ item.viewCount }}
    </div>
    <div class="td center">
      <fixIcon v-if="item.topFixed" />
    </div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
import fixIcon from "@/components/svg/icons/svgFix";

export default {
  extends: TableListRow,
  methods: {
    onBeforeCheck() {
      return ["RESERVED", "DISABLED"].includes(this.item.publishedStatus);
    },
  },
  computed: {
    noticeStatus() {
      switch (this.item.publishedStatus) {
        case "PUBLISHED":
          return { label: "게시중", color: "purple" };
        case "DISABLED":
          return { label: "미게시", color: "yellow" };
        case "RESERVED":
          return { label: "예약", color: "blue" };
        default:
          return { label: this.item.publishedStatus };
      }
    },
    disabled() {
      return !["RESERVED", "DISABLED"].includes(this.item.publishedStatus);
    },
  },
  components: {
    fixIcon,
  },
};
</script>

<style lang="scss" scoped></style>
