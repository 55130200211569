<template>
  <Modal width="600px">
    <control>
      <template v-slot:label>개정일</template>
      <datepicker v-model="date" class="input" />
    </control>
    <control>
      <template v-slot:label>개정내용</template>
      <InputText
        v-model.trim="revision"
        class="input"
        placeholder="개정내용에 대해 간단히 설명해주세요."
      />
    </control>
    <control>
      <template v-slot:label>내용</template>
      <Textarea
        v-model.trim="contents"
        class="input ta"
        placeholder="개정할 내용을 입력해주세요."
      />
    </control>
  </Modal>
</template>

<script>

import ModalFormControl from "@/components/modal/ModalFormControl";
import {InputText, Datepicker, Textarea, Modal} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      date: false,
      revision: "",
      contents: "",
    };
  },
  components: {
    control: ModalFormControl,
    Modal,
    Datepicker,
    InputText,
    Textarea,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}

.ta::v-deep(.textarea-wrapper textarea) {
  min-height: 128px;
}
</style>
