export default [
  { value: "ALL", label: "공통" },
  { value: "DASHBOARD", label: "대시보드" },
  { value: "CONTENT", label: "콘텐츠 관리" },
  { value: "FAN", label: "팬관리" },
  { value: "SALE", label: "판매현황" },
  { value: "SETTLEMENT", label: "정산현황" },
  {
    value: "ANIMATION_STICKER_UPLOAD",
    label: "콘텐츠 업로드 - 애니메이션 스티커",
  },
  { value: "STICKER_UPLOAD", label: "콘텐츠 업로드 - 스티커" },
  { value: "IMAGE_UPLOAD", label: "콘텐츠 업로드 - 이미지" },
  { value: "COLORING_UPLOAD", label: "콘텐츠 업로드 - 컬러링 시트" },
  { value: "AUDIO_UPLOAD", label: "콘텐츠 업로드 - 음원" },
  { value: "PROFILE", label: "프로필" },
];
