<template>
  <div class="tr">
    <div class="td">
      <Checkbox
        v-on:click="toggleCheck" 
        :checked="checked" 
        :isRemoveAll="true"
      />
    </div>
    <div class="td">작성일</div>
    <div class="td">제목</div>
    <div class="td center">게시자/작성자</div>
    <div class="td center">게시일</div>
    <div class="td center">게시상태</div>
    <div class="td center">조회수</div>
    <div class="td center">상단고정</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";
export default {
  extends: TableListRow,
  props: ["checked"],
  methods: {
    toggleCheck() {
      this.$emit("toggleCheck", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.td {
  // Zeplin 기준 11px (0.6875rem) 이지만, 가독성을 위해 14px (0.8125rem)으로 변경
  // font-size: 0.6875rem;
  font-weight: bold;
}
</style>
