<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.975 30C51.975 42.1365 42.1365 51.975 30 51.975C17.8635 51.975 8.025 42.1365 8.025 30C8.025 17.8635 17.8635 8.025 30 8.025C42.1365 8.025 51.975 17.8635 51.975 30ZM54.375 30C54.375 43.4619 43.4619 54.375 30 54.375C16.5381 54.375 5.625 43.4619 5.625 30C5.625 16.5381 16.5381 5.625 30 5.625C43.4619 5.625 54.375 16.5381 54.375 30ZM23.9062 30C25.9255 30 27.5625 28.363 27.5625 26.3438C27.5625 24.3245 25.9255 22.6875 23.9062 22.6875C21.887 22.6875 20.25 24.3245 20.25 26.3438C20.25 28.363 21.887 30 23.9062 30ZM32.4375 26.5625C32.4375 26.0102 32.8852 25.5625 33.4375 25.5625H38.75C39.3023 25.5625 39.75 26.0102 39.75 26.5625C39.75 27.1148 39.3023 27.5625 38.75 27.5625H33.4375C32.8852 27.5625 32.4375 27.1148 32.4375 26.5625ZM30 41.7966L25.0784 36.875H34.9216L30 41.7966ZM37.3358 34.875H22.6642C21.7733 34.875 21.3271 35.9521 21.9571 36.5821L29.2929 43.9179C29.6834 44.3084 30.3166 44.3084 30.7071 43.9179L38.0429 36.5821C38.6729 35.9521 38.2267 34.875 37.3358 34.875Z"
      fill="#ADB5BD"
    />
  </svg>
</template>
