<template>
  <admin-layout>
    <template v-slot:title v-if="isReviewReady">
      <admin-title class="title">
        {{ contentTitle }}
      </admin-title>
    </template>

    <template v-slot:head v-if="isReviewReady">
      <div>
        <div
          class="review-list-message"
          v-if="userIsInRole('META_DATA_MANAGER')"
        >
          심사 완료 콘텐츠
          <strong>{{ reviewWaitListIds.length }}개 남음</strong>
        </div>
        <!-- 다음 심사 {{nextEntryId}} -->
      </div>
    </template>

    <template v-slot:body v-if="isReviewReady">
      <div class="section-bar">
        <div class="section-title">
          <Badge :solid="true" :color="creatorGrade.color">
            {{ creatorGrade.label }}
          </Badge>
          <div class="title">
            {{ creator.nickname }} ({{ creator.username }})
          </div>
          <router-link
            :to="{
              name: 'SingleCreator',
              params: { creatorId: creator.creatorId },
            }"
          >
            크리에이터 상세정보
          </router-link>
        </div>
        <div class="section-actions">
          <Button :loading="isLoading" v-on:click="goBack"> 이전 </Button>
          <Button v-on:click="reviewDone" :loading="isLoading">
            <template v-if="userIsInRole(['ADMIN', 'OCS_OPERATOR'])">
              저장
            </template>
            <template v-else> 저장 후 다음 </template>
          </Button>
        </div>
      </div>

      <div class="cards" v-if="content">
        <div class="card-col">
          <card-main
            :content="content"
            :contentId="contentId"
            :contentTitle="contentTitle"
            :contentKOTitle="contentKOTitle"
            :contentKODesc="contentKODesc"
            :contentENTitle="contentENTitle"
            :contentENDesc="contentENDesc"
            :noDownload="true"
            :canEdit="false"
            :stickerOptions="{
              hasDarkmode: false,
              hasCheckeredBG: true,
            }"
            v-on:setTextContent="setTextContent"
            v-on:pushTag="pushTag"
            v-on:removeTag="removeTag"
          />
        </div>
        <div class="card-col">
          <card-category
              :_categoryIds="content.categoryIds"
              :activeOption="true"
              ref="categories"
          />
          <card-image-type
            :imageType="content.imageType"
            v-on:setImageType="setImageType"
            ref="imageTypes"
            v-if="$route.params.type === 'STOCK_IMAGE'"
          />
          <card-mood
            :mood="content.mood"
            @set-mood="setMood"
            ref="moods"
            v-if="$route.params.type === 'AUDIO'"
          />
          <card-quality :_grade="review.grade" ref="quality" />
          <card-admin-tags
            :tags="content.keywords"
            v-on:pushTag="pushAdminTag"
            v-on:removeTag="removeAdminTag"
          />
        </div>
      </div>

      <div class="section-bar big spacer">
        <div class="section-title">
          <div class="title big">심사 이력</div>
        </div>
      </div>

      <content-review-log :contentId="contentId" v-if="contentId" />
    </template>
  </admin-layout>

  <!-- <modal-continue
    v-if="!nextEntryId"
    :isOpen="isContinueOpen"
    v-on:confirm="continueReview"
    v-on:close="closeContinue"
  /> -->
</template>

<script>
import SingleContentBase from "@/views/contents/main/SingleContentBase";

import SingleContentCategory from "@/views/contents/cards/SingleContentCategory";
import SingleContentQuality from "@/views/contents/cards/SingleContentQuality";
import SingleContentAdminTags from "@/views/contents/cards/SingleContentAdminTags";
import SingleContentMood from "@/views/contents/cards/SingleContentMood";
import SingleContentImageType from "@/views/contents/cards/SingleContentImageType";

import {
  getReviewStatusDisplayData,
  getCreatorGradeDisplayData,
} from "@/utils";
import { apiGet, apiPost } from "../../../../api/apiMethods";

const CATEGORIES = {
  STICKER: "sticker",
  STOCK_IMAGE: "image",
  COLORING_SHEET: "coloringSheet",
  AUDIO: "audio",
};

export default {
  extends: SingleContentBase,
  data() {
    return {
      review: false,
      isReject: false,
      isSalesModalOpen: false,
      relatedContents: [],
      creatorContents: [],
      reviewWaitListIds: [],
      isReviewReady: false,
      isReviewLoading: false,
      // isContinueOpen: false,
      isLoading: false,
      contentKOTitle: "",
      contentKODesc: "",
      contentENTitle: "",
      contentENDesc: "",
      imageType: "",
    };
  },
  created() {
    this.loadSingleMetadata();
  },
  watch: {
    async $route(n, o) {
      if (
        n.name === "SingleMetadata" &&
        n.params.metadataId !== o.params.metadataId
      ) {
        await this.loadSingleMetadata();
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // Single Review
    async requestSingleMetadata() {
      const API_SINGLE_METADATA_URL = `dam/v5/operator/metaEntries/${
        this.$route.params.metadataId
      }/${CATEGORIES[this.$route.params.type]}`;
      // const response = await axios.get(API_SINGLE_METADATA_URL, { headers: this.$store.getters["user/getAPIHeaders"]})
      const response = await apiGet(API_SINGLE_METADATA_URL);
      return response?.data;
    },
    async loadSingleMetadata() {
      this.isReviewLoading = true;
      this.isReviewReady = false;
      this.review = false;
      this.content = false;
      this.creator = false;
      try {
        this.clearModal();
        const review = await this.requestSingleMetadata();
        await this.getMetadataList();
        this.content = review[CATEGORIES[this.$route.params.type]];
        this.creator = review[CATEGORIES[this.$route.params.type]].creator;
        this.review = review;
        console.log("review", this.review);
        // this.$refs.quality.setGrade(this.review?.grade || null)
        this.syncContentTextContents();
      } catch (err) {
        this.content = false;
        this.creator = false;
        this.$router.replace({ name: "PageNotFound " });
      } finally {
        this.isReviewLoading = false;
        this.isReviewReady = true;
        if (this.review?.status === "DONE") {
          await this.onAlreadyReviewed();
        }
      }
    },

    clearModal() {
      this.$refs.rejectReviewModal?.clear();
    },
    async onAlreadyReviewed() {
      await this.$confirm({
        cancelText: "닫기",
        msg: "이미 입력 완료된 콘텐츠입니다.",
      });
    },
    // closeContinue() {
    //   this.isContinueOpen = false;
    // },
    async getMetadataList() {
      try {
        const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
        const LIST_API_URL = isAdmin
          ? `/dam/v5/admin/metaEntries?type=${this.$route.params.type}`
          : `/dam/v5/operator/metaEntries?type=${this.$route.params.type}`;
        const response = await apiGet(LIST_API_URL);
        console.log("getmeta", response);
        this.reviewWaitListIds =
          response?.data?.elements
            .filter((i) => i.status === "ASSIGNED")
            .map((i) => i.entryId) || [];
      } catch (err) {
        console.error("getMetadataList", err);
      }
    },
    async reviewDone() {
      if (this.isReviewDone) {
        return this.onAlreadyReviewed();
      }

      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);

      const data = {
        ...{
          categoryIds: this.content.categoryIds,
          tags: this.content.tags,
          keywords: this.content.keywords,
          grade: this.content.grade || "LOW",
        },
        ...{
          categoryIds: this.$refs.categories.categoryIds,
          grade: this.$refs.quality.grade,
        },
      };

      const API_TYPE = CATEGORIES[this.$route.params.type];

      if (API_TYPE === "audio") {
        data.mood = this.content.mood;
      } else if (API_TYPE === "image") {
        data.imageType = this.content.imageType;
      }

      if (!data.categoryIds || data.categoryIds.length < 1) {
        await this.$alert({
          html: "<strong>[카테고리]</strong>를 선택해주세요.",
        });
        return;
      }

      if (!data.grade || data.grade === "NONE") {
        await this.$alert({
          html: "<strong>[콘텐츠 품질 점수]</strong>를 입력해주세요.",
        });
        return;
      }
      const confirm = await this.$confirm({
        msg: "메타데이터를 저장하시겠습니까?",
        confirmText: "예",
        cancelText: "아니요",
      });

      if (!confirm) return;

      try {
        const API_SAVE_URL = `${
          isAdmin ? "/dam/v5/admin/metaEntries" : "/dam/v5/operator/metaEntries"
        }/${this.$route.params.metadataId}/${API_TYPE}`;
        await apiPost(API_SAVE_URL, data);
        this.onAfterReview();
      } catch (err) {
        console.error("save", err, err.response);
        this.$error();
      } finally {
        this.isLoading = false;
      }
    },
    async onAfterReview(response) {
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      if (isAdmin) {
        await this.$alert({
          msg: "메타데이터를 저장했습니다.",
        });
        return;
      }
      console.log("REVIEW WAIT LIST IDS", this.reviewWaitListIds);
      console.log("NEXT REVIEW ID", this.nextEntryId);

      console.log(response, this.nextEntryId);
      if (this.nextEntryId) {
        await this.$router.push({
          name: "SingleMetadata",
          params: {
            type: this.$route.params.type,
            metadataId: this.nextEntryId,
          },
        });
        // await this.loadSingleMetadata()
      } else {
        console.log("NEXT REVIEW ID DOESNT EXIST", "CONTINUE OPEN");
        console.log("Should show modal");
        const continueReview = await this.$confirm({
          html: `
            대기중인 콘텐츠 메타 입력이 완료되었습니다. 계속 입력하실 경우,
            <strong>[이어서 입력]</strong>을 선택해주세요. 입력 대기 화면에서 ‘입력
            시작'을 통해 새로운 리스트를 시작할 수도 있습니다.
          `,
          confirmText: "이어서 입력",
          cancelText: "돌아가기",
        });

        if (continueReview) {
          this.continueReview();
        } else {
          this.$router.push({
            name: "Metadata",
            params: { type: this.$route.params.type },
          });
        }
      }
    },
    async continueReview() {
      try {
        await apiPost(
          `/dam/v5/admin/metaEntries?type=${this.$route.params.type}`
        );
        await this.getMetadataList();
        if (this.nextEntryId) {
          await this.$router.push({
            name: "SingleMetadata",
            params: {
              type: this.$route.params.type,
              metadataId: this.nextEntryId,
            },
          });
          // await this.loadSingleMetadata()
        } else {
          await this.$alert({
            msg: "입력할 콘텐츠가 존재하지 않습니다.",
          });
          this.$router.push({
            name: "Metadata",
            params: { type: this.$route.params.type },
          });
        }
      } catch (e) {
        console.log(e.response);
        return this.$confirm({
          msg: "배정을 받는데에 문제가 생겼습니다.",
          cancelText: "닫기",
          confirmText: "확인",
          onAfterClose: () => {
            this.$router.push({
              name: "Metadata",
              params: {
                type: this.$route.params.type,
              },
            });
          },
        });
      }
    },
  },
  computed: {
    isReviewDone() {
      return this.review.status === "DONE";
    },
    contentTitle() {
      if (this.content) {
        const KO = this.content.textContents.find((t) => t.lang === "KO");
        const EN = this.content.textContents.find((t) => t.lang === "EN");

        return KO && KO.title ? KO.title : EN && EN.title ? EN.title : "--";
      } else {
        return "--";
      }
    },
    nextEntryId() {
      const list =
        this.reviewWaitListIds.filter(
          (entryId) => entryId !== this.$route.params.metadataId
        ) || [];
      if (list.length <= 0) {
        return false;
      }
      return list[0];
    },
    CREATOR_CONTENTS_API() {
      return `${this.$API_URL}/admin/${this.contentType}/`;
    },
    RELATED_CONTENTS_API() {
      return `${this.$API_URL}/admin/${this.contentType}/${this.contentId}/related`;
    },
    contentType() {
      if (!this.content) return;

      switch (this.$route.params.type) {
        case "STICKER":
          return "stickers";
        case "COLORING_SHEET":
          return "coloringSheets";
        case "STOCK_IMAGE":
          return "images";
        case "AUDIO":
          return "audios";
        default:
          return null;
      }
    },
    contentId() {
      if (!this.content) return;

      switch (this.$route.params.type) {
        case "STICKER":
          return this.content.stickerId;
        case "COLORING_SHEET":
          return this.content.coloringSheetId;
        case "STOCK_IMAGE":
          return this.content.imageId;
        case "AUDIO":
          return this.content.audioId;
        default:
          return null;
      }
    },
    isContentOnSale() {
      return this.content.status === "SALE";
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.creator.grade);
    },
    status() {
      if (this.review && this.review.status) {
        return getReviewStatusDisplayData(this.review.status);
      } else {
        return false;
      }
    },
  },
  components: {
    // Cards
    "card-category": SingleContentCategory,
    "card-quality": SingleContentQuality,
    "card-admin-tags": SingleContentAdminTags,
    "card-mood": SingleContentMood,
    "card-image-type": SingleContentImageType,
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0 0;
}

.sales-count {
  margin: 16px 0 0;
  font-size: 16px;
  color: var(--mono-800);
}

.section-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;
  &.big {
    margin: 20px 0 40px;
  }
  &.spacer {
    margin-top: 80px;
  }
}

.section-bar .section-title {
  display: flex;
  align-items: center;
}
.section-bar a {
  color: var(--mono-900);
  padding: 0 12px;
  border: 1px solid var(--mono-900);
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 22px;
  outline: 0;
  text-decoration: none;
}

.section-title .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 20px 0 4px;
  .big & {
    font-size: 24px;
    margin: 0 0 0;
    color: #333;
  }
}

.section-actions button {
  margin-left: 10px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.card-col {
  // flex: 1;
}

.card {
  padding: 20px;
  background-color: white;
  // height: 100%;
  & + .card {
    margin-top: 20px;
  }
}

.review-list-message {
  font-size: 16px;
  font-weight: 400;
  color: var(--mono-700);

  strong {
    font-weight: 500;
    color: var(--mono-900);
  }
}
</style>
