<template>
  <svg
    width="44"
    height="18"
    viewBox="0 0 44 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="18" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.03809 9.00661C8.03809 10.6166 9.37439 11.9566 10.9805 11.9566C12.5866 11.9566 13.8957 10.6166 13.8957 9.00661C13.8957 7.39636 12.5866 6.04311 10.9805 6.04311C9.37439 6.04311 8.03809 7.39636 8.03809 9.00661ZM10.9804 14C8.22701 14 6 11.7673 6 9.00675C6 6.2325 8.22701 4 10.9804 4C13.7204 4 15.9743 6.2325 15.9743 9.00675C15.9743 11.7673 13.7204 14 10.9804 14ZM26.6283 8.273C25.8498 8.27 23.7829 8.26225 22.7543 8.261C22.5897 8.26075 22.4379 8.34925 22.3563 8.49225L21.7626 9.5315C21.6317 9.76125 21.797 10.047 22.0608 10.047H24.7185C24.2998 11.155 23.2358 11.9568 21.9938 11.9568C20.3874 11.9568 19.0513 10.617 19.0513 9.007C19.0513 7.3965 20.3874 6.04325 21.9938 6.04325C23.0256 6.04325 23.935 6.602 24.4532 7.4285C24.539 7.565 24.6853 7.65125 24.8459 7.65125H26.1506C26.4817 7.65125 26.7044 7.30975 26.5715 7.0055C26.5051 6.8535 26.4316 6.7055 26.3513 6.562C25.4928 5.031 23.8555 4 21.9938 4C19.2089 4 16.9627 6.284 17.0141 9.1015C17.0615 11.6898 19.1194 13.8405 21.697 13.9913C24.5689 14.1595 26.9874 11.8663 26.9874 9.007C26.9874 8.86525 26.9817 8.72525 26.9702 8.58675C26.9555 8.40925 26.8059 8.27375 26.6283 8.273ZM33.6183 6.10858C31.7544 5.70908 30.0635 7.19509 30.0635 9.00659C30.0635 10.6166 31.3998 11.9566 33.0059 11.9566C33.2704 11.9566 33.5265 11.9193 33.7704 11.8516L32.4361 9.51684C32.3044 9.28659 32.4703 8.99984 32.7348 8.99984H33.9008C34.0652 8.99984 34.217 9.08784 34.2986 9.23084L35.2446 10.8863C35.7895 10.2238 36.0553 9.32159 35.8543 8.37534C35.6157 7.25159 34.7389 6.34883 33.6183 6.10858ZM33.0062 4C34.8679 4 36.5054 5.031 37.3637 6.562C37.7684 7.284 38 8.1175 38 9.00675C38 11.8663 35.5813 14.1593 32.7094 13.991C30.1318 13.8403 28.0739 11.6895 28.0267 9.1015C27.9751 6.28375 30.2213 4 33.0062 4Z"
      fill="#00C389"
    />
  </svg>
</template>
