<template>
  <div class="tr">
    <div class="td">
      <Checkbox :checked="checked" v-on:click="toggleCheck(item.no)" />
    </div>
    <div class="td">
      <a href="" v-on:click="selectRevision">{{ item.no }}</a>
    </div>
    <div class="td">
      <a href="" v-on:click="selectRevision">{{ item.updatedDate }}</a>
    </div>
    <div class="td">
      <a href="" v-on:click="selectRevision">{{ item.revision }}</a>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
  methods: {
    selectRevision(e) {
      e.preventDefault();
      this.$emit("selectRevision", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  font-size: 0.75rem;
}
</style>
