<template>
  <AdminLayout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="FAQ 카테고리">
          <Dropdown
            v-model="faqCategory"
            :placeholder="faqCategory.name"
            :list="faqCategories"
            class="dropdown-wrap"
          />
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="질문">
          <InputText
            v-model.trim="title"
            placeholder="질문을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="내용">
          <div class="editor-wrap">
            <Editor v-model="contents" category="FAQ" ref="editor" />
          </div>
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button color="primary" v-on:click="handlePreviewClick">
        미리보기
      </Button>
      <Button
        color="primary"
        v-if="route.name === 'FaqAdd'"
        v-on:click="handleSaveClick"
        :disabled="!faqCategory || !title"
        :loading="isLoading"
      >
        저장
      </Button>
      <Button
        color="primary"
        v-else
        v-on:click="handleUpdateClick"
        :disabled="!faqCategory || !title"
        :loading="isLoading"
      >
        수정
      </Button>
      <Button v-on:click="$router.go(-1)"> 취소 </Button>
    </template>
  </AdminLayout>
  <Modal
    v-if="isModalOpen"
    :isDialog="true"
    :isOpen="isModalOpen"
    :width="modalWidth"
    :height="modalHeight"
    :isLongList="true"
    v-on:confirm="handleModalConfirm"
    v-on:cancel="handleModalConfirm"
  >
    <div :style="modalStyle" v-html="modalContents" />
  </Modal>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import AdminLayout from "@/layouts/AdminLayout.vue";
import {
  PagePanel,
  PagePanelFormRow,
  Dropdown,
  InputText,
  Button,
  Modal,
} from "@ogqcorp/v-ui";
import Editor from "@/components/el/inputs/Editor.Prose.vue";
import { apiGet, apiPost, apiPut } from "../../api/apiMethods";

const route = useRoute();
const router = useRouter();
const title = ref("");
const editor = ref(null);
const contents = ref("");
const isLoading = ref(false);
const isModalOpen = ref(false);
const modalContents = ref("");
const modalWidth = ref("");
const modalHeight = ref("");
const modalStyle = ref("");

const faqCategory = ref("");
const faqCategories = ref([]);

onMounted(async () => {
  await loadCategories();
  if (route.name === "FaqEdit") {
    console.log(route.name);
    await loadFaqData();
  }
});

const loadCategories = async () => {
  try {
    const categories = await apiGet(`/admin/faqCategories`);
    faqCategories.value = categories.data.elements.map((el) => ({
      ...el,
      label: el.title,
      value: el.faqCategoryId,
    }));
    faqCategory.value = faqCategories.value[0].value;
  } catch (err) {
    console.error("Load Faq Categories Error ", err);
  }
};

const loadFaqData = async () => {
  try {
    const { data } = await apiGet(`/admin/faqs/${route.params.faqId}`);
    faqCategory.value = data.faqCategoryId;
    title.value = data.title;
    contents.value = data.html;
    editor.value.setContent(contents.value);
  } catch (err) {
    console.error("Load FAQ Data Error ", err);
  }
};
const handleSaveClick = async () => {
  try {
    isLoading.value = true;
    let convertEditor = await editor.value.getHTMLContents();

    if (convertEditor === "<p></p>") {
      modalContents.value = "<p>내용을 입력해주세요.</p>";
      isModalOpen.value = true;
      isLoading.value = false;
    } else {
      if (convertEditor.includes("img src")) {
        convertEditor = convertEditor.replace("<p>&lt;img src=", "<img src=");
        convertEditor = convertEditor.replace("/&gt;</p>", "/>");
      }
      const faqData = {
        title: title.value,
        html: convertEditor,
        faqCategoryId: faqCategory.value,
      };

      await apiPost(`/admin/faqs`, faqData);
      router.push({
        name: "Faq",
      });
    }
  } catch (err) {
    console.error("Save FAQ Error ", err);
  } finally {
    isLoading.value = false;
  }
};
const handleUpdateClick = async () => {
  try {
    isLoading.value = true;
    let convertEditor = await editor.value.getHTMLContents();
    if (convertEditor.includes("img")) {
      convertEditor = convertEditor.replace("<p>&lt;img", "<img");
      convertEditor = convertEditor.replace("/&gt;</p>", "/>");
    }
    const faqData = {
      faqId: route.params.faqId,
      title: title.value,
      html: convertEditor,
      faqCategoryId: faqCategory.value,
    };

    await apiPut(`/admin/faqs/${route.params.faqId}`, faqData);
    router.push({ name: "Faq" });
  } catch (err) {
    console.error("Update FAQ Error ", err);
  } finally {
    isLoading.value = false;
  }
};
const handlePreviewClick = async () => {
  let preEditor = await editor.value.getHTMLContents();
  if (preEditor.includes("img ")) {
    preEditor = preEditor.replace("<p>&lt;img ", "<img ");
    preEditor = preEditor.replace("/&gt;</p>", "/>");
  }
  modalContents.value = preEditor;
  modalWidth.value = "80%";
  modalHeight.value = "80%";
  modalStyle.value = "min-height: 63vh;";
  isModalOpen.value = true;
};
const handleModalConfirm = () => {
  isModalOpen.value = false;
};
</script>

<style lang="scss" scoped>
.dropdown-wrap {
  width: 180px;
}
.stretch {
  width: 100%;
}
.editor-wrapper {
  min-height: 300px;
}
</style>
