<template>
  <div
    class="tr"
    :class="{ stickers: this.$route.params.category === 'stickers' }"
  >
    <div class="td">
      <Checkbox />
    </div>
    <div class="td">
      {{ $formatDate(item.createdAt * 1000) }}
    </div>
    <div class="td">
      <div class="img-wrapper">
        <img :src="$getImgUrl(thumbnailUrl)" />
      </div>
    </div>
    <div class="td">
      <router-link
        v-if="contentId"
        :to="{
          name: 'SingleContent',
          params: {
            category: $route.params.category,
            contentId: contentId,
          },
        }"
      >
        {{ contentTitle }}
      </router-link>
    </div>
    <div class="td">
      {{ category }}
    </div>
    <div v-if="this.$route.params.category === 'stickers'" class="td">
      {{ salesTypes }}
    </div>
    <div class="td">
      <Badge :color="status.color">
        {{ status.label }}
      </Badge>
    </div>
    <div class="td">
      <router-link
        class="username"
        :to="{
          name: 'SingleCreator',
          params: {
            creatorId: item.creator.creatorId,
          },
        }"
      >
        {{ item.creator.username }}
        <br />
        ({{ item.creator.nickname }})
      </router-link>
    </div>
    <div class="td">
      <Badge :color="creatorGrade.color" :solid="true">
        {{ creatorGrade.label }}
      </Badge>
    </div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
import {
  getCreatorGradeDisplayData,
  getContentStatusDisplayData,
} from "@/utils";

export default {
  props: ["item"],
  extends: TableListRow,
  computed: {
    contentTitle() {
      const KO = this.item.textContents.find((t) => t.lang === "KO");
      const EN = this.item.textContents.find((t) => t.lang === "EN");

      return KO && KO.title ? KO.title : EN && EN.title ? EN.title : "--";
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.item.creator.grade);
    },
    category() {
      switch (this.$route.params.category) {
        case "stickers":
          return "스티커";
        case "images":
          return "이미지";
        case "coloringSheets":
          return "컬러링시트";
        case "audios":
          return "음원";
        case "virtualAssets":
          return "방송용 에셋";
        default:
          return this.$route.params.category;
      }
    },
    salesTypes() {
      return this.item.salesTypes?.every((v) => v === "SUBSCRIPTION")
        ? "구독 판매"
        : "일반 판매";
    },
    thumbnailUrl() {
      switch (this.$route.params.category) {
        case "stickers":
          return this.item.mainImageUrl;
        case "images":
          return this.item.imageUrl;
        case "coloringSheets":
          return this.item.afterImagePath
            ? this.item.afterImageUrl
            : this.item.beforeImageUrl;
        case "audios":
          return this.item.coverImageUrl;
        case "virtualAssets":
          return this.item.imageUrl;
        default:
          return this.item.coverImageUrl;
      }
    },
    status() {
      return getContentStatusDisplayData(this.item.status);
    },
    contentId() {
      switch (this.$route.params.category) {
        case "stickers":
          return this.item.stickerId;
        case "images":
          return this.item.imageId;
        case "coloringSheets":
          return this.item.coloringSheetId;
        case "audios":
          return this.item.audioId;
        case "virtualAssets":
          return this.item.virtualAssetId;
        default:
          return "0";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
