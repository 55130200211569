<template>
  <div class="tooltip-wrapper">
    <div
      class="tooltip-title"
      v-on:click.stop="handleTooltipClick"
    >
      <slot name="title" />
    </div>
    <div
      v-if="isActive"
      :class="[
        'tooltip-body',
        { 'mb-full': mobileFull }
      ]"
    >
      <div
        :class="[
          'tooltip-body-contents',
          `align-${alignValue}`,
          `background-color-${backgroundColor}`,
          { 'mb-full': mobileFull }
        ]"
        ref="tooltipBody"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onBeforeUnmount, onMounted, ref } from 'vue';

defineEmits(['update:modelValue']);
defineProps({
  alignValue: {
    type: String,
    default: 'left',
    validator: (v) => [
      'left',
      'right',
      'center'
    ].includes(v),
    required: false
  },
  backgroundColor: {
    type: String,
    default: 'default',
    validator: (v) => [
      'default',
      'white'
    ].includes(v),
    required: false
  },
  mobileFull: {
    type: Boolean,
    default: false,
    required: false
  }
});

const isActive = ref(false);
const tooltipBody = ref(null);

onBeforeUnmount(() => {
  document.removeEventListener('click', onOutsideClick);
});
onMounted(() => {
  document.addEventListener('click', onOutsideClick);
});

const onOutsideClick = async (e) => {
  if (tooltipBody.value === null || tooltipBody.value.contains(e.target)) {
    return;
  }

  isActive.value = false;
};

const handleTooltipClick = () => {
  isActive.value = !isActive.value;
}
</script>

<style lang="scss" scoped>
.tooltip-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tooltip-body {
  position: relative;
  z-index: 30;
  &.mb-full {
    @media screen and (max-width: 669px) {
      position: unset;
    }
  }
}
.tooltip-body-contents {
  position: absolute;
  top: 0;
  background-color: var(--tooltip-bg-color);
  margin: 10px 0 0 0;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(13, 0, 66, 0.15);
  z-index: 30;

  /* 정렬 */
  &.align-left {
    left: 0;
  }
  &.align-right {
    right: 0;
  }
  &.align-center{
    left: 50%;
    transform: translate(-50%);
  }

  /* 배경색 */
  &.background-color-default {
    --tooltip-bg-color: var(--mono-050);
  }
  &.background-color-white {
    --tooltip-bg-color: var(--mono-000);
  }

  &.mb-full {
    @media screen and (max-width: 669px) {
      width: 100%;
      top: auto;
    }
  }
}
</style>
