<template>
  <Modal :title="'필터'" :isDialog="true">
    <control>
      <template v-slot:label>크리에이터 구분</template>
      <div class="radios">
        <Radio
          :checked="sellerType === 'PERSON'"
          v-on:click="setVal('sellerType', 'PERSON')"
          class="radio-item"
          label="개인"
        />
        <Radio
          :checked="sellerType === 'COMPANY' || sellerType === 'COMPANY'"
          v-on:click="setVal('sellerType', 'COMPANY')"
          class="radio-item"
          label="사업자"
        />
        <Radio
          :checked="sellerType === 'FREE'"
          v-on:click="setVal('sellerType', 'FREE')"
          class="radio-item"
          label="무료"
        />
        <!-- <Radio
          :checked="sellerType === 'PERSONAL_COMPANY'"
          v-on:click="setVal('sellerType', 'PERSONAL_COMPANY')"
          class="radio-item"
          label="개인사업자"
        />
        <Radio
          :checked="sellerType === 'COMPANY'"
          v-on:click="setVal('sellerType', 'COMPANY')"
          class="radio-item"
          label="법인사업자"
        /> -->
      </div>
    </control>
    <!-- <control>
      <template v-slot:label>계약 구분</template>
      <div class="radios">
        <Radio
          :checked="directConnected === false"
          v-on:click="setVal('directConnected', false)"
          class="radio-item"
          label="약관동의"
        />
        <Radio
          :checked="directConnected === true"
          v-on:click="setVal('directConnected', true)"
          class="radio-item"
          label="직접계약"
        />
      </div>
    </control> -->
    <control>
      <template v-slot:label>크리에이터 등급</template>
      <div class="radios">
        <Radio
          :checked="grade === 'PRO'"
          v-on:click="setVal('grade', 'PRO')"
          class="radio-item"
          label="PRO"
        />
        <Radio
          :checked="grade === 'PARTNER'"
          v-on:click="setVal('grade', 'PARTNER')"
          class="radio-item"
          label="PARTNER"
        />
        <Radio
          :checked="grade === 'NORMAL'"
          v-on:click="setVal('grade', 'NORMAL')"
          class="radio-item"
          label="NORMAL"
        />
      </div>
    </control>
    <control>
      <template v-slot:label>관리자 구분 항목</template>
      <div class="radios">
        <Radio
          :checked="brand"
          v-on:click="setVal('brand', !brand)"
          class="radio-item"
          label="Brand"
        />
        <Radio
          :checked="streamer"
          v-on:click="setVal('streamer', !streamer)"
          class="radio-item"
          label="Streamer"
        />
      </div>
    </control>
    <control>
      <template v-slot:label>콘텐츠 품질 등급</template>
      <div class="radios">
        <Radio
          :checked="qualityGrade === 'STANDARD'"
          v-on:click="setVal('qualityGrade', 'STANDARD')"
          class="radio-item"
          label="STANDARD"
        />
        <Radio
          :checked="qualityGrade === 'SPECIAL'"
          v-on:click="setVal('qualityGrade', 'SPECIAL')"
          class="radio-item"
          label="SPECIAL"
        />
        <Radio
          :checked="qualityGrade === 'BAD'"
          v-on:click="setVal('qualityGrade', 'BAD')"
          class="radio-item"
          label="BAD"
        />
      </div>
    </control>
    <control>
      <template v-slot:label>등록기간</template>
      <div class="datepickers">
        <Datepicker
          v-model="begunAt"
          class="datepicker"
          placeholder="시작일 (시작시간)"
          :stretch="true"
        />
        <Datepicker
          v-model="endedAt"
          class="datepicker"
          placeholder="종료일 (종료시간)"
          :stretch="true"
        />
      </div>
    </control>
  </Modal>
</template>

<script>
import ModalFormControl from "@/components/modal/ModalFormControl";
import {Radio, Datepicker, Modal} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      sellerType: null,
      directConnected: null,
      grade: null,
      brand: null,
      streamer: null,
      qualityGrade: null,
      begunAt: null,
      endedAt: null,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    setVal(key, value) {
      this[key] = this[key] === value ? null : value;
    },
    getQuery() {
      const keys = [
        "sellerType",
        "directConnected",
        "grade",
        "brand",
        "streamer",
        "qualityGrade",
        "begunAt",
        "endedAt",
      ];
      return keys
        .filter((key) => {
          return this[key] != null;
        })
        .reduce((obj, key) => {
          // if (["begunAt", "endedAt"].includes(key)) {
          //   if (!this[key]) {
          //     return { ...obj };
          //   } else {
          //     return { ...obj, [key]: parseInt(this[key] / 1000) };
          //   }
          // }
          return { ...obj, [key]: this[key] };
        }, {});
    },
    reset() {
      this.sellerType = this.$route.query.sellerType
        ? this.$route.query.sellerType
        : null;
      this.directConnected = this.$route.query.directConnected
        ? JSON.parse(this.$route.query.directConnected)
        : null;
      this.grade = this.$route.query.grade
        ? this.$route.query.grade
        : null;
      this.brand = this.$route.query.brand
        ? this.$route.query.brand
        : null;
      this.streamer = this.$route.query.streamer
        ? this.$route.query.streamer
        : null;
      this.qualityGrade = this.$route.query.streaqualityGrademer
        ? this.$route.query.qualityGrade
        : null;
      this.begunAt = this.$route.query.begunAt
        ? parseInt(this.$route.query.begunAt) * 1000
        : null;
      this.endedAt = this.$route.query.endedAt
        ? parseInt(this.$route.query.endedAt) * 1000
        : null;
    },
  },
  components: {
    control: ModalFormControl,
    Modal,
    Radio,
    Datepicker,
  },
};
</script>

<style lang="scss" scoped>
.datepickers {
  margin-bottom: -10px;
}

.datepickers .datepicker {
  width: 100%;
  margin-bottom: 10px;
}

.radios {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -15px;
}

.radios .radio-item {
  margin: 0 20px 15px 0;
}
</style>
