<template>
  <div class="tr">
    <div class="td">
      <Checkbox 
        v-on:click="toggleCheck" 
        :checked="checked" 
        :isRemoveAll="true"
      />
    </div>
    <div class="td">작성일</div>
    <div class="td">메일 제목</div>
    <div class="td">게시자/작성자</div>
    <div class="td">수신자</div>
    <div class="td">발송일</div>
    <div class="td">발송상태</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>

<style lang="scss" scoped>
.tr.status {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  padding: 8px 0 0;
  border-top: 1px solid var(--mono-700);
}

.td-span {
  display: grid;
}
.td.span-4 {
  grid-column: span 4;
  text-align: center;
}
.td.span-4 > .td.span-4 {
  padding-bottom: 8px;
}

.td {
  font-weight: bold;
}
</style>
