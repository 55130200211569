import axios from "axios";
import { downloadZip } from "client-zip";

const fileTypes = [
  ".jpeg",
  ".gif",
  ".jpg",
  ".png",
  ".mpeg",
  ".mp4",
  ".mpga",
  ".mp3",
  ".wav",
];

const downloadBlob = (blob, fileName = "business_license") => {
  const objectURL = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = objectURL;
  link.download = `${fileName}.${blob.type.split("/")[1]}`;
  link.click();
  link.remove();
  return objectURL;
};

const createFile = (blob, filename = "main", url) => {
  console.log(blob, filename);
  if (blob.type.indexOf("image") > -1) {
    return new File([blob], `${filename}.${blob.type.replace("image/", "")}`, {
      type: blob.type,
    });
  } else if (blob.type.indexOf("audio") > -1) {
    return new File([blob], `${filename}.${blob.type.replace("audio/", "")}`, {
      type: blob.type,
    });
  } else {
    for (let fileType of fileTypes) {
      if (url.indexOf(fileType) > -1) {
        return new File([blob], `${filename}${fileType}`);
      }
    }
    return new File([blob], `${filename}`);
  }
};

const downloadZipFile = async (queue, fileName) => {
  const files = [];
  for (let i = 0; i < queue.length; i++) {
    let item = queue[i];
    let name = item.key;
    if (item.key == null) {
      name = i - 1;
    }
    const { data } = await axios.get(item.url, { responseType: "blob" });
    files.push(createFile(data, name, item.url));
  }
  const zip = await downloadZip(files).blob();
  const link = document.createElement("a");
  const objectURL = URL.createObjectURL(zip);
  link.href = objectURL;
  link.download = `${fileName}.zip`;
  link.click();
  link.remove();
  return objectURL;
};

const downloadDigitalAssetFiles = async (assetType, fileData, fileName) => {
  const assetData = {
    stickers: [
      { key: "main", url: fileData?.mainImageUrl },
      { key: "tab", url: fileData?.tabImageUrl },
      ...(fileData?.stickerImageUrls || []).map((url) => ({ url })),
    ],
    coloringSheets: [
      { key: "컬러링후", url: fileData?.afterImageUrl },
      { key: "컬러링전", url: fileData?.beforeImageUrl },
    ],
    audios: [
      { key: "음원커버", url: fileData?.coverImageUrl },
      { key: "오디오", url: fileData?.audioFileUrl },
    ],
    images: [{ key: "이미지", url: fileData?.imageUrl }],
    virtualAssets: [
      { key: "메인 이미지", url: fileData?.mainImageUrl },
      { key: "상세 이미지", url: fileData?.descImageUrl },
      ...(fileData?.sampleImageUrls || []).map((url, i) => ({
        key: `미리보기 이미지_${i + 1}`,
        url,
      })),
    ],
  };
  const queue = assetData[assetType] || [];

  return downloadZipFile(queue, fileName);
};

export {
  createFile,
  downloadDigitalAssetFiles,
  downloadBlob,
};
