<script>
import AdminLayout from "@/layouts/AdminLayout";
import AdminTitle from "@/layouts/AdminTitle";

import { Button, Badge } from "@ogqcorp/v-ui";
import SingleContentCardMain from "@/views/contents/main/SingleContentCardMain";
import ContentReviewLog from "@/views/contents/reviews/log/ContentReviewLog";

import {
  getCreatorGradeDisplayData,
  getContentStatusDisplayData,
} from "@/utils";

export default {
  data() {
    return {
      isLoading: false,
      unregisterRouterGuard: null,
      content: false,
      creator: false,

      contentKOTitle: "",
      contentKODesc: "",
      contentENTitle: "",
      contentENDesc: "",
    };
  },
  methods: {
    // Text Contents
    syncContentTextContents() {
      const KO = this.content.textContents.find((t) => t.lang === "KO");
      const EN = this.content.textContents.find((t) => t.lang === "EN");
      this.contentKOTitle = KO && KO.title ? KO.title : "";
      this.contentKODesc = KO && KO.description ? KO.description : "";
      this.contentENTitle = EN && EN.title ? EN.title : "";
      this.contentENDesc = EN && EN.description ? EN.description : "";
    },
    getEdittedTextContent() {
      const KO = this.content.textContents.find((t) => t.lang === "KO");
      const EN = this.content.textContents.find((t) => t.lang === "EN");
      return [
        {
          lang: "KO",
          title: this.contentKOTitle ? this.contentKOTitle : KO ? KO.title : "",
          description: this.contentKODesc
            ? this.contentKODesc
            : EN
            ? EN.description
            : "",
        },
        {
          lang: "EN",
          title: this.contentENTitle ? this.contentENTitle : EN ? EN.title : "",
          description: this.contentENDesc
            ? this.contentENDesc
            : EN
            ? EN.description
            : "",
        },
      ].filter((t) => t.title || t.description);
    },
    setTextContent({ value, refName }) {
      this[refName] = value;
    },
    // Basic Edits
    setImageType(imageType) {
      this.content.imageType = imageType;
    },
    setMood(mood) {
      this.content.mood = mood;
    },
    // Router Guards
    setupRouterGuard() {
      this.clearRouterGuard();
      this.unregisterRouterGuard = this.$router.beforeEach(this.onRouterBack);
    },
    clearRouterGuard() {
      if (this.unregisterRouterGuard == null) return;

      this.unregisterRouterGuard();
      this.unregisterRouterGuard = null;
    },
    async onRouterBack(to, from, next) {
      if (this.isEditted()) {
        const confirm = await this.$confirm({
          msg: "변경된 내용을 저장하시겠습니까?",
          cancelText: "아니요",
        });
        console.log("confirm", confirm);
        this.clearRouterGuard();
        if (confirm) {
          await this.saveChanges();
          next(false);
        } else {
          next();
        }
      } else {
        next();
      }
    },
    // Tags
    pushAdminTag(tag) {
      if (this.content.keywords.includes(tag)) return;
      this.content.keywords.push(tag);
    },
    removeAdminTag(tag) {
      this.content.keywords = this.content.keywords.filter((t) => tag !== t);
    },
    pushTag(tag) {
      if (this.content.tags.includes(tag)) return;
      this.content.tags.push(tag);
    },
    removeTag(tag) {
      this.content.tags = this.content.tags.filter((t) => tag !== t);
    },
    // 정말 지저분한 코드. 빨리 백엔드 수정이 필요함
    getUppercaseType(t = "stickers") {
      if (t === "stickers") {
        return "STICKER";
      } else if (t === "images") {
        return "STOCK_IMAGE";
      } else if (t === "coloringSheets") {
        return "COLORING_SHEET";
      } else if (t === "audios") {
        return "AUDIO";
      } else {
        return t;
      }
    },
    // Edit Check
    isEditted() {
      if (
        !this.content ||
        !this.$refs.categories ||
        !this.$refs.price ||
        !this.$refs.markets ||
        !this.$refs.price
      )
        return false;

      console.log(
        "!this.content.categoryIds.equals(this.$refs.categories.categoryIds) "
      );
      console.log(
        !this.content.categoryIds.equals(this.$refs.categories.categoryIds)
      );
      console.log(
        "!this.content.blacklistMarkets.equals(this.$refs.markets.blacklistMarkets) "
      );
      console.log(
        !this.content.blacklistMarkets.equals(
          this.$refs.markets.blacklistMarkets
        )
      );
      console.log(
        "!this.content.whitelistMarkets.equals(this.$refs.markets.whitelistMarkets) "
      );
      console.log(
        !this.content.whitelistMarkets.equals(
          this.$refs.markets.whitelistMarkets
        )
      );
      console.log("this.content.isFree !== this.$refs.price.isFree ");
      console.log(this.content.isFree !== this.$refs.price.isFree);
      console.log("this.content.grade !== this.$refs.quality.grade");
      console.log(this.content.grade !== this.$refs.quality.grade);

      return (
        !this.content.categoryIds.equals(this.$refs.categories.categoryIds) ||
        !this.content.blacklistMarkets.equals(
          this.$refs.markets.blacklistMarkets
        ) ||
        !this.content.whitelistMarkets.equals(
          this.$refs.markets.whitelistMarkets
        ) ||
        this.content.isFree !== this.$refs.price.isFree ||
        this.content.grade !== this.$refs.quality.grade
      );
    },
  },
  computed: {
    contentTitle() {
      if (this.content) {
        const KO = this.content.textContents.find((t) => t.lang === "KO");
        const EN = this.content.textContents.find((t) => t.lang === "KO");
        return KO?.title ? KO.title : EN?.title ? EN.title : "--";
      }
      return "--";
    },
    status() {
      return getContentStatusDisplayData(this.content.status);
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.creator.grade);
    },
    isContentOnSale() {
      return this.content?.status === "SALE";
    },
    contentType() {
      if (!this.content) return;
      switch (this.$route.params.type) {
        case "STICKER":
          return "stickers";
        case "COLORING_SHEET":
          return "coloringSheets";
        case "STOCK_IMAGE":
          return "images";
        case "AUDIO":
          return "audios";
        case "VIRTUAL_ASSETS":
          return "virtualAssets";
        default:
          return this.$route.params.category;
      }
    },
    contentId() {
      if (!this.content) return;
      switch (this.$route.params.type) {
        case "STICKER":
          return this.content.stickerId;
        case "COLORING_SHEET":
          return this.content.coloringSheetId;
        case "STOCK_IMAGE":
          return this.content.imageId;
        case "AUDIO":
          return this.content.audioId;
        case "VIRTUAL_ASSETS":
          return this.content.virtualAssetId;
        default:
          return null;
      }
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "admin-title": AdminTitle,
    Badge,
    Button,
    "card-main": SingleContentCardMain,
    "content-review-log": ContentReviewLog,
  },
};
</script>
