<template>
  <div class="table-v">
    <slot />
  </div>
</template>

<style lang="scss">
.table-v {
  width: 100%;
  border: solid 1px var(--mono-100);
}
</style>
