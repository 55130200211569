<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck(item.emailId)" :checked="checked" />
    </div>
    <div class="td">{{ $formatDate(item.sendAt * 1000) }}</div>
    <div class="td">
      <router-link
        :to="{
          name: 'MailManualEdit',
          params: {
            mailId: item.emailId,
          },
        }"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td">
      <Badge :color="status.color">
        {{ status.label }}
      </Badge>
    </div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
import { getEmailStatus } from "../../../../utils/index";

export default {
  extends: TableListRow,
  computed: {
    status() {
      return getEmailStatus(this.item.emailStatus);
    },
    disabled() {
      return this.item.emailStatus !== "RESERVED";
    },
  },
  methods: {
    onBeforeCheck() {
      console.log(this.item.emailStatus);
      return this.item.emailStatus === "RESERVED";
    },
    toggleCheck(id) {
      this.$emit("toggleCheck", id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
