<template>
  <AdminLayout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="전체"
          :active="!$route.query[statusKey]"
          v-on:click="setStatus()"
        />
        <Tab
          label="게시중"
          :active="$route.query[statusKey] === 'PUBLISHED'"
          v-on:click="setStatus('PUBLISHED')"
        />
        <Tab
          label="미게시"
          :active="$route.query[statusKey] === 'DISABLED'"
          v-on:click="setStatus('DISABLED')"
        />
        <Tab
          label="예약"
          :active="$route.query[statusKey] === 'RESERVED'"
          v-on:click="setStatus('RESERVED')"
        />
      </div>
    </template>
    <template v-slot:bodyHead>
      <TableAction>
        <template v-slot:actions>
          <Button size="small" :to="{ name: 'NoticeAdd' }"> 추가 </Button>
          <Button
            size="small"
            :disabled="!isAnyRowsChecked"
            v-on:click="deleteSelected"
          >
            선택 삭제
          </Button>
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
      </TableAction>
    </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <NoticeTableRowHead
            v-on:toggleCheck="toggleAllRows('noticeId')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <template v-else-if="list.length > 0">
          <div class="table-body">
            <NoticeTableRow
              v-for="item in list"
              v-on:toggleCheck="toggleCheckRow"
              :item="item"
              :checked="checkedRows.includes(item.noticeId)"
              :key="item.noticeId"
              :id="item.noticeId"
            />
          </div>
        </template>
      </PagePanel>
    </template>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import NoticeTableRow from "@/views/service/notice/NoticeTableRow";
import NoticeTableRowHead from "@/views/service/notice/NoticeTableRowHead";
import { Button } from "@ogqcorp/v-ui";
import { ref, toRefs } from "vue";
export default {
  setup() {
    const settings = {
      API_PATH: "/admin/notices",
      listKey: "elements",
      idKey: "noticeId",
      deleteMessageHTML: "선택하신 공지사항을<br>삭제하시겠습니까?",
      statusKey: "publishedStatus",
    };
    const checkedRows = ref([]);
    const isPaginated = ref(false);
    const toggleCheckCondition = (item) => {
      return ["RESERVED", "DISABLED"].includes(item.publishedStatus);
    };
    return {
      ...toRefs(settings),
      checkedRows,
      isPaginated,
      toggleCheckCondition,
    };
  },
  extends: TableView,
  components: {
    AdminLayout,
    NoticeTableRow,
    NoticeTableRowHead,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.725fr 2.5fr repeat(5, 0.5fr);
  align-items: center;
}
</style>
