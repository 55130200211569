<template>
  <card>
    <template v-slot:title>구분</template>
    <template v-slot:titleExtra> (택1) </template>

    <div class="categories">
      <div
        class="category-item"
        v-for="imageTypeItem in imageTypes"
        :key="imageTypeItem.imageType"
        v-on:click="setImageType(imageTypeItem.imageType)"
      >
        <Radio
          :checked="imageType === imageTypeItem.imageType"
          :label="imageTypeItem.krName"
          size="small"
        />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Radio } from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      imageTypes: [
        {
          imageType: "PICTURE",
          krName: "국내외 사진",
        },
        {
          imageType: "GRAPHIC",
          krName: "그래픽/합성",
        },
        {
          imageType: "ILLUSTRATE",
          krName: "일러스트",
        },
      ],
      imageType: null,
    };
  },
  methods: {
    setImageType(type) {
      this.imageType = type;
    },
  },
  computed: {},
  components: {
    card: SingleContentCard,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 14px;
  grid-gap: 16px 8px;
}

.category-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category-item label {
  margin-left: 6px;
  color: var(--mono-900);
}
</style>
