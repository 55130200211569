<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck(item.termId)" :checked="checked" />
    </div>
    <div class="td">{{ $formatDate(item.createdAt * 1000) }}</div>
    <div class="td">
      <router-link
        :to="{
          name: 'PolicyEdit',
          params: { policyId: item.termId },
        }"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td">
      <a :href="item.linkUrl" target="_blank">
        {{ item.linkUrl }}
      </a>
    </div>
    <div class="td">{{ item.publishedDate }}</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  font-size: 0.75rem;
}
</style>
