<template>
  <div class="sticker-main-list">
    <div
      class="sticker-main-item"
      :class="{
        dark: options && options.hasDarkmode,
        transparency: options && options.hasCheckeredBG,
      }"
    >
      <div
        class="sticker-image"
        v-on:click="openImageViewer(0)"
        :class="{
          darkmode: options && options.hasDarkmode && isDarkmode,
          transparency: options && options.hasCheckeredBG,
        }"
      >
        <img :src="$getImgUrl(mainImageUrl, 'o240_240')" alt="main.png" />
      </div>
      <div class="sticker-name">main.png</div>
    </div>
    <div
      class="sticker-main-item"
      :class="{
        dark: options && options.hasDarkmode,
        transparency: options && options.hasCheckeredBG,
      }"
    >
      <div
        class="sticker-image"
        v-on:click="openImageViewer(1)"
        :class="{
          darkmode: options && options.hasDarkmode && isDarkmode,
          transparency: options && options.hasCheckeredBG,
        }"
      >
        <img :src="$getImgUrl(tabImageUrl, 'o240_240')" alt="tab.png" />
      </div>
      <div class="sticker-name">tab.png</div>
    </div>

    <div class="sticker-darkmode" v-if="options && options.hasDarkmode">
      다크모드 대응 체크 <toggle v-model="isDarkmode" class="toggle" />
    </div>
  </div>
  <div class="sticker-grid">
    <div
      class="sticker-item"
      v-for="sticker, i in stickerImages"
      :key="sticker.stickerImageId"
    >
      <div
        class="sticker-image"
        v-on:click="openImageViewer(i+2)"
        :class="{
          darkmode: options && options.hasDarkmode && isDarkmode,
          transparency: options && options.hasCheckeredBG,
        }"
      >
        <img :src="$getImgUrl(sticker.imageUrl, 'o240_240')" :alt="sticker.name" />
      </div>
      <div class="sticker-name">
        {{ sticker.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {Toggle} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      isDarkmode: false,
    };
  },
  methods: {
    openImageViewer(index=0) {
      console.log("Open", index)
      this.$imageViewer.open({
        mode: 'stickers',
        index,
        images: [
          {url: this.$getImgUrl(this.mainImageUrl, 'o240_240'), name: 'main.png'},
          {url: this.$getImgUrl(this.tabImageUrl, 'o240_240'), name: 'tab.png'},
          ...this.stickerImages.map((s)=> {
            return {
              url: this.$getImgUrl(s.imageUrl, 'o240_240'),
              name: s.name
            }
          })
        ]
      })
    }
  },
  props: ["mainImageUrl", "tabImageUrl", "stickerImages", "options"],
  components: {
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
.sticker-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-gap: 45px;
  grid-gap: 14px 10px;
}

.sticker-image {
  // width: 62px;
  // height: 54px;
  width: 100%;
  height: 82px;
  display: flex;
  &.transparency {
    $s: 16px;
    background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
      $s};
  }

  img {
    width: auto;
    height: 100%;
    margin: auto;
    // object-fit: contain;
  }

  &.darkmode {
    background: var(--mono-900);
  }
}

.sticker-name {
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
  color: var(--mono-900);
}

.sticker-main-list {
  display: flex;
  margin: 0 0 40px;
}

.sticker-main-item {
  width: 120px;
  margin-right: 10px;
}

.sticker-darkmode {
  margin-left: auto;
  font-size: 16px;
  color: var(--mono-900);
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: 500;
  .toggle {
    margin-left: 10px;
  }
}
.sticker-main-item .sticker-image {
  border: 1px solid var(--primary-200);
  width: 120px;
  height: 100px;
  display: flex;
  img {
    width: 80px;
    height: 80px;
    margin: auto;
    object-fit: contain;
  }
}
.sticker-main-item.transparency .sticker-image {
  border-color: var(--mono-900);
}
.sticker-main-item .sticker-name {
  text-align: left;
  color: var(--mono-500);
  font-size: 14px;
  font-weight: 500;
}
.sticker-main-item.transparency .sticker-name {
  color: var(--mono-900);
}
</style>
