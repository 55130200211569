<template>  
  <EditorButton
    class="color-selector"
    v-on:click="toggleMenu"
    ref="button"
    :active="isOpen"
  >
    <span class="letter" :style="previewColorStyle">
      A
    </span>
    <template v-slot:root>
      <div class="menu" v-if="isOpen">
        <div class="colors">
          <div 
            v-for="color, i in colors"
            v-on:click="setColor(color)"
            class="preset-color-item"
            :key="i"
          >
            <span :style="{
              backgroundColor: color
            }"></span>
          </div>
          <div
            class="preset-color-item"
            v-on:click="setColor()"
          >
            <span></span>
          </div>
        </div>
      </div>
    </template>
  </EditorButton>
</template>

<script>
import EditorButton from '../Editor.Prose.Button'

export default {
  props: {
    currentColor: {
      type: String
    }
  },
  data() {
    return {
      isOpen: false,
      colors: [
        '#5c7cfa',
        '#339af0',
        '#ff6b6b',
        '#20c997',
        // '#fcc419',
        '#9A9A9A',
      ]
    }
  },
  beforeUnmount() {
    this.close()
  },
  methods: {
    toggleMenu() {
      this[this.isOpen ? 'close' : 'open']()
    },
    open() {
      this.isOpen = true
      document.addEventListener('click', this.clickOutside, false)
    },
    close() {
      this.isOpen = false
      document.removeEventListener('click', this.clickOutside, false)
    },
    clickOutside(e) {
      console.log('click outside')
      if (this.$refs.button.$el.contains(e.target)) {
        return
      }
      this.close()
    },
    setColor(hexString) {
      this.$emit('setColor', hexString)
    }
  },
  computed: {
    previewColorStyle() {
      if (this.currentColor) {
        return {
          'color': this.currentColor
        }
      }
      return ''
    }
  },
  components: {
    EditorButton
  }
}
</script>

<style lang="scss" scoped>
.color-selector {
  position: relative;
}

.color-selector .menu {
  position: absolute;
  top: 100%;
  left: 4px;
  background-color: white;
  border: 1px solid var(--input-border-color);
  padding: 4px;
  border-radius: 4px;
  z-index: 10;
  margin-top: -4px;
  box-shadow: 0px 4px 8px rgba(27, 34, 63, 0.1);
  // min-width: 140px;
}

.colors {
  display: grid;
  grid-template-columns: repeat(3, 18px);
  grid-gap: 4px;
}

.preset-color-item {
  cursor: pointer;
  width: 18px;
  height: 18px;
  span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: 200ms border-radius;
    background-color: var(--mono-900);
  }
  &:hover span {
    border-radius: 4px;
  }
}

span.letter {
  font-weight: 700;
}
</style>