<template>
  <div class="tr">
    <div class="td"></div>
    <div class="td">
      {{ $formatDate(item.createdAt) }}
    </div>
    <div class="td">
      <!-- Profile Image -->
      <avatar :url="item.profileImgUrl" />
    </div>
    <div class="td">
      <router-link
        class="username"
        :to="{
          name: 'SingleCreator',
          params: {
            creatorId: item.creatorId,
          },
        }"
      >
        {{ item.username }}
        <br />
        ({{ item.nickname }})
      </router-link>
    </div>
    <div class="td center">
      <!-- Grade -->
      <div class="badge-wrapper">
        <Badge :solid="true" :color="creatorGrade.color">
        {{ item.grade }}
        </Badge>
        <Badge v-if="item.brand" :solid="true" :color="'blue'">
          Brand
        </Badge>
        <Badge v-if="item.streamer" :solid="true" :color="'gray'">
          Streamer
        </Badge>
      </div>
    </div>
    <div class="td center">
      <Badge :solid="true" :color="contentQualityGrade.color">
      {{ item.qualityGrade }}
      </Badge>
    </div>
    <div class="td center">
      {{ sellerType }}
    </div>
    <div class="td center">
      <Badge :color="status.color">
        {{ status.label }}
      </Badge>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";
import Avatar from "@/components/user/avatar/Avatar";
import { getCreatorGradeDisplayData, getContentQualityGradeDisplayData } from "@/utils";

export default {
  props: ["item"],
  extends: TableListRow,
  computed: {
    sellerType() {
      switch (this.item.sellerType) {
        case "FREE":
          return "무료";
        case "PERSON":
          return "개인";
        case "COMPANY":
          return "법인사업자";
        case "PERSONAL_COMPANY":
          return "개인사업자";
        default:
          return this.item.sellerType;
      }
    },
    status() {
      switch (this.item.status) {
        case "ENABLED":
          return { label: "일반", color: "blue" };
        case "SALE_STOP":
          return { label: "판매중지", color: "yellow" };
        case "DELETED":
          return { label: "삭제됨", color: "blue" };
        case "EXITED":
          return { label: "탈퇴", color: "red" };
        default:
          return { label: this.item.status };
      }
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.item.grade);
    },
    contentQualityGrade() {
      return getContentQualityGradeDisplayData(this.item.qualityGrade)
    },
  },
  components: {
    avatar: Avatar,
  },
};
</script>

<style lang="scss" scoped>
.username {
  font-size: 10px;
}
.badge-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .badge {
    margin-bottom: 6px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
