<template>
  <h1>약관 변경 내역</h1>
  <TableAction>
    <template v-slot:actions>
      <Button class="Button" v-on:click="$emit('createRevision')">약관 추가</Button>
      <Button :disabled="!isAnyRowsChecked">선택 삭제</Button>
    </template>
    <template v-slot:pagination>
      <PaginationPages
        :pagesCount="pagesCountToDisplay"
        :currentPage="currentPage"
        :hasNextPage="hasNextPage"
        v-on:requestPage="loadPage"
        v-on:nextPage="gotoNextPage"
        v-on:previousPage="gotoPreviousPage"
      />
    </template>
  </TableAction>
  <PagePanel>
    <template v-if="list.length > 0">
      <div class="table-head">
        <table-row-head
          v-on:toggleCheck="toggleAllRows('no')"
          :checked="checkedRows.length > 0"
        />
      </div>
      <div class="table-body">
        <table-row
          v-for="item in list"
          v-on:toggleCheck="toggleCheckRow"
          v-on:selectRevision="selectRevision"
          :item="item"
          :checked="checkedRows.includes(item.no)"
          :key="item.no"
          :id="item.no"
        />
      </div>
    </template>
    <template v-if="!isLoading && list.length < 1">
      <div class="table-empty-list-message">데이터가 없습니다.</div>
    </template>
  </PagePanel>
</template>

<script>
import PolicyEditTableRow from "@/views/service/policy/editList/PolicyEditTableRow";
import PolicyEditTableRowHead from "@/views/service/policy/editList/PolicyEditTableRowHead";
import TableView from "@/components/table/TableView";
import {Button} from "@ogqcorp/v-ui";
// import axios from 'axios'

export default {
  data() {
    return {
      API_URL: `/admin/terms/${this.$route.params.policyId}/revisions`,
      listKey: "elements",
      idKey: "no",
    };
  },
  extends: TableView,
  methods: {
    selectRevision(item) {
      this.$emit("selectRevision", item);
    },
    refresh() {
      this.loadPage();
    },
  },
  emits: ["createRevision", "selectRevision"],
  components: {
    Button,
    "table-row": PolicyEditTableRow,
    "table-row-head": PolicyEditTableRowHead,
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions .Button {
  margin-right: 16px;
}

.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.65fr 1.4fr 1.25fr 0.65fr;
  align-items: center;
}
.table-body::v-deep .tr {
  padding: 24px 0;
  border-bottom: 1px solid var(--mono-100);
}
.table-head::v-deep .td,
.table-body::v-deep .td {
  padding: 0 8px;
  font-size: 0.8125rem;
  color: var(--mono-700);
}
.table-head::v-deep .td {
  font-size: 0.6875rem;
  font-weight: bold;
}

h1 {
  font-size: 18px;
  font-weight: 700;
  color: var(--mono-900);
  margin: 80px 0 30px;
}
</style>
