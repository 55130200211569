<template>
  <PagePanel
    title="크리에이터 정보"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <creator-profile
      :creator="creator"
      v-on:removeProfileImage="$emit('removeProfileImage')"
      v-on:removeCoverImage="$emit('removeCoverImage')"
    />

    <table-v>
      <table-v-row>
        <template v-slot:th>가입일자</template>
        <template v-slot:td>{{
          $formatDate(creator.joinedAt, "YYYY-MM-DD HH:mm:ss")
        }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>유저 ID</template>
        <template v-slot:td>{{ creator.username }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>닉네임</template>
        <template v-slot:td>{{ creator.nickname }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>콘텐츠 품질 등급</template>
        <template v-slot:td>
          <dropdown v-model="qualityGrade" :list="qualityGrades" />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>설정 크리에이터 등급</template>
        <template v-slot:td>
          {{ creatorGrade }}
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>별도 관리 항목(new)</template>
        <template v-slot:td>
          <Checkbox
            :checked="creator.brand"
            v-on:click="$emit('update:brand', !creator.brand)"
            label="Brand"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Checkbox
            :checked="creator.streamer"
            v-on:click="$emit('update:streamer', !creator.streamer)"
            label="Streamer"
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>이메일</template>
        <template v-slot:td>{{ creator.email || "--" }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>웹사이트</template>
        <template v-slot:td>{{ creator.website || "--" }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>한줄소개</template>
        <template v-slot:td>{{ creator.introduction || "--" }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>상세소개</template>
        <template v-slot:td>{{ creator.details || "--" }}</template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>소속 파트너</template>
        <template v-slot:td>
          <!-- <i-logo-naver />
          <i-logo-afreeca-tv />
          <i-logo-ogq /> -->
          <!--  -->
          <i-logo-naver v-if="partner === 'NOM partner'" />
          <i-logo-afreeca-tv v-else-if="partner === 'AOM partner'" />
          <i-logo-ogq v-else-if="partner === 'OGQ partner'" />
          <template v-else-if="partner && partner.partnerName">
            {{ partner.partnerName }}
          </template>
          <template v-else> -- </template>
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>메시지 수신설정</template>
        <template v-slot:td>
          <div class="radios">
            <Radio
              class="radio-item"
              size="small"
              label="수신"
              :checked="creator.agreeToReceiveSMS"
              v-on:click="$emit('update:agreeToReceiveSMS', true)"
            />
            <Radio
              class="radio-item"
              size="small"
              label="미수신"
              :checked="!creator.agreeToReceiveSMS"
              v-on:click="$emit('update:agreeToReceiveSMS', false)"
            />
          </div>
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>홍보성 이메일 수신설정</template>
        <template v-slot:td>
          <div class="radios">
            <Radio
              class="radio-item"
              size="small"
              label="수신"
              :checked="creator.agreeToReceiveEmail"
              v-on:click="$emit('update:agreeToReceiveEmail', true)"
            />
            <Radio
              class="radio-item"
              size="small"
              label="미수신"
              :checked="!creator.agreeToReceiveEmail"
              v-on:click="$emit('update:agreeToReceiveEmail', false)"
            />
          </div>
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";

import { Checkbox, Radio, PagePanel, Dropdown } from "@ogqcorp/v-ui";

import svgNaver from "@/components/svg/logos/svgNaver";
import svgAfreecaTV from "@/components/svg/logos/svgAfreecaTV";
import svgOGQ from "@/components/svg/logos/svgOGQ";

import SingleCreatorProfileTable from "../SingleCreatorProfileTable";
import { sellerTypes, creatorGrades, qualityGrades } from "../../creatorUtils";

export default {
  props: ["creator", "partners", "registrationFile"],
  data() {
    return {
      sellerTypes,
      qualityGrades,
      creatorGrades,
    };
  },
  inheritAttrs: false,
  methods: {
    imageSelected(imageData) {
      this.$emit("imageSelected", imageData);
    },
    imageDeleted() {
      this.$emit("imageDeleted");
    },
  },
  computed: {
    qualityGrade: {
      get() {
        return this.creator.qualityGrade;
      },
      set(value) {
        this.$emit("update:qualityGrade", value);
      },
    },
    creatorGrade: {
      get() {
        return this.creator.grade;
      },
      set(value) {
        this.$emit("update:creatorGrade", value);
      },
    },
    sellerType: {
      get() {
        return this.creator.sellerInfo.sellerType;
      },
      set(value) {
        this.$emit("update:sellerType", value);
      },
    },
    distributionRate: {
      get() {
        return this.creator.distributionRate;
      },
      set(value) {
        this.$emit("update:distributionRate", value);
      },
    },
    partner() {
      const partner = this.partners.find(
        (p) => p.partnerId === this.creator.partnerId
      );
      // console.log("PARTNERS", this.partners.map((p)=> [p.partnerId, p.partnerName]))
      // console.log("CURRENT PARTNER", partner)

      return partner ? partner.partnerName : false;
    },
  },
  components: {
    "table-v": TableV,
    "table-v-row": TableVRow,
    PagePanel,
    Checkbox,
    Radio,
    Dropdown,
    "i-logo-naver": svgNaver,
    "i-logo-afreeca-tv": svgAfreecaTV,
    "i-logo-ogq": svgOGQ,
    "creator-profile": SingleCreatorProfileTable,
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;

  &:deep(button) {
    margin-left: 10px;
  }
}

.radios {
  display: flex;
  width: 100%;
  flex: 1;
}
.radio-item {
  margin-right: 20px;
}

.form-registration {
  display: flex;
  width: 100%;
}

.uploader {
  margin-left: auto;
}

.direct:deep(.input-wrapper) {
  margin-left: 30px;
  width: 140px;
}
</style>
