<template>
  <admin-layout :isNavPathOnRight="true">
    <template v-slot:title v-if="creator">
      <admin-title class="title">
        {{ creator.nickname }}
        <Badge :color="creatorGrade.color" solid>
          {{ creator.grade }}
        </Badge>
        <Badge :color="contentQualityGrade.color" solid>
          {{ creator.qualityGrade }}
        </Badge>
        <Badge v-if="creator.brand" :color="'blue'" solid> Brand </Badge>
        <Badge v-if="creator.streamer" :color="'gray'" solid> Streamer </Badge>
      </admin-title>
    </template>
    <template v-slot:body v-if="creator">
      <div class="creator-action-container">
        <div class="pills">
          <Button
            :to="{
              name: 'Contents',
              params: {
                category: 'stickers',
              },
              query: {
                searchType: 'CREATOR_ID',
                query: creator.creatorId,
              },
            }"
            :rounded="true"
            :solid="true"
            color="primary"
            class="pill-item"
          >
            스티커
          </Button>
          <Button
            :to="{
              name: 'Contents',
              params: {
                category: 'images',
              },
              query: {
                searchType: 'CREATOR_ID',
                query: creator.creatorId,
              },
            }"
            :rounded="true"
            :solid="true"
            color="primary"
            class="pill-item"
          >
            이미지
          </Button>
          <Button
            :to="{
              name: 'Contents',
              params: {
                category: 'coloringSheets',
              },
              query: {
                searchType: 'CREATOR_ID',
                query: creator.creatorId,
              },
            }"
            :rounded="true"
            :solid="true"
            color="primary"
            class="pill-item"
          >
            컬러링시트
          </Button>
          <Button
            :to="{
              name: 'Contents',
              params: {
                category: 'audios',
              },
              query: {
                searchType: 'CREATOR_ID',
                query: creator.creatorId,
              },
            }"
            :rounded="true"
            :solid="true"
            color="primary"
            class="pill-item"
          >
            음원
          </Button>
          <Button
            :to="{
              name: 'Contents',
              params: {
                category: 'virtualAssets',
              },
              query: {
                searchType: 'CREATOR_ID',
                query: creator.creatorId,
              },
            }"
            :rounded="true"
            :solid="true"
            color="primary"
            class="pill-item"
          >
            방송용 에셋
          </Button>
        </div>
        <div class="msg-buttons">
          <Button>알림 메시지 보내기</Button>
          <Button>보낸 내역</Button>
        </div>
      </div>
      <ul class="tabs">
        <li
          v-for="tab in tabs"
          :key="tab.label"
          :class="{ active: tab.id === activeTab }"
          @click="handleTabClick(tab)"
        >
          {{ tab.label }}
        </li>
      </ul>
      <div class="sections">
        <!-- Tab 1: 크리에이터 정보 -->
        <!-- 기본 정보 -->
        <section-info
          :creator="creator"
          :partners="partners"
          v-on:imageSelected="setRegistrationImage"
          v-on:imageDeleted="removeRegistrationImage"
          v-on:removeProfileImage="removeProfileImage"
          v-on:removeCoverImage="removeCoverImage"
          v-model:creatorGrade.sync="creator.creatorGrade"
          v-model:qualityGrade.sync="creator.qualityGrade"
          v-model:brand.sync="creator.brand"
          v-model:streamer.sync="creator.streamer"
          v-model:agreeToReceiveSMS.sync="creator.agreeToReceiveSMS"
          v-model:agreeToReceiveEmail.sync="creator.agreeToReceiveEmail"
          v-model:directConnected.sync="creator.directConnected"
          v-if="creator && activeTab === 1"
        />
        <div class="buttons" v-if="creator && activeTab === 1">
          <Button
            color="primary"
            :loading="isLoading"
            v-on:click="saveCreator"
            class="save"
          >
            저장
          </Button>
        </div>
        <!-- 크리에이터 등급 관리 -->
        <section-grade-list
          v-if="creator && activeTab === 1"
          :creator="creator"
          :logs="creatorGradeChangeLogs"
          v-on:updatedGrade="updatedGrade"
        />
        <!-- 수수료 관리 -->
        <section-distribution
          v-if="creator && activeTab === 1"
          :currentDistributionRate="currentDistributionRate"
          :logs="creatorDistributionChangeLogs"
          @updatedDistributionRate="updatedDistributionRate"
          v-model:distributionRate="currentDistributionRate"
        />
        <!-- TODO: sync는 vue2 문법...  -->

        <!-- Tab 2: 인증 관리 -->
        <!-- 판매자 유형 -->
        <section-seller
          v-if="creator && activeTab === 2"
          :creator="creator"
          :sellerInfo="sellerInfo"
          :registrationFile="registrationFile"
          @imageSelected="setRegistrationImage"
          v-model:sellerType.sync="sellerInfo.sellerType"
          v-model:taxExempted.sync="sellerInfo.companyInfo.taxExempted"
        />
        <!-- 휴대폰 인증 -->
        <!-- v-if="creator && activeTab === 2 && sellerInfo.sellerType !== 'COMPANY'" -->
        <section-mobile
          v-if="creator && activeTab === 2"
          :creator="creator"
          :sellerInfo="sellerInfo"
          v-model:name.sync="sellerInfo.personIdentification.name"
          v-model:telecom.sync="sellerInfo.personIdentification.telecom"
          v-model:personPhoneNumber.sync="
            sellerInfo.personIdentification.personPhoneNumber
          "
          v-model:birthday.sync="sellerInfo.personIdentification.birthday"
          v-model:gender.sync="sellerInfo.personIdentification.gender"
        />
        <!-- 계좌 인증 -->
        <section-banking
          v-if="creator && activeTab === 2"
          :creator="creator"
          :sellerInfo="sellerInfo"
          v-model:bankCode.sync="sellerInfo.bankAccount.bankCode"
          v-model:bankName.sync="sellerInfo.bankAccount.bankName"
          v-model:accountNumber.sync="sellerInfo.bankAccount.accountNumber"
          v-model:ownerName.sync="sellerInfo.bankAccount.ownerName"
        />

        <!-- 실명 인증 -->
        <section-identity
          v-if="
            creator &&
            !['COMPANY', 'PERSONAL_COMPANY'].includes(sellerInfo.sellerType) &&
            activeTab === 2
          "
          :creator="creator"
          :sellerInfo="sellerInfo"
          v-model:name.sync="sellerInfo.residentRegistration.name"
          v-model:residentNumber.sync="
            sellerInfo.residentRegistration.residentNumber
          "
        />

        <!-- 사업자 정보 -->
        <section-company
          v-if="
            creator &&
            (sellerInfo.sellerType === 'COMPANY' ||
              sellerInfo.sellerType === 'PERSONAL_COMPANY') &&
            activeTab === 2
          "
          :creator="creator"
          :sellerInfo="sellerInfo"
          v-model:companyName.sync="sellerInfo.companyInfo.companyName"
          v-model:ceoName.sync="sellerInfo.companyInfo.ceoName"
          v-model:registrationNumber.sync="
            sellerInfo.companyInfo.registrationNumber
          "
          v-model:businessCategory.sync="
            sellerInfo.companyInfo.businessCategory
          "
          v-model:officeAddress.sync="sellerInfo.companyInfo.officeAddress"
        />
        <div class="buttons seller-info" v-if="creator && activeTab === 2">
          <Button v-on:click="handleAuthReset">인증 정보 초기화</Button>
          <Button
            color="primary"
            :loading="isLoading"
            v-on:click="saveCreatorSellerInfo"
            class="save"
          >
            저장
          </Button>
        </div>
      </div>
      <!-- Tab 3: 판매 마켓 관리 -->
      <div class="sections" v-if="activeTab === 3">
        <!-- 판매 마켓 관리 -->
        <section-market
          :_whitelistMarkets="marketInfo.whiteListMarkets"
          :_blacklistMarkets="marketInfo.blackListMarkets"
          ref="markets"
        />
        <div class="buttons">
          <Button
            color="primary"
            :loading="isLoading"
            v-on:click="saveCreatorMarkets"
            class="save"
          >
            저장
          </Button>
        </div>
      </div>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import AdminTitle from "@/layouts/AdminTitle";

import SingleCreatorSectionInfo from "./sections/SingleCreatorSectionInfo";
import SingleCreatorCreatorGrade from "./sections/SingleCreatorCreatorGrade.vue";
import SingleCreatorDistributionPolicies from "./sections/SingleCreatorDistributionPolicies.vue";
import SingleCreatorSectionMobile from "./sections/SingleCreatorSectionMobile";
import SingleCreatorSectionBanking from "./sections/SingleCreatorSectionBanking";
import SingleCreatorSectionIdentity from "./sections/SingleCreatorSectionIdentity";
import SingleCreatorSectionCompany from "./sections/SingleCreatorSectionCompany";
import SingleCreatorSectionMarket from "./sections/SingleCreatorSectionMarket";
import SingleCreatorSellerType from "./sections/SingleCreatorSellerType";
import {
  getCreatorGradeDisplayData,
  getContentQualityGradeDisplayData,
} from "@/utils";

import { Button, Badge } from "@ogqcorp/v-ui";

import { apiGet, apiPut, apiDelete } from "@/api/apiMethods";

export default {
  data() {
    return {
      isLoading: false,
      API_URL: `/admin/v3/creators/${this.$route.params.creatorId}`,
      SALES_MARKET_API_URL: `/dam/v5/admin/creators/${this.$route.params.creatorId}/sales-markets`,
      SELLER_INFO_API_URL: `/pmt/v5/admin/seller-infos/${this.$route.params.creatorId}`,
      PARTNER_API_URL: "/ptn/v5/admin/creators/partners",
      GRADE_LOGS_API_URL: `/ptn/v5/admin/creators/${this.$route.params.creatorId}/grade/changeLogs`,
      DISTRIBUTION_LOGS_API_URL: `/pmt/v5/admin/creators/${this.$route.params.creatorId}/distributionPolicies`,
      creator: false,
      sellerInfo: false,
      marketInfo: null,
      creatorGradeChangeLogs: [],
      creatorDistributionChangeLogs: [],
      currentDistributionRate: 0,
      partners: [],
      registrationFile: null,
      activeTab: 1,
      tabs: [
        {
          id: 1,
          label: "크리에이터 정보",
        },
        {
          id: 2,
          label: "인증 관리",
        },
        {
          id: 3,
          label: "판매 마켓 관리",
        },
      ],
      banks: [],
    };
  },
  created() {
    this.fetchCreatorById();
    this.getBanks();
  },
  methods: {
    async getBanks() {
      const res = await apiGet("/ptn/v5/banks");
      console.log("getbankkk", res);
      this.banks = res.data.map(({ bankCode, bankName }) => ({
        value: bankCode,
        label: bankName,
      }));
    },
    handleTabClick(tab) {
      this.activeTab = tab.id;
    },
    updatedGrade() {
      this.fetchCreatorById();
    },
    updatedDistributionRate() {
      this.fetchCreatorById();
    },
    removeProfileImage() {
      this.creator.profileImg = null;
      this.creator.profileImgUrl = null;
    },
    removeCoverImage() {
      this.creator.cover = null;
      this.creator.coverUrl = null;
    },
    setRegistrationImage(imageData) {
      this.registrationFile = imageData;
    },
    removeRegistrationImage() {
      this.registrationFile = null;
    },
    setDefaultSellerInfo(sellerInfo) {
      const defaults = {
        companyInfo: {
          companyName: "",
          ceoName: "",
          registrationNumber: "",
          businessCategory: "",
          officeAddress: "",
        },
        personIdentification: {
          name: "",
          telecom: "",
          personPhoneNumber: "",
          birth: "",
          gender: "",
        },
        bankAccount: {
          ownerName: "",
          accountNumber: "",
          bankName: "",
          bankCode: "",
        },
        residentRegistration: { name: "", residentNumber: "" },
      };
      Object.keys(defaults).forEach((key) => {
        sellerInfo[key] = sellerInfo[key] || defaults[key];
      });
      this.sellerInfo = sellerInfo;
    },
    async fetchCreatorById() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const [
          partnersResponse,
          sellerInfoResponse,
          creatorResponse,
          salesMarketResponse,
          gradeLogsResponse,
          distributionLogsResponse,
          distributionRateResponse,
        ] = await Promise.all([
          apiGet(this.PARTNER_API_URL),
          apiGet(this.SELLER_INFO_API_URL),
          apiGet(this.API_URL, {}, true),
          apiGet(this.SALES_MARKET_API_URL),
          apiGet(this.GRADE_LOGS_API_URL),
          apiGet(this.DISTRIBUTION_LOGS_API_URL),
          apiGet(this.DISTRIBUTION_LOGS_API_URL + "/current"),
        ]);
        this.partners = partnersResponse.data || [];
        const sellerInfo = sellerInfoResponse.data;
        this.setDefaultSellerInfo(sellerInfo);
        this.creator = creatorResponse.data[0];
        this.marketInfo = salesMarketResponse.data;
        this.creatorGradeChangeLogs = gradeLogsResponse.data.elements || [];
        this.creatorDistributionChangeLogs =
          distributionLogsResponse.data || [];
        this.currentDistributionRate = distributionRateResponse.data || 0;
      } catch (err) {
        console.log(err.response);
      } finally {
        this.isLoading = false;
      }
    },
    async handleAuthReset() {
      try {
        const confirm = await this.$confirm({
          msg: "크리에이터의 인증 정보를 초기화하시겠습니까?",
          confirmText: "확인",
        });
        if (confirm) {
          try {
            await apiDelete(`${this.SELLER_INFO_API_URL}/reset`);
            this.$alert({
              msg: "인증 정보 초기화 완료",
            });
            location.reload();
          } catch (err) {
            console.log(err.response);
          }
        }
      } catch (e) {
        this.$error(e);
      }
    },
    async saveCreatorMarkets() {
      if (this.isLoading) return;
      this.$confirm({
        msg: "저장하시겠습니까?",
        onConfirm: async () => {
          this.isLoading = true;
          const data = {
            blackListMarkets: this.$refs.markets.blacklistMarkets,
            whiteListMarkets: this.$refs.markets.whitelistMarkets,
          };
          try {
            await apiPut(this.SALES_MARKET_API_URL, data);
            await this.fetchCreatorById();
            this.$confirm({
              msg: "크리에이터 정보를 성공적으로 저장했습니다.",
            });
          } catch (err) {
            console.log(err.response);
            this.$error();
          } finally {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          }
        },
      });
    },
    async saveCreatorSellerInfo() {
      if (this.isLoading) return;
      const isCompany = ["COMPANY", "PERSONAL_COMPANY"].includes(
        this.sellerInfo.sellerType
      );
      this.$confirm({
        msg: "저장하시겠습니까?",
        onConfirm: async () => {
          this.isLoading = true;
          const data = {
            sellerType: this.sellerInfo.sellerType,
            taxExempted: this.sellerInfo.companyInfo.taxExempted,
            name: this.sellerInfo.personIdentification.name,
            telecom: this.sellerInfo.personIdentification.telecom,
            personPhoneNumber:
              this.sellerInfo.personIdentification.personPhoneNumber,
            birth: this.sellerInfo.personIdentification.birthday,
            gender: this.sellerInfo.personIdentification.gender,
            bankName: this.sellerInfo.bankAccount.bankName,
            ownerName: this.sellerInfo.bankAccount.ownerName,
            bankCode: this.sellerInfo.bankAccount.bankCode,
            accountNumber: this.sellerInfo.bankAccount.accountNumber,
            // Company
            companyName: isCompany
              ? this.sellerInfo.companyInfo.companyName
              : null,
            ceoName: isCompany ? this.sellerInfo.companyInfo.ceoName : null,
            officeAddress: isCompany
              ? this.sellerInfo.companyInfo.officeAddress
              : null,
            registrationNumber: isCompany
              ? this.sellerInfo.companyInfo.registrationNumber
              : null,
            businessCategory: isCompany
              ? this.sellerInfo.companyInfo.businessCategory
              : null,
            registrationFile:
              isCompany && this.registrationFile
                ? this.registrationFile.dataUrl
                : null,
            registrationFileName:
              isCompany && this.registrationFile
                ? this.registrationFile.file.name
                : null,
            // Resident
            residentRegistrationName: !isCompany
              ? this.sellerInfo.residentRegistration.name
              : null,
            residentRegistrationNumber: !isCompany
              ? this.sellerInfo.residentRegistration.residentNumber
              : null,
          };
          console.log(
            "Save Creator SellerInfo as",
            JSON.parse(JSON.stringify(data))
          );
          try {
            await apiPut(this.SELLER_INFO_API_URL, data);
            await this.fetchCreatorById();
            this.$confirm({
              msg: "크리에이터 인증 정보를 성공적으로 저장했습니다.",
            });
          } catch (err) {
            console.log(err.response);
            this.$error();
          } finally {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          }
        },
      });
    },
    async saveCreator() {
      if (this.isLoading) return;
      this.$confirm({
        msg: "설정 항목을 다시 한번 확인해주세요.",
        title: "저장하시겠습니까?",
        onConfirm: async () => {
          this.isLoading = true;
          const data = {
            partnerId: this.creator.partnerId,
            sellerType: this.sellerInfo.sellerType,
            qualityGrade: this.creator.qualityGrade,
            brand: this.creator.brand,
            streamer: this.creator.streamer,
            directConnected: this.creator.directConnected,
            website: this.creator.website,
            introduction: this.creator.introduction,
            details: this.creator.details,
            cover: this.creator.cover,
            profileImg: this.creator.profileImg,
            agreeToReceiveSMS: this.creator.agreeToReceiveSMS,
            agreeToReceiveEmail: this.creator.agreeToReceiveEmail,
          };
          console.log("Save Creator as", JSON.parse(JSON.stringify(data)));
          try {
            await apiPut(this.API_URL, data, true);
            this.$confirm({
              msg: "크리에이터 정보를 성공적으로 저장했습니다.",
            });
            await this.fetchCreatorById();
          } catch (err) {
            console.log(err.response);
            this.$error();
          } finally {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          }
        },
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/getCurrentUser"];
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.creator.grade);
    },
    contentQualityGrade() {
      return getContentQualityGradeDisplayData(this.creator.qualityGrade);
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "admin-title": AdminTitle,
    "section-info": SingleCreatorSectionInfo,
    "section-grade-list": SingleCreatorCreatorGrade,
    "section-distribution": SingleCreatorDistributionPolicies,
    "section-mobile": SingleCreatorSectionMobile,
    "section-banking": SingleCreatorSectionBanking,
    "section-identity": SingleCreatorSectionIdentity,
    "section-company": SingleCreatorSectionCompany,
    "section-market": SingleCreatorSectionMarket,
    "section-seller": SingleCreatorSellerType,
    Badge,
    Button,
  },
};
</script>

<style lang="scss" scoped>
// .pills {
//   margin: 40px 0 0;
// }
.creator-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .msg-buttons {
    button {
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.tabs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  list-style: none;
  li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    max-height: 50px;
    padding: 12px 0 8px;
    margin-left: 50px;
    &:hover,
    &.active {
      cursor: pointer;
      font-weight: bold;
      border-bottom: 3px solid #000;
    }
  }
}

.pill-item {
  margin: 0 10px 0 0;
}

.panel {
  padding: 40px !important;
}

.buttons {
  display: flex;
  margin-top: 60px;
  .save {
    width: 200px;
    margin-left: auto;
  }
  &.seller-info {
    justify-content: flex-end;
    .save {
      margin-left: 30px;
    }
  }
}
</style>
