<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="게시여부">
          <toggle v-model="activated" />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시위치">
          <Dropdown
            v-model="position"
            placeholder="미선택"
            :list="positionArr"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시시간">
          <div class="group">
            <Datepicker
              v-model="begunAt"
              class="datepicker-item"
              placeholder="시작일 (시작시간)"
            />
            <span class="dash">-</span>
            <Datepicker
              v-model="endedAt"
              class="datepicker-item"
              placeholder="종료일 (종료시간)"
            />
          </div>
        </PagePanelFormRow>
      </PagePanel>

      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow
          label="PC 이미지"
          :alignTop="true"
          class="page-panel pc-image"
        >
          <Tooltip
            v-model="isActiveTooltip"
            alignValue="left"
            class="tooltip pc-image"
          >
            <template v-slot:title>
              <svgExclamation />
            </template>
            <template v-slot:body>
              <div class="tooltip-box-wrapper">
                <span class="tooltip-box-title">[OCS 배너 사이즈]</span>
                <div class="tooltip-box-section">
                  <span class="tooltip-box-sub-title">(1) 대시보드</span>
                  <div class="tooltip-box-description-wrapper">
                    <span class="tooltip-box-description"
                      >- PC: 1440 x 180px</span
                    >
                    <span class="tooltip-box-description"
                      >- Mobile: 350 x 200px</span
                    >
                  </div>
                </div>
                <div class="tooltip-box-section">
                  <span class="tooltip-box-sub-title">(2) 랜딩 페이지</span>
                  <div class="tooltip-box-description-wrapper">
                    <span class="tooltip-box-description"
                      >- PC: 1020 x 680px</span
                    >
                    <span class="tooltip-box-description"
                      >- Mobile: 660 x 440px</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </Tooltip>
          <image-uploader
            v-on:imageSelected="addBannerImage($event, 'pc')"
            v-on:imageDeleted="removeBannerImage('pc')"
            :defaultImage="isEdit ? originalData.pcImageUrl : false"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="이동 URL">
          <InputText
            v-model.trim="pcLink"
            placeholder="URL을 입력해주세요"
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="Mobile 이미지" :alignTop="true">
          <image-uploader
            v-on:imageSelected="addBannerImage($event, 'mobile')"
            v-on:imageDeleted="removeBannerImage('mobile')"
            :defaultImage="isEdit ? originalData.mobileImageUrl : false"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="이동 URL">
          <InputText
            v-model.trim="mobileLink"
            placeholder="URL을 입력해주세요"
            class="stretch"
          />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button :disabled="true">미리보기</Button>
      <Button color="primary" v-on:click="saveChanges" :loading="isLoading">
        저장
      </Button>
      <Button v-on:click="$router.push(prevRoute)">취소</Button>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import ImageUploader from "@/components/uploader/ImageUploader";
import Tooltip from "@/components/tooltip/Tooltip.vue";
import {
  Button,
  Dropdown,
  Toggle,
  InputText,
  PagePanel,
  PagePanelFormRow,
  Datepicker,
} from "@ogqcorp/v-ui";
import svgExclamation from "@/components/svg/icons/svgExclamation.vue";
import axios from "axios";
import { apiGet, apiPost, apiPut } from "../../../api/apiMethods";

export default {
  data() {
    return {
      // View Data
      isEdit: false,
      isLoading: false,
      isActiveTooltip: false,
      originalData: false,
      prevRoute: null,
      // Data
      activated: false,
      position: false,
      positionArr: [
        { value: "DASHBOARD", label: "대시보드" },
        { value: "LANDING_TOP", label: "랜딩 페이지" },
      ],
      begunAt: false,
      endedAt: false,
      title: "",
      pc: false,
      pcLink: "",
      mobile: false,
      mobileLink: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "Banner") {
        vm.prevRoute = from;
      } else {
        vm.prevRoute = { name: "Banner" };
      }
    });
  },
  created() {
    if (this.$route.name === "BannerEdit") {
      this.isEdit = true;
      this.getBannerData();
    }
  },
  methods: {
    async getBannerData() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const { data } = await apiGet(
          `/admin/banners/${this.$route.params.bannerId}`
        );
        console.log(data);
        this.originalData = data;
        this.title = data.title;
        this.position = data.position;
        this.pcLink = data.pcLink || "";
        this.mobileLink = data.mobileLink || "";
        this.begunAt = new Date(data.begunAt * 1000);
        this.endedAt = new Date(data.endedAt * 1000);
        this.activated = data.activated;
      } catch (err) {
        console.dir(err);
      } finally {
        this.isLoading = false;
      }
    },
    addBannerImage(imageData, type) {
      this[type] = imageData.file;
    },
    removeBannerImage(type) {
      this[type] = false;
    },
    saveChanges() {
      if (this.isEdit) {
        this.editBanner();
      } else {
        this.submitBanner();
      }
    },
    async editBanner() {
      if (this.isLoading) return;

      if (!this.position) return;
      if (!this.begunAt || !this.endedAt) return;
      if (this.title.length < 1) return;

      this.isLoading = true;
      const updateBanner = {
        title: this.title,
        position: this.position,
        begunAt: this.$formatTime(this.begunAt.getTime()),
        endedAt: this.$formatTime(this.endedAt.getTime()),
        pcLink: this.pcLink,
        mobileLink: this.mobileLink,
        activated: this.activated,
      };
      const formData = new FormData();
      if (this.pc) {
        formData.append("pc", this.pc);
      }
      if (this.mobile) {
        formData.append("mobile", this.mobile);
      }
      const json = JSON.stringify(updateBanner);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("updateBanner", blob);

      try {
        await apiPut(`/admin/banners/${this.$route.params.bannerId}`, formData);
        this.$router.push({
          name: "Banner",
        });
      } catch (err) {
        console.log(err.response);
      } finally {
        this.isLoading = false;
      }
    },
    async submitBanner() {
      if (this.isLoading) return;

      if (!this.position) return;
      if (!this.begunAt || !this.endedAt) return;
      if (this.title.length < 1) return;

      this.isLoading = true;
      const bannerData = {
        title: this.title,
        position: this.position,
        begunAt: this.$formatTime(this.begunAt.getTime()),
        endedAt: this.$formatTime(this.endedAt.getTime()),
        pcLink: this.pcLink,
        mobileLink: this.mobileLink,
        activated: this.activated,
      };
      const formData = new FormData();
      formData.append("pc", this.pc);
      formData.append("mobile", this.mobile);
      const json = JSON.stringify(bannerData);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("bannerData", blob);

      try {
        await apiPost("/admin/banners", formData);
        this.$router.push({
          name: "Banner",
        });
      } catch (err) {
        console.log(err.response);
      } finally {
        this.isLoading = false;
      }
    },
  },

  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    Datepicker,
    Button,
    Dropdown,
    Tooltip,
    "image-uploader": ImageUploader,
    Toggle,
    svgExclamation,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.dropdown {
  width: 364px;
}

.group .radio-wrapper {
  margin-right: 30px;
}

// tooltip
.page-panel.pc-image {
  position: relative;
}

.tooltip.pc-image {
  position: absolute;
  left: 40px;
  top: 50px;
}

.tooltip-box-wrapper {
  width: 280px;
  padding: 20px;
}

span.tooltip-box-title {
  font-weight: 700;
  font-size: 16px;
}

.tooltip-box-section {
  width: 100%;
  margin: 10px 0 0;
}

span.tooltip-box-sub-title {
  font-weight: 500;
  font-size: 14px;
}

.tooltip-box-description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0 0;
}

span.tooltip-box-description {
  color: var(--mono-800);
  font-weight: 400;
  font-size: 12px;
}
</style>
