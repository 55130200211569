<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="스티커"
          v-on:click="setContentType('STICKER')"
          :active="$route.params.type === 'STICKER'"
        />
        <Tab
          label="이미지"
          v-on:click="setContentType('STOCK_IMAGE')"
          :active="$route.params.type === 'STOCK_IMAGE'"
        />
        <Tab
          label="컬러링시트"
          v-on:click="setContentType('COLORING_SHEET')"
          :active="$route.params.type === 'COLORING_SHEET'"
        />
        <Tab
          label="음원"
          v-on:click="setContentType('AUDIO')"
          :active="$route.params.type === 'AUDIO'"
        />
        <Tab
          label="방송용 에셋"
          v-on:click="setContentType('VIRTUAL_ASSETS')"
          :active="$route.params.type === 'VIRTUAL_ASSETS'"
        />
      </div>
    </template>

    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Dropdown
            v-model="salesType"
            placeholder="판매 형태"
            :list="salesTypeArr"
            v-if="
              userIsInRole(['ADMIN', 'OCS_OPERATOR']) &&
              $route.params.type === 'STICKER'
            "
          />
          <Dropdown
            v-model="status"
            placeholder="콘텐츠 상태"
            :list="statusArr"
            v-if="userIsInRole(['ADMIN', 'OCS_OPERATOR'])"
          />
          <Dropdown
            v-model="step"
            placeholder="콘텐츠 상태"
            :list="stepsArr"
            v-if="userIsInRole('REVIEWER')"
          />
          <Dropdown
            v-model="qualityGrade"
            placeholder="퀄리티"
            :list="qualityGradeArr"
          />
          <Button
            color="primary"
            v-if="userIsInRole(['REVIEWER']) && list.length < 1"
            v-on:click="requestReviewStart"
          >
            배정받기
          </Button>
          <Button
            color="primary"
            v-else-if="userIsInRole(['REVIEWER']) && list.length > 0"
            :to="{
              name: 'SingleContentReview',
              params: {
                type: $route.params.type,
                reviewId: list[0].reviewId,
              },
            }"
          >
            심사 시작
          </Button>
        </template>
        <template v-slot:search>
          <form
            v-on:submit.prevent="search"
            v-if="userIsInRole(['ADMIN', 'OCS_OPERATOR'])"
          >
            <Dropdown
              v-model="searchType"
              placeholder="검색 옵션"
              :list="searchTypeArr"
              class="dropdown"
              :cancelOnReselect="false"
            />
            <InputSearch
              v-model.trim="searchQuery"
              :placeholder="searchTypePlaceholders[searchType]"
            />
          </form>
        </template>
      </TableFilter>
    </template>

    <template v-slot:body>
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1">
          <template v-slot:message v-if="userIsInRole(['REVIEWER'])">
            배정받기 버튼을 눌러 심사를 시작해주세요!
          </template>
        </TableEmpty>
        <div class="table-body" v-else-if="list.length > 0">
          <table-row
            v-for="(item, i) in list"
            :key="i"
            :item="item"
            :id="item.reviewId"
          />
        </div>
      </PagePanel>
      <br />
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";

import TableView from "@/components/table/TableView";
import { Button, InputSearch } from "@ogqcorp/v-ui";
import ContentReviewRowHead from "./ContentReviewRowHead";
import ContentReviewRow from "./ContentReviewRow";
import { getReviewStatusListData } from "@/utils";
import { apiPost } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      isRequestingStart: false,
      searchQuery: "",
      contentType: "sticker",
      listKey: "elements",
      // Search
      status: this.$route.query.status ? this.$route.query.status : false,
      statusArr: [
        { value: false, label: "전체" },
        ...getReviewStatusListData(),
      ].filter(({ value }) => value !== "REVIEW2"),
      step: false,
      stepsArr: [
        { value: false, label: "전체" },
        { value: "ASSIGNED", label: "심사중" },
        { value: "PAUSED", label: "심사보류" },
        { value: "DONE", label: "심사완료" },
      ],
      salesType: false,
      salesTypeArr: [
        { value: false, label: "전체" },
        { value: "SINGLE", label: "일반 판매" },
        { value: "SUBSCRIPTION", label: "구독 판매" },
      ],
      qualityGrade: this.$route.query.qualityGrade || false,
      qualityGradeArr: [
        { value: false, label: "전체" },
        { value: "STANDARD", label: "STANDARD" },
        { value: "SPECIAL", label: "SPECIAL" },
        { value: "BAD", label: "BAD" },
      ],
      searchType: "NICKNAME",
      searchTypeArr: [
        { value: "TITLE", label: "콘텐츠명" },
        { value: "NICKNAME", label: "닉네임" },
        { value: "CREATOR_ID", label: "크리에이터 아이디" },
        { value: "EMAIL", label: "이메일" },
      ],
      searchTypePlaceholders: {
        NICKNAME: "닉네임을 검색해주세요.",
        TITLE: "콘텐츠명을 검색해주세요.",
        CREATOR_ID: "크리에이터 아이디를 검색해주세요.",
        EMAIL: "이메일을 검색해주세요.",
      },
    };
  },
  extends: TableView,
  watch: {
    status() {
      this.setFilters();
    },
    step() {
      this.setFilters();
    },
    qualityGrade() {
      this.setFilters();
    },
    salesType() {
      this.setFilters();
    },
    $route(n) {
      if (n.name !== this.$route.name) return;
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      this[isAdmin ? "status" : "step"] =
        n.query[isAdmin ? "status" : "step"] || false;
      this.qualityGrade = n.query.qualityGrade || false;
    },
  },
  methods: {
    onAfterCreated() {
      this.API_URL = "/dam/v5/admin/reviews";
      this.loadPage(
        {
          type: this.$route.params.type,
          page: parseInt(this.$route.params.page),
        },
        { ...this.$route.query },
        false
      );
    },
    async requestReviewStart() {
      if (this.isRequestingStart) return;
      this.isRequestingStart = true;

      try {
        await apiPost(`/dam/v5/admin/reviews?type=${this.$route.params.type}`);
        await this.loadPage(
          { ...this.$route.params, ...{ page: 1 } },
          null,
          true
        );

        if (this.list.length < 1) {
          await this.$alert({
            msg: "배정받을 콘텐츠가 존재하지 않습니다.",
          });
        }
      } catch (err) {
        if (err?.response?.status === 409) {
          await this.$error("이미 배정을 받은 상태입니다.");
        } else {
          await this.$error();
        }
        await this.loadPage(
          { ...this.$route.params, ...{ page: 1 } },
          null,
          true
        );
      } finally {
        this.isRequestingStart = false;
      }
    },
    async setFilters() {
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);

      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
        },
        {
          ...this.$route.query,
          ...{
            [isAdmin ? "status" : "step"]: isAdmin
              ? this.status || undefined
              : this.step || undefined,
          },
          ...{
            qualityGrade: this.qualityGrade || undefined,
            salesType: this.salesType || undefined,
          },
        },
        true
      );
    },
    async setContentType(type) {
      if (this.$route.params.type === type) return;
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1, type },
        },
        null,
        true
      );
    },
    isContentType(type) {
      return this.$route.query && this.$route.query.artworkType === type;
    },
  },
  computed: {
    isContentSticker() {
      return this.contentType === "sticker";
    },
    isContentImage() {
      return this.contentType === "image";
    },
    isContentColoring() {
      return this.contentType === "coloring";
    },
    isContentAudio() {
      return this.contentType === "audio";
    },
    isContentTemplate() {
      return this.contentType === "template";
    },
  },
  components: {
    "admin-layout": AdminLayout,
    InputSearch,
    "table-row-head": ContentReviewRowHead,
    "table-row": ContentReviewRow,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 40px 0.75fr 0.75fr 1.5fr 0.75fr 1fr 1fr 0.75fr 1.1fr;
  align-items: center;
  &.sticker {
    grid-template-columns: 40px 0.75fr 0.75fr 1.5fr 0.75fr 1fr 1fr 1fr 0.75fr 1.1fr;
  }
}

.dropdown {
  width: 170px;
}
</style>
