<template>
  <svg
    width="52"
    height="16"
    viewBox="0 0 52 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="16" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4933 9.1049L19.1429 6.46667H19.2189L19.8696 9.1049H18.4933ZM20.7564 4H17.6047L14.6959 11.9997H17.7705L18.0389 10.9538H20.3228L20.5917 11.9997H23.6651L20.7564 4ZM37.2757 4.00024H31.6725V11.9999H37.3627V9.91626H34.4096V8.99136H37.05V7.02369H34.4096V6.08267H37.2757V4.00024ZM11.3389 4.00032V8.03489L8.77225 4.00032H6V12H8.76361V7.96231L11.3291 12H14.1025V4.00032H11.3389ZM42.4074 7.37705C42.2456 7.51848 41.9358 7.58796 41.6784 7.58796H41.0852V6.01172H41.6668C41.9461 6.01172 42.264 6.0843 42.4184 6.22697C42.5721 6.37151 42.6441 6.56629 42.6441 6.81504C42.6441 7.05076 42.5687 7.23872 42.4074 7.37705ZM43.7646 9.00735C44.7464 8.71208 45.3805 7.94846 45.3805 6.72209C45.3805 4.662 43.8625 4.00073 41.8624 4.00073H38.4089V11.9998H41.1731V9.50113L42.8236 11.9998H46.0001L43.7646 9.00735ZM26.7026 8.82201L28.0652 4.00024H31.0798L27.969 11.9999H25.3591L22.25 4.00024H25.2647L26.6261 8.82201H26.7026Z"
      fill="#00C73C"
    />
  </svg>
</template>
