<template>

  <form 
    v-on:submit.prevent
    ref="form"
  >
    <draggable 
      v-model="stickers" 
      :animation="240"
      :removeCloneOnHide="true"
      class="sticker-grid"
      item-key="index"
      v-on:change="updateStickerIndice"
      v-on:move="updateStickerIndice"
      v-on:drag="isRepositioning = true"
      v-on:drop="isRepositioning = false"
    >
      <template #item="{element}">
        <div class="sticker-item-wrapper" :key="element.index">
          <StickerItem 
            :sticker="element.sticker"
            :index="element.index"
            :key="element.index"
            :highlightRange="highlightRange"
            :isRepositioning="isRepositioning"
            v-on:setStickerFileAtIndex="setStickerFileAtIndex"
            v-on:highlightItems="setHighlightItems"
          />
        </div>
      </template>
    </draggable>
  </form>
</template>

<script>
import {readFileAsDataUrl} from '@/utils/files'
import Toast from '@/components/toast'
import StickerItem from './StickerItem'
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      isInputLoading: false,
      stickers: Array(24).fill(1).map((n, i)=> {return {index: i, sticker: null}}),
      isDragActive: false,
      isLoadingSingle: false,
      isDragging: false,
      isRepositioning: false,
      highlightRange: []
    }
  },
  watch: {
    stickers(n) {
      this.$emit('stickerUpdated', n)
    }
  },
  methods: {
    updateStickerIndice() {
      console.log("change")
      this.stickers = this.stickers.map((o, i)=> {
        return {
          ...o,
          index: i
        }
      })
    },
    onDrop(e) {
      console.log('onDrop', e);     
    },
    setDropActive() {
      // console.log('setDropActive', e);     
      this.isDragActive = true
    },
    setDropInactive() {
      // console.log('setDropInactive', e);     
      console.log("drop inactive")
      this.isDragActive = false
    },
    setHighlightItems({index, length=0}){
      if (index == null) {
        this.highlightRange = []
        return
      }
      this.highlightRange = this.getRangeFromIndexAndLen(index, length)
    },
    getRangeFromIndexAndLen(index, len, max=23) {
      let start = index
      let end = index + len - 1
      let overflownAmount = end > max ? end % max : 0
      end = Math.min(end, max)
      let nums = []
      for (let i=start; i <= end; i++) {
        nums.push(i)
      }
      for (let i=0; i < overflownAmount; i++) {
        nums.push(i)
      }
      return nums
    },
    async setStickerFileAtIndex({files, index}) {
      const range = this.getRangeFromIndexAndLen(index, files.length)
      Array.from(files).forEach(async (file, i)=> {
        const result = await readFileAsDataUrl(file)

        if (result.meta.dimensions.width !== 740 || result.meta.dimensions.height !== 640) {
          Toast(`${result.name} - 스티커의 가로세로 크기는 740px * 640px이어야 합니다.`, {type: 'error'})
        } else {
          this.stickers.splice(range[i], 1, {
            ...this.stickers[range[i]],
            ...{sticker: result}
          })
        }

        console.log(`File ${i}`, file, range[i], result)
      })
      console.log("DONE")
      this.highlightRange = []
      this.$emit('stickerUpdated', this.stickers)
    },
  },
  components: {
    StickerItem,
    draggable
  }
}
</script>

<style lang="scss" scoped>

.sticker-grid {
  --sticker-item-size: 160px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(6, var(--sticker-item-size));
  width: calc(var(--sticker-item-size) * 6);
  z-index: 3;
  $s: 16px;
  background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
    $s};
  outline: 1px solid var(--mono-400);

}

.sticker-item-wrapper {
  display: inline-block;
  width: var(--sticker-item-size);
  height: var(--sticker-item-size);
  // outline: 1px solid red;
}

.sticker-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

</style>