<template>
  <div class="tr head">
    <div class="td">
      <Checkbox v-on:click="toggleCheck" :checked="checked" :isRemoveAll="true" />
    </div>
    <div class="td">NO</div>
    <div class="td">이름</div>
    <div class="td">이메일</div>
    <div class="td">권한</div>
    <div class="td">사용여부</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>
