<template>
  <div class="images-main-list">
    <div class="image-item" v-on:click="openImageViewer">
      <img :src="$getImgUrl(imageUrl, 'o640_640')" />
    </div>
  </div>
</template>

<script>
import { apiGet } from "@/api/apiMethods";

export default {
  data() {
    return {};
  },
  methods: {
    openImageViewer() {
      this.$imageViewer.open({
        mode: "image",
        images: [
          {
            url: this.$getImgUrl(this.imageUrl, "o640_640"),
            name: "",
            replaceUrl: async () => {
              const res = await apiGet(
                `/dam/v5/admin/images/${this.contentId}/download`
              );
              return res?.data?.imageUrl;
            },
          },
        ],
      });
    },
  },
  props: ["imageUrl", "contentId"],
  components: {},
};
</script>

<style lang="scss" scoped>
.images-main-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.image-item {
  position: relative;
  border: 1px solid var(--primary-200);
  width: 490px;
  height: 490px;
}

.image-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
