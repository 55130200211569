<template>
  <PagePanel
    title="수수료 관리"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>적용 수수료율</template>
        <template v-slot:td>
          <div class="rate-wrapper">{{ distributionRate }}</div>
          %&nbsp;&nbsp;&nbsp;
          <Checkbox
            :checked="toggleDistribution"
            v-on:click="toggleDistribution = !toggleDistribution"
            label="수수료율 수동 조정"
          />
          <Button
            v-on:click="isEditModalOpen = true"
            class="edit-grade"
            :disabled="!toggleDistribution"
          >
            등록
          </Button>
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
  <PagePanel
    title="관리 이력"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <div class="tr">
      <div class="th">변경일자</div>
      <div class="th">기존 수수료율</div>
      <div class="th">변경 수수료율</div>
      <div class="th">적용 서비스월</div>
      <div class="th">변경 담당자</div>
      <div class="th">운영자 메모</div>
      <div class="th">변경 상태</div>
    </div>
    <div class="tr" v-for="log in logs" :key="log.logId">
      <div class="td">
        {{ $formatDate(log.createdAt, "YYYY-MM-DD HH:mm:ss") }}
      </div>
      <div class="td">{{ log.from }}%</div>
      <div class="td">{{ log.to }}%</div>
      <div class="td">{{ log.startedMonth }}</div>
      <div class="td">{{ log.updaterId }}</div>
      <div class="td">{{ log.reason }}</div>
      <div class="td">
        {{ statusText(log.status) }}
        <Button
          v-if="log.status === 'RESERVED'"
          v-on:click="deleteLog(log.startedMonth)"
          class="edit-grade"
          :size="'xsmall'"
        >
          삭제
        </Button>
      </div>
    </div>
  </PagePanel>
  <Modal
    :title="'정산 수수료율 등록'"
    :isOpen="isEditModalOpen"
    :isDialog="true"
    confirmText="확인"
    v-on:cancel="closeModal"
    v-on:confirm="save"
  >
    <modal-simple-row label="기존 수수료율">
      {{ currentDistributionRate }}&nbsp;%
    </modal-simple-row>
    <modal-simple-row label="변경 수수료율">
      <div>
        <InputText
          class="distributionRate-input"
          v-model="distributionInfo.rate"
          :size="'small'"
          :type="'number'"
        />
        %
      </div>
    </modal-simple-row>
    <modal-simple-row label="적용 시점">
      <Datepicker
        class="datepicker"
        v-model="distributionInfo.startedMonth"
        placeholder="수수료율 적용 시점"
        activatedMonth
      />
    </modal-simple-row>
    <modal-simple-row label="운영자 메모">
      <Textarea
        v-model="distributionInfo.reason"
        class="input ta"
        :maxLen="300"
        :minHeight="'120px'"
      />
    </modal-simple-row>
    <hr />
    <p class="modal-desc">
      변경 수수료율과 수수료율 적용 시점이<br />
      올바르게 적용되었는지 확인해주세요.
    </p>
  </Modal>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";
import ModalSimpleFormRow from "@/components/modal/simple/ModalSimpleFormRow";

import {
  PagePanel,
  Button,
  Modal,
  InputText,
  Checkbox,
  Textarea,
  Datepicker,
} from "@ogqcorp/v-ui";
import { apiDelete, apiPost } from "../../../../../api/apiMethods";

export default {
  props: ["logs", "currentDistributionRate"],
  data() {
    return {
      API_URL: `/pmt/v5/admin/creators/${this.$route.params.creatorId}/distributionPolicies`,
      isEditModalOpen: false,
      toggleDistribution: false,
      distributionInfo: {
        startedMonth: false,
        rate: this.currentDistributionRate,
        reason: "",
      },
    };
  },
  computed: {
    distributionRate: {
      get() {
        return this.currentDistributionRate;
      },
      set(value) {
        this.$emit("update:currentDistributionRate", value);
      },
    },
  },
  methods: {
    statusText(status) {
      return status === "APPLIED" ? "적용완료" : "예약중";
    },
    closeModal() {
      this.isEditModalOpen = false;
    },
    async save() {
      // console.log(
      //   "save",
      //   this.currentDistributionRate,
      //   this.distributionInfo.distributionRate
      // );
      if (this.currentDistributionRate === this.distributionInfo.rate) {
        this.closeModal();
        return;
      }

      const data = {
        startedMonth: this.$formatDate(
          this.distributionInfo.startedMonth,
          "YYYYMM"
        ),
        rate: this.distributionInfo.rate,
        reason: this.distributionInfo.reason,
      };

      await apiPost(this.API_URL, data);
      try {
        this.$emit("updatedDistributionRate");
        this.closeModal();
      } catch (err) {
        const { code } = err.response.data;
        if (code === 400000) {
          this.$confirm({
            msg: "이전 서비스월로는 신규 등록이 어렵습니다.",
          });
        }
        if (code === 409000) {
          this.$confirm({
            msg: "예약된 수수료율 적용 서비스월을 확인해주세요.",
          });
        }
        console.log(err.response);
      }
    },
    async deleteLog(startedMonth) {
      this.$confirm({
        msg: "예약 중인 변경 수수료율을 삭제하시겠습니까?",
        onConfirm: async () => {
          try {
            await apiDelete(`${this.API_URL}/${startedMonth}`);
            this.$emit("updatedDistributionRate");
            console.log("delete log");
          } catch (err) {
            console.log(err.response);
          }
        },
      });
    },
  },
  components: {
    "table-v": TableV,
    "table-v-row": TableVRow,
    "modal-simple-row": ModalSimpleFormRow,
    PagePanel,
    Button,
    Modal,
    InputText,
    Checkbox,
    Textarea,
    Datepicker,
  },
};
</script>

<style lang="scss" scoped>
.tr {
  display: grid;
  grid-template-columns: 1fr 0.9fr 0.9fr 0.9fr 0.9fr 1.6fr 1fr;
  align-items: center;
}
.th {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 16px 20px;
  background-color: var(--mono-100);
  color: #333;
}
.td {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 16px 20px;
  border: solid 1px var(--mono-100);
  border-right: solid 1px var(--mono-100);
  color: #333;
}
.edit-grade {
  margin-left: auto;
}
hr {
  margin-bottom: 20px;
}
.modal-desc {
  font-size: 14px;
}
.ta {
  width: 100%;
}
.rate-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  margin-right: 4px;
  color: var(--mono-600);
  background-color: var(--mono-100);
  border: 1px solid var(--mono-200);
}
.distributionRate-input {
  width: 80px;
  margin-right: 4px;
}
.datepicker {
  width: 226px;
  .calendar-time {
    display: none;
  }
}
</style>
