<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="전체"
          :active="!$route.query[statusKey]"
          v-on:click="setStatus()"
        />
        <Tab
          label="게시중"
          :active="$route.query[statusKey] === 'PUBLISHED'"
          v-on:click="setStatus('PUBLISHED')"
        />
        <Tab
          label="미게시"
          :active="$route.query[statusKey] === 'DISABLED'"
          v-on:click="setStatus('DISABLED')"
        />
        <Tab
          label="예약"
          :active="$route.query[statusKey] === 'RESERVED'"
          v-on:click="setStatus('RESERVED')"
        />
        <Tab
          label="종료"
          :active="$route.query[statusKey] === 'CLOSED'"
          v-on:click="setStatus('CLOSED')"
        />
      </div>
    </template>

    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Dropdown
            v-model="position"
            placeholder="전체"
            :list="positionArr"
          />
        </template>
      </TableFilter>
    </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button :to="{ name: 'BannerAdd' }">배너 추가</Button>
          <Button :disabled="!isAnyRowsChecked" v-on:click="deleteSelected"
            >선택 삭제</Button
          >
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head
            v-on:toggleCheck="toggleAllRows('bannerId')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <div class="table-body" v-else-if="list.length > 0">
          <table-row
            v-for="item in list"
            v-on:toggleCheck="toggleCheckRow"
            :checked="checkedRows.includes(item.bannerId)"
            :item="item"
            :key="item.bannerId"
            :id="item.bannerId"
          />
        </div>
      </PagePanel>
    </template>
  </admin-layout>
  
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import {Button, Dropdown, svg} from "@ogqcorp/v-ui";
import BannerTableRowHead from "@/views/service/banner/BannerTableRowHead";
import BannerTableRow from "@/views/service/banner/BannerTableRow";
const {svgChevronDown} = svg
export default {
  data() {
    return {
      position: this.$route.query.position ? this.$route.query.position : false,
      positionArr: [
        { value: false, label: "전체"},
        { value: 'DASHBOARD', label: '대시보드' },
        { value: 'LANDING_TOP', label: '랜딩 페이지' },
      ],
      deleteMessageHTML: `선택하신 배너를<br>삭제하시겠습니까?`,
      API_URL: `${this.$API_URL}/admin/banners`,
      searchQuery: "",
      idKey: "bannerId",
      listKey: "elements",
      statusKey: "publishedStatus"
    };
  },
  extends: TableView,
  watch: {
    position() {
      this.setFilter();
    },
    $route(n) {
      if (n.name !== this.$route.name) return;
      this.position = n.query.position || false;
    }
  },
  methods: {
    toggleCheckCondition(item) {
      return ['RESERVED', 'DISABLED'].includes(item.publishedStatus)
    },
    openModal() {
      this.$router.push({
        ...this.$router.currentRoute,
        query: {
          ...this.$route.query,
          ...{ modal: true },
        },
      });
    },
    closeModal() {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          ...this.$route.query,
          ...{ modal: undefined },
        },
      });
    },
    async setFilter() {
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
        },
        {
          ...this.$route.query,
          ...{
            position: this.position || undefined
          }
        },
        true
      );
    }
  },
  components: {
    "admin-layout": AdminLayout,
    "table-row-head": BannerTableRowHead,
    "table-row": BannerTableRow,
    Button,
    Dropdown,
    svgChevronDown
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.5fr 0.5fr 0.65fr 1.5fr 0.55fr 0.5fr;
  align-items: center;
}
</style>
