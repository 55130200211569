<template>
  <QuillEditor 
    v-model:content="content"
    contentType="html"
    theme="snow" 
    :toolbar="toolbar"
    :modules="modules"  
    ref="editor"
  />
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import BlotFormatter from 'quill-blot-formatter'
import './EditorStylesBubble.scss'
import './EditorStylesSnow.scss'
import { apiPost } from "@/api/apiMethods";

export default {
  
  props: {
    category: {
      type: String,
      default: "NOTICE"
    },
    toolbar: {
      type: [String, Array],
      default: ()=> {
        return [
          [{ 'header': 1 }, { 'header': 2 }],
          // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],  
          // ['blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'align': [] }],
           ['link', 'image'],
          ['clean'],
        ]
      }
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      content: "",
      modules: [
        {
          name: 'blotFormatter',  
          module: BlotFormatter, 
          options: {/* options */}
        }
      ]
    }
  },
  watch: {
    content(n) {
      this.$emit('update:modelValue', n)
    }
  },
  methods: {
    setContent(str) {
      console.log(str)
      this.$refs.editor.pasteHTML(str)
    },
    async getHTMLContents(options) {
      console.log("getHTMLContents")
      const isHTMLString = options?.isHTMLString != null ? options.isHTMLString : true
      const parser = new DOMParser()
      const html = parser.parseFromString(this.content, 'text/html')
      let imgs = Array.from(html.documentElement.querySelectorAll('img')) || []
      console.log("imgs", imgs)
      const imgDataUrls = imgs.filter(img=> !img.src.startsWith('http'))
      console.log("Upload", imgDataUrls)
      for (let img of imgDataUrls) {
        let dataUrl = img.src
        // const formData = new FormData()
        // formData.append("file", file)
        try {
          const response = await apiPost('/admin/editor/upload`', {dataUrl, category: this.category})
          
          if (response?.data?.url) {
            img.src = response.data.url
          } else {
            img.parentNode.removeChild(img)
          }
        } catch (err) {
          console.log(err.response)
          return ""
        }
      }

      return isHTMLString ? html.documentElement.querySelector('body').innerHTML : html.documentElement
    }
  },
  components: {
    QuillEditor
  }
}
</script>