<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="12" height="12" rx="6" fill="#7D8D92" />
    <path
      d="M7.88308 9.50645H8.50338L8.65107 4.30768H7.73538L7.88308 9.50645ZM8.18831 11.8104C8.55261 11.8104 8.83815 11.5348 8.83815 11.1311C8.83815 10.7274 8.55261 10.432 8.18831 10.432C7.83384 10.432 7.53846 10.7274 7.53846 11.1311C7.53846 11.5348 7.83384 11.8104 8.18831 11.8104Z"
      fill="white"
    />
  </svg>
</template>
