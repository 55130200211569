<template>
  <div class="section-bar big spacer">
    <div class="section-title">
      <div class="title big">콘텐츠 상태 변경 예약</div>
    </div>
  </div>
  <Card>
    <div class="status-reservation-wrap">
      <div class="card-control card-control-c">
        <div class="card-control-head">
          <div class="label">일시</div>
        </div>
        <div class="card-control-body">
          <Datepicker v-model="statusReserveAt" placeholder="상태 변경 일시" />
        </div>
      </div>
      <div class="card-control card-control-c">
        <div class="card-control-head">
          <div class="label">변경할 상태</div>
        </div>
        <div class="card-control-body">
          <Dropdown
            v-model="statusReserveStatus"
            class="stretch"
            :list="statusList"
            :stretch="true"
            placeholder="상태값"
          />
        </div>
      </div>
    </div>
    <div class="action-button-wrap">
      <Button
        v-if="!statusReserveData?.reservedAt"
        class="btn-register"
        @click="registerReserveStatus"
        :disabled="isRegisterButtonDisabled"
        >등록</Button
      >
      <template v-else>
        <Button class="btn-modify" @click="modifyReserveStatus">수정</Button>
        <Button class="btn-cancel" @click="cancelReserveStatus"
          >예약 취소</Button
        >
      </template>
    </div>
  </Card>
</template>

<script setup>
import Card from "@/views/contents/main/SingleContentCard";
import Item from "./SingleContentContentItem";
import { Datepicker, Button, Dropdown } from "@ogqcorp/v-ui";
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  defineProps,
  watch,
  watchEffect,
  reactive,
} from "vue";
import { apiDelete, apiGet, apiPost, apiPut } from "../../../api/apiMethods";
import { useRoute } from "vue-router";
import { getContentTypeDisplayData } from "@/utils";

const {
  appContext: {
    app: {
      config: {
        globalProperties: { $formatTime },
      },
    },
    provides: { $alert },
  },
} = getCurrentInstance();

const route = useRoute();

const statusReserveData = ref(null);
const statusReserveAt = ref(0);
const statusReserveStatus = ref("");
const statusList = [
  { value: "STOP_SALE", label: "판매 중지" },
  { value: "FREE_SALE", label: "무료로 판매" },
  { value: "PAID_SALE", label: "유료로 판매" },
];

onMounted(() => {
  getReserveStatus();
});

const isRegisterButtonDisabled = computed(() => {
  return !statusReserveStatus.value || !statusReserveAt.value;
});

const getReserveStatus = async () => {
  try {
    const { data } = await apiGet(`/dam/v5/admin/reserved-tasks`, {
      daId: route.params.contentId,
      daType: getContentTypeDisplayData(route.params.category),
    });
    statusReserveData.value = data;
    statusReserveAt.value = new Date(data.reservedAt * 1000);
    statusReserveStatus.value = data.status;
  } catch (error) {
    if (error.response.status === 409) {
      statusReserveAt.value = 0;
      statusReserveStatus.value = "";
      statusReserveData.value = null;
    }
  }
};
const registerReserveStatus = async () => {
  await apiPost("/dam/v5/admin/reserved-tasks", {
    daId: route.params.contentId,
    daType: getContentTypeDisplayData(route.params.category),
    reservedAt: $formatTime(statusReserveAt.value.getTime()),
    reserveCommand: statusReserveStatus.value,
  });
  await $alert({ msg: "상태변경 예약이 완료되었습니다." });
  await getReserveStatus();
};
const modifyReserveStatus = async () => {
  await apiPut(`/dam/v5/admin/reserved-tasks/${statusReserveData.value.id}`, {
    reservedAt: $formatTime(statusReserveAt.value.getTime()),
    reserveCommand: statusReserveStatus.value,
  });
  await $alert({ msg: "상태가 수정되었습니다." });
  await getReserveStatus();
};
const cancelReserveStatus = async () => {
  await apiDelete(`/dam/v5/admin/reserved-tasks/${statusReserveData.value.id}`);
  await $alert({ msg: "상태변경 예약이 취소되었습니다." });
  await getReserveStatus();
};
</script>

<style lang="scss" scoped>
.table {
  color: var(--mono-900);
  background-color: white;
}

.thead {
  padding: 0 40px;
  background: var(--mono-200);
}
.tbody {
  padding: 0 40px;
}

.th {
  padding: 20px 0;
  text-align: center;
}

.tbody:deep(.tr),
.thead .tr {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1fr;
  text-align: center;
}

.tbody:deep(.td),
.tbody:deep(.th) {
  padding: 30px 0;
  font-size: 14px;

  &.status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.section-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;
  &.big {
    margin: 20px 0 40px;
  }
  &.spacer {
    margin-top: 80px;
  }
}
.section-bar .section-title {
  display: flex;
  align-items: center;
}
.section-bar a {
  color: var(--mono-900);
  padding: 0 12px;
  border: 1px solid var(--mono-900);
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 22px;
  outline: 0;
  text-decoration: none;
}

.section-title .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 20px 0 4px;
  .big & {
    font-size: 24px;
    margin: 0 0 0;
    color: #333;
  }
}

.card {
  //overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status-reservation-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}
.action-button-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.card-control {
  width: 200px;
}
.card-control + .card-control {
  //   margin-top: 36px;
}
.card-control + .card-control-c {
  //   margin-top: 10px;
}
.card-control:not(.card-control-c):last-child {
  margin-top: 40px;
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-control-head .actions {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}
.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}
.card-control-head + .card-control-body {
  margin: 8px 0 0;
  width: 200px;
}

.card-control-body {
  .message {
    font-size: 14px;
    color: var(--mono-400);
  }
  :deep(.datepicker-outer) {
    width: 100%;
  }
}
</style>
