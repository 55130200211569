<template>
  <card>
    <template v-slot:title>판매 가격 관리</template>
    <div class="list">
      <div
        class="item"
        v-for="({ label, value }, i) in priceOptions"
        :key="`option-${i}`"
      >
        <Radio
          :disabled="isDisabled"
          :checked="isFree === value"
          v-on:click="setIsFree(value)"
          :label="label"
          size="small"
        />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Radio } from "@ogqcorp/v-ui";

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    _isFree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceOptions: [
        {
          label: "유료",
          value: false,
        },
        {
          label: "무료",
          value: true,
        },
      ],
      isFree: false,
    };
  },
  created() {
    this.isFree = this._isFree;
  },
  watch: {
    _isFree(n) {
      this.isFree = n;
    },
  },
  methods: {
    setIsFree(_isFree) {
      this.isFree = _isFree;
    },
  },
  components: {
    card: SingleContentCard,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-gap: 16px;
}

.item {
  display: flex;
  align-items: center;
}

.item label {
  margin-left: 6px;
  font-size: 14px;
  color: var(--mono-900);
}
</style>
