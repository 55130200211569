<template>
  <div class="item-wrapper" :class="[item.status, categoryKey]">
    <div class="item" v-on:click="clickContent">
      <img :src="$getImgUrl(thumbnailUrl)" />
    </div>
    <div class="item-label">
      {{ status.label }}
    </div>
  </div>
</template>

<script>
import { getContentStatusDisplayData } from "@/utils";

export default {
  props: ["item"],
  methods: {
    async clickContent() {
      let { href } = this.$router.resolve({
        name: "SingleContent",
        params: {
          category: this.categoryKey,
          contentId: this.contentId,
        },
      });
      window.open(href, "_blank");
    },
  },
  computed: {
    status() {
      return getContentStatusDisplayData(this.item.status);
    },
    categoryKey() {
      switch (this.$route.params.type) {
        case "STICKER":
          return "stickers";
        case "STOCK_IMAGE":
          return "images";
        case "COLORING_SHEET":
          return "coloringSheets";
        case "AUDIO":
          return "audios";
        case "VIRTUAL_ASSETS":
          return "virtualAssets";
        default:
          return "stickers";
      }
    },
    contentId() {
      switch (this.$route.params.type) {
        case "STICKER":
          return this.item.stickerId;
        case "STOCK_IMAGE":
          return this.item.imageId;
        case "COLORING_SHEET":
          return this.item.coloringSheetId;
        case "AUDIO":
          return this.item.audioId;
        case "VIRTUAL_ASSETS":
          return this.item.virtualAssetId;
        default:
          return this.item.contentId;
      }
    },
    thumbnailUrl() {
      switch (this.$route.params.type) {
        case "STICKER":
          return this.item.mainImageUrl;
        case "STOCK_IMAGE":
          return this.item.imageUrl;
        case "COLORING_SHEET":
          return this.item.beforeImageUrl;
        case "AUDIO":
          return this.item.coverImageUrl;
        case "VIRTUAL_ASSETS":
          return this.item.imageUrl;
        default:
          return this.item.coverImageUrl;
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
  width: 100%;
  height: 90px;
  border: 1px solid var(--mono-100);
  display: flex;
  img {
    display: block;
    // width: 62px;
    // height: 54px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
  }
  .stickers & img {
    width: 80px;
    height: 70px;
    object-fit: contain;
  }
  &:hover {
    border-color: var(--mono-300);
  }
  .SALE_STOP & {
    opacity: 0.5;
  }
  .REJECTED & {
    border-color: var(--red);
  }
}

.item-label {
  font-size: 12px;
  margin-top: 4px;
  color: var(--mono-900);
  .SALE_STOP & {
    color: var(--mono-500);
  }
  .REJECTED & {
    color: var(--red);
  }
}
</style>
