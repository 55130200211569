const getFiles = (acceptFileType="", acceptMultipleFiles=false)=> {
  return new Promise((res)=> {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = acceptFileType
    input.multiple = acceptMultipleFiles
    input.onchange = (e) => {
      return res(e.target.files)
    }
    input.click()
  })
}
const readFileAsDataUrl = async (file)=> {
  const format = file?.name?.substring(file?.name?.lastIndexOf('.') + 1).toUpperCase();
  return new Promise((resolve, reject)=> {
    const fileReader = new FileReader()
    fileReader.onload = async ()=> {
      const meta = ((format === 'AI') || (format === 'EPS') || (format === 'PSD')) ? {} : await getFileCustomMetas(file, fileReader.result)
      return resolve({
        dataUrl: fileReader.result,
        name: file.name,
        size: file.size,
        type: file.type,
        meta,
        file
      })
    }
    fileReader.onerror = (err)=> reject(err)
    fileReader.readAsDataURL(file)
  })
}

const getFileCustomMetas = async (file, dataUrl)=> {
  if (file.type.indexOf('image') > -1) {
    return {
      dimensions: await getImageMetas(dataUrl)
    }
  }
  return {}
}

const getImageMetas = async (dataUrl)=> {
  return new Promise((resolve, reject)=> {
    const img = new Image()
    img.onload = ()=> {
      const {width, height} = img
      return resolve({
        width,
        height
      })
    }
    img.onerror = ()=> {
      return reject("Image load failed")
    }
    img.src = dataUrl
  })
}

export {
  getFiles,
  readFileAsDataUrl,
}
