<template>
  <Modal
    title="수신인 검색 (크리에이터 ID)"
    :isDialog="true"
    :isLoading="isLoading"
    :confirmText="'선택'"
    @confirm="selectReceiver"
    @cancel="close"
  >
    <ModalFormControl>
      <InputText
        v-model="creatorId"
        placeholder="크리에이터ID를 입력하세요."
        class="input"
      />
    </ModalFormControl>
    <div class="creator-wrapper" v-if="creatorList">
      <div v-if="creatorList.length" class="creator">
        <Checkbox :checked="isChecked" @click="toggleCheck" />
        <span>
          {{ `${receiver.creatorId} (${receiver.nickname})` }}
        </span>
      </div>
      <div v-else class="creator--empty">조회된 크리에이터가 없습니다.</div>
    </div>
  </Modal>
</template>

<script>
// 5c9b771c6ce99, 619a256fefcf5, 6142cee390faa
import ModalFormControl from "@/components/modal/ModalFormControl";
import { InputText, Modal, Checkbox } from "@ogqcorp/v-ui";
import { apiGet } from "../../../../api/apiMethods";
export default {
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      creatorList: [],
      creatorId: "",
      receiver: null,
      isChecked: false,
    };
  },
  watch: {
    creatorId() {
      this.searchReceiverByCreatorId();
    },
  },
  methods: {
    clear() {
      this.receiver = null;
      this.isChecked = false;
      this.creatorList = [];
      this.creatorId = "";
    },
    selectReceiver() {
      if (!this.isChecked) return;
      this.$emit("selectReceiver", this.receiver);
      this.clear();
    },
    async searchReceiverByCreatorId() {
      const response = await apiGet(
        "/ptn/v5/admin/creators",
        {
          searchType: "CREATOR_ID",
          query: this.creatorId,
        },
        true
      );
      this.creatorList = response?.data?.elements;
      this.receiver = response?.data?.elements?.[0];
    },
    toggleCheck() {
      this.isChecked = !this.isChecked;
    },
    close() {
      this.$emit('close')
    }
  },
  components: {
    Modal,
    ModalFormControl,
    InputText,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}
.creator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  margin: 20px 0 0;
}
.creator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  background-color: var(--mono-050);

  &--empty {
    color: var(--mono-500);
    text-align: center;
  }
}
</style>
