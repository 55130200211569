<template>
  <div class="tr">
    <div class="th">
      {{ $formatDate(item.createdAt * 1000, "YYYY-MM-DD HH:mm:ss") }}
    </div>
    <div class="th">{{ type }}</div>
    <div class="th">{{ item.validator && item.validator.nickname }}</div>
    <div class="th status">
      <span>{{ status }}</span>
      <Button
        v-if="item.failReason"
        size="xsmall"
        color="primary"
        :border="false"
        v-on:click.prevent="
          $confirm({
            title: '불합격 사유',
            msg: item.failReason,
          })
        "
      >
        불합격 사유
      </Button>
    </div>
    <div class="th">
      <Button
        v-if="item.memo"
        size="xsmall"
        color="primary"
        :border="false"
        v-on:click.prevent="
          $confirm({
            title: '비고',
            msg: item.memo,
          })
        "
      >
        비고
      </Button>
    </div>
  </div>
</template>

<script>
import { Button } from "@ogqcorp/v-ui";

export default {
  props: ["item"],
  computed: {
    type() {
      switch (this.item.type) {
        case "QUALITY":
          return "OGQ 심사";
        case "PUBLICITY":
          return "퍼블리티권 심사";
        case "IP":
          return "지적재산권 심사";
        default:
          return this.item.type;
      }
    },
    status() {
      switch (this.item.status) {
        case "REVIEW":
          return "심사 요청";
        case "REJECTED":
          return "반려됨";
        case "PAUSED":
          return "보류됨";
        case "CANCELED":
          return "취소됨";
        case "DONE":
          return "완료";
        case "BLOCKED":
          return "차단";
        case "SALE":
          return "판매중";
        case "SALE_STOP":
          return "판매 중지";
        default:
          return this.item.status;
      }
    },
  },
  components: {
    Button,
  },
};
</script>
