<template>
  <div class="card">
    <card-title v-if="$slots.title">
      <template v-slot:title>
        <slot name="title" />
      </template>
      <template v-slot:titleExtra v-if="$slots.titleExtra">
        <slot name="titleExtra" />
      </template>
    </card-title>
    <slot />
  </div>
</template>

<script>
import SingleContentCardTitle from "./SingleContentCardTitle";
export default {
  components: {
    "card-title": SingleContentCardTitle,
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px;
  background-color: white;
}
</style>
