<template>
  <div 
    :class="[
      'row',
      {
        'is-expandable': isExpandable,
        'is-expanded': isExpanded,
        'can-expand': canExpand
      }
    ]"
  >
    <div class="th">
      <slot name="key" />
      <button v-if="canEdit" v-on:click="toggleEdit">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8.00033"
            cy="8.00065"
            r="6.66667"
            transform="rotate(90 8.00033 8.00065)"
            fill="#DEE2E6"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.8134 5.20236L5.24026 9.84898C5.12436 9.96681 5.05617 10.1233 5.04881 10.2884L5.01121 11.1373L5.79579 11.0528C5.9493 11.0363 6.09236 10.9671 6.20066 10.857L10.8052 6.17849C11.0642 5.91535 11.0609 5.49209 10.7977 5.2331L10.7588 5.19482C10.4956 4.93584 10.0724 4.93922 9.8134 5.20236ZM4.52553 9.14562C4.23577 9.44002 4.06531 9.83135 4.04704 10.244L4.00084 11.2874C3.97819 11.7986 4.41631 12.2099 4.92514 12.1552L5.90317 12.0498C6.2869 12.0085 6.64456 11.8356 6.91531 11.5605L11.5199 6.88189C12.1673 6.22403 12.1589 5.16586 11.5011 4.5184L11.4622 4.48013C10.8043 3.83267 9.74613 3.8411 9.09868 4.49897L4.52553 9.14562Z"
            fill="white"
          />
          <path
            d="M5.94434 8.69922L7.47523 10.2962"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      </button>
    </div>
    <div 
      :class="[
          'td'
        ]
      "
      v-on:click="toggleExpand"
      ref="td"
    >
      <span class="td-inner" ref="tdContents">
        <slot name="value" v-if="!isEdit" />
      </span>
        <slot name="edit" v-if="isEdit" ref="editSlot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.canExpand = this.isExpandable && this.$refs.td.offsetWidth < this.$refs.tdContents.offsetWidth
  },
  data() {
    return {
      canExpand: false,
      isExpanded: false,
      isEdit: false,
    };
  },
  methods: {
    toggleExpand(e) {
      if (!this.isExpandable || this.isEdit) return
      if (!this.isExpanded && this.$refs.td.offsetWidth > this.$refs.tdContents.offsetWidth) return
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      
      this.isExpanded = !this.isExpanded
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
      if (this.isEdit) {
        this.isExpanded = false
        this.$emit("editOn");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: grid;
  align-items: center;
  grid-template-columns: 2.2fr 3.1fr;
  color: var(--mono-900);
  font-size: 14px;
  height: 40px;
  & + .row {
    // margin-top: 20px;
  }
  &.is-expanded {
    height: auto;
    align-items: flex-start;
  }
}

.th {
  display: flex;
  align-items: center;
  font-weight: 500;

  button {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    border: 0;
    background: transparent;
    padding: 0;
  }
}

.td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .can-expand & {
    cursor: pointer;
  }
  .is-expandable &:hover {
    // background-color: var(--mono-100);
    // border-radius: 1px;
  }
  .is-expanded & {
    white-space: pre-wrap;
    word-break: break-all;
    text-overflow: none;
    overflow: visible;
  }
}
</style>
