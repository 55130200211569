<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="m4 4 16 16m0-16L4 20"
        stroke="#212529"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="1.8"
      />
    </g>
  </svg>
</template>
