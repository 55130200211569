<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="자동 이메일"
          :active="false"
          v-on:click="$router.push({ name: 'MailAuto' })"
        />
        <Tab label="수동 이메일" :active="true" />
      </div>
    </template>

    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:search>
          <form v-on:submit.prevent="search">
            <Dropdown
              v-model="searchType"
              class="dropdown"
              placeholder="검색 옵션"
              :list="searchTypeArr"
            />
            <InputSearch
              v-model.trim="searchQuery"
              placeholder="이메일 제목을 검색해주세요."
            />
          </form>
        </template>
      </TableFilter>
    </template>

    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button :to="{ name: 'MailManualAdd' }">이메일 작성</Button>
          <Button :disabled="!isAnyRowsChecked" v-on:click="deleteSelected"
            >선택 삭제</Button
          >
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head
            v-on:toggleCheck="toggleAllRows('id')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <template v-else-if="list.length">
          <div class="table-body">
            <table-row
              v-for="(item, i) in list"
              :key="i"
              :item="item"
              :checked="checkedRows.includes(item.emailId)"
              :id="item.emailId"
              @toggleCheck="toggleCheckRow"
            />
          </div>
        </template>
      </PagePanel>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import MailTableRowHead from "@/views/service/mail/manual/MailManualTableRowHead";
import MailTableRow from "@/views/service/mail/manual/MailManualTableRow";
import { Button, Radio, svg, InputSearch } from "@ogqcorp/v-ui";
import { apiDelete } from "../../../../api/apiMethods";
const { svgChevronDown } = svg;

export default {
  data() {
    return {
      API_URL: `/admin/emails`,
      listKey: "elements",
      isFilterModalOpen: false,
      deleteMessageHTML: `선택하신 수동 이메일을 <br> 삭제하시겠습니까?`,
      filterKey: "searchType",
      searchType: "TITLE",
      searchTypeArr: [{ value: "TITLE", label: "제목" }],
      searchKey: "keyword",
      searchQuery: "",
    };
  },
  extends: TableView,
  methods: {
    async deleteEmail(emailId) {
      await apiDelete(`/admin/emails/${emailId}`);
    },
    async deleteSelected() {
      try {
        for (const id of this.checkedRows) {
          await this.deleteEmail(id);
        }
        await this.$confirm({
          msg: "이메일이 정상적으로 삭제되었습니다.",
          cancelText: "닫기",
        });
        this.loadPage();
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    "admin-layout": AdminLayout,
    Button,
    Radio,
    svgChevronDown,
    InputSearch,
    "table-row-head": MailTableRowHead,
    "table-row": MailTableRow,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 0.5fr 1fr 3fr 1fr;
  align-items: center;
}
</style>
