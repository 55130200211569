<template>
  <Modal
    :title="'해당 콘텐츠를 차단하시겠습니까?'"
    :isDialog="true"
    width="600px"
  >
    <control>
      <template v-slot:label>차단 사유</template>
      <Dropdown
        v-model="presetReason"
        placeholder="선택"
        :list="presetReasonsArr"
        :stretch="true"
      />
    </control>
    <control v-if="presetReason === 'others'">
      <template v-slot:label>직접 입력</template>
      <Textarea
        v-model="reason"
        class="input ta"
        placeholder="카테고리명을 입력해주세요."
        :maxLen="120"
      />
    </control>
    <!-- <control>
      {{reason}}
    </control> -->
  </Modal>
</template>

<script>
import ModalFormControl from "@/components/modal/ModalFormControl";
import presetReasons from "@/views/contents/main/BlockReasons";

import { Modal, Dropdown, Textarea } from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      presetReason: false,
      presetReasons,
      reason: "",
      memo: "",
    };
  },
  props: ["isReject", "contentType"],
  watch: {
    presetReason(n) {
      console.log(n);
      if (n === "others" || !n) {
        this.reason = "";
      } else {
        const preset = this.presetReasonsArr.find((p) => p.value === n);
        if (preset) {
          this.reason = preset.label;
        } else {
          this.reason = "";
        }
      }
    },
  },
  methods: {
    clear() {
      this.presetReason = false;
      this.reason = "";
    },
  },
  computed: {
    presetReasonsArr() {
      if (this.contentType) {
        return this.presetReasons.filter(
          (p) => p.contentType === this.contentType || p.contentType == null
        );
      } else {
        return this.presetReasons.filter((p) => p.contentType == null);
      }
    },
  },
  components: {
    control: ModalFormControl,
    Modal,
    Textarea,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.input.ta:deep(textarea) {
  min-height: 200px;
}
</style>
