<template>
  <AdminLayout :title="'애니메이션 스티커 업로드'" :compact="true">
    <!-- <template v-slot:title>애니메이션 스티커 업로드</template> -->
    <template v-slot:body>
      <StickerMain :mainImage="mainImage" v-on:mainSelected="onMainSelected" />
      <StickerGrid v-on:stickerUpdated="stickerUpdated" />
      <StickerTabs
        :stickers="stickers"
        :tabImage="tabImage"
        v-on:tabSelected="onTabSelected"
      />

      <hr />

      <div class="card-grid">
        <Card>
          <template v-slot:title>애니메이션 스티커 정보</template>
          <div class="card-control card-control-c">
            <InputText
              v-model="title"
              placeholder="제목을 입력해주세요."
              size="medium"
              :stretch="true"
            />
          </div>
          <div class="card-control card-control-c">
            <Textarea
              v-model="description"
              placeholder="설명을 입력해주세요."
              minHeight="150px"
            />
          </div>
          <div class="card-control card-control-c">
            <tag-input v-on:pushTag="pushTag" />
            <br />
            <SingleContentTags :tags="tags" v-on:removeTag="removeTag" />
            <div class="message">
              3개 이상 20개 이하의 태그를 작성할 수 있어요.
            </div>
            <!-- message="3개 이상 20개 이하의 태그를 작성할 수 있어요."
              messageColor="gray"
              :stretch="true"  -->
          </div>
          <br />
          <div class="card-control">
            <div class="card-control-head">
              <div class="label">업로드 크리에이터(필수)</div>
              <div class="actions">
                <Button @click="openCreatorModal" :solid="true" size="xsmall">
                  매칭하기
                </Button>
              </div>
            </div>
            <div class="card-control-body">
              <InputText
                v-model="creatorId"
                :stretch="true"
                :readonly="true"
                placeholder="크리에이터 ID를 입력해주세요."
              />
            </div>
          </div>
          <br />
          <div class="card-control card-control-c">
            <div class="card-control-head">
              <div class="label">판매 타입</div>
            </div>
            <div class="card-control-body">
              <div class="list">
                <div
                  v-for="({ label, value }, i) in salesTypeArr"
                  :key="i"
                  class="item"
                >
                  <Radio
                    :checked="salesType === value"
                    :label="label"
                    @click="setSalesType(value)"
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div class="d">
          <SingleContentRecommendedUsablePlace
            :isDisabled="salesType === 'SUBSCRIPTION'"
            ref="places"
          />
          <div class="d">
            <SingleContentAdminTags
              :tags="keywords"
              v-on:pushTag="pushAdminTag"
              v-on:removeTag="removeAdminTag"
              class="card"
            />
          </div>
          <div class="d">
            <SingleContentManageMarket
              :isDisabled="salesType === 'SUBSCRIPTION'"
              class="card"
              ref="markets"
            />
            <Card>
              <div class="card-control card-control-c">
                <div class="card-control-head">
                  <div class="label">가격</div>
                  <div class="actions">
                    <Radio
                      v-model="isFree"
                      :value="true"
                      label="무료"
                      :disabled="salesType === 'SUBSCRIPTION'"
                    />
                    <Radio
                      v-model="isFree"
                      :value="false"
                      label="유료"
                      :disabled="salesType === 'SUBSCRIPTION'"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div class="d">
            <Card>
              <div class="card-control card-control-c">
                <div class="card-control-head">
                  <div class="label">판매 시작일</div>
                  <div class="actions">
                    <Toggle v-model="sellReserved" />
                  </div>
                </div>
                <br />
                <div class="card-control-head">
                  <Datepicker
                    v-model="sellReservedAt"
                    placeholder="판매 시작일"
                    :disabled="salesType === 'SUBSCRIPTION'"
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <SingleContentCategory
          class="card-sticker"
          _categoryType="stickers"
          :activeOption="true"
          ref="categories"
        />
        <div class="col">
          <SingleContentQuality class="card" ref="grade" />
        </div>
        <div class="col">
          <Card>
            <div class="card-control">
              <div class="card-control-head">
                <div class="label">퍼블리시티권(선택)</div>
                <div class="actions">
                  <Button size="xsmall" :solid="true" @click="openBJModal"
                    >매칭하기
                  </Button>
                </div>
              </div>
              <div class="card-control-body">
                <div class="message">
                  퍼블리시티권 매칭은 현재 아프리카TV BJ만 매칭 가능하며,
                  아프리카TV OGQ마켓에서만 판매됩니다.
                </div>
                <div v-if="publicity" class="creator-matched">
                  <Badge :color="'blue'">매칭완료</Badge>
                  {{ `${publicity?.nickname} (${publicity?.partnerId})` }}
                </div>
              </div>
            </div>
            <div class="card-control">
              <Button
                color="primary"
                :solid="true"
                :stretch="true"
                v-on:click="handleClickUpload"
              >
                업로드하기
              </Button>
            </div>
          </Card>
        </div>
      </div>

      <ContentUploadCreatorModal
        v-on:setCreator="setCreatorId"
        ref="creatorModal"
      />
      <ContentUploadBJModal @setPartner="setBJId" ref="bjModal" />
    </template>
  </AdminLayout>
</template>

<script>
import { readFileAsDataUrl } from "@/utils/files";
import AdminLayout from "@/layouts/AdminLayout";
import TagInput from "@/components/tag/TagInput";
import SingleContentTags from "@/views/contents/main/SingleContentTags";
import StickerGrid from "@/components/stickers/upload/StickerGrid";
import StickerTabs from "@/components/stickers/upload/StickerTabs";
import StickerMain from "@/components/stickers/upload/StickerMain";
import SingleContentCategory from "@/views/contents/cards/SingleContentCategory";
import SingleContentManageMarket from "@/views/contents/cards/SingleContentManageMarket";
import SingleContentQuality from "@/views/contents/cards/SingleContentQuality";
import SingleContentAdminTags from "@/views/contents/cards/SingleContentAdminTags";
import SingleContentRecommendedUsablePlace from "@/views/contents/cards/SingleContentRecommendedUsablePlace";
import {
  InputText,
  Textarea,
  Button,
  Radio,
  Toggle,
  Datepicker,
  Badge,
} from "@ogqcorp/v-ui";
import Card from "@/views/contents/main/SingleContentCard";
import Toast from "@/components/toast";
import ContentUploadCreatorModal from "@/views/contents/upload/ContentUploadCreatorModal";
import ContentUploadBJModal from "./ContentUploadBJModal";
import { apiPost } from "../../../../api/apiMethods";

// import UploadSidebarControl from '@/components/upload/UploadSidebarControl'
// import UploadSidebarControlGroup from '@/components/upload/UploadSidebarControlGroup'

export default {
  data() {
    return {
      title: "",
      description: "",
      grade: "NONE",
      tags: [],
      keywords: [],
      isFree: false,
      stickers: [],
      mainImage: null,
      tabImage: null,
      creator: null,
      creatorId: null, // "60cc4adc09ce7"
      reserved: false,
      publishedAt: null,
      sellReserved: false,
      sellReservedAt: null,
      subscription: this.salesType === "SUBSCRIPTION",
      expiredAt: null,
      publicity: null,
      partnerId: "",
      salesType: "SINGLE",
      salesTypeArr: [
        {
          value: "SINGLE",
          label: "일반 판매",
        },
        {
          value: "SUBSCRIPTION",
          label: "구독 판매",
        },
      ],
    };
  },
  watch: {
    salesType(n) {
      if (n === "SUBSCRIPTION") {
        // 스티커 업로드 > 구독 선택시
        this.$refs.places.recommendedUsablePlace = ["CHATTING"];
        this.$refs.markets.whitelistMarkets = ["17a75d997360002"];
        this.$refs.markets.mode = "whitelist";
        return;
      }
      this.$refs.places.recommendedUsablePlace = [];
      this.$refs.markets.mode = "all";
      this.$refs.markets.whitelistMarkets = [];
    },
  },
  methods: {
    setSalesType(type) {
      this.salesType = type;
    },
    openCreatorModal() {
      this.$refs.creatorModal.open();
    },
    openBJModal() {
      this.$refs.bjModal.open();
    },
    setCreatorId(creator) {
      this.creator = creator;
      // this.creatorId = `${creator.creatorId} (${creator.nickname})`;
      this.creatorId = creator.creatorId;
    },
    setBJId(bj) {
      this.publicity = bj;
      this.partnerId = bj.partnerId;
    },
    stickerUpdated(n) {
      this.stickers = n;
    },
    async onTabSelected(file) {
      const data = await readFileAsDataUrl(file);

      if (data.type !== "image/png") {
        return Toast("탭 이미지는 png 형식이어야 합니다.", { type: "error" });
      }

      if (
        data.meta.dimensions.width !== 96 ||
        data.meta.dimensions.height !== 74
      ) {
        return Toast("탭 이미지는 가로 96px 세로 74px이어야 합니다.", {
          type: "error",
        });
      }

      this.tabImage = data;
      Toast("탭 이미지를 등록했습니다.", { type: "success" });
    },
    async onMainSelected(file) {
      const data = await readFileAsDataUrl(file);
      console.log(JSON.parse(JSON.stringify(data)));

      if (data.type !== "image/png") {
        return Toast("메인 이미지는 png 형식이어야 합니다.", { type: "error" });
      }

      if (
        data.meta.dimensions.width !== 240 ||
        data.meta.dimensions.height !== 240
      ) {
        return Toast("메인 이미지는 가로 240px 세로 240px이어야 합니다.", {
          type: "error",
        });
      }

      this.mainImage = data;

      Toast("메인 이미지를 등록했습니다.", { type: "success" });
    },
    pushTag(inputTag) {
      const allTags = inputTag.trim().split(",");

      for (const tag of allTags) {
        let tagToAdd = tag.trim();
        const delimiters = [32, 188, 44, 13];

        for (const delimiterChar of delimiters) {
          tagToAdd = tagToAdd.replace(
            new RegExp(String.fromCharCode(delimiterChar), "gi"),
            ""
          );
        }

        if (tagToAdd.length < 1) return;
        if (this.tags.includes(tagToAdd.trim())) return;
        this.tags.push(tagToAdd.trim());
      }
    },
    removeTag(tag) {
      this.tags = this.tags.filter((t) => tag !== t);
    },
    pushAdminTag(inputTag) {
      const allTags = inputTag.trim().split(",");

      for (const tag of allTags) {
        let tagToAdd = tag.trim();
        const delimiters = [32, 188, 44, 13];

        for (const delimiterChar of delimiters) {
          tagToAdd = tagToAdd.replace(
            new RegExp(String.fromCharCode(delimiterChar), "gi"),
            ""
          );
        }

        if (tagToAdd.length < 1) return;
        if (this.keywords.includes(tagToAdd.trim())) return;
        this.keywords.push(tagToAdd.trim());
      }
    },
    removeAdminTag(tag) {
      this.keywords = this.keywords.filter((t) => tag !== t);
    },
    async uploadContent() {
      try {
        this.isLoading = true;

        const data = {
          animated: true,
          mainImagePath: "",
          tabImagePath: "",
          stickerImages: [],
          textContents: [
            {
              lang: "KO",
              title: this.title,
              description: this.description,
            },
          ],
          tags: this.tags || [],
          isFree: this.isFree,
          userId: this.creator?.creatorId || null,
          publicity: null,
          ipId: null,
          keywords: this.keywords,
          grade: this.$refs.grade.grade,
          categoryIds: this.$refs.categories.categoryIds || [],
          blacklistMarkets: this.$refs.markets.blacklistMarkets,
          whitelistMarkets: this.$refs.markets.whitelistMarkets,
          publishedAt:
            this.sellReserved && this.sellReservedAt
              ? this.$formatTime(this.sellReservedAt.getTime())
              : null,
          expiredAt: null,
          sellReserved: false,
          sellReservedAt: null,
          subscription: this.salesType === "SUBSCRIPTION",
          recommendedUsablePlace: this.$refs.places.recommendedUsablePlace,
          // publishedAt:
          //   this.reserved && this.publishedAt
          //     ? this.$formatTime(this.publishedAt.getTime())
          //     : null,
          // expiredAt:
          //   this.reserved && this.expiredAt
          //     ? this.$formatTime(this.expiredAt.getTime())
          //     : null,
        };

        // Validate Pre-Upload data here
        const stickers = this.stickers
          .filter((s) => s.sticker != null)
          .map((s, i) => {
            return {
              ...s,
              index: i,
            };
          });
        if (!this.mainImage) {
          throw new Error("메인 이미지를 등록해야 합니다.");
        }
        if (!stickers || stickers.length < 1) {
          throw new Error("스티커를 최소 1개를 등록하세요.");
        }
        if (!this.tabImage) {
          throw new Error("탭 이미지를 등록해야 합니다.");
        }
        if (this.title.trim().length < 1) {
          throw new Error("제목을 입력해주세요.");
        }
        if (this.title.trim().length > 40) {
          throw new Error("제목을 40자 이하로 입력해주세요.");
        }
        if (this.description.trim().length < 1) {
          throw new Error("설명을 입력해주세요.");
        }
        if (this.description.trim().length > 160) {
          throw new Error("설명을 160자 이하로 입력해주세요.");
        }
        if (this.tags.length < 3 || this.tags.length > 20) {
          throw new Error(
            `태그는 3개 이상, 20개 이하여야 합니다. (현재 개수: ${this.tags.length})`
          );
        }
        if (!data.userId) {
          throw new Error("크리에이터를 선택해주세요.");
        }
        if (data.categoryIds.length < 1) {
          throw new Error("카테고리를 선택해주세요.");
        }
        // TODO: 종료일 배포 후 주석 풀기
        // if (this.reserved && data.publishedAt >= data.expiredAt) {
        //   throw new Error("예약일/종료일을 확인해주세요.");
        // }

        // Upload Files
        const stickerImages = [];
        const main = await this.uploadFile(
          "GIF_STICKER",
          this.mainImage,
          "main.png"
        );
        data.mainImagePath = main.data.path;
        const tab = await this.uploadFile(
          "GIF_STICKER_TAB",
          this.tabImage,
          "tab.png"
        );
        data.tabImagePath = tab.data.path;
        for (let i = 0; i < stickers.length; i++) {
          const image = stickers[i];
          const fileName = `${i + 1}.gif`;
          const stickerData = await this.uploadFile(
            "GIF_STICKER_IMAGE",
            image.sticker,
            fileName
          );
          stickerImages.push({
            name: fileName,
            imagePath: stickerData.data.path,
          });
        }

        data.stickerImages = stickerImages;

        // Upload Content
        await apiPost(
          `/dam/v5/admin/stickers?creatorId=${this.creatorId}`,
          data
        );
        Toast(`[${this.title.trim()}] 성공적으로 등록됐습니다.`, {
          type: "success",
        });
        setTimeout(
          () =>
            this.$router.push({
              name: "Contents",
              params: {
                category: "stickers",
                page: 1,
              },
            }),
          2000
        );
      } catch (err) {
        console.log(err.response);
        Toast(err.message || "문제가 생겼습니다.", { type: "error" });
      } finally {
        this.isLoading = false;
      }
    },
    async handleClickUpload() {
      if (this.isLoading) return;

      try {
        let confirm;
        if (this.salesType === "SUBSCRIPTION") {
          confirm = this.$confirm({
            msg: "[구독판매]로 업로드하시겠습니까?",
          });
          if (confirm) {
            await this.uploadContent();
          }
          return;
        }
        await this.uploadContent();
      } catch (error) {
        console.log(error);
      }
    },
    async uploadFile(fileType, fileData, fileName) {
      const API_URL = `/admin/file/${fileType}/upload?userId=${this.creator?.creatorId}`;
      const formData = new FormData();
      const file = new File([fileData.file], fileName);
      formData.append("userId", this.creator?.creatorId);
      formData.append("file", file);
      return await apiPost(API_URL, formData, true);
    },
  },
  components: {
    AdminLayout,
    TagInput,
    SingleContentTags,
    StickerMain,
    StickerGrid,
    StickerTabs,
    SingleContentCategory,
    SingleContentManageMarket,
    SingleContentQuality,
    SingleContentAdminTags,
    SingleContentRecommendedUsablePlace,
    Card,
    InputText,
    Textarea,
    Button,
    Radio,
    Toggle,
    Badge,
    Datepicker,
    ContentUploadCreatorModal,
    ContentUploadBJModal,
  },
};
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  .col {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px 20px;
    overflow: hidden;
  }
}

.card-sticker {
  grid-column: span 2;
}

hr {
  background-color: var(--mono-200);
  border: 0;
  width: 100%;
  height: 1px;
  margin: 50px 0;
}

.card-control + .card-control {
  margin-top: 36px;
}

.card-control + .card-control-c {
  margin-top: 10px;
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head .actions {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}

.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head + .card-control-body {
  margin: 16px 0 0;
}

.card-control-body .message {
  font-size: 14px;
  color: var(--mono-400);
}

.card-control:not(.card-control-c):last-child {
  margin-top: 70px;
}

.input-tag {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.message {
  font-size: 14px;
  color: var(--mono-500);
  margin-top: 10px;
}

.creator-matched {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  padding: 20px 0;
  background-color: var(--mono-050);
  font-size: 14px;
}

.datepicker-outer {
  width: 200px;
}
</style>
