<template>
  <div class="coloring-main-list">
    <div class="image-item" v-on:click="openImageViewer">
      <img :src="$getImgUrl(beforeImageUrl, 'o640_640')" />
      <div class="badge">
        <Badge :solid="true" color="gray">컬러링전</Badge>
      </div>
    </div>
    <div class="image-item" :class="{ empty: !afterImageUrl }">
      <template v-if="afterImageUrl">
        <img
          :src="$getImgUrl(afterImageUrl, 'o640_640')"
          v-on:click="openImageViewer"
        />
      </template>
      <template v-else>
        <div class="inner">
          <i-restrict />
          <div class="text">컬러링 후 이미지가 없습니다.</div>
        </div>
      </template>
      <div class="badge">
        <Badge :solid="true" color="gray">컬러링후</Badge>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge } from "@ogqcorp/v-ui";
import svgRestrict from "@/components/svg/icons/svgRestrict";
import { apiGet } from "@/api/apiMethods";

export default {
  data() {
    return {};
  },
  props: ["afterImageUrl", "beforeImageUrl", "contentId"],
  methods: {
    openImageViewer() {
      // TODO: Make total replace mapper in image viewr images
      const images = [
        {
          url: this.$getImgUrl(this.beforeImageUrl, "o640_640"),
          name: "",
          replaceUrl: async () => {
            try {
              const res = await apiGet(
                `/dam/v5/admin/coloringSheets/${this.contentId}/download`
              );
              return res?.data?.beforeImageUrl;
            } catch (error) {
              console.log(error.response);
            }
          },
        },
      ];
      if (this.afterImageUrl) {
        images.push({
          url: this.$getImgUrl(this.afterImageUrl, "o640_640"),
          name: "",
          replaceUrl: async () => {
            try {
              const res = await apiGet(
                `/dam/v5/admin/coloringSheets/${this.contentId}/download`
              );
              return res?.data?.afterImageUrl;
            } catch (error) {
              console.log(error.response);
            }
          },
        });
      }

      this.$imageViewer.open({
        mode: "coloringSheets",
        images,
      });
    },
  },
  components: {
    Badge,
    "i-restrict": svgRestrict,
  },
};
</script>

<style lang="scss" scoped>
.coloring-main-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.image-item {
  position: relative;
  border: 1px solid var(--primary-200);
  width: 490px;
  height: 290px;
  &.empty {
    border-color: var(--mono-100);
  }
}

.image-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-item .badge {
  position: absolute;
  top: 20px;
  right: 20px;
}

.inner {
  width: 100%;
  height: 100%;
  background-color: var(--mono-100);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 24px;
    height: 24px;
    margin: 0 0 10px;
  }
  .text {
    font-size: 14px;
    color: var(--mono-400);
  }
}
</style>
