<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="사용여부">
          <Toggle />
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText placeholder="제목을 입력해주세요." class="stretch" />
        </PagePanelFormRow>
        <PagePanelFormRow label="URL">
          <InputText
            placeholder="메시지를 클릭하면 해당 URL로 이동합니다."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="내용" :alignTop="true">
          <Textarea placeholder="HTML로 내용을 입력해주세요." />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button color="primary">저장</Button>
      <Button>취소</Button>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";

import {Button, Toggle, InputText, PagePanel, PagePanelFormRow, Textarea} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      useOptions: true,
      mailType: false,
      mailTypes: [],
      updateDate: false,
      reservationDate: false,
    };
  },
  methods: {},
  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    // 'datepicker': DatePicker,
    Textarea,
    // 'radio': Radio,
    Button,
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 30px;
  }
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.buttons button {
  margin-right: 8px;
}
.input {
  width: 340px;
}
</style>
