<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <NavPath :tree="$route.meta.navTree" />
    </template>
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="소속" :isRequired="true">
          <Dropdown
            v-model="organization"
            :list="organizations"
            placeholder="소속을 선택해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="관리자 ID" :isRequired="true">
          <InputText placeholder="관리자 ID를 입력해주세요." class="stretch" />
        </PagePanelFormRow>
        <PagePanelFormRow label="이름" :isRequired="true">
          <InputText placeholder="이름을 입력해주세요." class="stretch" />
        </PagePanelFormRow>
        <PagePanelFormRow label="이메일" :isRequired="true">
          <InputText placeholder="이메일을 입력해주세요." class="stretch" />
        </PagePanelFormRow>
        <PagePanelFormRow label="어드민" :isRequired="true">
          <div class="buttons">
            <Button
              v-on:click="setAdminValues('market', 'adminType')"
              :color="adminType === 'market' ? 'primary' : 'default'"
            >
              마켓
            </Button>
            <Button
              v-on:click="setAdminValues('operator', 'adminType')"
              :color="adminType === 'operator' ? 'primary' : 'default'"
            >
              운영
            </Button>
          </div>
        </PagePanelFormRow>
        <PagePanelFormRow label="권한" :isRequired="true">
          <div class="buttons">
            <Button
              v-on:click="setAdminValues('admin', 'adminLevel')"
              :color="adminLevel === 'admin' ? 'primary' : 'default'"
            >
              일반
            </Button>
            <Button
              v-on:click="setAdminValues('super', 'adminLevel')"
              :color="adminLevel === 'super' ? 'primary' : 'default'"
            >
              마스터
            </Button>
          </div>
        </PagePanelFormRow>
      </PagePanel>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import {Button, NavPath, InputText, PagePanel, PagePanelFormRow, Dropdown} from "@ogqcorp/v-ui"

export default {
  data() {
    return {
      organization: false,
      organizations: [],
      adminType: false,
      adminLevel: false,
    };
  },
  methods: {
    setAdminValues(val, key = "adminType") {
      if (this[key] === val) {
        this[key] = false;
        return;
      }
      this[key] = val;
    },
  },
  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    Button,
    NavPath,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.buttons button {
  margin-right: 8px;
}
</style>
