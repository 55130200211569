<template>
  <card>
    <template v-slot:title>
      <span>
        {{ category === "audios" ? "장르" : "카테고리" }}
      </span>
    </template>
    <template v-slot:titleExtra>
      <div class="card-title-extra">
        <template v-if="maxCount > 1"> (최대 {{ maxCount }}개) </template>
        <template v-else> (택{{ maxCount }}) </template>
        <template v-if="activeOption">
          <Toggle v-model="activated" />
        </template>
      </div>
    </template>

    <div class="categories">
      <div
        class="category-item"
        v-for="category in categories"
        v-on:click="selectCategory(category.categoryId)"
        :data-category="category.categoryId"
        :key="category.categoryId"
      >
        <Radio
          :checked="categoryIds.includes(category.categoryId)"
          :label="`${category.krName}`"
          size="small"
        />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Radio, Toggle } from "@ogqcorp/v-ui";
import { apiGet } from "../../../api/apiMethods";

export default {
  props: {
    _categoryType: String,
    _categoryIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeOption: Boolean,
  },
  data() {
    return {
      categories: [],
      categoryIds: [],
      activated: true,
    };
  },
  created() {
    this.categoryIds = [...this._categoryIds];
    this.getCategories();
  },
  watch: {
    _categoryIds(n) {
      this.categoryIds = [...n];
    },
    activated() {
      this.getCategories();
    },
  },
  methods: {
    selectCategory(categoryId) {
      if (this.categoryIds.includes(categoryId)) {
        this.categoryIds = this.categoryIds.filter((cId) => cId !== categoryId);
        return;
      }

      if (this.categoryIds.length >= this.maxCount) {
        this.categoryIds.shift();
      }
      this.categoryIds.push(categoryId);
      console.log(this.categoryIds);
    },
    async getCategories() {
      this.categories = [];
      const { data } = await apiGet("/dam/v5/categories", {
        type: this.getCategoryType(),
      });
      this.categories = data.filter((c) =>
        this.activated ? c.activated === this.activated : true
      );
      const activatedCategoryIds = this.categories.map((c) => c.categoryId);
      this.categoryIds = this.categoryIds.filter((c) =>
        activatedCategoryIds.includes(c)
      );
    },
    getCategoryType() {
      const c =
        this.$route.params.category ||
        this.$route.path.substring(this.$route.path.lastIndexOf("/") + 1);
      if (c === "stickers" || c === "animated") {
        return "STICKER";
      } else if (c === "images") {
        return "STOCK_IMAGE";
      } else if (c === "audios") {
        return "AUDIO";
      } else if (c === "coloringSheets") {
        return "COLORING_SHEET";
      } else if (c === "virtualAssets") {
        return "VIRTUAL_ASSETS";
      }
      return this.$route.params.type;
    },
  },
  computed: {
    maxCount() {
      return this.category === "images" ? 3 : 1;
    },
    category() {
      if (this._categoryType) {
        return this._categoryType;
      }
      let cat = this.$route.params.category || this.$route.params.type;
      switch (cat) {
        case "STICKER":
          return "stickers";
        case "COLORING_SHEET":
          return "coloringSheets";
        case "STOCK_IMAGE":
          return "images";
        case "AUDIO":
          return "audios";
        default:
          return cat;
      }
    },
  },
  components: {
    card: SingleContentCard,
    Radio,
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 14px;
  grid-gap: 16px 8px;
}

.category-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category-item label {
  margin-left: 6px;
  color: var(--mono-900);
}

.card-title-extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
