<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck(item.userId)" :checked="checked" />
    </div>
    <div class="td">
      {{ idx + 1 }}
    </div>
    <div class="td">
      <a href="" v-on:click="editStaff">
        {{ item.name }}
      </a>
    </div>
    <div class="td">
      <a href="" v-on:click="editStaff">
        {{ item.email }}
      </a>
    </div>
    <div class="td">
      {{ staffRole }}
    </div>
    <div class="td">
      <Toggle v-model="activated" v-on:click="toggleActivation(item.userId)" />
    </div>
  </div>
</template>

<script>
import { Toggle, TableListRow } from "@ogqcorp/v-ui";
import { apiPut } from "@/api/apiMethods";

export default {
  data() {
    return {
      activated: false,
    };
  },
  created() {
    this.activated = this.item.activated;
  },
  methods: {
    editStaff(e) {
      e.preventDefault();
      this.$emit("edit", this.item.userId);
    },
    toggleCheck(userId) {
      this.$emit("toggleCheck", userId);
    },
    toggleActivation(userId) {
      this.activated = !this.activated;
      this.$emit("toggleActivated", userId, this.activated);
    },
  },
  watch: {
    "item.activated"(n) {
      this.activated = n;
    },
  },
  extends: TableListRow,
  props: {
    idx: {
      type: Number,
    },
    item: {
      type: Object,
    },
    checked: {
      type: Boolean,
    },
  },
  computed: {
    staffRole() {
      return {
        META_DATA_MANAGER: "메타데이터 관리자",
        SEARCH_MANAGER: "검색어 관리자",
        REVIEWER: "심사자",
        OCS_OPERATOR: "운영자",
        ADMIN: "마스터 관리자",
      }[this.item.staffRole];
    },
  },
  components: {
    Toggle,
  },
};
</script>
