<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="발송 대상">
          <div class="group">
            <Radio :label="'개별 크리에이터'" />
            <Radio :label="'모든 크리에이터'" />
          </div>
        </PagePanelFormRow>
        <PagePanelFormRow label="크리에이터 ID">
          <InputText
            placeholder="크리에이터 ID를 입력해주세요."
            class="input"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="발송 시작일">
          <datepicker
            v-model="reservationDate"
            class="datepicker-item"
            placeholder="시작일 (시작시간)"
          />
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText placeholder="제목을 입력해주세요." class="stretch" />
        </PagePanelFormRow>
        <PagePanelFormRow label="내용" :alignTop="true">
          <Textarea placeholder="HTML로 내용을 입력해주세요." />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <div class="buttons">
        <Button color="primary-outline n-margin">미리보기</Button>
        <div>
          <Button color="primary">저장</Button>
          <Button>취소</Button>
        </div>
      </div>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import {Button, Textarea, Radio, InputText, PagePanel, PagePanelFormRow, Datepicker} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      useOptions: true,
      mailType: false,
      mailTypes: [],
      updateDate: false,
      reservationDate: false,
    };
  },
  methods: {},
  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    Datepicker,
    Textarea,
    Radio,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 30px;
  }
}
.input {
  width: 340px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.n-margin {
  margin: 0 !important;
}
</style>
