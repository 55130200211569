<template>
  <div>
    <PagePanel
      title="이미지"
      :titleOption="{bold: true}"
      :isRaw="true"
    >
      <template v-slot:titleAction>
        <Button
          :isFile="true"
          v-on:selectFiles="onImageSelected"
        >
          파일 업로드
        </Button>
      </template>

      <div class="inner">
        <div
          class="img-wrapper"
          v-on:click="onClickImageSelected"
          v-on:drop.prevent="onDrop"
          v-on:dragover.prevent="onDragOver"
          v-on:dragleave.prevent="onDragLeave"
        >
          <img v-if="image" :src="image.dataUrl" alt="upload">
        </div>
        <div class="img-guide">
          <ul>
            <li>이미지 파일 (2,000px 이상의 jpg, png 파일)</li>
          </ul>
        </div>
      </div>
    </PagePanel>
  </div>
</template>

<script>
import {Button, PagePanel} from '@ogqcorp/v-ui';

export default {
  emits: [
    'imageSelected'
  ],
  props: {
    image: Object
  },
  data() {
    return {
      isDragOver: false
    }
  },
  methods: {
    onImageSelected(file) {
      this.$emit('imageSelected', file);
    },
    onClickImageSelected() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.onchange = (e) => {
        const file = e.target.files[0]
        this.$emit('imageSelected', file);
      }
      input.click()
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if (!file) return;
      this.$emit('imageSelected', file);
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    }
  },
  components: {
    Button,
    PagePanel
  },
}
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-wrapper {
  width: 100%;
  height: 100%;
  min-height: 500px;
  border: 1px solid var(--mono-400);
  $s: 16px;
  background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
    $s};
}
.img-wrapper img {
  width: 100%;
  height: 100%;
}
</style>
