<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.0002 12.4899L10.0002 9.48991L13.0002 12.4899L13.9902 11.5L10.0002 7.51001L6.01025 11.5L7.0002 12.4899Z"
    />
  </svg>
</template>
