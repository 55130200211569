<template>
  <div class="tags">
    <Tag
      class="tag"
      v-for="(tag, i) in tags"
      :key="i"
      v-on:clickRemove="removeTag(tag)"
    >
      {{ tag }}
    </Tag>
  </div>
</template>

<script>
import {Tag} from "@ogqcorp/v-ui";

export default {
  props: ["tags"],
  methods: {
    removeTag(tag) {
      this.$emit("removeTag", tag);
    },
  },
  components: {
    Tag
  },
};
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  .tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
