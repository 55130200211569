<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck" :checked="checked" :isRemoveAll="true" />
    </div>
    <div class="td">작성일</div>
    <div class="td">제목</div>
    <div class="td">링크 URL</div>
    <div class="td">최종 개정일</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>
