<template>
  <PagePanel 
    title="탭 이미지"
    :isRaw="true"
  >
    <template v-slot:titleAction>
      <Button 
        :isFile="true"
        v-on:selectFiles="onTabSelected"
      >
        파일 업로드
      </Button>
    </template>
    <div class="tab-list-wrapper">
      <div class="btn-faux-back">
        <svgChevronLeft />
      </div>
      <div class="btn-faux-history">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13 6.85716C13 6.30488 12.5523 5.85716 12 5.85716C11.4477 5.85716 11 6.30488 11 6.85716V12.8572V13.2714L11.2929 13.5643L14.7215 16.9928C15.112 17.3834 15.7452 17.3834 16.1357 16.9928C16.5262 16.6023 16.5262 15.9692 16.1357 15.5786L13 12.4429V6.85716Z" fill="#ADB5BD"/>
        </svg>
      </div>
      <div class="tab-list">
        <div :class="[
          'tab-item-user',
          {
            'tab-item-selected': tabImage
          }
        ]">
          <template v-if="tabImage">
            <img :src="tabImage.dataUrl">
          </template>
          <template v-else>
            <img :src="require(`@/assets/stickers/tab-user.png`)">
          </template>
        </div>  
        <div 
          class="tab-item-faux"
          v-for="i in 8"
          :key="i"
        >
          <img :src="require(`@/assets/stickers/tab-${i-1}.png`)">
        </div>
      </div>
    </div>
    <div class="tag-page">
      <div class="page-items">
        <div 
          :class="[
            'page-item',
            {
              'page-item-user': stickers[i -1]?.sticker
            }
          ]" 
          v-for="i in 14" 
          :key="i"
        >
          <template v-if="stickers[i -1]?.sticker">
            <img :src="stickers[i - 1].sticker.dataUrl">
          </template>
          <template v-else>
            <img :src="require(`@/assets/stickers/main-${i-1}.png`)">
          </template>
        </div>
      </div>
    </div>
  </PagePanel>
</template>

<script>
import {PagePanel, Button, svg} from '@ogqcorp/v-ui'
const {svgChevronLeft} = svg

export default {
  props: [
    'stickers',
    'tabImage'
  ],
  emits: ['tabSelected'],
  methods: {
    onTabSelected(file) {
      this.$emit('tabSelected', file)
    }
  },
  components: {
    PagePanel,
    svgChevronLeft,
    Button
  }
}
</script>

<style lang="scss" scoped>

.tab-list-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F1F3F5;
  height: 44px;
  padding: 0 20px;
}

.tab-list {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 16px;
}

.tab-item-user {
  display: flex;
  height: 44px;
  width: 56px;
  background-color: var(--primary-050);
}

.tab-item-user img {
  display: block;
  width: auto;
  height: 37px;
  opacity: 0.3;
  margin: auto;
  filter: grayscale(1);
}

.tab-item-selected img {
  opacity: 1;
  filter: none;
}

.tab-item-faux {
  display: block;
  width: auto;
  height: 37px;
  opacity: 0.3;

  img {
    display: block;
    width: auto;
    height: 100%;
  }
}

.page-items {
  padding: 43px 50px;
  display: grid;
  grid-template-columns: repeat(7, 80px);
  grid-gap: 50px;
}

.page-item {
  display: flex;
  img {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
    opacity: 0.3;
  }
}

.page-item-user img {
  opacity: 1;
}

.btn-faux-back {
  margin-right: 30px; 
  svg {
    fill: var(--mono-900);
  }
}

</style>