<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="스티커"
          v-on:click="setContentType('STICKER')"
          :active="$route.params.type === 'STICKER'"
        />
        <Tab
          label="이미지"
          v-on:click="setContentType('STOCK_IMAGE')"
          :active="$route.params.type === 'STOCK_IMAGE'"
        />
        <Tab
          label="컬러링시트"
          v-on:click="setContentType('COLORING_SHEET')"
          :active="$route.params.type === 'COLORING_SHEET'"
        />
        <Tab
          label="음원"
          v-on:click="setContentType('AUDIO')"
          :active="$route.params.type === 'AUDIO'"
        />
      </div>
    </template>
    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Dropdown
            v-model="status"
            placeholder="콘텐츠 상태"
            :list="statusArr"
            v-if="userIsInRole(['ADMIN', 'OCS_OPERATOR', 'META_DATA_MANAGER'])"
          />
          <Button
            color="primary"
            v-if="userIsInRole(['META_DATA_MANAGER']) && list.length < 1"
            v-on:click="requestReviewStart"
          >
            배정받기
          </Button>
          <Button
            color="primary"
            v-if="userIsInRole(['META_DATA_MANAGER']) && list.length > 0"
            :to="{
              name: 'SingleMetadata',
              params: {
                type: $route.params.type,
                metadataId: list[0].entryId,
              },
            }"
          >
            심사 시작
          </Button>
        </template>
      </TableFilter>
    </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1">
          <template v-slot:message v-if="userIsInRole(['REVIEWER'])">
            배정받기 버튼을 눌러 심사를 시작해주세요!
          </template>
        </TableEmpty>
        <div class="table-body" v-else-if="list.length > 0">
          <table-row
            v-for="item in list"
            :key="item.entryId"
            :item="item"
            :id="item.entryId"
          />
        </div>
      </PagePanel>
      <br />
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import { Button, PagePanel } from "@ogqcorp/v-ui";
import MetadataRowHead from "./MetadataRowHead";
import MetadataRow from "./MetadataRow";
// import { getReviewStatusListData } from "@/utils";
import { apiPost } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      isRequestingStart: false,
      searchQuery: "",
      contentType: "sticker",
      listKey: "elements",
      // Search
      status: false,
      statusArr: [
        { value: false, label: "전체" },
        { value: "INIT", label: "배정전" },
        { value: "ASSIGNED", label: "배정됨" },
        { value: "DONE", label: "입력완료" },
      ],

      step: false,
      stepsArr: ["INIT", "ASSIGNED", "PAUSED", "DONE"],
      // statusArr: [
      //   {value: 'REVIEW', label: '심사중'},
      //   {value: 'CANCELED', label: '재심사'},
      //   {value: 'REJECTED', label: '반려'},
      //   {value: 'PAUSED', label: '보류'},
      // ],
      searchType: false,
      searchTypeArr: [
        { value: "TITLE", label: "콘텐츠명" },
        { value: "NICKNAME", label: "닉네임" },
        { value: "CREATOR_ID", label: "크리에이터 아이디" },
        { value: "EMAIL", label: "이메일" },
      ],
    };
  },
  extends: TableView,
  watch: {
    status() {
      this.setFilters();
    },
    step() {
      this.setFilters();
    },
    $route(n) {
      if (n.name !== this.$route.name) return;
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      this[isAdmin ? "status" : "step"] =
        n.query[isAdmin ? "status" : "step"] || false;
    },
  },
  methods: {
    onAfterCreated() {
      if (this.userIsInRole(["ADMIN", "OCS_OPERATOR"])) {
        this.API_URL = `/dam/v5/admin/metaEntries`;
      } else if (this.userIsInRole(["META_DATA_MANAGER"])) {
        this.API_URL = `/dam/v5/operator/metaEntries`;
      }
      this.loadPage(
        { ...this.$route.params, ...{ page: 1 } },
        { ...this.$route.query },
        false
      );
    },
    async requestReviewStart() {
      if (this.isRequestingStart) return;
      this.isRequestingStart = true;
      let type = this.$route.params.type;
      let size = process.env.NODE_ENV === "production" ? 40 : 2;
      try {
        await apiPost("/dam/v5/operator/metaEntries?type="+type+"&assignedSize="+size);
        await this.loadPage(
          { ...this.$route.params, ...{ page: 1 } },
          null,
          true
        );

        if (this.list.length < 1) {
          await this.$alert({
            msg: "배정받을 콘텐츠가 존재하지 않습니다.",
          });
        }
      } catch (err) {
        console.log(err, err.response);
        if (err?.response?.status === 409) {
          await this.$error("이미 배정을 받은 상태입니다.");
        } else {
          await this.$error();
        }
      } finally {
        this.isRequestingStart = false;
      }
    },
    async setFilters() {
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
        },
        {
          ...this.$route.query,
          ...{
            [isAdmin ? "status" : "step"]: isAdmin
              ? this.status || undefined
              : this.step || undefined,
          },
        },
        true
      );
    },
    async setContentType(type) {
      if (this.$route.params.type === type) return;
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1, type },
        },
        null,
        true
      );
    },
    isContentType(type) {
      return this.$route.query && this.$route.query.artworkType === type;
    },
  },
  computed: {
    isContentSticker() {
      return this.contentType === "sticker";
    },
    isContentImage() {
      return this.contentType === "image";
    },
    isContentColoring() {
      return this.contentType === "coloring";
    },
    isContentAudio() {
      return this.contentType === "audio";
    },
    isContentTemplate() {
      return this.contentType === "template";
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "table-row-head": MetadataRowHead,
    "table-row": MetadataRow,
    PagePanel,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.table-head:deep(.tr),
.table-body:deep(.tr) {
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr 1fr 0.8fr 1.2fr 1.75fr 0.8fr;
  align-items: center;
}
.table-head:deep(.subset),
.table-body:deep(.subset) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.dropdown {
  width: 170px;
}
</style>
