<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9998 7.51009L9.9998 10.5101L6.9998 7.51009L6.00985 8.50004L9.9998 12.49L13.9897 8.50004L12.9998 7.51009Z"
    />
  </svg>
</template>
