import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
  name: 'custom-image',
  defaultOptions: {
    ...Image.options,
    sizes: ['small', 'medium', 'large']
  },

  addAttributes() {
    return {
      ...Image.config.addAttributes(),
      size: {
        default: 'small',
        rendered: false
      },
      float: {
        default: 'none',
        rendered: false
      }

    }
  },

  setAttributes: (attributes) => ({ tr, dispatch }) => {
    // Check it's a valid size option
    console.log('setAttributes', {attributes, tr, dispatch})
    if (!this.options.sizes.includes(attributes.size)) {
        return false
    }

    const { selection } = tr
    const options = {
        ...selection.node.attrs,
        ...attributes
    }
    const node = this.type.create(options)

    if (dispatch) {
        tr.replaceRangeWith(selection.from, selection.to, node)
    }
  },

  addCommands() {
    return {
      setImage: (options) => ({ tr, commands }) => {
        console.log('setImage', {options, tr, commands})
        if (tr.selection?.node?.type?.name == 'custom-image') {
          return commands.updateAttributes('custom-image', options)
        }
        else {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
      },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    console.log('rednerHTML', {node, HTMLAttributes})
    HTMLAttributes.class = ' img-size-' + node.attrs.size
    // HTMLAttributes.class += ' img-float-' + node.attrs.float

    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
    ]
  }
})