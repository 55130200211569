export default [
  {
    value: "general_regulation",
    label: "내부 심사규정에 의해 반려되었습니다.",
    key: "퀄리티",
  },
  {
    value: "stickers_darkmode",
    label:
      "메인/탭/스티커 전체에 다크모드에서 잘 보일 수 있게 캐릭터와 글씨에 흰색 테두리를 추가해주세요.",
    key: "다크모드",
    contentType: "stickers",
  },
  {
    value: "stickers_transparency_01",
    label: "메인 이미지와 탭 이미지도 배경이 투명한 png 파일이어야 합니다.",
    key: "투명",
    contentType: "stickers",
  },
  {
    value: "stickers_transparency_02",
    label:
      "스티커는 배경이 투명한 png파일이어야 합니다. 배경이 투명한지 확인해주세요.",
    key: "투명",
    contentType: "stickers",
  },
  {
    value: "stickers_readability",
    label: "텍스트의 가독성이 떨어집니다.",
    key: "가독성",
    contentType: "stickers",
  },
  {
    value: "stickers_outline",
    label:
      "이미지에 적용된 흰색 테두리 선이 너무 얇거나 흐릿합니다. 더 두껍고 명확한 테두리를 추가해주세요.",
    key: "테두리",
    contentType: "stickers",
  },
  {
    value: "stickers_mundane",
    label: "24개 이미지의 사용성에 따른 차이가 보이지 않습니다.",
    key: "사용성",
    contentType: "stickers",
  },
  {
    value: "general_duplicate",
    label: "마켓 내 동일한 콘텐츠가 있습니다.",
    key: "동일",
  },
  {
    value: "general_duplicate_upload",
    label: "중복 업로드 된 콘텐츠입니다.",
    key: "중복",
  },
  {
    value: "general_privacy",
    label: "초상권 침해로 반려되었습니다.",
    key: "초상권",
  },
  {
    value: "general_brand",
    label: "상표권 침해로 반려되었습니다.",
    key: "상표권",
  },
  {
    value: "general_copyright",
    label: "저작권 또는 지적재산권 침해로 반려되었습니다.",
    key: "저작권",
  },
  {
    value: "general_pulicity",
    label: "퍼블리시티권 위반으로 반려되었습니다.",
    key: "욕설",
  },
  {
    value: "general_violent",
    label:
      "욕설, 폭력, 비방, 선정성, 정치적/종교적 성향이 짙은 내용이 포함되어 반려되었습니다.",
    key: "욕설",
  },
  {
    value: "general_hold_event",
    label: "이벤트/프로모션 콘텐츠로 심사 보류합니다.",
    key: "이벤트",
  },
  // {
  //   value: "stickers_similar",
  //   label:
  //     "구도와 캐릭터의 연출, 동작 등이 상당 부분 일치하는 스티커 세트가 존재합니다.",
  //   key: "유사",
  //   contentType: "stickers",
  // },
  {
    value: "stickers_same",
    label: "세트 내 구도와 연출이 상당 수 일치하는 스티커가 존재합니다.",
    key: "반복",
    contentType: "stickers",
  },

  {
    value: "images_brightness",
    label: "사진이 지나치게 밝거나 어둡습니다.",
    key: "밝기",
    contentType: "images",
  },
  {
    value: "images_noise",
    label: "사진에 노이즈가 지나치게 많습니다.",
    key: "노이즈",
    contentType: "images",
  },
  {
    value: "images_focus",
    label: "초점이 맞지 않습니다.",
    key: "초점",
    contentType: "images",
  },
  {
    value: "images_after",
    label: "보정이 과도한 사진입니다.",
    key: "보정",
    contentType: "images",
  },
  {
    value: "images_balance",
    label: "수직/수평이 맞지 않습니다.",
    key: "수평",
    contentType: "images",
  },
  {
    value: "images_resolution",
    label: "해상도가 4MP 이하입니다.",
    key: "해상도",
    contentType: "images",
  },
  {
    value: "images_signature",
    label: "판매 콘텐츠 내의 서명 표기는 불가합니다.",
    key: "서명",
    contentType: "images",
  },

  {
    value: "coloringSheets_colored",
    label: "이미 색이 칠해져 있습니다.",
    key: "채색",
    contentType: "coloringSheets",
  },
  {
    value: "coloringSheets_notwhite",
    label: "배경색이 흰색이 아닙니다.",
    key: "배경색",
    contentType: "coloringSheets",
  },
  {
    value: "coloringSheets_small",
    label: "색을 칠할 수 있는 영역이 좁습니다.",
    key: "영역",
    contentType: "coloringSheets",
  },
  {
    value: "coloringSheets_outline",
    label: "선의 경계가 모호하여 색칠 영역이 불분명합니다.",
    key: "라인",
    contentType: "coloringSheets",
  },

  {
    value: "audios_coverart",
    label: "앨범 아트 이미지 저작권 위반으로 반려되었습니다.",
    key: "앨범아트",
    contentType: "audios",
  },
  {
    value: "audios_lrbalance",
    label: "좌,우 밸런스가 맞지 않습니다.",
    key: "밸런스",
    contentType: "audios",
  },
  {
    value: "audios_master",
    label: "볼륨이 지나치게 작거나 큽니다.",
    key: "볼륨",
    contentType: "audios",
  },
  {
    value: "audios_lowquality",
    label: "저음질 음원입니다.",
    key: "저음질",
    contentType: "audios",
  },

  {
    value: "general_self",
    label: "본인 요청에 의해 반려되었습니다.",
    key: "본인",
  },
  { value: "others", label: "기타", key: "기타" },
];
