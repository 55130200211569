<template>
  <div class="tr">
    <div class="td">NO</div>
    <div class="td">
      {{ $route.params.type === "AUDIO" ? "장르명 (KR)" : "카테고리명 (KR)" }}
    </div>
    <div class="td">
      {{ $route.params.type === "AUDIO" ? "장르명 (EN)" : "카테고리명 (EN)" }}
    </div>
    <div class="td">카테고리로 지정할 태그</div>
    <div class="td">포함 컨텐츠 수</div>
    <div class="td">사용여부</div>
    <div class="td">변경</div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
export default {
  extends: TableListRow,
  components: {},
};
</script>
