<template v-if="list.length">
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          :key="`type-${i}`"
          v-for="({ value, label }, i) in contentTypeArr"
          :label="label"
          @click="setContentType(value)"
          :active="$route.params.category === value"
        />
      </div>
    </template>
    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Dropdown
            v-if="$route.params.category === 'stickers'"
            v-model="salesTypeFilter"
            placeholder="판매 형태"
            :list="salesTypeFilterArr"
          />
          <Dropdown
            v-model="filter"
            placeholder="콘텐츠 상태"
            :list="filterArr"
          />
        </template>
        <template v-slot:search>
          <form v-on:submit.prevent="search">
            <Dropdown
              v-model="searchType"
              placeholder="검색 옵션"
              :list="searchTypeArr"
              :cancelOnReselect="false"
              class="dropdown"
            />
            <InputSearch
              v-model.trim="searchQuery"
              :placeholder="searchTypePlaceholders[searchType]"
            />
          </form>
        </template>
      </TableFilter>
    </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <div class="table-body" v-else-if="list.length > 0">
          <table-row
            v-for="(item, i) in list"
            :key="i"
            :item="item"
            :id="item.artworkId"
          />
        </div>
      </PagePanel>
      <br />
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import ContentRowHead from "./ContentRowHead";
import ContentRow from "./ContentRow";

import { InputSearch, PagePanel } from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      isOCP: this.$route.params.category === "stickers",
      API_URL: `/dam/v5/admin/${this.$route.params.category}`,
      searchQuery: "",
      // contentType: "sticker",
      contentTypeArr: [
        { value: "stickers", label: "스티커" },
        { value: "images", label: "이미지" },
        { value: "coloringSheets", label: "컬러링시트" },
        { value: "audios", label: "음원" },
        { value: "virtualAssets", label: "방송용 에셋" },
      ],
      listKey: "elements",
      filterKey: "status",
      salesTypeFilterKey: "salesTypes",
      // Search
      salesTypeFilter: false,
      salesTypeFilterArr: [
        { value: false, label: "전체" },
        { value: "SINGLE", label: "일반 판매" },
        { value: "SUBSCRIPTION", label: "구독 판매" },
      ],
      filter: false,
      searchType: "NICKNAME",
      searchTypeArr: [
        { value: "TITLE", label: "콘텐츠명" },
        { value: "NICKNAME", label: "닉네임" },
        { value: "CREATOR_ID", label: "크리에이터 아이디" },
        { value: "EMAIL", label: "이메일" },
      ],
      searchKey: "query",
      searchTypePlaceholders: {
        NICKNAME: "닉네임을 검색해주세요.",
        TITLE: "콘텐츠명을 검색해주세요.",
        CREATOR_ID: "크리에이터 아이디를 검색해주세요.",
        EMAIL: "이메일을 검색해주세요.",
      },
    };
  },
  extends: TableView,
  watch: {
    filter(n) {
      this.filterKey = "status";
      this.setFilter(n);
      this.filter = n;
    },
    salesTypeFilter(n) {
      this.filterKey = "salesType";
      this.setFilter(n);
      this.filter = n;
    },
    $route(to, _from) {
      this.API_URL = `/dam/v5/admin/${to.params.category}`;
    },
  },
  methods: {
    async setContentType(category) {
      if (this.$route.params.category === category) return;
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
          category,
        },
        null,
        true
      );
    },
    isContentType(type) {
      return this.$route.query && this.$route.query.artworkType === type;
    },
  },
  computed: {
    filterArr() {
      return [
        { value: false, label: "전체" },
        { value: "SALE", label: "판매중" },
        { value: "SALE_STOP", label: "판매중지" },
        this.$route.params.category === "stickers"
          ? { value: "SALE_REVIEW", label: "판매검토" }
          : null,
        { value: "DELETED", label: "삭제됨" },
        { value: "BLOCKED", label: "차단" },
        this.$route.params.category === "stickers"
          ? { value: "WAIT_SALE", label: "예약" }
          : null,
      ].filter((option) => option !== null);
    },
    // isContentSticker() {
    //   return this.contentType === "sticker";
    // },
    // isContentImage() {
    //   return this.contentType === "image";
    // },
    // isContentColoring() {
    //   return this.contentType === "coloring";
    // },
    // isContentAudio() {
    //   return this.contentType === "audio";
    // },
    // isContentTemplate() {
    //   return this.contentType === "template";
    // },
  },
  components: {
    "admin-layout": AdminLayout,
    InputSearch,
    "table-row-head": ContentRowHead,
    "table-row": ContentRow,
    PagePanel,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 40px 0.75fr 1fr 1.25fr 1fr 1fr 1fr 1fr;
  align-items: center;
  &.stickers {
    grid-template-columns: 40px 0.75fr 1fr 1.25fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.dropdown {
  width: 170px;
}
</style>
