<template>
  <div class="table-v-row">
    <div class="th">
      <div class="inner">
        <slot name="th" />
      </div>
    </div>
    <div class="td">
      <div class="inner">
        <slot name="td" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-v-row {
  display: grid;
  grid-template-columns: 204px 1fr;
  align-items: center;
  border-bottom: solid 1px var(--mono-100);
  &:last-child {
    border: 0;
  }
  ::v-slotted(.divider) {
    background-color: var(--mono-100);
    height: 12px;
    width: 1px;
    margin: 0 8px;
    display: inline-block;
    content: "";
  }
}

.th {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 16px 20px;
  background-color: var(--mono-100);
  border-right: solid 1px var(--mono-100);
  color: #333;
}
.td {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  padding: 16px 40px;
  color: #333;
}
.inner {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
