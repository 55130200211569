<template>
  <button 
    :class="[
      'editor-toolbar-button',
      {
        active: active
      }
    ]"
  >
    <div class="inner">
      <slot />
    </div>
    <slot name="root"/>
  </button>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

button.editor-toolbar-button {
  position: relative;
  outline: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  min-width: 32px;
  padding: 0 4px;
  display: flex;
  border-radius: 4px;
  color: var(--mono-900);
  margin: 0 1px;


  

  &:deep svg {
    display: block;
    height: 18px;
    width: auto;
    margin: auto;
    fill: currentColor;
  }
  &:hover {
    background-color: var(--primary-100);
  }
  &.active {
    background-color: var(--primary-500);
    color: white;
  }

  &:after {
    position: absolute;
    content: "";
    z-index: 0;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background-color: var(--primary-050);
    box-shadow: 0 0 0 2px var(--primary-500);
    border-radius: 2px;
    transition: 200ms transform, 200ms opacity;
    transform: scale(0.75);
    opacity: 0;
  }

  &:focus-visible:after {
    transform: scale(1.0);
    opacity: 0.75;
  }
  &:focus-visible {
    color: var(--primary-500);
  }


  .inner {
    margin: auto;
    position: relative;
    z-index: 2;
  }
}
</style>