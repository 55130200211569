<template>
  <div class="row">
    <div class="label-col">
      <label for="">{{ label }}</label>
    </div>
    <div class="content-col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["label"],
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  margin-bottom: 24px;
}

.label-col label {
  color: var(--mono-900);
  font-weight: bold;
}

.content-col {
  display: flex;
}
</style>
