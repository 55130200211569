<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <div class="list">
        <button @click="$router.push({name: 'ContentUploadAnimatedStickers'})">
          <i-sticker />
          <span>애니메이션 스티커</span>
        </button>
        <button @click="$router.push({name: 'ContentUploadStickers'})">
          <i-sticker />
          <span>스티커</span>
        </button>
        <button @click="$router.push({name: 'ContentUploadImage'})">
          <i-image />
          <span>이미지</span>
        </button>
        <button @click="$router.push({name: 'ContentUploadImageSource'})">
          <i-image />
          <span>원본 파일(AI, PSD, EPS)</span>
        </button>
        <!--
        <button>
          <i-audio />
          <span>음원</span>
        </button>
        <button>
          <i-coloring-sheet />
          <span>컬러링 시트</span>
        </button> -->
      </div>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import svgSticker from "@/components/svg/icons/svgSticker";
// import svgAudio from "@/components/svg/icons/svgAudio";
import svgImage from "@/components/svg/icons/svgImage";
// import svgColoringSheet from "@/components/svg/icons/svgColoringSheet";

export default {
  components: {
    "admin-layout": AdminLayout,
    "i-sticker": svgSticker,
    "i-image": svgImage,
    // "i-audio": svgAudio,
    // "i-coloring-sheet": svgColoringSheet,
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  margin: 145px auto 0;
}

.list button {
  width: 520px;
  height: 88px;
  padding: 0 55px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-radius: 44px;
  font-size: 20px;
  color: var(--mono-500);
  border: 0;
  background-color: #fff;
  &:hover {
    background-color: var(--primary-050);
    color: var(--primary-500);
  }
}

.list button svg {
  margin-right: 8px;
  &:deep(path) {
    fill: currentColor;
  }
}
</style>
