<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.9 9.9V50.1H50.1V9.9H9.9ZM9.5 7.5C8.39543 7.5 7.5 8.39543 7.5 9.5V50.5C7.5 51.6046 8.39543 52.5 9.5 52.5H50.5C51.6046 52.5 52.5 51.6046 52.5 50.5V9.5C52.5 8.39543 51.6046 7.5 50.5 7.5H9.5ZM41.25 23.25C43.7353 23.25 45.75 21.2353 45.75 18.75C45.75 16.2647 43.7353 14.25 41.25 14.25C38.7647 14.25 36.75 16.2647 36.75 18.75C36.75 21.2353 38.7647 23.25 41.25 23.25ZM16.8284 43.75L43.1716 43.75L30 30.5784L16.8284 43.75ZM14.4142 45.75L45.5858 45.75C46.4767 45.75 46.9229 44.6729 46.2929 44.0429L30.7071 28.4571C30.3166 28.0666 29.6834 28.0666 29.2929 28.4571L13.7071 44.0429C13.0771 44.6729 13.5233 45.75 14.4142 45.75ZM20 18.5C20.5523 18.5 21 18.0523 21 17.5C21 16.9477 20.5523 16.5 20 16.5H15.25C14.6977 16.5 14.25 16.9477 14.25 17.5C14.25 18.0523 14.6977 18.5 15.25 18.5H20Z"
      fill="#ADB5BD"
    />
  </svg>
</template>
