<template>
  <card>
    <template v-slot:title>분위기</template>
    <template v-slot:titleExtra> (택1) </template>

    <div class="categories">
      <div
        class="category-item"
        v-for="moodItem in moods"
        :key="moodItem.mood"
        v-on:click="selectMood(moodItem.mood)"
      >
        <Radio
          :checked="mood === moodItem.mood"
          :label="moodItem.krName"
          size="small"
        />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Radio } from "@ogqcorp/v-ui";
import { apiGet } from "../../../api/apiMethods";

export default {
  props: {
    _mood: String,
  },
  data() {
    return {
      moods: [],
      mood: "",
    };
  },
  created() {
    this.mood = this._mood;
    this.getMoods();
  },
  methods: {
    selectMood(val) {
      // this.$emit("setMood", val);
      this.mood = val;
    },
    async getMoods() {
      this.moods = [];
      const res = await apiGet("/dam/v5/audio-moods");
      this.moods = res.data;
    },
  },
  computed: {},
  components: {
    card: SingleContentCard,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 14px;
  grid-gap: 16px 8px;
}

.category-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category-item label {
  margin-left: 6px;
  color: var(--mono-900);
}
</style>
