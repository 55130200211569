<template>
  <div class="tr">
    <div class="td">
      <Checkbox 
        v-on:click="toggleCheck(item.id)" 
        :checked="checked" 
      />
    </div>
    <div class="td">{{ $formatDate(item.createdAt * 1000) }}</div>
    <div class="td">{{ item.title }}</div>
    <div class="td">{{ item.writerName }}</div>
    <div class="td">
      <Badge :color="status.color">
        {{ status.label }}
      </Badge>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";

export default {
  extends: TableListRow,
  computed: {
    status() {
      if (this.item.activated) {
        return { label: "사용중", color: "purple" };
      } else {
        return { label: "미사용", color: "yellow" };
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
