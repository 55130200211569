<template>
  <card>
    <template v-slot:title> 작가의 다른 콘텐츠 </template>

    <div
      :class="[
        'list',
        {
          scroll: scrollEnabled,
        },
      ]"
      ref="list"
    >
      <div class="items" ref="items">
        <template v-for="(item, i) in creatorContents">
          <template v-if="hasImage(item)">
            <item :item="item" :key="i" />
          </template>
        </template>
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import SingleContentContentItem from "./SingleContentContentItem";
export default {
  props: ["creatorContents"],
  data() {
    return {
      scrollEnabled: false,
    };
  },
  created() {},
  watch: {
    creatorContents() {
      this.$nextTick(() => {
        this.scrollEnabled =
          this.$refs.items.offsetHeight > this.$refs.list.offsetHeight;
      });
    },
  },
  methods: {
    hasImage(item) {
      switch (this.$route.params.type) {
        case "STICKER":
          return item.mainImageUrl;
        case "STOCK_IMAGE":
          return item.imageUrl;
        case "COLORING_SHEET":
          return item.afterImageUrl;
        case "AUDIO":
          return item.coverImageUrl;
        case "VIRTUAL_ASSETS":
          return item.imageUrl;
        default:
          return item.coverImageUrl;
      }
    },
  },
  computed: {},
  components: {
    card: SingleContentCard,
    item: SingleContentContentItem,
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-height: 355px;
  overflow: hidden;
  &.scroll {
    overflow: auto;
    margin-right: -10px;
    @extend .scrollbar;
  }
}
.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 14px 12px;
  .scroll & {
    margin-right: 10px;
  }
}
</style>
