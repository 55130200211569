<template>
  <div class="section-bar big spacer">
    <div class="section-title">
      <div class="title big">심사 이력</div>
    </div>
  </div>
  <div class="table">
    <div class="thead">
      <div class="tr">
        <div class="th">날짜</div>
        <div class="th">심사유형</div>
        <div class="th">검증자</div>
        <div class="th">상태</div>
        <div class="th">비고</div>
      </div>
    </div>
    <TableEmpty v-if="!isLoading && list.length < 1" />
    <div class="tbody" v-else-if="list.length > 0">
      <table-item v-for="item in list" :item="item" :key="item.reviewLogId" />
    </div>
  </div>
</template>

<script>
import { TableEmpty } from "@ogqcorp/v-ui";
import axios from "axios";
import ContentReviewLogItem from "./ContentReviewLogItem";
import { apiGet } from "@/api/apiMethods";

export default {
  props: ["contentId"],
  data() {
    return {
      isLoading: false,
      list: [],
      // API_URL: "/dam/v5/admin/reviewLogs",
      API_URL: `/dam/v5/admin/digital-assets/${this.contentId}/logs`,
    };
  },
  watch: {
    contentId: {
      handler(n) {
        this.getReviewLogs(n);
      },
      immediate: true,
    },
  },
  methods: {
    async getReviewLogs(n) {
      const API_URL = `/dam/v5/admin/digital-assets/${n}/logs`;
      const reviewLogsRes = await apiGet(API_URL);
      this.list = reviewLogsRes.data;
    },
  },
  components: {
    TableEmpty,
    "table-item": ContentReviewLogItem,
  },
};
</script>

<style lang="scss" scoped>
.table {
  color: var(--mono-900);
  background-color: white;
}

.thead {
  padding: 0 40px;
  background: var(--mono-200);
}
.tbody {
  padding: 0 40px;
}

.th {
  padding: 20px 0;
  text-align: center;
}

.tbody:deep(.tr),
.thead .tr {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1fr;
  text-align: center;
}

.tbody:deep(.td),
.tbody:deep(.th) {
  padding: 30px 0;
  font-size: 14px;

  &.status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.section-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;
  &.big {
    margin: 20px 0 40px;
  }
  &.spacer {
    margin-top: 80px;
  }
}
.section-bar .section-title {
  display: flex;
  align-items: center;
}
.section-bar a {
  color: var(--mono-900);
  padding: 0 12px;
  border: 1px solid var(--mono-900);
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 22px;
  outline: 0;
  text-decoration: none;
}

.section-title .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 20px 0 4px;
  .big & {
    font-size: 24px;
    margin: 0 0 0;
    color: #333;
  }
}
</style>
