<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.83539 2.27428C4.78284 2.14291 4.87959 2 5.02108 2H10.9757C11.1172 2 11.214 2.14291 11.1614 2.27428L10.2116 4.6489C10.1915 4.69901 10.1926 4.75509 10.2145 4.80441L12.2371 9.35514C12.2958 9.4874 12.199 9.63637 12.0543 9.63637H3.94252C3.79779 9.63637 3.70098 9.4874 3.75976 9.35514L5.7823 4.80441C5.80422 4.75509 5.80528 4.69901 5.78524 4.6489L4.83539 2.27428ZM6.20271 3L6.71371 4.27751C6.83397 4.57816 6.82763 4.91465 6.69612 5.21055L5.17353 8.63637H10.8233L9.3007 5.21055C9.16919 4.91465 9.16284 4.57816 9.2831 4.27751L9.7941 3H6.20271Z"
      fill="#040606"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 9C8.27614 9 8.5 9.22386 8.5 9.5L8.5 13.5C8.5 13.7761 8.27614 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5L7.5 9.5C7.5 9.22386 7.72386 9 8 9Z"
      fill="#040606"
    />
  </svg>
</template>
