<template>
  <AdminLayout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="상단고정">
          <Toggle v-model="topFixed" />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시여부">
          <Toggle v-model="activated" />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시일 선택">
          <Datepicker
            v-model="publishedAt"
            class="datepicker-item"
            placeholder="게시일 날짜 선택"
          />
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <div class="editor-wrap">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요"
            :stretch="true"
            ref="inputTitle"
          />
        </div>
        <div class="editor-wrap">
          <Editor v-model="contents" category="NOTICE" ref="editor" />
        </div>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button color="primary" :loading="isLoading" v-on:click="saveChanges"
        >저장</Button
      >
      <Button v-on:click="$router.push({ name: 'Notice' })">취소</Button>
    </template>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import {
  Button,
  Toggle,
  InputText,
  PagePanel,
  PagePanelFormRow,
  Datepicker,
} from "@ogqcorp/v-ui";
import { onBeforeMount, onMounted, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import Editor from "@/components/el/inputs/Editor.Prose";
import { apiGet, apiPost, apiPut } from "../../../api/apiMethods";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const isEdit = ref(route.name === "NoticeEdit");
    const topFixed = ref(false);
    const originalData = ref(null);
    const activated = ref(false);
    const publishedAt = ref(false);
    const title = ref("");
    const contents = ref("");

    // Template refs
    const editor = ref(null);

    const getNoticeData = async () => {
      if (isLoading.value) return;
      try {
        isLoading.value = true;
        const { data } = await apiGet(
          `/admin/notices/${route.params.noticeId}`
        );
        originalData.value = data;
        title.value = data.title || "";
        contents.value = data.contents || "";
        activated.value = data.activated;
        publishedAt.value = new Date(data.publishedAt * 1000);
        topFixed.value = data.topFixed || false;
        editor.value.setContent(contents.value);
      } catch (err) {
        if (err.code === 40000) {
          await router.replace({ name: "PageNotFound" });
        }
      } finally {
        isLoading.value = false;
      }
    };
    const constructNoticeData = async () => {
      return {
        activated: activated.value || false,
        topFixed: topFixed.value || false,
        title: title.value.trim() || "",
        contents: await editor.value.getHTMLContents(),
        publishedAt: parseInt(publishedAt.value.getTime() / 1000),
      };
    };
    const validateNoticeData = ({ title, contents, publishedAt }) => {
      if (!publishedAt) {
        throw new Error("<strong>[게시일]</strong>을 선택해주세요.");
      }
      if (!title || title.length < 1) {
        throw new Error("<strong>[제목]</strong>을 입력해주세요.");
      }
      if (!contents || contents.length < 1) {
        throw new Error("<strong>[내용]</strong>을 입력해주세요.");
      }
    };

    onMounted(() => {
      if (!isEdit.value) return;
      getNoticeData();
    });

    return {
      isLoading,
      isEdit,
      topFixed,
      originalData,
      activated,
      title,
      contents,
      publishedAt,
      getNoticeData,
      constructNoticeData,
      validateNoticeData,
      editor,
    };
  },
  methods: {
    saveChanges() {
      console.log("saveChanges");
      if (this.isEdit) {
        this.editNotice();
      } else {
        this.submitNotice();
      }
    },
    async submitNotice() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const data = await this.constructNoticeData();
        // 데이터 검증
        this.validateNoticeData(data);

        await apiPost(`/admin/notices`, data);
        await this.$alert({
          html: `[<strong>${data.title}(</strong>)]#{이} 성공적으로 등록됐습니다.`,
        });
        await this.$router.push({ name: "Notice" });
      } catch (err) {
        await this.$error(err.message);
      } finally {
        this.isLoading = false;
      }
    },
    async editNotice() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const data = await this.constructNoticeData();
        // 데이터 검증
        this.validateNoticeData(data);

        await apiPut(`/admin/notices/${this.originalData.noticeId}`, data);
        await this.$alert({
          html: `[<strong>${data.title}(</strong>)]#{이} 성공적으로 등록됐습니다.`,
        });
        await this.$router.push({ name: "Notice" });
      } catch (err) {
        await this.$error(err.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    AdminLayout,
    PagePanel,
    PagePanelFormRow,
    Toggle,
    InputText,
    Datepicker,
    Button,
    Editor,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}

.editor-wrap {
  padding: 24px 24px 24px 40px;
}
.editor-wrap + .editor-wrap {
  padding-top: 0;
}
</style>
