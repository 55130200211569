<template>
  <admin-layout>
    <template v-slot:title v-if="isReviewReady">
      <admin-title class="title">
        {{ contentTitle }}
        ({{ daId }})
        <Badge :color="status.color"> {{ status.label }} </Badge>
      </admin-title>
    </template>

    <template v-slot:head v-if="isReviewReady">
      <div>
        <div class="review-list-message" v-if="userIsInRole('REVIEWER')">
          심사 콘텐츠
          <strong>{{ reviewWaitListIds.length }}개 남음</strong>
        </div>
        <!-- 다음 심사 {{nextReviewId}} -->
      </div>
    </template>

    <template v-slot:body v-if="isReviewReady">
      <div class="section-bar">
        <div class="section-title">
          <Badge :solid="true" :color="creatorGrade.color">
            {{ creatorGrade.label }}
          </Badge>
          &nbsp;
          <Badge :color="contentQualityGrade.color" solid>
            {{ creator.qualityGrade }}
          </Badge>
          &nbsp;
          <Badge v-if="creator.brand" :color="'blue'" solid> B </Badge>
          &nbsp;
          <Badge v-if="creator.streamer" :color="'gray'" solid> ST </Badge>
          <div class="title">
            {{ creator.nickname }} ({{ creator.username }})
          </div>
          <router-link
            :to="{
              name: 'SingleCreator',
              params: { creatorId: creator.creatorId },
            }"
          >
            크리에이터 상세정보
          </router-link>
        </div>
        <div class="section-actions">
          <Button
            :solid="true"
            color="primary"
            :loading="isLoading"
            v-on:click="openPauseModal"
          >
            심사 보류
          </Button>
          <Button
            :solid="true"
            color="primary"
            :loading="isLoading"
            v-on:click="openRejectModal"
          >
            심사 반려
          </Button>
          <Button v-on:click="reviewDone" :isLoading="isLoading">
            심사 통과
          </Button>
        </div>
      </div>

      <div class="cards" v-if="content">
        <div class="card-col">
          <card-main
            :content="content"
            :contentId="contentId"
            :contentTitle="contentTitle"
            :contentKOTitle="contentKOTitle"
            :contentKODesc="contentKODesc"
            :contentENTitle="contentENTitle"
            :contentENDesc="contentENDesc"
            :canEdit="true"
            :stickerOptions="{
              hasDarkmode: true,
              hasCheckeredBG: true,
            }"
            v-on:setTextContent="setTextContent"
            v-on:pushTag="pushTag"
            v-on:removeTag="removeTag"
          />
        </div>
        <div class="card-col">
          <card-related-contents :relatedContents="relatedContents" />
          <card-creator-contents :creatorContents="creatorContents" />
          <card-recommended-place
            v-if="$route.params.type === 'STICKER'"
            :_recommendedUsablePlace="content.recommendedUsablePlace"
            :isDisabled="
              content?.salesTypes?.every((v) => v === 'SUBSCRIPTION')
            "
            ref="places"
          />
          <card-manage-market
            :_blacklistMarkets="content.blacklistMarkets"
            :_whitelistMarkets="content.whitelistMarkets"
            :isDisabled="
              (content.publicity != null && content.publicity.ownerId) ||
              content?.salesTypes?.every((v) => v === 'SUBSCRIPTION')
            "
            ref="markets"
          />
          <card-manage-price
            :_isFree="content.isFree"
            :isDisabled="
              content?.salesTypes?.every((v) => v === 'SUBSCRIPTION')
            "
            ref="price"
          />
          <card-publish-reservation
            v-if="
              (!content?.salesTypes?.every((v) => v === 'SUBSCRIPTION') &&
                review?.status === 'REVIEW') ||
              review?.status === 'PAUSED' ||
              review?.status === 'RETRY'
            "
            :_publishedAt="content.publishedAt"
            ref="publishReservation"
          />
        </div>
      </div>
      <content-review-log :contentId="contentId" v-if="contentId" />
    </template>
  </admin-layout>

  <!-- 심사 보류 및 반려 모달 -->
  <modal-sales-end
    v-on:close="isSalesModalOpen = false"
    :onConfirm="rejectOrPauseReview"
    :isOpen="isSalesModalOpen"
    :isLoading="isLoading"
    :isReject="isReject"
    :contentType="contentType"
    ref="rejectReviewModal"
  />
</template>

<script>
import axios from "axios";

import SingleContentBase from "@/views/contents/main/SingleContentBase";

import SingleContentManageMarket from "@/views/contents/cards/SingleContentManageMarket";
import SingleContentManagePrice from "@/views/contents/cards/SingleContentManagePrice";
import SingleContentRelatedContents from "@/views/contents/cards/SingleContentRelatedContents";
import SingleContentCreatorContents from "@/views/contents/cards/SingleContentCreatorContents";
import SingleContentRecommendedUsablePlace from "@/views/contents/cards/SingleContentRecommendedUsablePlace";
import SingleContentPublishReservation from "@/views/contents/cards/SingleContentPublishReservation";
import SingleContentSalesEndModal from "./SingleContentSalesEndModal";

import {
  getReviewStatusDisplayData,
  getCreatorGradeDisplayData,
  getContentQualityGradeDisplayData,
} from "@/utils";
import { apiGet, apiPost } from "../../../../api/apiMethods";

const CATEGORIES = {
  STICKER: "sticker",
  STOCK_IMAGE: "image",
  COLORING_SHEET: "coloringSheet",
  AUDIO: "audio",
  VIRTUAL_ASSETS: "virtualAsset",
};

export default {
  extends: SingleContentBase,
  data() {
    return {
      review: false,
      isReject: false,
      isSalesModalOpen: false,
      relatedContents: [],
      creatorContents: [],
      reviewWaitListIds: [],
      isReviewReady: false,
      isReviewLoading: false,
      isLoading: false,
    };
  },
  created() {
    this.loadSingleReviewById();
  },
  watch: {
    $route(n) {
      if (n.name === "SingleContentReview") {
        this.loadSingleReviewById();
      }
    },
  },
  methods: {
    // Single Review
    async requestSingleReviewById() {
      const API_SINGLE_REVIEW_URL = `/dam/v5/admin/reviews/${
        this.$route.params.reviewId
      }/${CATEGORIES[this.$route.params.type]}`;
      const response = await apiGet(API_SINGLE_REVIEW_URL);
      return response?.data;
    },
    async loadSingleReviewById() {
      this.isReviewLoading = true;
      try {
        const review = await this.requestSingleReviewById();

        console.log(
          "loadSingleREviewByID, review",
          review,
          review[CATEGORIES[this.$route.params.type]]
        );

        if (review[CATEGORIES[this.$route.params.type]].publicity) {
          review[CATEGORIES[this.$route.params.type]].whitelistMarkets = [
            process.env.NODE_ENV === "production"
              ? "16f19df1d6d0001"
              : "16f63f02e7e0005",
          ];
          review[CATEGORIES[this.$route.params.type]].blacklistMarkets = [];
        }
        await this.getReviewList();
        this.content = review[CATEGORIES[this.$route.params.type]];
        this.contentId = this.content[`${this.contentType.slice(0, -1)}Id`];
        console.log("this.content", this.content);

        this.creator = review[CATEGORIES[this.$route.params.type]].creator;
        console.log("this.creator", this.creator);
        this.review = review;
        console.log("review", { review, content: this.content });

        this.syncContentTextContents();
        this.getRelatedContents();
        this.getCreatorContents();
      } catch (err) {
        this.content = false;
        this.creator = false;
        this.$router.replace({ name: "PageNotFound" });
      } finally {
        this.isReviewLoading = false;
        this.isReviewReady = true;
        if (this.review?.status === "DONE") {
          await this.onAlreadyReviewed();
        }
      }
    },

    // Modal Imported in Template
    openPauseModal() {
      if (this.isReviewDone) {
        this.onAlreadyReviewed();
      } else {
        this.isReject = false;
        this.isSalesModalOpen = true;
      }
    },
    openRejectModal() {
      if (this.isReviewDone) {
        this.onAlreadyReviewed();
      } else {
        this.isReject = true;
        this.isSalesModalOpen = true;
      }
    },
    //
    onAlreadyReviewed() {
      this.$alert({
        msg: "이미 심사가 완료된 콘텐츠입니다.",
      });
    },
    //
    setTextContent({ value, refName }) {
      this[refName] = value;
    },
    // Admin Tags
    pushKeyword(tag) {
      if (this.content.keywords.includes(tag)) return;
      this.content.keywords.push(tag);
    },
    removeKeyword(tag) {
      this.content.keywords = this.content.keywords.filter((t) => tag !== t);
    },
    // Creator Tags
    pushTag(tag) {
      if (this.content.tags.includes(tag)) return;
      this.content.tags.push(tag);
    },
    removeTag(tag) {
      console.log("tag remove", tag);
      this.content.tags = this.content.tags.filter((t) => tag !== t);
    },
    // Get Data
    async getReviewList() {
      try {
        const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
        const LIST_API_URL = `/dam/v5/admin/reviews?type=${this.$route.params.type}`;
        const response = await apiGet(LIST_API_URL);
        this.reviewWaitListIds =
          response?.data?.elements
            .filter((i) => i.status === "REVIEW" || i.status === "RETRY")
            .map((i) => i.reviewId) || [];
      } catch (err) {
        console.error("getReviewList", err);
      }
    },
    async getRelatedContents() {
      try {
        this.relatedContents = [];
        const RELATED_CONTENTS_API = `/dam/v5/admin/${this.contentType}/${this.contentId}/related`;
        const response = await apiGet(RELATED_CONTENTS_API);
        this.relatedContents = response?.data?.elements;
      } catch (err) {
        console.error("getRelatedContents", err);
      }
    },
    async getCreatorContents() {
      try {
        this.creatorContents = [];
        const response = await apiGet(
          `/dam/v5/admin/${this.contentType}/creators/${this.creator.creatorId}/list`
        );
        this.creatorContents = response?.data?.elements;
      } catch (err) {
        console.error("getCreatorContents");
      }
    },
    getImageUrl(content) {
      if (!content) return;

      switch (content.type) {
        case "STICKER":
          return content.mainImageUrl;
        case "STOCK_IMAGE":
          return content.imageUrl;
        case "COLORING_SHEET":
          return content.beforeImageUrl;
        case "AUDIO":
          return content.coverImageUrl;
        case "VIRTUAL_ASSETS":
          return content.imageUrl;
      }
    },
    async rejectOrPauseReview() {
      console.log("rejectOrPauseReview  ", this.content);

      const reason = this.$refs.rejectReviewModal.reason || "";
      const memo = this.$refs.rejectReviewModal.memo || "";

      if (!reason) {
        await this.$alert({
          msg: "사유를 선택해주세요.",
        });
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;

      try {
        const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
        const daName = this.contentTitle;
        const daType = this.content.type;
        const creatorId = this.creator.creatorId;
        let data = {
          reason,
          memo,
          daName,
          daType,
          creatorId,
        };

        if (this.isReject) {
          data = {
            ...data,
            imageUrl: this.getImageUrl(this.content),
            digitalAssetId: this.contentId,
          };
        }
        console.log("request data - 메모 포함", data);

        const API_URL = `/dam/v5/admin/reviews/${this.$route.params.reviewId}/${
          this.isReject ? "rejected" : "paused"
        }`;
        const response = await apiPost(API_URL, data);
        console.log("response", API_URL, response);
        this.onAfterReview();
      } catch (err) {
        console.log("rejectOrPauseReview", err);
      } finally {
        this.isSalesModalOpen = false;
        this.$refs.rejectReviewModal.clear();
        this.isLoading = false;
      }
    },
    async reviewDone() {
      if (this.isReviewDone) {
        return this.onAlreadyReviewed();
      }
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      const confirm = await this.$confirm({
        msg: "이 콘텐츠를 심사 통과시키시겠습니까?",
        confirmText: "예",
        cancelText: "아니요",
      });
      if (!confirm) return;
      const data = {
        ...{
          textContents: this.content.textContents,
          tags: this.content.tags,
          blacklistMarkets: this.content.blacklistMarkets,
          whitelistMarkets: this.content.whitelistMarkets,
          isFree: this.content.isFree,
          recommendedUsablePlace: this.content.recommendedUsablePlace,
        },
        ...{
          blacklistMarkets: this.$refs.markets.blacklistMarkets,
          whitelistMarkets: this.$refs.markets.whitelistMarkets,
          textContents: this.getEdittedTextContent(),
          isFree: this.$refs.price.isFree,
          ...(this.content.type === "STICKER"
            ? {
                recommendedUsablePlace:
                  this.$refs.places.recommendedUsablePlace,
              }
            : null),
          publishedAt:
            (this.$refs.publishReservation?.isPublishReserved &&
              this.$formatTime(
                this.$refs.publishReservation?.publishReservedAt
              )) ||
            null,
        },
        daName: this.contentTitle,
        daType: this.content.type,
        creatorId: this.creator.creatorId,
      };
      try {
        this.isLoading = true;
        const API_REVIEW_URL = `/dam/v5/admin/reviews/${this.$route.params.reviewId}/done`;
        await apiPost(API_REVIEW_URL, data);
        this.onAfterReview();
      } catch (err) {
        console.error("reviewDone", err.response);
        this.$error();
      } finally {
        this.isLoading = false;
      }
    },
    async onAfterReview() {
      const isAdmin = this.userIsInRole(["ADMIN", "OCS_OPERATOR"]);
      if (isAdmin) {
        // this.loadSingleReviewById();
        await this.$alert({
          msg: "심사를 완료했습니다.",
        });
        window.location.reload();
        return;
      }
      console.log("REVIEW WAIT LIST IDS", this.reviewWaitListIds);
      console.log("NEXT REVIEW ID", this.nextReviewId);
      if (this.nextReviewId) {
        if (this.nextReviewId) {
          console.log("NEXT REVIEW ID EXISTS");
          this.isContinueOpen = false;
          await this.$router.push({
            name: "SingleContentReview",
            params: {
              type: this.$route.params.type,
              reviewId: this.nextReviewId,
            },
          });
        }
      } else {
        console.log("NEXT REVIEW ID DOESNT EXIST", "CONTINUE OPEN");
        console.log("Should show modal");
        const continueReview = await this.$confirm({
          html: `
            대기중인 콘텐츠의 심사가 완료되었습니다. 계속 입력하실 경우,
            <strong>[이어서 입력]</strong>을 선택해주세요. 입력 대기 화면에서 ‘입력
            시작'을 통해 새로운 리스트를 시작할 수도 있습니다.
          `,
          confirmText: "이어서 입력",
          cancelText: "돌아가기",
        });

        if (continueReview) {
          this.continueReview();
        } else {
          this.$router.push({
            name: "ContentReviews",
            params: { type: this.$route.params.type },
          });
        }
      }
    },
    async continueReview() {
      try {
        await apiPost(`/dam/v5/admin/reviews?type=${this.$route.params.type}`);
        this.isContinueOpen = false;
        await this.getReviewList();
        if (this.nextReviewId) {
          await this.$router.push({
            name: "SingleContentReview",
            params: {
              type: this.$route.params.type,
              reviewId: this.nextReviewId,
            },
          });
        } else {
          await this.$alert({
            msg: "심사할 콘텐츠가 존재하지 않습니다.",
          });
          this.$router.push({
            name: "ContentReviews",
            params: { type: this.$route.params.type },
          });
        }
      } catch (e) {
        console.log(e.response);
        return this.$confirm({
          msg: "배정을 받는데에 문제가 생겼습니다.",
          cancelText: "닫기",
          confirmText: "확인",
          onAfterClose: () => {
            this.$router.push({
              name: "ContentReviews",
              params: {
                type: this.$route.params.type,
              },
            });
          },
        });
      }
    },
  },
  computed: {
    daId() {
      return this.content[CATEGORIES[this.$route.params.type] + "Id"] || "";
    },
    isReviewDone() {
      return this.review.status === "DONE" || this.review.status === "CANCELED";
    },
    nextReviewId() {
      const filteredList = this.reviewWaitListIds.filter(
        (reviewId) => reviewId !== this.$route.params.reviewId
      );
      return filteredList.length > 0 ? filteredList[0] : false;
    },
    creator() {
      return this.review
        ? this.review[CATEGORIES[this.$route.params.type]].creator
        : false;
    },
    creatorGrade() {
      return getCreatorGradeDisplayData(this.creator.grade);
    },
    contentQualityGrade() {
      return getContentQualityGradeDisplayData(this.creator.qualityGrade);
    },
    status() {
      if (this.review && this.review.status) {
        return getReviewStatusDisplayData(
          this.review.status,
          this.review.retried
        );
      } else {
        return false;
      }
    },
  },
  components: {
    // Cards
    "card-manage-market": SingleContentManageMarket,
    "card-manage-price": SingleContentManagePrice,
    "card-related-contents": SingleContentRelatedContents,
    "card-creator-contents": SingleContentCreatorContents,
    "card-recommended-place": SingleContentRecommendedUsablePlace,
    "card-publish-reservation": SingleContentPublishReservation,
    // Modals
    "modal-sales-end": SingleContentSalesEndModal,
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0 0;
}

.sales-count {
  margin: 16px 0 0;
  font-size: 16px;
  color: var(--mono-800);
}

.section-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;
  &.big {
    margin: 20px 0 40px;
  }
  &.spacer {
    margin-top: 80px;
  }
}

.section-bar .section-title {
  display: flex;
  align-items: center;
}
.section-bar a {
  color: var(--mono-900);
  padding: 0 12px;
  border: 1px solid var(--mono-900);
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 22px;
  outline: 0;
  text-decoration: none;
}

.section-title .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 20px 0 4px;
  .big & {
    font-size: 24px;
    margin: 0 0 0;
    color: #333;
  }
}

.section-actions button {
  margin-left: 10px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.card-col {
  // flex: 1;
}

.card {
  padding: 20px;
  background-color: white;
  // height: 100%;
  & + .card {
    margin-top: 20px;
  }
}

.review-list-message {
  font-size: 16px;
  font-weight: 400;
  color: var(--mono-700);

  strong {
    font-weight: 500;
    color: var(--mono-900);
  }
}
</style>
