<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="전체"
          :active="!$route.query[statusKey]"
          v-on:click="setStatus()"
        />
        <Tab
          label="게시중"
          :active="$route.query[statusKey] === 'PUBLISHED'"
          v-on:click="setStatus('PUBLISHED')"
        />
        <Tab
          label="미게시"
          :active="$route.query[statusKey] === 'DISABLED'"
          v-on:click="setStatus('DISABLED')"
        />
        <Tab
          label="예약"
          :active="$route.query[statusKey] === 'RESERVED'"
          v-on:click="setStatus('RESERVED')"
        />
        <Tab
          label="종료"
          :active="$route.query[statusKey] === 'CLOSED'"
          v-on:click="setStatus('CLOSED')"
        />
      </div>
    </template>

    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Button 
            v-on:click="openFilterModal"
            :color="$route.query[filterKey] ? 'primary': 'default'"
            :solid="$route.query[filterKey] ? true : false"
          >
            {{ statusLabel }}
            <template v-slot:icon>
              <svgChevronDown />
            </template>
          </Button>
        </template>
        <template v-slot:search> </template>
      </TableFilter>
    </template>

    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button :to="{ name: 'PopupAdd' }">추가</Button>
          <Button :disabled="!isAnyRowsChecked" v-on:click="deleteSelected"
            >선택 삭제</Button
          >
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head
            v-on:toggleCheck="toggleAllRows('popupId')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <template v-else-if="list.length">
          <div class="table-body">
            <table-row
              v-for="item in list"
              v-on:toggleCheck="toggleCheckRow"
              :item="item"
              :checked="checkedRows.includes(item.popupId)"
              :key="item.popupId"
              :id="item.popupId"
            />
          </div>
        </template>
      </PagePanel>
    </template>
  </admin-layout>

  <Modal
    title="게시위치"
    :isDialog="true"
    :confirmText="'적용'"
    v-on:confirm="onFilterModalConfirm"
    v-on:open="onFilterModalOpen"
    ref="filterModal"
  >
    <template v-slot:list>
      <Radio
        class="modal-radio"
        v-for="popupPosition in popupPositions"
        v-on:click="selectFilter(popupPosition.value)"
        :checked="popupPosition.value === filter"
        :label="popupPosition.label"
        :key="popupPosition.value"
      />
    </template>
  </Modal>

</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";

import {Button, Modal, Radio, svg} from "@ogqcorp/v-ui";
const {svgChevronDown} = svg
import PopupTableRow from "@/views/service/popup/PopupTableRow";
import PopupTableRowHead from "@/views/service/popup/PopupTableRowHead";

import popupPositions from "./popupPositions";

export default {
  data() {
    return {
      API_URL: `/admin/popups`,
      deleteMessageHTML: `선택하신 팝업을 <br> 삭제하시겠습니까?`,
      searchQuery: "",
      listKey: "elements",
      idKey: "popupId",
      checkedRows: [],
      filter: null,
      filterKey: 'position',
      popupPositions
      // queryKeys: [
      //   'status', 'position'
      // ]
    };
  },
  extends: TableView,
  methods: {
    toggleCheckCondition(item) {
      return ['RESERVED', 'DISABLED'].includes(item.publishedStatus)
    },
    async applyFilter() {
      const query = this.$refs.filterModal.getQuery();
      await this.loadPage(
        {
          params: {
            ...this.$route.params,
            ...{ page: 1 },
          },
          query: {
            ...this.$route.query,
            ...query,
          },
        },
        { pushRoute: true }
      );
      this.isFilterModalOpen = false;
    },
  },
  computed: {
    statusLabel() {
      let pos = popupPositions.find(
        ({ value }) => value === this.$route.query.position
      );

      return pos ? pos.label : "게시위치";
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "table-row-head": PopupTableRowHead,
    "table-row": PopupTableRow,
    Modal,
    Radio,
    svgChevronDown,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.75fr 0.75fr 0.5fr 1.5fr 0.75fr 0.75fr 0.5fr 0.5fr;
  align-items: center;
}
</style>
