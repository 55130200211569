<template>
  <card>
    <template v-slot:title> 유사 콘텐츠 </template>

    <div 
      :class="[
        'list',
        {
          scroll: scrollEnabled
        }
      ]"
      ref="list"
    >
      <div 
        class="items"
        ref="items"
      >
        <item v-for="(item, i) in relatedContents" :item="item" :key="i" />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import SingleContentContentItem from "./SingleContentContentItem";
export default {
  props: ["relatedContents"],
  data() {
    return {
      scrollEnabled: false
    };
  },
  created() {},
  mounted() {
    console.log(this.$refs.items.offsetHeight, this.$refs.list.offsetHeight)
  },
  watch: {
    relatedContents() {
      this.$nextTick(()=> {
        this.scrollEnabled = this.$refs.items.offsetHeight > this.$refs.list.offsetHeight
      })
    }
  },
  methods: {},
  computed: {},
  components: {
    card: SingleContentCard,
    item: SingleContentContentItem,
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-height: 355px;
  overflow: hidden;
  &.scroll {
    overflow: auto;
    margin-right: -10px;
    @extend .scrollbar;
  }
}
.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 14px 12px;
  .scroll & {
    margin-right: 10px;
  }
}
</style>
