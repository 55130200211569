<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M4.2 11c0-3.839 3.161-7 7-7s7 3.161 7 7c0 2.032-.79 3.839-2.145 5.08C14.813 17.324 13.119 18 11.2 18c-3.839 0-7-3.161-7-7z"
        stroke="#ADB5BD"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        stroke="#ADB5BD"
        stroke-width="1.8"
        stroke-linecap="square"
        d="m17 17 2.8 3"
      />
    </g>
  </svg>
</template>
