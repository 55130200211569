<template>
  <PagePanel
    title="크리에이터 등급 관리"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>크리에이터 등급</template>
        <template v-slot:td>
          {{ creator.grade }}
          <Button
            :loading="isLoading"
            v-on:click="isEditModalOpen = true"
            class="edit-grade"
          >
            등록
          </Button>
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
  <PagePanel
    title="관리 이력"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <div class="tr">
      <div class="th">변경일자</div>
      <div class="th">기존 등급</div>
      <div class="th">변경 등급</div>
      <div class="th">변경 담당자</div>
      <div class="th">운영자 메모</div>
    </div>
    <div class="tr" v-for="log in logs.elements" :key="log.logId">
      <div class="td">
        {{ $formatDate(log.createdAt, "YYYY-MM-DD HH:mm:ss") }}
      </div>
      <div class="td">{{ log.from }}</div>
      <div class="td">{{ log.to }}</div>
      <div class="td">{{ log.updater.name }}</div>
      <div class="td">{{ log.reason }}</div>
    </div>
  </PagePanel>
  <Modal
    :title="'크리에이터 등급 등록'"
    :isOpen="isEditModalOpen"
    :isDialog="true"
    confirmText="확인"
    v-on:cancel="closeModal"
    v-on:confirm="save"
  >
    <modal-simple-row label="기존 등급">
      {{ creator.grade }}
      <!-- <InputText
        v-model.trim="creatorInfo.currentGrade"
        class="stretch"
        readonly
      /> -->
    </modal-simple-row>
    <modal-simple-row label="변경 등급">
      <Dropdown
        v-model="creatorInfo.updatedGrade"
        class="stretch"
        placeholder="등급을 선택해주세요."
        :list="creatorGrades"
        stretch
      />
    </modal-simple-row>
    <modal-simple-row label="운영자 메모">
      <Textarea
        v-model="creatorInfo.reason"
        class="input ta"
        :maxLen="300"
        :minHeight="'120px'"
      />
    </modal-simple-row>
    <hr />
    <p class="modal-desc">
      크리에이터 등급 변경 시 즉시 적용됩니다.<br />
      올바른 등급이 적용되었는지 확인해주세요.
    </p>
  </Modal>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";
import ModalSimpleFormRow from "@/components/modal/simple/ModalSimpleFormRow";
import { creatorGrades } from "../../creatorUtils";

import { PagePanel, Button, Modal, Dropdown, Textarea } from "@ogqcorp/v-ui";
import { apiPut } from "../../../../../api/apiMethods";

export default {
  props: ["creator", "logs"],
  data() {
    return {
      UPDATE_API_URL: `/ptn/v5/admin/creators/${this.$route.params.creatorId}/grade`,
      isEditModalOpen: false,
      creatorGrades,
      creatorInfo: {
        currentGrade: this.creator.grade,
        updatedGrade: this.creator.grade,
        reason: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.isEditModalOpen = false;
    },
    async save() {
      if (this.creator.grade === this.creatorInfo.updatedGrade) {
        this.closeModal();
        return;
      }
      const data = {
        grade: this.creatorInfo.updatedGrade,
        reason: this.creatorInfo.reason,
        updaterId: this.currentUser.serviceUserId,
      };
      try {
        await apiPut(this.UPDATE_API_URL, data);
        this.$emit("updatedGrade");
        this.closeModal();
      } catch (err) {
        console.log(err.response);
      }
    },
  },
  components: {
    "table-v": TableV,
    "table-v-row": TableVRow,
    "modal-simple-row": ModalSimpleFormRow,
    PagePanel,
    Button,
    Modal,
    Dropdown,
    Textarea,
  },
};
</script>

<style lang="scss" scoped>
.tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.6fr 1.4fr;
  align-items: center;
}
.th {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 16px 20px;
  background-color: var(--mono-100);
  color: #333;
}
.td {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 16px 20px;
  border: solid 1px var(--mono-100);
  border-right: solid 1px var(--mono-100);
  color: #333;
}
.edit-grade {
  margin-left: auto;
}
hr {
  margin-bottom: 20px;
}
.modal-desc {
  font-size: 14px;
}
.ta {
  width: 100%;
}
</style>
