<template>
  <card>
    <template v-slot:title> 관리자가 등록한 태그 </template>
    <template v-slot:titleExtra>(최대 20개)</template>
    <tag-input v-on:pushTag="pushTag" />
    <tags :tags="tags" v-on:removeTag="removeTag" class="tags" />
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import SingleContentTags from "./SingleContentTags";
import TagInput from '@/components/tag/TagInput'

export default {
  props: ["tags"],
  methods: {
    pushTag(tag="") {
      if (!tag) return;
      this.$emit("pushTag", tag);
    },
    removeTag(tag) {
      this.$emit("removeTag", tag);
    },
  },
  components: {
    card: SingleContentCard,
    tags: SingleContentTags,
    "tag-input": TagInput
  },
};
</script>

<style lang="scss" scoped>

.tags {
  margin-top: 20px;
}
</style>
