<template>
  <AdminLayout :title="$route.meta.title">
    <template v-slot:head> </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button v-on:click="openModal">관리자 추가</Button>
          <Button :disabled="!isAnyRowsChecked" v-on:click="deleteSelected"
            >선택 삭제</Button
          >
          <div class="action-status">{{ checkedRows.length }}개 선택됨</div>
        </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <AdminTableRowHead
            v-on:toggleCheck="toggleAllRows('userId')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <div class="table-body">
          <AdminTableRow
            v-for="(item, idx) in list"
            :key="item.userId"
            :idx="idx"
            :item="item"
            :checked="checkedRows.includes(item.userId)"
            @toggleCheck="toggleCheckRow"
            @edit="openModal(null, $event)"
            @toggleActivated="toggleActivated"
          />
        </div>
      </PagePanel>
    </template>
  </AdminLayout>

  <Modal
    :title="isEditStaff ? '관리자 수정' : '관리자 추가'"
    :isOpen="isAddModalOpen"
    :isDialog="true"
    :confirmText="modalConfirmText"
    v-on:cancel="closeModal"
    v-on:confirm="handleClickModalConfirm"
  >
    <modal-simple-row v-if="!isEditStaff && !isRegisteredOID" label="OID 확인">
      <InputText
        v-model.trim="staff.email"
        class="stretch"
        placeholder="이메일을 입력해주세요."
      />
    </modal-simple-row>
    <template v-else>
      <modal-simple-row label="이름">
        <InputText
          v-model.trim="staff.name"
          class="stretch"
          placeholder="이름을 입력해주세요."
        />
      </modal-simple-row>
      <modal-simple-row label="이메일">
        <InputText
          v-model.trim="staff.email"
          class="stretch"
          placeholder="이메일을 입력해주세요."
          :readonly="!this.isEditStaff"
        />
      </modal-simple-row>
      <modal-simple-row label="권한">
        <Dropdown
          v-model="staff.staffRole"
          class="stretch"
          placeholder="권한을 선택해주세요."
          :list="staffRoleArr"
          :stretch="true"
        />
      </modal-simple-row>
    </template>
  </Modal>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import ModalSimpleFormRow from "@/components/modal/simple/ModalSimpleFormRow";
import AdminTableRow from "@/views/admin/AdminTableRow";
import AdminTableRowHead from "@/views/admin/AdminTableRowHead";
import {
  Button,
  InputSearch,
  InputText,
  PagePanel,
  Dropdown,
  Modal,
} from "@ogqcorp/v-ui";
import { apiGet, apiPost, apiPut, apiDelete } from "@/api/apiMethods";

export default {
  data() {
    return {
      deleteMessageHTML: "관리자를 삭제하시겠습니까?",
      API_URL: "/admin/staffs",
      isAddModalOpen: false,
      shallowList: true,
      searchQuery: "",
      noPagination: true,
      // Staff Modification Values
      isEditStaff: false,
      staffRoleArr: [
        { value: "META_DATA_MANAGER", label: "메타데이터 관리자" },
        { value: "SEARCH_MANAGER", label: "검색어 관리자" },
        { value: "REVIEWER", label: "심사자" },
        { value: "OCS_OPERATOR", label: "운영자" },
        { value: "ADMIN", label: "마스터 관리자" },
      ],
      // Staff Add Data
      staff: {
        ogqId: "",
        userId: "",
        name: "",
        email: "",
        staffRole: "ADMIN",
        activated: false,
      },
      // OID 확인 여부
      isRegisteredOID: false,
    };
  },
  computed: {
    modalConfirmText() {
      return !this.isRegisteredOID
        ? "확인"
        : !this.isEditStaff
        ? "등록"
        : "수정";
    },
  },
  extends: TableView,
  methods: {
    toggleCheckRow(id) {
      if (this.checkedRows.includes(id)) {
        this.checkedRows = this.checkedRows.filter((v) => v !== id);
        return;
      }
      this.checkedRows.push(id);
    },
    async toggleActivated(userId, activated) {
      console.log(123, userId, activated);
      this.staff.userId = userId;
      this.staff.activated = activated;
      await this.editStaff();
    },
    onAfterCreated() {
      this.loadPageOld();
    },
    validateStaffInput() {
      const { name, email, staffRole } = this.staff;
      if (!name.trim()) this.$error("이름을 입력해주세요.");
      if (!email.trim()) this.$error("이메일을 입력해주세요.");
      if (!this.staffRoleArr.find(({ value }) => value === staffRole))
        this.$error("권한을 확인해주세요.");
    },
    async deleteSelected() {
      try {
        await apiDelete(`${this.API_URL}/${[...this.checkedRows]}`);
        await this.$alert({
          msg: "관리자 권한 삭제가 완료되었습니다.",
        });
        this.closeModal();
        await this.loadPage();
      } catch (err) {
        this.$alert({ msg: "계정 삭제가 불가능합니다." });
      }
    },
    async handleClickModalConfirm() {
      if (!this.isRegisteredOID && !this.isEditStaff) {
        await this.getOidUserByEmail();
        return;
      }
      if (this.isEditStaff) {
        await this.editStaff();
      } else {
        await this.saveStaff();
      }
    },
    async getOidUserByEmail() {
      try {
        const response = await apiGet(
          `/acc/v1/oapi/oidUsers?email=${this.staff.email}`
        );
        if (response.status === 200) {
          await this.$alert({
            msg: "관리자 등록이 가능한 계정입니다.",
          });
          this.staff.email = response.data.email;
          this.staff.ogqId = response.data.ogqId;
          this.staff.name = response.data.nickname;
          this.isRegisteredOID = true;
        }
      } catch (err) {
        this.$alert({
          msg: "관리자 등록이 불가능한 계정입니다.",
        });
      }
    },
    async editStaff() {
      try {
        await apiPut(`${this.API_URL}/${this.staff.userId}`, {
          staffRole: this.staff.staffRole,
          activated: this.staff.activated,
        });
        await this.$alert({
          msg: "관리자 수정이 완료되었습니다",
        });
        this.closeModal();
        await this.loadPage();
      } catch (error) {
        this.$alert({ msg: "관리자 정보를 수정할 수 없습니다." });
      }
    },
    async saveStaff() {
      try {
        this.validateStaffInput();
        await apiPost(this.API_URL, this.staff);
        await this.$alert({
          msg: "관리자 등록이 완료되었습니다",
        });
        this.closeModal();
        await this.loadPage();
      } catch (err) {
        if (err.response.status === 500) {
          await this.$alert({
            msg: "이미 관리자로 등록된 계정입니다.",
          });
        }
      }
    },
    clearModalForm() {
      this.staff = {
        ogqId: "",
        name: "",
        email: "",
        staffRole: "ADMIN",
      };
    },
    openModal(e, userId) {
      let result = userId != null ? this.setUserData(userId) : true;
      console.log(222, userId, result);
      this.isEditStaff = userId != null;
      if (result) {
        this.isAddModalOpen = true;
      }
    },
    closeModal() {
      this.isAddModalOpen = false;
      this.isEditStaff = false;
      this.isRegisteredOID = false;
      this.clearModalForm();
    },
    setUserData(userId) {
      const user = this.list.find((u) => u.userId === userId);
      if (user) {
        this.staff = {
          userId: userId,
          name: user.name,
          email: user.email,
          staffRole: user.staffRole,
        };
      }
      return user;
    },
  },
  components: {
    AdminLayout,
    Button,
    AdminTableRow,
    AdminTableRowHead,
    PagePanel,
    "modal-simple-row": ModalSimpleFormRow,
    Modal,
    InputText,
    InputSearch,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 1fr 1.75fr 3fr 2.5fr 1fr;
  align-items: center;
}
.table-body::v-deep .tr {
  padding: 24px 0;
  border-bottom: 1px solid var(--mono-100);
}
.table-head::v-deep .td,
.table-body::v-deep .td {
  padding: 0 8px;
  font-size: 0.8125rem;
  color: var(--mono-700);
}
.table-head::v-deep .td {
  font-size: 0.6875rem;
  font-weight: bold;
}
</style>
