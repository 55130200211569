<template>
  <div 
    :class="[
      'sticker-item',
      { 
        'sticker-item-drop': isHighlighted,
      }
    ]"
    v-on:click="selectFileForSticker"
    v-on:drop.prevent="onDrop"
    v-on:dragenter.prevent="setDropActive"
    v-on:dragover.prevent="setDropActive"
    v-on:dragleave.prevent="setDropInactive"
    ref="item"
    :data-index="index"
  >
    <div class="sticker-item-label" v-if="!isRepositioning">
      {{index + 1}}
    </div>
    <div class="sticker-img-cont" v-if="sticker">
      <div class="sticker-img-wrapper" v-if="sticker">
        <img :src="sticker.dataUrl" :alt="sticker.name" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDragActive: false,
      cursor: {
        x: 0, y: 0
      },
      isMouseDown: false,
      clonedEl: null
    }
  },
  props: {
    index: {
      type: Number,
    },
    sticker: Object,
    isRepositioning: Boolean,
    highlightRange: Array,
  },
  methods: {
    setDropActive(e) {
      this.$emit('highlightItems', {
        index: this.index,
        length: e.dataTransfer.items.length
      })
      this.isDragActive = true
    },
    setDropInactive() {
      this.$emit('highlightItems', {})
      this.isDragActive = false
    },
    onDrop(e) {
      console.log("drop", e.dataTransfer.files)
      const files = e.dataTransfer.files
      this.$emit('setStickerFileAtIndex', {
        index: this.index,
        files
      })
      this.isDragActive = false
    },
    selectFileForSticker() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.multiple = false
      input.onchange = (e) => {
        const file = e.target.files[0]
        this.$emit('setStickerFileAtIndex', {
          index: this.index,
          files: [file]
        })
      }
      input.click()
    },
  },
  computed: {
    isHighlighted() {
      return this.highlightRange.includes(this.index)
    }
  }
}
</script>

<style lang="scss" scoped>

.sticker-item {
  position: relative;
  width: var(--sticker-item-size);
  height: var(--sticker-item-size);
  // border: 1px solid var(--mono-400);
  // outline: 1px solid var(--mono-400);
  user-select: none;
  // background-color: white;

  &.swap-ready {
    background-color: var(--primary-050);
  }
  &.sticker-dragging { 
    outline-style: dashed;
    opacity: 0.5;
  }
  &.insert-after {
    box-shadow: inset -3px 0 0 0 var(--primary-500);
    z-index: 2;
  }
  &.insert-before {
    box-shadow: inset 3px 0 0 0 var(--primary-500);
    z-index: 2;
  }
}

.sticker-item-cloned {
  --sticker-cloned-item-size: 160px;
  display: block;
  width: var(--sticker-cloned-item-size);
  height: var(--sticker-cloned-item-size);
  border: 0;
  background-color: transparent;
}

.sticker-item-drop {
  background-color: var(--primary-050);
}

.sticker-item-label {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 16px;
  opacity: 0;
  color: var(--mono-500);
}

.sticker-img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // &.transparency {
  // }
}

.sticker-img-wrapper {
  width: 100px;
  height: 87px;
  margin: auto;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


</style>