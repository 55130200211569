const SELLER_TYPES = {
  FREE: "무료",
  PERSON: "개인",
  COMPANY: "사업자",
};

const QUALITY_GRADES = {
  STANDARD: "STANDARD",
  SPECIAL: "SPECIAL",
  BAD: "BAD",
};

const CREATOR_GRADES = {
  NORMAL: "NORMAL",
  PRO: "PRO",
  PARTNER: "PARTNER",
};

const sellerTypes = (() => {
  return Object.keys(SELLER_TYPES).map((key) => {
    return { value: key, label: SELLER_TYPES[key] };
  });
})();

const qualityGrades = (() => {
  return Object.keys(QUALITY_GRADES).map((key) => {
    return { value: key, label: QUALITY_GRADES[key] };
  });
})();

const creatorGrades = (() => {
  return Object.keys(CREATOR_GRADES).map((key) => {
    return { value: key, label: CREATOR_GRADES[key] };
  });
})();

export { sellerTypes, qualityGrades, creatorGrades };
