<template>
  <card>
    <template v-slot:title>추천 사용처</template>
    <div class="card-control card-control-c">
      <div class="card-control-body">
        <div class="list-row">
          <Checkbox
            v-for="({ label, value }, i) in usablePlaceArr"
            :key="`place-${i}`"
            :label="label"
            :value="value"
            :checked="recommendedUsablePlace.includes(value)"
            :disabled="isDisabled"
            @click="toggleCheckbox(value)"
          />
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Checkbox } from "@ogqcorp/v-ui";

export default {
  props: {
    _recommendedUsablePlace: {
      type: Array,
      default: () => [],
    },
    salesTypes: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recommendedUsablePlace: [],
      usablePlaceArr: [
        {
          value: "CHATTING",
          label: "채팅용",
        },
        {
          value: "POSTING",
          label: "포스팅용",
        },
        {
          value: "BROADCASTING",
          label: "방송용",
        },
      ],
    };
  },
  watch: {
    _recommendedUsablePlace: {
      handler(newVal) {
        this.recommendedUsablePlace = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    toggleCheckbox(val) {
      if (!this.recommendedUsablePlace.includes(val)) {
        this.recommendedUsablePlace.push(val);
        return;
      }
      let index = this.recommendedUsablePlace.findIndex((v) => v === val);
      this.recommendedUsablePlace.splice(index, 1);
    },
  },
  components: {
    card: SingleContentCard,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.card {
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head + .card-control-body {
  margin: 16px 0 0;
}

.card-control-body > .list-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
</style>
