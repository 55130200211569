<template>
  <div class="tr">
    <div class="td"></div>
    <div class="td">등록일시</div>
    <div class="td">프로필이미지</div>
    <div class="td center">유저 ID<br />(닉네임)</div>
    <!-- <div class="td center">등급</div> -->
    <div class="td center">크리에이터 등급</div>
    <div class="td center">콘텐츠 품질 등급</div>
    <!-- <div class="td">구분</div> -->
    <div class="td center">판매자 유형</div>
    <div class="td center">활동 상태</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";
export default {
  extends: TableListRow,
  components: {},
};
</script>
