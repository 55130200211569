<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="링크 URL">
          <InputText
            v-model.trim="linkUrl"
            placeholder="URL을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="배포일">
          <datepicker
            v-model="publishedDate"
            class="datepicker-item"
            placeholder="날짜 (시간)"
          />
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="내용" :alignTop="true">
          <Textarea
            v-model.trim="contents"
            placeholder="HTML로 내용을 입력해주세요."
          />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button :disabled="true">미리보기</Button>
      <Button v-on:click="$router.push(prevRoute)">취소</Button>
      <Button color="primary" v-on:click="saveChanges">저장</Button>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import {
  Button,
  Textarea,
  InputText,
  PagePanel,
  PagePanelFormRow,
  Datepicker,
} from "@ogqcorp/v-ui";
import { apiPost } from "../../../api/apiMethods";
export default {
  data() {
    return {
      prevRoute: null,
      API_URL: "/admin/terms",
      isLoading: false,
      title: "",
      linkUrl: "",
      contents: "",
      publishedDate: false,
      ordering: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "Policy") {
        vm.prevRoute = from;
      } else {
        vm.prevRoute = { name: "Policy" };
      }
    });
  },
  methods: {
    saveChanges() {
      this.submitPolicy();
    },
    async submitPolicy() {
      if (this.isLoading) return;
      this.isLoading = true;
      const policyData = {
        title: this.title.trim(),
        linkUrl: this.linkUrl.trim(),
        contents: this.contents.trim(),
        ordering: 0,
        publishedDate: this.$formatDate(
          this.publishedDate.getTime(),
          "YYYYMMDD"
        ),
      };
      console.log(JSON.stringify(policyData));
      try {
        await apiPost(this.API_URL, policyData);
        this.$router.push({
          name: "Policy",
        });
      } catch (err) {
        console.dir(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    Datepicker,
    Textarea,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.buttons button {
  margin-right: 8px;
}
</style>
