<template>
  <div class="tr">
    <div class="td">등록일시</div>
    <div class="td">썸네일</div>
    <div class="td">콘텐츠명</div>
    <div class="td">유저 ID<br />(닉네임)</div>
    <div class="td">작가등급</div>
    <div class="td">관리자</div>
    <div class="td center">
      메타데이터
      <div class="subset">
        <div class="subset-item">{{$route.params.type === 'AUDIO' ? '장르' : '카테고리'}}</div>
        <div class="subset-item">품질점수</div>
      </div>
    </div>
    <div class="td right">입력상태</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";
export default {
  extends: TableListRow,
  components: {},
};
</script>

<style lang="scss" scoped>
.subset {
  font-size: 11px;
  margin: 4px 0 0;
}
.subset-item {
  font-weight: 400;
  padding: 5px 20px;
  background-color: var(--mono-050);
}
</style>