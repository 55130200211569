<template>
  <div class="tr" :class="{ sticker: $route.params.type === 'STICKER' }">
    <div class="td">
      <Checkbox />
    </div>
    <div class="td">
      {{ $formatDate(item.createdAt * 1000) }}
    </div>
    <div class="td">
      <div class="img-wrapper">
        <img :src="$getImgUrl(item.da.imageUrl)" />
      </div>
    </div>
    <div class="td">
      <router-link
        v-if="item.reviewId"
        :to="{
          name: 'SingleContentReview',
          params: {
            type: item.da.daType,
            reviewId: item.reviewId,
          },
        }"
      >
        {{ item.da.daName }}
      </router-link>
    </div>
    <div class="td">
      {{ category }}
    </div>
    <div v-if="$route.params.type === 'STICKER'" class="td">
      {{ item.salesType === "SUBSCRIPTION" ? "구독 판매" : "일반 판매" }}
    </div>
    <div class="td">
      <router-link
        class="username"
        :to="{
          name: 'SingleCreator',
          params: {
            creatorId: item.creator.creatorId,
          },
        }"
      >
        {{ item.creator.nickname }}
        <br />
        ({{ item.creator.username }})
      </router-link>
    </div>
    <div class="td">
      {{ item.da.daId }}
    </div>
    <div class="td">
      <Badge
        :solid="true"
        :color="$getCreatorGradeDisplayData(item.creator.grade).color"
      >
        {{ $getCreatorGradeDisplayData(item.creator.grade).label }}
      </Badge>
    </div>
    <div class="td">
      <div class="badges">
        <Badge :color="status.color">
          {{ status.label }}
        </Badge>
        <Badge
          v-if="failReason"
          :color="failReason.color"
          :solid="true"
          class="badge-fail-reason"
        >
          {{ failReason.label }}
        </Badge>
      </div>
    </div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
import { getReviewStatusDisplayData } from "@/utils";
import presetReasons from "@/views/contents/main/BlockReasons";

export default {
  props: ["item"],
  extends: TableListRow,
  computed: {
    // salesTypes() {
    //   const salesTypeObj = {
    //     SINGLE: "일반 판매",
    //     SUBSCRIPTION: "구독 판매",
    //   };
    //   return this.item.salesTypes
    //     .map((type) => salesTypeObj[type] || "")
    //     .join(", ");
    // },
    category() {
      switch (this.item.da.daType) {
        case "STICKER":
          return "스티커";
        case "STOCK_IMAGE":
          return "이미지";
        case "COLORING_SHEET":
          return "컬러링시트";
        case "AUDIO":
          return "음원";
        case "VIRTUAL_ASSETS":
          return "방송용 에셋";
        default:
          return this.item.da.daType;
      }
    },
    status() {
      return getReviewStatusDisplayData(this.item.status);
    },
    failReason() {
      if (this.item.status !== "PAUSED") return;
      let find = presetReasons.find((r) => r.label === this.item.failReason);
      return {
        color: "red",
        label: find?.key ? find.key : "기타",
      };
    },
    // contentId() {
    //   switch (this.$route.params.category ) {
    //     case 'stickers':
    //       return this.item.stickerId
    //     case 'images':
    //       return this.item.imageId
    //     case 'coloringSheets':
    //       return this.item.coloringSheetId
    //     case 'audios':
    //       return this.item.audioId
    //     default:
    //       return '0'
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.tr > .td {
  // TODO: v-ui TableListRow 컴포넌트에 공통적용할것
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.img-wrapper {
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.badges {
  display: flex;
  align-items: center;
}

.badge-fail-reason {
  margin-left: 4px;
}
</style>
