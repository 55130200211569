<template>
  <div class="tr">
    <div class="td">{{ item.ordering }}</div>
    <div class="td">{{ item.krName }}</div>
    <div class="td">{{ item.enName }}</div>
    <div class="td">{{ item.tags.join(", ") }}</div>
    <div class="td">{{ item.assets }}</div>
    <div class="td">
      <Toggle v-model="activated" />
    </div>
    <div class="td">
      <Button size="small" v-on:click="$emit('edit', this.item.categoryId)">
        변경
      </Button>
    </div>
  </div>
</template>

<script>
import { Button, TableListRow, Toggle } from "@ogqcorp/v-ui";
import { getReviewStatusDisplayData } from "@/utils";
import { apiPut } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  props: ["item"],
  extends: TableListRow,
  components: {
    Toggle,
    Button,
  },
  computed: {
    activated: {
      get() {
        return this.item ? this.item.activated : false;
      },
      async set() {
        if (this.isLoading) return;
        this.isLoading = true;
        const { ordering, krName, enName, categoryId, tags } = this.item;
        console.log(ordering, krName, enName);
        try {
          await apiPut(`/dam/v5/categories/${categoryId}`, {
            krName,
            enName,
            ordering,
            activated: !this.item.activated,
            tags,
          });
          this.$emit("setActivated", {
            categoryId,
            activated: !this.item.activated,
          });
        } catch (error) {
          console.log(error.response);
        } finally {
          this.isLoading = false;
        }
      },
    },
    category() {
      switch (this.item.da.daType) {
        case "STICKER":
          return "스티커";
        case "STOCK_IMAGE":
          return "이미지";
        case "COLORING_SHEET":
          return "컬러링시트";
        case "AUDIO":
          return "음원";
        default:
          return this.item.da.daType;
      }
    },
    status() {
      return getReviewStatusDisplayData(this.item.status);
    },
    // contentId() {
    //   switch (this.$route.params.category ) {
    //     case 'stickers':
    //       return this.item.stickerId
    //     case 'images':
    //       return this.item.imageId
    //     case 'coloringSheets':
    //       return this.item.coloringSheetId
    //     case 'audios':
    //       return this.item.audioId
    //     default:
    //       return '0'
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  width: 48px;
  height: 48px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
