<template>
  <AdminLayout :title="$route.meta.title">
    <template #bodyHead>
      <div class="category_box">
        <ul class="category">
          <li
            v-for="(category, i) in categories"
            v-on:click="handleClickCategory(category.faqCategoryId)"
            :class="{ on: faqCategoryId === category.faqCategoryId }"
            :key="i"
          >
            {{ category.title }}
          </li>
        </ul>
        <Button size="small" v-on:click="handleCategoryEdit">
          FAQ 카테고리 편집
        </Button>
      </div>
      <TableAction>
        <template #actions>
          <Button v-if="!showOrder" size="small" :to="{ name: 'FaqAdd' }">
            FAQ항목 추가
          </Button>
          <Button
            v-if="!showOrder && faqList.length"
            size="small"
            v-on:click="handleOrderClick"
          >
            순서 변경
          </Button>
          <Button
            v-if="!showOrder && faqList.length"
            size="small"
            v-on:click="showSelect = true"
          >
            선택
          </Button>
          <Button
            v-if="selectFaqIds.length && !showOrder"
            size="small"
            v-on:click="handleDeleteClick"
          >
            선택 삭제
          </Button>
          <Button v-if="showOrder" size="small" v-on:click="handleOrderSave">
            저장
          </Button>
          <Button v-if="showOrder" size="small" v-on:click="handleOrderCancel">
            취소
          </Button>
        </template>
      </TableAction>
    </template>
    <template #body>
      <SlickList
        v-if="faqList.length > 0"
        class="faq_list"
        v-model:list="faqList"
        axis="y"
        :useDragHandle="true"
      >
        <SlickItem
          v-for="(faq, i) in faqList"
          class="faq-list-li"
          :key="faq.faqId"
          :index="i"
        >
          <div class="faq_f">
            <DragHandle>
              <svgDrawer v-if="showOrder" />
            </DragHandle>
            <Checkbox
              v-if="showSelect && !showOrder"
              v-on:click="handleSelectClick(faq.faqId)"
              v-model="faq.checked"
            />
            <span @click="showFaq === faq ? (showFaq = '') : (showFaq = faq)">
              {{ faq.title }}
            </span>
            <div class="faq-svg-wrap">
              <router-link
                :to="{
                  name: 'FaqEdit',
                  params: {
                    faqId: faq.faqId,
                  },
                }"
              >
                <svgEdit />
              </router-link>
              <svgChevronUp
                v-if="showFaq === faq"
                style="fill: var(--mono-500)"
                @click="showFaq === faq ? (showFaq = '') : (showFaq = faq)"
              />
              <svgChevronDown
                v-else
                style="fill: var(--mono-500)"
                @click="showFaq === faq ? (showFaq = '') : (showFaq = faq)"
              />
            </div>
          </div>
          <div class="faq_q" v-show="faq === showFaq" v-html="faq.html" />
        </SlickItem>
      </SlickList>
      <div v-else class="list-none">
        등록된 항목이 없습니다. FAQ 항목을 추가해주세요.
      </div>
    </template>
  </AdminLayout>
  <Modal
    v-if="isEditModalOpen"
    :title="'FAQ 카테고리 편집'"
    :isDialog="true"
    :isOpen="isEditModalOpen"
    :width="modalWidth"
    :height="modalHeight"
    :isLongList="true"
    :confirmText="'순서 저장'"
    v-on:confirm="handleCategoryOrderSave"
    v-on:cancel="handleModalClose"
  >
    <div class="edit-modal">
      <Button
        size="small"
        style="width: fit-content; margin: 0 0 0 auto"
        v-on:click="handleCategoryAddView"
      >
        카테고리 추가
      </Button>
      <div v-if="isCategoryAdd" class="faq_list">
        <div class="faq-list-li">
          <div class="faq_f">
            <InputText
              v-model="newCategoryName"
              placeholder="카테고리명 입력"
            />
            <div class="faq-svg-wrap">
              <svgPlus v-on:click="handleCategoryAdd" />
            </div>
          </div>
        </div>
      </div>
      <SlickList
        class="faq-list-scroll"
        v-model:list="categories"
        axis="y"
        :useDragHandle="true"
      >
        <SlickItem
          v-for="(category, i) in categories"
          class="faq-list-li"
          :key="category.faqCategoryId"
          :index="i"
        >
          <div class="faq_f">
            <DragHandle v-if="!isEditCategory">
              <svgDrawer />
            </DragHandle>

            <InputText
              v-if="category.edit"
              v-model="editCategoryName"
              :placeholder="category.title"
            />
            <span v-else>
              {{ category.title }}
            </span>

            <div class="faq-svg-wrap">
              <svgEdit
                v-if="!isEditCategory"
                v-on:click="handleCategoryNameEdit(i)"
              />
              <svgMinus
                v-if="!isEditCategory"
                v-on:click="handleCategoryDeleteView(category.faqCategoryId)"
              />
              <Button
                v-if="isEditCategory"
                size="xsmall"
                v-on:click="handleCategoryNameSave(i)"
              >
                변경
              </Button>
              <Button
                v-if="isEditCategory"
                size="xsmall"
                color="primary"
                v-on:click="handleCategoryEditClose(i)"
              >
                취소
              </Button>
            </div>
          </div>
        </SlickItem>
      </SlickList>
    </div>
  </Modal>
  <Modal
    v-if="isSimpleModalOpen"
    :isOpen="isSimpleModalOpen"
    :isDialog="true"
    :title="simpleModalTitle"
    v-on:confirm="handleSimpleModalConfirm"
    v-on:cancel="handleSimpleModalClose"
  >
  </Modal>
</template>

<script setup>
import { ref, onMounted, watch, watchEffect, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdminLayout from "@/layouts/AdminLayout.vue";

import svgChevronDown from "@/components/svg/svgChevronDown.vue";
import svgChevronUp from "@/components/svg/svgChevronUp.vue";
import svgEdit from "@/components/svg/svgEdit.vue";
import svgDrawer from "@/components/svg/svgDrawer.vue";
import svgMinus from "@/components/svg/svgMinus.vue";
import svgPlus from "@/components/svg/svgPlus.vue";
import { TableAction, Button, Checkbox, Modal, InputText } from "@ogqcorp/v-ui";
import { SlickList, SlickItem, DragHandle } from "vue-slicksort";
import { apiGet, apiPut, apiPost, apiDelete } from "../../api/apiMethods";

const route = useRoute();
const router = useRouter();

const showFaq = ref("");
const showSelect = ref(false);
const showOrder = ref(false);
const categories = ref([]);
const faqList = ref([]);
const faqCategoryId = ref("");
const selectFaqIds = ref([]);
const isEditModalOpen = ref(false);
const modalWidth = ref("");
const modalHeight = ref("");
const isSimpleModalOpen = ref(false);
const simpleModalTitle = ref("");
const isCategoryAdd = ref(false);
const newCategoryName = ref("");
const simpleModalType = ref("");
const deleteCategoryId = ref("");
const editCategoryName = ref("");
const isEditCategory = ref(false);

onMounted(async () => {
  await loadCategories();
  await router.push({
    query: {
      faqCategoryId: faqCategoryId.value,
    },
  });
  await loadFaqs();
});

watch(
  () => route.query.faqCategoryId,
  async () => {
    await loadFaqs();
  }
);

const loadCategories = async () => {
  try {
    const faqCategories = await apiGet("/admin/faqCategories");
    categories.value = faqCategories.data.elements.map((el) => ({
      ...el,
      edit: false,
    }));
    faqCategoryId.value = categories.value[0]?.faqCategoryId;
  } catch (err) {
    console.error("Load Faq Categories Error ", err);
  }
};

const loadFaqs = async () => {
  try {
    const faqs = await apiGet(
      `/admin/faqs?pageSize=1000&faqCategoryId=${faqCategoryId.value}`
    );
    faqList.value = faqs.data.elements.map((el) => ({
      ...el,
      checked: false,
    }));
  } catch (err) {
    console.error("Load Faqs Error ", err);
  }
};

const handleClickCategory = async (categoryId) => {
  selectFaqIds.value = [];
  showSelect.value = false;
  faqCategoryId.value = categoryId;
  await router.push({
    query: {
      faqCategoryId: categoryId,
    },
  });
};

const handleSelectClick = (faqId) => {
  if (selectFaqIds.value.includes(faqId)) {
    selectFaqIds.value.splice(selectFaqIds.value.indexOf(faqId), 1);
  } else {
    selectFaqIds.value.push(faqId);
  }
};

const handleDeleteClick = async () => {
  try {
    await apiDelete("/admin/faqs", {
      data: {
        faqIds: selectFaqIds.value,
      },
    });
    selectFaqIds.value = [];
    await loadFaqs();
  } catch (err) {
    console.error("Delete Faq Error ", err);
  }
};

const handleOrderClick = () => {
  showOrder.value = true;
};

const handleOrderSave = async () => {
  try {
    let faqOrderList = faqList.value.map((el, i) => ({
      faqId: el.faqId,
      ordering: i,
    }));
    await apiPut(`/admin/faqs/${route.params.faqCategoryId}/ordering`, {
      ordering: faqOrderList,
    });
    router.push({ name: "Faq" });
  } catch (err) {
    console.error("Update Ordering FAQ Error ", err);
  } finally {
    showOrder.value = false;
  }
};

const handleOrderCancel = () => {
  showOrder.value = false;
};

const handleCategoryEdit = () => {
  modalWidth.value = "600px";
  isEditModalOpen.value = true;
};

const handleCategoryOrderSave = async () => {
  try {
    let categoryOrderList = categories.value.map((el, i) => ({
      faqCategoryId: el.faqCategoryId,
      ordering: i,
    }));
    await apiPut("/admin/faqCategories/ordering", {
      ordering: categoryOrderList,
    });
  } catch (err) {
    console.error("Update Ordering FAQ Error ", err);
  } finally {
    simpleModalType.value = "order";
    simpleModalTitle.value = "카테고리 순서가 저장되었습니다.";
    isSimpleModalOpen.value = true;
  }
};

const handleModalClose = () => {
  isEditModalOpen.value = false;
};

const handleCategoryNameEdit = (i) => {
  isEditCategory.value = true;
  categories.value[i].edit = true;
};

const handleCategoryNameSave = async (i) => {
  try {
    await apiPut(`/admin/faqCategories/${categories.value[i].faqCategoryId}`, {
      title: editCategoryName.value,
    });
    await loadCategories();
  } catch (err) {
    console.error("Update FAQ Category Error ", err);
  } finally {
    isEditCategory.value = false;
    categories.value[i].edit = false;
  }
};

const handleCategoryEditClose = (i) => {
  isEditCategory.value = false;
  categories.value[i].edit = false;
};

const handleCategoryDeleteView = (categoryId) => {
  deleteCategoryId.value = categoryId;
  simpleModalType.value = "delete";
  simpleModalTitle.value =
    "카테고리를 삭제할 경우, 등록된 항목도 모두 삭제됩니다. 자주 묻는 질문 카테고리를 삭제하시겠습니까?";
  isSimpleModalOpen.value = true;
};

const handleCategoryDelete = async () => {
  try {
    await apiDelete(`/admin/faqCategories/${deleteCategoryId.value}`);
    await loadCategories();
    await loadFaqs();
  } catch (err) {
    console.log(err);
  }
};

const handleSimpleModalConfirm = async () => {
  if (simpleModalType.value === "delete") {
    await handleCategoryDelete();
  }
  await loadCategories();
  await loadFaqs();
  isSimpleModalOpen.value = false;
  // isEditModalOpen.value = false;
};

const handleSimpleModalClose = () => {
  deleteCategoryId.value = "";
  isSimpleModalOpen.value = false;
};

const handleCategoryAddView = () => {
  isCategoryAdd.value = true;
};

const handleCategoryAdd = async () => {
  try {
    await apiPost("/admin/faqCategories", {
      title: newCategoryName.value,
    });
    simpleModalType.value = "add";
    isCategoryAdd.value = false;
    simpleModalTitle.value = "카테고리가 추가되었습니다.";
    newCategoryName.value = "";
    isSimpleModalOpen.value = true;
  } catch (err) {
    console.log("Add FAQ Category Error ", err);
  }
};
</script>

<style lang="scss" scoped>
.table-head:deep .tr,
.table-body:deep .tr {
  display: grid;
  grid-template-columns: 48px 0.725fr 2.5fr repeat(4, 0.5fr);
  align-items: center;
}
.category_box {
  display: flex;
  justify-content: space-between;
}
.category {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin: 0;
  width: 80%;
  & > li {
    padding: 8px 18px;
    border-radius: 100px;
    border: 1px solid var(--color-mono-100, #d9dbdf);
    font-size: 14px;
    cursor: pointer;

    width: max-content;
    &:hover {
      background-color: var(--mono-200);
    }
    &.on {
      background-color: var(--mono-200);
    }
  }
}
.faq_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.faq-list-scroll {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 500px;
  overflow-y: scroll;
  padding: 10px;
}
.faq-list-li {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(27, 34, 63, 0.1);
  z-index: 9999;
  & > .faq_f {
    padding: 12px 16px;
    background: #fff;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: block;
    }
    &:hover {
      color: var(--purple);
      text-decoration: underline;
    }
    span {
      cursor: pointer;
      margin: 0 0 0 10px;
    }
    svg {
      cursor: pointer;
    }
  }
  & > .faq_q {
    padding: 20px;
    background: var(--mono-200);

    :deep(img) {
      &.img-size-small {
        width: 50%;
      }
      &.img-size-medium {
        width: 75%;
      }
      &.img-size-large {
        width: 100%;
      }
    }
  }
}
.faq-svg-wrap {
  display: flex;
  gap: 10px;
  margin: 0 0 0 auto;
  align-items: center;
}
.actions-wrapper {
  padding: 30px 0 20px;
}
.edit-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.list-none {
  display: flex;
  margin: 10px;
}
</style>
