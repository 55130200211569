<template>
  <card>
    <div
      class="card-main"
      :class="{
        'card-review': $route.name === 'SingleContentReview',
      }"
    >
      <stickers
        v-if="category === 'stickers'"
        :mainImageUrl="content.mainImageUrl"
        :tabImageUrl="content.tabImageUrl"
        :stickerImages="content.stickerImages"
        :options="stickerOptions"
      />
      <stock-images
        v-if="category === 'images'"
        :imageUrl="content.imageUrl"
        :contentId="contentId"
      />
      <coloring-sheets
        v-if="category === 'coloringSheets'"
        :afterImageUrl="content.afterImagePath ? content.afterImageUrl : false"
        :beforeImageUrl="content.beforeImageUrl"
        :contentId="contentId"
      />
      <audios
        v-if="category === 'audios'"
        :contentId="contentId"
        :coverImageUrl="content.coverImageUrl"
      />
      <virtual-assets v-if="category === 'virtualAssets'" :content="content" />

      <Button
        class="btn-download"
        v-on:click="downloadDigitalAssets(category)"
        v-if="!noDownload && !userIsInRole('REVIEWER')"
        :loading="isDownloadLoading"
      >
        원본 다운로드
      </Button>
    </div>
    <card-title>
      <template v-slot:title> 콘텐츠 기본정보 </template>
    </card-title>
    <div class="table">
      <row>
        <template v-slot:key>등록일자</template>
        <template v-slot:value>{{
          $formatDate(content.createdAt * 1000, "YYYY-MM-DD HH:mm:ss")
        }}</template>
      </row>
      <row>
        <template v-slot:key>콘텐츠 타입</template>
        <template v-slot:value>{{ categoryLabel }}</template>
      </row>
      <row v-if="category === 'virtualAssets'">
        <template v-slot:key>에셋 타입</template>
        <template v-slot:value>{{ virtualAssetType }}</template>
      </row>
      <row>
        <template v-slot:key>판매 형태</template>
        <template v-slot:value>{{
          content.salesTypes === undefined
            ? "일반판매"
            : content.salesTypes.includes("SINGLE")
            ? "일반판매"
            : "구독판매"
        }}</template>
      </row>
      <!-- <row>
        <template v-slot:key>가격</template>
        <template v-slot:value></template>
      </row> -->
      <row
        :canEdit="canEdit"
        :isExpandable="true"
        v-on:editOn="focusInput('inputKOTitle')"
        ref="contentKOTitle"
      >
        <template v-slot:key>콘텐츠명(KR)</template>
        <template v-slot:value>
          {{ contentKOTitle }}
        </template>
        <template v-slot:edit>
          <form
            v-on:submit.prevent="setTextContent('KO', 'title', 'textKOTitle')"
          >
            <InputText
              v-model="textKOTitle"
              size="small"
              class="input"
              v-on:keydown.esc="cancelEdit('contentKOTitle')"
              ref="inputKOTitle"
            />
          </form>
        </template>
      </row>
      <row
        :canEdit="canEdit"
        :isExpandable="true"
        ref="contentKODesc"
        v-on:editOn="focusInput('inputKODesc')"
      >
        <template v-slot:key>콘텐츠 설명(KR)</template>
        <template v-slot:value>
          {{ contentKODesc }}
        </template>
        <template v-slot:edit>
          <form
            v-on:submit.prevent="
              setTextContent('KO', 'description', 'textKODesc')
            "
          >
            <InputText
              v-model="textKODesc"
              size="small"
              class="input"
              v-on:keydown.esc="cancelEdit('contentKODesc')"
              ref="inputKODesc"
            />
          </form>
        </template>
      </row>
      <row
        :canEdit="canEdit"
        :isExpandable="true"
        ref="contentENTitle"
        v-on:editOn="focusInput('inputENTitle')"
      >
        <template v-slot:key>콘텐츠명(EN)</template>
        <template v-slot:value>
          {{ contentENTitle }}
        </template>
        <template v-slot:edit>
          <form
            v-on:submit.prevent="setTextContent('EN', 'title', 'textENTitle')"
          >
            <InputText
              v-model="textENTitle"
              size="small"
              class="input"
              v-on:keydown.esc="cancelEdit('contentENTitle')"
              ref="inputENTitle"
            />
          </form>
        </template>
      </row>
      <row
        :canEdit="canEdit"
        :isExpandable="true"
        v-on:editOn="focusInput('inputENDesc')"
        ref="contentENDesc"
      >
        <template v-slot:key>콘텐츠 설명(EN)</template>
        <template v-slot:value>
          {{ contentENDesc }}
        </template>
        <template v-slot:edit>
          <form
            v-on:submit.prevent="
              setTextContent('EN', 'description', 'textENDesc')
            "
          >
            <InputText
              v-model="textENDesc"
              size="small"
              class="input"
              v-on:keydown.esc="cancelEdit('contentENDesc')"
              ref="inputENDesc"
            />
          </form>
        </template>
      </row>
      <row>
        <template v-slot:key>퍼블리시티권(원저작자)</template>
        <template v-slot:value>{{
          content.publicity ? content.publicity.ownerName : "--"
        }}</template>
      </row>
      <row v-if="category === 'images'">
        <template v-slot:key>원본 사이즈 (가로x세로)</template>
        <template v-slot:value>
          {{ content.width.toLocaleString() }} x
          {{ content.height.toLocaleString() }}
        </template>
      </row>
    </div>

    <card-title>
      <template v-slot:title> 크리에이터가 등록한 태그 </template>
    </card-title>

    <tag-input v-on:pushTag="pushTag" />
    <tags :tags="content.tags" v-on:removeTag="removeTag" />
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Button, InputText } from "@ogqcorp/v-ui";
import SingleContentCardTitle from "@/views/contents/main//SingleContentCardTitle";
import SingleContentCardTableRow from "@/views/contents/main/SingleContentCardTableRow";
import SingleContentTags from "@/views/contents/main/SingleContentTags";

import ContentViewerStickers from "@/views/contents/viewer/ContentViewerStickers";
import ContentViewerColoringSheet from "@/views/contents/viewer/ContentViewerColoringSheet";
import ContentViewerAudios from "@/views/contents/viewer/ContentViewerAudios";
import ContentViewerStockImage from "@/views/contents/viewer/ContentViewerStockImage";
import ContentViewerVirtualAssets from "@/views/contents/viewer/ContentViewerVirtualAssets";
import TagInput from "@/components/tag/TagInput";

import { downloadDigitalAssetFiles } from "@/views/contents/fileUtil";
import { apiGet } from "../../../api/apiMethods";

export default {
  data() {
    return {
      isDownloadLoading: false,
      objectURL: null,
      textKOTitle: "",
      textKODesc: "",
      textENTitle: "",
      textENDesc: "",
    };
  },
  props: [
    "content",
    "contentId",
    "contentKOTitle",
    "contentKODesc",
    "contentENTitle",
    "contentENDesc",
    "contentTitle",
    "noDownload",
    "stickerOptions",
    "canEdit",
  ],
  created() {
    this.textKOTitle = this.contentKOTitle;
    this.textKODesc = this.contentKODesc;
    this.textENTitle = this.contentENTitle;
    this.textENDesc = this.contentENDesc;
  },
  beforeUnmount() {
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  },
  methods: {
    focusInput(refName) {
      this.$nextTick(() => {
        this.$refs[refName].focus();
      });
    },
    setTextContent(lang, key, refName) {
      this.$emit(`setTextContent`, {
        lang,
        key,
        value: this[refName],
        refName: refName.replace("text", "content"),
      });
      this.$refs[refName.replace("text", "content")].toggleEdit();
    },
    cancelEdit(ref) {
      console.log(ref);
      this.$refs[ref].toggleEdit();
    },
    removeTag(tag) {
      this.$emit("removeTag", tag);
    },
    pushTag(tag) {
      this.$emit("pushTag", tag);
    },
    async downloadDigitalAssets(digitalAssetType) {
      if (this.isDownloadLoading) return;
      this.isDownloadLoading = true;
      const fileName = this.contentTitle;
      try {
        const { data } = await apiGet(this.API_DOWNLOAD_URL);
        const objectURL = await downloadDigitalAssetFiles(digitalAssetType, data, fileName);
        if (this.objectURL) {
          URL.revokeObjectURL(objectURL);
          this.objectURL = null;
        }
        this.objectURL = objectURL;
      } catch (error) {
        console.log(error, error.response);
        this.$confirm({
          msg: "문제가 생겼습니다.",
          cancelText: "닫기",
        });
      } finally {
        this.isDownloadLoading = false;
      }
    },
  },
  computed: {
    API_DOWNLOAD_URL() {
      return `/dam/v5/admin/${this.category}/${this.contentId}/download`;
    },
    category() {
      let cat = this.$route.params.category || this.$route.params.type;
      switch (cat) {
        case "STICKER":
          return "stickers";
        case "COLORING_SHEET":
          return "coloringSheets";
        case "STOCK_IMAGE":
          return "images";
        case "AUDIO":
          return "audios";
        case "VIRTUAL_ASSETS":
          return "virtualAssets";
        default:
          return cat;
      }
    },
    categoryLabel() {
      let type = this.$route.params.category || this.$route.params.type;
      switch (type) {
        case "stickers":
        case "STICKER":
          return "스티커";
        case "images":
        case "STOCK_IMAGES":
          return "이미지";
        case "coloringSheets":
        case "COLORING_SHEET":
          return "컬러링시트";
        case "audios":
        case "AUDIO":
          return "음원";
        case "virtualAssets":
        case "VIRTUAL_ASSETS":
          return "방송용 에셋";
        default:
          return type;
      }
    },
    virtualAssetType() {
      return (
        {
          BROADCAST: "방송용 배경",
          PROP: "소품",
        }[this.content.virtualAssetType] || "--"
      );
    },
  },
  components: {
    card: SingleContentCard,
    stickers: ContentViewerStickers,
    "stock-images": ContentViewerStockImage,
    "coloring-sheets": ContentViewerColoringSheet,
    "virtual-assets": ContentViewerVirtualAssets,
    audios: ContentViewerAudios,
    Button,
    InputText,
    row: SingleContentCardTableRow,
    tags: SingleContentTags,
    "tag-input": TagInput,
    "card-title": SingleContentCardTitle,
  },
};
</script>

<style lang="scss" scoped>
.card-main {
  display: flex;
  flex-direction: column;
  padding: 0 0 40px;
  border-bottom: 1px solid var(--primary-050);
  margin: 0 0 40px;
}
.btn-download {
  // width: 120px;
  margin: 40px 0 0 auto;
  .card-review & {
    margin: 40px auto 0 0;
  }
}
.table {
  margin: -15px 0 60px;
}
.input {
  width: 100%;
}

.tags {
  margin-top: 20px;
}
</style>
