<template>
  <Modal
    title="테스트 이메일"
    :isDialog="true"
    :isLoading="isLoading"
    :confirmText="'보내기'"
    v-on:confirm="sendEmail"
  >
    <control>
      <template v-slot:label>메일 주소</template>
      <InputText
        v-model="emailAddress"
        placeholder="이메일 주소를 입력하세요."
        class="input"
      />
    </control>
  </Modal>
</template>

<script>
import ModalFormControl from "@/components/modal/ModalFormControl";
import {InputText, Modal} from "@ogqcorp/v-ui";

export default {
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      emailAddress: "",
    };
  },
  methods: {
    sendEmail() {
      this.$emit("sendEmail", this.emailAddress);
    },
  },
  components: {
    Modal,
    control: ModalFormControl,
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}
</style>
