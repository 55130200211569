<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="공지형태">
          <div class="group">
            <Radio
              v-for="(radio, i) in popupTypeArr"
              v-on:click="popupType = radio.name"
              :checked="popupType === radio.name"
              :disabled="this.isEdit && popupType !== radio.name"
              :label="radio.label"
              :key="i"
            />
            <!-- <Radio v-on:click="isHTML = true" :checked="isHTML" label="직접입력" /> -->
          </div>
        </PagePanelFormRow>
        <PagePanelFormRow label="게시여부">
          <Toggle v-model="activated" />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시위치">
          <Dropdown
            v-model="postPosition"
            placeholder="게시할 위치를 선택해주세요"
            :list="postPositionArr"
            :stretch="true"
            class="dropdown"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="게시시간">
          <div class="group">
            <Datepicker
              v-model="begunAt"
              class="datepicker-item"
              placeholder="시작일 (시작시간)"
            />
            <span class="dash">-</span>
            <Datepicker
              v-model="endedAt"
              class="datepicker-item"
              placeholder="종료일 (종료시간)"
            />
          </div>
        </PagePanelFormRow>
      </PagePanel>

      <PagePanel :isForm="true" v-if="popupType === 'IMAGE'">
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요"
            class="stretch"
            ref="inputTitle"
          />
        </PagePanelFormRow>
      </PagePanel>

      <PagePanel :isForm="true" v-if="popupType === 'IMAGE'">
        <PagePanelFormRow label="팝업이미지 (PC)" :alignTop="true">
          <image-uploader
            v-on:imageSelected="addPopupImage($event, 'pc')"
            v-on:imageDeleted="removePopupImage('pc')"
            :defaultImage="isEdit ? originalData.pcImageUrl : false"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="팝업 URL (PC)" :alignTop="true">
          <InputText
            v-model="pcLink"
            :stretch="true"
            :readonly="true"
            :placeholder="'현재 지원되지 않는 기능입니다.'"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="팝업이미지 (모바일)" :alignTop="true">
          <image-uploader
            v-on:imageSelected="addPopupImage($event, 'mobile')"
            v-on:imageDeleted="removePopupImage('mobile')"
            :defaultImage="isEdit ? originalData.mobileImageUrl : false"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="팝업 URL (모바일)" :alignTop="true">
          <InputText
            v-model="mobileLink"
            :stretch="true"
            :readonly="true"
            :placeholder="'현재 지원되지 않는 기능입니다.'"
          />
        </PagePanelFormRow>
      </PagePanel>

      <PagePanel
        :isForm="true"
        v-if="popupType === 'HTML' || popupType === 'HTML_SOURCE'"
      >
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요"
            class="stretch"
            ref="inputTitle"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="내용">
          <Textarea
            v-if="popupType === 'HTML_SOURCE'"
            v-model.trim="content"
            placeholder="HTML로 내용을 입력해주세요."
            ref="inputContent"
          />
          <Editor
            v-if="popupType === 'HTML'"
            v-model="content"
            category="POPUP"
            ref="editor"
          />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button :disabled="true">미리보기</Button>
      <Button color="primary" :loading="isLoading" v-on:click="saveChanges"
        >저장</Button
      >
      <Button v-on:click="$router.go(-1)">취소</Button>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import ImageUploader from "@/components/uploader/ImageUploader";
import popupPositions from "./popupPositions";
import Editor from "@/components/el/inputs/Editor.Quill";
import {
  Button,
  Radio,
  Toggle,
  InputText,
  PagePanel,
  PagePanelFormRow,
  Dropdown,
  Datepicker,
  Textarea,
} from "@ogqcorp/v-ui";
import { apiGet, apiPost, apiPut } from "../../../api/apiMethods";

export default {
  data() {
    return {
      // View Data
      isEdit: this.$route.name === "PopupEdit",
      isLoading: false,
      postPositionArr: popupPositions,
      popupTypeArr: [
        {
          name: "IMAGE",
          label: "이미지",
        },
        {
          name: "HTML_SOURCE",
          label: "HTML",
        },
        {
          name: "HTML",
          label: "직접입력",
        },
      ],
      // Popup Data
      originalData: false,
      activated: false,
      popupType: "IMAGE",
      isHTML: false,
      postPosition: false,
      begunAt: false,
      endedAt: false,
      title: "",
      content: "",
      pc: false,
      pcLink: "",
      mobile: false,
      mobileLink: "",
    };
  },
  created() {
    if (this.isEdit) this.getPopupData();
  },
  mounted() {},
  methods: {
    async getPopupData() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const response = await apiGet(
          `/admin/popups/${this.$route.params.popupId}`
        );
        const data = response.data;
        this.originalData = data;
        this.title = data.title || "";
        this.content = data.contents || "";
        this.activated = data.activated;
        this.begunAt = new Date(data.begunAt * 1000);
        this.endedAt = new Date(data.endedAt * 1000);
        this.postPosition = data.popupPosition;
        this.popupType = data.popupType;
        if (this.popupType === "HTML") {
          this.$nextTick(() => this.$refs.editor.setContent(this.content));
        }
      } catch (err) {
        if (err?.response?.data?.code === 40000) {
          this.$router.replace({ name: "PageNotFound" });
        }
      } finally {
        this.isLoading = false;
      }
    },
    addPopupImage(imageData, type) {
      this[type] = imageData.file;
    },
    removePopupImage(type) {
      this[type] = false;
    },
    saveChanges() {
      if (this.isEdit) {
        this.editPopup();
      } else {
        this.submitPopup();
      }
    },
    async editPopup() {
      if (this.isLoading) return;
      if (!this.postPosition) return;
      if (!this.begunAt || !this.endedAt) return;

      try {
        this.isLoading = true;
        let content = this.content;

        if (this.popupType === "HTML") {
          content = await this.$refs.editor.getHTMLContents();
        }

        const updateData = {
          title: this.title,
          content: content,
          position: this.postPosition,
          begunAt: this.$formatTime(this.begunAt.getTime()),
          endedAt: this.$formatTime(this.endedAt.getTime()),
          popupType: this.popupType,
          activated: this.activated,
        };
        const formData = new FormData();
        if (this.pc) {
          formData.append("pc", this.pc);
        }
        if (this.mobile) {
          formData.append("mobile", this.mobile);
        }
        const json = JSON.stringify(updateData);
        const blob = new Blob([json], { type: "application/json" });
        formData.append("updateData", blob);

        await apiPut(`/admin/popups/${this.originalData.popupId}`, formData);
        await this.$alert({
          html: `[<strong>${updateData.title}(</strong>)]#{이} 성공적으로 등록됐습니다.`,
        });
        this.$router.push({
          name: "Popup",
        });
      } catch (err) {
        console.log(err.response);
      } finally {
        this.isLoading = false;
      }
    },
    async submitPopup() {
      if (this.isLoading) return;
      if (!this.postPosition) return;
      if (!this.begunAt || !this.endedAt) return;

      try {
        this.isLoading = true;
        let content = this.content;

        if (this.popupType === "HTML") {
          content = await this.$refs.editor.getHTMLContents();
        }
        const popupData = {
          title: this.title,
          content: content,
          position: this.postPosition,
          begunAt: this.$formatTime(this.begunAt.getTime()),
          endedAt: this.$formatTime(this.endedAt.getTime()),
          popupType: this.popupType,
          activated: this.activated,
        };
        const formData = new FormData();
        if (!this.isHTML) {
          formData.append("pc", this.pc);
          formData.append("mobile", this.mobile);
        }
        const json = JSON.stringify(popupData);
        const blob = new Blob([json], { type: "application/json" });
        formData.append("popupData", blob);

        await apiPost("/admin/popups", formData);
        await this.$alert({
          html: `[<strong>${popupData.title}(</strong>)]#{이} 성공적으로 등록됐습니다.`,
        });
        this.$router.push({
          name: "Popup",
        });
      } catch (err) {
        this.$error(err?.response?.data?.message);
      } finally {
        this.isLoading = false;
      }
    },
  },

  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    Editor,
    "image-uploader": ImageUploader,
    Datepicker,
    Dropdown,
    Button,
    Radio,
    Textarea,
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.dropdown {
  width: 364px;
}

.group .radio-wrapper {
  margin-right: 30px;
}
</style>
