<template>
  <AdminLayout :title="'이미지 업로드'" :compact="true">
    <template v-slot:body>
      <UploadImage :image="image" v-on:imageSelected="onImageSelected" />

      <hr />

      <div class="card-grid">
        <div class="col">
          <div class="d">
            <Card>
              <template v-slot:title>이미지 정보</template>
              <div class="card-control card-control-c">
                <InputText
                  v-model="title"
                  placeholder="제목을 입력해주세요."
                  size="medium"
                  :stretch="true"
                />
              </div>
              <div class="card-control card-control-c">
                <Textarea
                  v-model="description"
                  placeholder="설명을 입력해주세요."
                  minHeight="150px"
                />
              </div>
              <div class="card-control card-control-c">
                <tag-input v-on:pushTag="pushTag" />
                <br />
                <SingleContentTags :tags="tags" v-on:removeTag="removeTag" />
                <div class="message">
                  3개 이상 20개 이하의 태그를 작성할 수 있어요.
                </div>
              </div>
              <div class="card-control">
                <div class="card-control-head">
                  <div class="label">사이즈</div>
                  <div class="size-data">
                    <span>{{ width }} px</span>
                    <span> X </span>
                    <span>{{ height }} px</span>
                  </div>
                </div>
              </div>
              <div class="card-control">
                <div class="card-control-head">
                  <div class="label">가격</div>
                  <div class="actions">
                    <Radio v-model="isFree" :value="true" label="무료" />
                    <Radio v-model="isFree" :value="false" label="유료" />
                  </div>
                </div>
              </div>
              <div class="card-control">
                <div class="card-control-head">
                  <div class="label">업로드 크리에이터(필수)</div>
                  <div class="actions">
                    <Button
                      v-on:click="openCreatorModal"
                      :solid="true"
                      size="xsmall"
                    >
                      매칭하기
                    </Button>
                  </div>
                </div>
                <div class="card-control-body">
                  <InputText
                    v-model="creatorId"
                    :stretch="true"
                    :readonly="true"
                    placeholder="크리에이터 ID를 입력해주세요."
                  />
                </div>
              </div>
              <div class="card-control">
                <div class="card-control-head">
                  <div class="label">예약 업로드</div>
                  <div class="actions">
                    <Toggle v-model="reserved" />
                  </div>
                </div>
                <br />
                <div class="card-control-head">
                  <Datepicker v-model="publishedAt" placeholder="예약일" />
                  ~
                  <Datepicker v-model="expiredAt" placeholder="종료일" />
                </div>
              </div>
            </Card>
          </div>
          <div class="d">
            <Card>
              <SingleContentImageType
                :imageType="imageType"
                @set-image-type="setImageType"
                ref="imageTypes"
              />
            </Card>
          </div>
          <div class="d">
            <Card>
              <SingleContentAdminTags
                :tags="keywords"
                v-on:pushTag="pushAdminTag"
                v-on:removeTag="removeAdminTag"
              />
            </Card>
          </div>
        </div>
        <div class="col">
          <div class="d">
            <Card>
              <SingleContentCategory
                class="card-sticker"
                _categoryType="images"
                :activeOption="true"
                ref="categories"
              />
            </Card>
          </div>
          <div class="d">
            <Card>
              <SingleContentManageMarket class="card" ref="markets" />
            </Card>
          </div>
          <div class="d">
            <Card>
              <SingleContentQuality class="card" ref="grade" />
              <div class="card-control">
                <Button
                  color="primary"
                  :solid="true"
                  :stretch="true"
                  v-on:click="onUpload"
                >
                  업로드하기
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <ContentUploadCreatorModal
        v-on:setCreator="setCreatorId"
        ref="creatorModal"
      />
    </template>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TagInput from "@/components/tag/TagInput";
import SingleContentTags from "@/views/contents/main/SingleContentTags";
import {
  InputText,
  Textarea,
  Button,
  Radio,
  Toggle,
  Datepicker,
} from "@ogqcorp/v-ui";
import Card from "@/views/contents/main/SingleContentCard";
import UploadImage from "@/components/image/upload/ImageMain";
import ContentUploadCreatorModal from "@/views/contents/upload/ContentUploadCreatorModal";
import SingleContentImageType from "@/views/contents/cards/SingleContentImageType";
import SingleContentAdminTags from "@/views/contents/cards/SingleContentAdminTags";
import SingleContentCategory from "@/views/contents/cards/SingleContentCategory";
import SingleContentManageMarket from "@/views/contents/cards/SingleContentManageMarket";
import SingleContentQuality from "@/views/contents/cards/SingleContentQuality";
import { readFileAsDataUrl } from "@/utils/files";
import Toast from "@/components/toast";
import { apiPost } from "../../../../api/apiMethods";

export default {
  name: "ContentUploadImage",
  data() {
    return {
      isLoading: false,
      title: "",
      description: "",
      grade: "NONE",
      tags: [],
      keywords: [],
      imageType: "",
      isFree: false,
      image: null,
      creator: null,
      creatorId: null,
      reserved: false,
      publishedAt: null,
      expiredAt: null,
      width: 0,
      height: 0,
      imageFileSize: 0,
      files: [],
    };
  },
  methods: {
    openCreatorModal() {
      this.$refs.creatorModal.open();
    },
    setCreatorId(creator) {
      this.creator = creator;
      // this.creatorId = `${creator.creatorId} (${creator.nickname})`;
      this.creatorId = creator.creatorId;
    },
    setImageType(imageType) {
      this.imageType = imageType;
    },
    pushTag(inputTag) {
      const allTags = inputTag.trim().split(",");

      for (const tag of allTags) {
        let tagToAdd = tag.trim();
        const delimiters = [32, 188, 44, 13];

        for (const delimiterChar of delimiters) {
          tagToAdd = tagToAdd.replace(
            new RegExp(String.fromCharCode(delimiterChar), "gi"),
            ""
          );
        }

        if (tagToAdd.length < 1) return;
        if (this.tags.includes(tagToAdd.trim())) return;
        this.tags.push(tagToAdd.trim());
      }
    },
    removeTag(tag) {
      this.tags = this.tags.filter((t) => tag !== t);
    },
    pushAdminTag(inputTag) {
      const allTags = inputTag.trim().split(",");

      for (const tag of allTags) {
        let tagToAdd = tag.trim();
        const delimiters = [32, 188, 44, 13];

        for (const delimiterChar of delimiters) {
          tagToAdd = tagToAdd.replace(
            new RegExp(String.fromCharCode(delimiterChar), "gi"),
            ""
          );
        }

        if (tagToAdd.length < 1) return;
        if (this.keywords.includes(tagToAdd.trim())) return;
        this.keywords.push(tagToAdd.trim());
      }
    },
    removeAdminTag(tag) {
      this.keywords = this.keywords.filter((t) => tag !== t);
    },
    async onImageSelected(file) {
      const data = await readFileAsDataUrl(file);

      if (data.type !== "image/png" && data.type !== "image/jpeg") {
        return Toast("이미지는 jpg, png 형식이어야 합니다.", { type: "error" });
      }

      if (
        data.meta.dimensions.width < 2000 ||
        data.meta.dimensions.height < 2000
      ) {
        return Toast("이미지는 2000px 이상이어야 합니다.", { type: "error" });
      }

      this.image = data;
      this.width = data.meta.dimensions.width;
      this.height = data.meta.dimensions.height;
      this.imageFileSize = data.file.size;

      Toast("이미지를 등록했습니다.", { type: "success" });
    },
    async onUpload() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        let data = {
          imagePath: "",
          textContents: [
            {
              lang: "KO",
              title: this.title.trim(),
              description: this.description.trim(),
            },
          ],
          tags: this.tags || [],
          isFree: this.isFree,
          userId: this.creator?.creatorId || null,
          keywords: this.keywords,
          grade: this.$refs.grade.grade,
          imageType: this.imageType,
          categoryIds: this.$refs.categories.categoryIds || [],
          blacklistMarkets: this.$refs.markets.blacklistMarkets,
          whitelistMarkets: this.$refs.markets.whitelistMarkets,
          width: this.width,
          height: this.height,
          imageFileSize: this.imageFileSize,
          publishedAt:
            this.reserved && this.publishedAt
              ? this.$formatTime(this.publishedAt.getTime())
              : null,
          expiredAt:
            this.reserved && this.expiredAt
              ? this.$formatTime(this.expiredAt.getTime())
              : null,
          files: this.files,
        };
        // ipId: null,

        // Validate Pre-Upload data here
        if (!this.image) {
          throw new Error("이미지를 등록해야 합니다.");
        }
        if (this.title.trim().length < 1) {
          throw new Error("제목을 입력해주세요.");
        }
        if (this.title.trim().length > 40) {
          throw new Error("제목을 40자 이하로 입력해주세요.");
        }
        if (this.description.trim().length < 1) {
          throw new Error("설명을 입력해주세요.");
        }
        if (this.description.trim().length > 160) {
          throw new Error("설명을 160자 이하로 입력해주세요.");
        }
        if (this.tags.length < 3 || this.tags.length > 20) {
          throw new Error(
            `태그는 3개 이상, 20개 이하여야 합니다. (현재 개수: ${this.tags.length})`
          );
        }
        if (!data.userId) {
          throw new Error("크리에이터를 선택해주세요.");
        }
        if (!this.imageType) {
          throw new Error("이미지 구분을 선택해주세요.");
        }
        if (data.categoryIds.length < 1) {
          throw new Error("카테고리를 선택해주세요.");
        }
        if (this.width === 0 || this.height === 0) {
          throw new Error("이미지가 제대로 등록되지 않았습니다.");
        }
        // TODO: 종료일 배포 후 주석 풀기
        // if (this.reserved && data.publishedAt >= data.expiredAt) {
        //   throw new Error("예약일/종료일을 확인해주세요.")
        // }
        // Upload Files
        const image = await this.uploadFile(
          "STOCK_IMAGE",
          this.image,
          this.image.name
        );
        data.imagePath = image.data.path;

        // Upload Content
        await apiPost(
          `/dam/v5/admin/images?creatorId=${this.creator.creatorId}`,
          data
        );
        Toast(`[${this.title.trim()}] 성공적으로 등록됐습니다.`, {
          type: "success",
        });
        setTimeout(
          () =>
            this.$router.push({
              name: "Contents",
              params: {
                category: "images",
                page: 1,
              },
            }),
          2000
        );
      } catch (err) {
        console.error(err.response);
        Toast(err.message || "문제가 생겼습니다.", { type: "error" });
      } finally {
        this.isLoading = false;
      }
    },
    uploadFile(fileType, fileData, fileName) {
      const API_URL = `/admin/file/${fileType}/upload`;
      const formData = new FormData();
      const file = new File([fileData.file], fileName);
      formData.append("userId", this.creator?.creatorId);
      formData.append("file", file);
      return apiPost(API_URL, formData, true);
    },
  },
  components: {
    AdminLayout,
    TagInput,
    SingleContentTags,
    InputText,
    Textarea,
    Button,
    Radio,
    Toggle,
    Datepicker,
    Card,
    UploadImage,
    ContentUploadCreatorModal,
    SingleContentImageType,
    SingleContentAdminTags,
    SingleContentCategory,
    SingleContentManageMarket,
    SingleContentQuality,
  },
};
</script>

<style lang="scss" scoped>
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  .col {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px 20px;
    //overflow: hidden;
    height: fit-content;
  }
}

.card-control {
  margin-top: 36px;
}

.card-control + .card-control-c {
  margin-top: 10px;
}

.card-control-r {
  display: flex;
}
.card-control-r > .list {
  display: flex;
  margin: 0 0 0 auto;
  gap: 10px;
}

.message {
  font-size: 14px;
  color: var(--mono-500);
  margin-top: 10px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head .actions {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}

.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head + .card-control-body {
  margin: 16px 0 0;
}

.card-control-body .message {
  font-size: 14px;
  color: var(--mono-400);
}
.input-tag {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-sticker {
  grid-column: span 2;
}

.size-data {
  display: flex;
  gap: 15px;
  color: var(--mono-500);
  font-size: 14px;
}

.datepicker-outer {
  width: 200px;
}
</style>
