<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab
          label="스티커"
          v-on:click="setContentType('STICKER')"
          :active="$route.params.type === 'STICKER'"
        />
        <Tab
          label="이미지"
          v-on:click="setContentType('STOCK_IMAGE')"
          :active="$route.params.type === 'STOCK_IMAGE'"
        />
        <Tab
          label="컬러링시트"
          v-on:click="setContentType('COLORING_SHEET')"
          :active="$route.params.type === 'COLORING_SHEET'"
        />
        <Tab
          label="음원"
          v-on:click="setContentType('AUDIO')"
          :active="$route.params.type === 'AUDIO'"
        />
      </div>
    </template>
    <template v-slot:bodyHead> </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button v-on:click="openCategoryAdd(null, $event)"> 추가 </Button>
        </template>
        <template v-slot:pagination> </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head />
        </div>
        <TableEmpty v-if="!isLoading && categoryList.length < 1">
          <template v-slot:message v-if="userIsInRole(['REVIEWER'])">
            배정받기 버튼을 눌러 심사를 시작해주세요!
          </template>
        </TableEmpty>
        <div class="table-body" v-else-if="categoryList.length > 0">
          <table-row
            v-for="(item, i) in categoryList"
            v-on:edit="openCategoryAdd"
            v-on:setActivated="setActivated"
            :key="i"
            :item="{ ...item, ordering: i + 1 }"
            :id="item.categoryId"
          />
        </div>
      </PagePanel>
    </template>
  </admin-layout>

  <CategoryAddModal
    :isOpen="isCategoryModalOpen"
    v-on:confirm="saveCategory"
    v-on:close="closeCategoryAdd"
    v-on:refresh="onAfterCreated"
    ref="modal"
  />
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import CategoryAddModal from "./CategoryAddModal";
import CategoriesRowHead from "./CategoriesRowHead";
import CategoriesRow from "./CategoriesRow";
import { getReviewStatusListData } from "@/utils";
import { Button } from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      API_URL: "/dam/v5/categories",
      isRequestingStart: false,
      searchQuery: "",
      contentType: "sticker",
      listKey: false,
      // Search
      status: false,
      statusArr: getReviewStatusListData(),
      isCategoryModalOpen: false,
      isCategoryEdit: false,
      // statusArr: [
      //   {value: 'REVIEW', label: '심사중'},
      //   {value: 'CANCELED', label: '재심사'},
      //   {value: 'REJECTED', label: '반려'},
      //   {value: 'PAUSED', label: '보류'},
      // ],
      searchType: false,
      noPagination: true,
      searchTypeArr: [
        { value: "TITLE", label: "콘텐츠명" },
        { value: "NICKNAME", label: "닉네임" },
        { value: "CREATOR_ID", label: "크리에이터 아이디" },
        { value: "EMAIL", label: "이메일" },
      ],
    };
  },
  extends: TableView,
  methods: {
    setActivated() {
      this.onAfterCreated();
    },
    openCategoryAdd(categoryId) {
      this.isCategoryModalOpen = true;
      this.isCategoryEdit = categoryId ? true : false;
      this.$refs.modal.isEdit = this.isCategoryEdit;
      this.$refs.modal.categoryId = categoryId ? categoryId : false;

      if (categoryId) {
        let category = this.list.find((c) => c.categoryId === categoryId);

        console.log(11123, category);
        if (category) {
          this.$refs.modal.setCategory(category);
        }
      }
    },
    closeCategoryAdd() {
      this.isCategoryModalOpen = false;
      this.isCategoryEdit = false;
      this.$refs.modal.isEdit = false;
      this.$refs.modal.categoryId = false;
      this.$refs.modal.clear();
    },
    async saveCategory() {
      await this.$refs.modal.save();
    },
    onAfterCreated() {
      this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
        },
        null,
        true
      );
    },
    async setContentType(type) {
      if (this.$route.params.type === type) return;
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1, type },
        },
        null,
        true
      );
    },
    isContentType(type) {
      return this.$route.query && this.$route.query.artworkType === type;
    },
  },
  computed: {
    isContentSticker() {
      return this.contentType === "sticker";
    },
    isContentImage() {
      return this.contentType === "image";
    },
    isContentColoring() {
      return this.contentType === "coloring";
    },
    isContentAudio() {
      return this.contentType === "audio";
    },
    isContentTemplate() {
      return this.contentType === "template";
    },
    categoryList() {
      return [...this.list].sort((a, b) => b.activated - a.activated);
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "table-row-head": CategoriesRowHead,
    "table-row": CategoriesRow,
    Button,
    CategoryAddModal,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.75fr 1fr 1fr 0.75fr;
  align-items: center;
}

.dropdown {
  width: 170px;
}
</style>
