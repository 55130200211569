<template>
  <div class="tr">
    <div class="td">
      <Checkbox 
        v-on:click="toggleCheck" 
        :checked="checked" 
        :isRemoveAll="true"
      />
    </div>
    <div class="td">작성일</div>
    <div class="td">게시위치</div>
    <div class="td">썸네일</div>
    <div class="td">제목</div>
    <div class="td">게시기간</div>
    <div class="td">게시상태</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>

<style lang="scss" scoped>
.td {
  font-weight: bold;
}
</style>
