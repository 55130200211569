<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck(item.popupId)" 
        :checked="checked" 
        :disabled="disabled"
      />
    </div>
    <div class="td">{{ $formatDate(item.createdAt * 1000) }}</div>
    <div class="td">{{ position }}</div>
    <div class="td">
      <div class="img-wrapper"></div>
    </div>
    <div class="td">
      <router-link
        :to="{
          name: 'PopupEdit',
          params: {
            popupId: item.popupId,
          },
        }"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td">
      {{ item.writerName }}
    </div>
    <div class="td">
      <div>{{ $formatDate(item.begunAt * 1000, "YYYY-MM-DD") }}</div>
      <div>{{ $formatDate(item.begunAt * 1000, "HH:mm:ss") }}</div>
      <div>~</div>
      <div>{{ $formatDate(item.endedAt * 1000, "YYYY-MM-DD") }}</div>
      <div>{{ $formatDate(item.endedAt * 1000, "HH:mm:ss") }}</div>
    </div>
    <div class="td">
      <Badge :color="status.status">
        {{ status.text }}
      </Badge>
    </div>
    <div class="td">
      {{ item.viewCount }}
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
import popupPositions from "./popupPositions";

export default {
  extends: TableListRow,
  methods: {
    onBeforeCheck() {
      return ['RESERVED', 'DISABLED'].includes(this.item.publishedStatus)
    }
  },
  computed: {
    status() {
      if (this.item.publishedStatus === "RESERVED") {
        return {
          status: "blue",
          text: "예약",
        };
      } else if (this.item.publishedStatus === "PUBLISHED") {
        return {
          status: "purple",
          text: "게시중",
        };
      } else if (this.item.publishedStatus === "DISABLED") {
        return {
          status: "yellow",
          text: "미게시",
        };
      } else if (this.item.publishedStatus === "CLOSED") {
        return {
          status: "gray",
          text: "종료",
        };
      } else {
        return {
          status: "purple",
          text: this.item.publishedStatus,
        };
      }
    },
    position() {
      let p = popupPositions.find((p) => p.value === this.item.popupPosition);
      return p ? p.label : this.item.popupPosition;
    },
    disabled() {
      return !['RESERVED', 'DISABLED'].includes(this.item.publishedStatus)
    }
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  width: 48px;
  height: 48px;
}
</style>
