<template>
  <PagePanel
    title="사업자 정보"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>업체명</template>
        <template v-slot:td>
          <InputText
            v-model="companyName"
            class="input"
            placeholder="업체명을 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>대표자</template>
        <template v-slot:td>
          <InputText
            v-model="ceoName"
            class="input"
            placeholder="대표자명을 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>사업자등록번호</template>
        <template v-slot:td>
          <InputText
            v-model="registrationNumber"
            class="input"
            placeholder="사업자 등록 번호를 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>업종</template>
        <template v-slot:td>
          <InputText
            v-model="businessCategory"
            class="input"
            placeholder="업종을 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>업태</template>
        <template v-slot:td>
          <InputText class="input" placeholder="업태를 입력하세요." />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>사업장 주소</template>
        <template v-slot:td>
          <InputText
            v-model="officeAddress"
            class="input"
            placeholder="사업장 주소를 입력해주세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>사업자 등록증</template>
        <template v-slot:td>
          <template v-if="sellerInfo.companyInfo.registrationFileName">
            <a href="" v-on:click.prevent="openFile">
              {{ sellerInfo.companyInfo.registrationFileName }}
            </a>
          </template>
          <template v-else> X </template>
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";
import { InputText, PagePanel } from "@ogqcorp/v-ui";
import { downloadBlob } from "@/views/contents/fileUtil";
import { apiGet } from "@/api/apiMethods";

export default {
  props: ["creator", "sellerInfo"],
  inheritAttrs: false,
  data() {
    return {
      fileDataUrl: false,
    };
  },
  created() {
    if (this.sellerInfo.companyInfo.registrationFileName) {
      this.getFile();
    }
  },
  methods: {
    async getFile() {
      try {
        const res = await apiGet(
          `/pmt/v5/admin/seller-infos/${this.creator.creatorId}/file`
        );
        this.fileDataUrl = res.data;
        // window.open(res.data, '_blank')
      } catch (err) {
        console.log(err.response);
      }
    },
    openFile() {
      const blob = this.dataURItoBlob(this.fileDataUrl);
      downloadBlob(blob, this.sellerInfo.companyInfo.registrationFileName);
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
  },
  computed: {
    companyName: {
      get() {
        return this.sellerInfo.companyInfo.companyName;
      },
      set(value) {
        this.$emit("update:companyName", value);
      },
    },
    ceoName: {
      get() {
        return this.sellerInfo.companyInfo.ceoName;
      },
      set(value) {
        this.$emit("update:ceoName", value);
      },
    },
    registrationNumber: {
      get() {
        return this.sellerInfo.companyInfo.registrationNumber;
      },
      set(value) {
        this.$emit("update:registrationNumber", value);
      },
    },
    businessCategory: {
      get() {
        return this.sellerInfo.companyInfo.businessCategory;
      },
      set(value) {
        this.$emit("update:businessCategory", value);
      },
    },
    officeAddress: {
      get() {
        return this.sellerInfo.companyInfo.officeAddress;
      },
      set(value) {
        this.$emit("update:officeAddress", value);
      },
    },
  },
  components: {
    PagePanel,
    "table-v": TableV,
    "table-v-row": TableVRow,
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 340px;
}
</style>
