<template>
  <div class="avatar">
    <img :src="imgUrl" alt="" />
  </div>
</template>

<script>
export default {
  props: ["url"],
  computed: {
    imgUrl() {
      return this.url
        ? this.$getImgUrl(this.url)
        : require(`@/assets/avatar_default.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
