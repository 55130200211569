<template>
  <div class="audio-main">
    <div class="cover-art">
      <img :src="$getImgUrl(coverImageUrl, 'o400_400')" />
    </div>
    <AudioPlayer class="audio-player" :source="source" v-if="source" />
  </div>
</template>

<script>
import { AudioPlayer } from "@ogqcorp/v-ui";
import { apiGet } from "../../../api/apiMethods";

export default {
  data() {
    return {
      isLoaded: false,
      source: false,
    };
  },
  props: ["coverImageUrl", "contentId"],
  created() {
    this.getAudio();
  },
  methods: {
    async getAudio() {
      try {
        const { data } = await apiGet(
          `/dam/v5/admin/audios/${this.contentId}/download?audioId=${this.source}`
        );
        const { audioFileUrl } = data;
        this.isLoaded = false;
        this.source = audioFileUrl;
              } catch (err) {
        console.log(err.response);
      }
    },
  },
  components: {
    AudioPlayer,
  },
};
</script>

<style lang="scss" scoped>
.audio-main {
}

.cover-art {
  position: relative;
  border: 1px solid var(--primary-200);
  width: 100%;
}

.cover-art img {
  display: block;
  width: 100%;
  height: auto;
}

.audio-player {
  margin-top: 25px;
}
</style>
