const selectFile = (options)=> {
  return new Promise((resolve)=> {
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = options?.accept || 'image/*'
    inputEl.multiple = options?.multiple || false

    inputEl.onchange = (e)=> {
      const files = (options?.multiple) ? e.target.files : e.target.files[0]
      resolve(files)
    }
    inputEl.click()
  })
}

const readFileAsDataUrl = (file)=> {
  return new Promise((resolve, reject)=> {
    const fileReader = new FileReader()
    fileReader.onload = ()=> {
      resolve(fileReader.result)
    }
    fileReader.onerror = reject
    fileReader.readAsDataURL(file)
  })
}


export {
  selectFile, readFileAsDataUrl
}