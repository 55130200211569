<template>
  <div class="creator-item">
    <div class="creator">
      <div class="creator-checkbox-wrapper">
        <Checkbox 
          :checked="isChecked"
          v-on:click="toggleCheck"
        />
      </div>
      {{creator.creatorId}} ({{creator.nickname}})
    </div>
  </div>
</template>

<script>
import {Checkbox} from '@ogqcorp/v-ui'

export default {
  props: {
    creator: Object,
    isChecked: Boolean
  },
  methods: {
    toggleCheck() {
      this.$emit('toggleCheck', !this.isChecked)
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style lang="scss" scoped>
.creator-item {
  position: relative;
  width: 100%;
  height: 140px;
  background-color: var(--mono-050);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 14px;
  margin: 20px 0 0;
}
.creator {
  margin: auto;
}

.creator-checkbox-wrapper {
  position: absolute;
  top: 14px;
  left: 14px;
}
</style>