<script>
export default {
  data() {
    return {
      isLoading: false,
      imageData: false,
      isDefaultImageHidden: false,
      imageName: "",
    };
  },
  props: ["defaultImage"],
  methods: {
    hideDefaultImage() {
      this.isDefaultImageHidden = true;
    },
    async onChange(e) {
      if (this.isLoading) return;
      this.isLoading = true;
      let file = e.target.files[0];
      let imageData = await this.readAsDataURL(file);
      console.log(imageData);
      this.$refs.form.reset();
      this.imageData = imageData;
      this.imageName = imageData.file.name;
      this.$emit("imageSelected", imageData);
      this.isLoading = false;
    },
    async onDrop(e) {
      if (this.isLoading) return;
      this.isLoading = true;
      let file = e.dataTransfer.files[0];
      let imageData = await this.readAsDataURL(file);
      this.$refs.form.reset();
      console.log(imageData);
      this.imageData = imageData;
      this.$emit("imageSelected", imageData);
      this.isLoading = false;
    },
    async readAsDataURL(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = async () => {
          let dimensions = file.type.indexOf('image') > -1 ? await this.getImageDimensions(fileReader.result) : false;
          return resolve({
            dataUrl: fileReader.result,
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
            dimensions: dimensions,
          });
        };
        fileReader.readAsDataURL(file);
      });
    },
    getImageDimensions(dataUrl) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          return resolve({
            height: img.height,
            width: img.width,
          });
        };
        img.src = dataUrl;
      });
    },
    deleteImage(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.imageData = false;
      this.imageName = "";
      this.$emit("imageDeleted");
    },
  },
  computed: {
    hasImage() {
      return !this.isLoading && this.imageData && this.imageData.dataUrl;
    },
  },
};
</script>
