<template>
  <div class="tr">
    <div class="td">
      <Checkbox 
        v-on:click="toggleCheck(id)" 
        :checked="checked" 
        :disabled="disabled"
      />
    </div>
    <div class="td">
      {{ $formatDate(item.createdAt * 1000) }}
    </div>
    <div class="td">
      {{ position }}
    </div>
    <div class="td">
      <div class="img-wrapper">
        <img :src="item.pcUrl" alt="" />
      </div>
    </div>
    <div class="td">
      <router-link
        :to="{
          name: 'BannerEdit',
          params: {
            bannerId: item.bannerId,
          },
        }"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td">
      <div>{{ $formatDate(item.begunAt * 1000, "YYYY-MM-DD") }}</div>
      <div>{{ $formatDate(item.begunAt * 1000, "HH:mm:ss") }}</div>
      <div>~</div>
      <div>{{ $formatDate(item.endedAt * 1000, "YYYY-MM-DD") }}</div>
      <div>{{ $formatDate(item.endedAt * 1000, "HH:mm:ss") }}</div>
    </div>
    <div class="td">
      <Badge :color="status.status">
        {{ status.text }}
      </Badge>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui";

export default {
  extends: TableListRow,
  methods: {
    onBeforeCheck() {
      return ['RESERVED', 'DISABLED'].includes(this.item.publishedStatus)
    }
  },
  computed: {
    disabled() {
      return !['RESERVED', 'DISABLED'].includes(this.item.publishedStatus)
    },
    status() {
      if (this.item.publishedStatus === "RESERVED") {
        return {
          status: "blue",
          text: "예약",
        };
      } else if (this.item.publishedStatus === "PUBLISHED") {
        return {
          status: "purple",
          text: "게시중",
        };
      } else if (this.item.publishedStatus === "DISABLED") {
        return {
          status: "yellow",
          text: "미게시",
        };
      } else if (this.item.publishedStatus === "CLOSED") {
        return {
          status: "gray",
          text: "종료",
        };
      } else {
        return {
          status: "purple",
          text: this.item.publishedStatus,
        };
      }
    },
    position() {
      switch (this.item.position) {
        case "DASHBOARD":
          return "대시보드";
        default:
          return this.item.position;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  width: 80px;
  height: 40px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 0 0;
  }
}
</style>
