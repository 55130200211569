<template>
  <div class="card-title">
    <div class="title">
      <slot name="title" />
      <div v-if="$slots.titleExtra">
        <slot name="titleExtra" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-title {
  display: flex;
  align-items: center;
  color: var(--mono-900);
  margin: 0 0 30px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;

  width: 100%;
  white-space: nowrap;
}

.title div {
  font-size: 14px;
  color: var(--mono-500);
  font-weight: 400;
  margin-left: 8px;

  width: 100%;
}
</style>
