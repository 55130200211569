import store from "@/store";


const Toast = (message="", options={}) => {
  let {type} = options
  
  
  const toast = {
    message,
    type: type != null ? type : 'default',
  }

  console.log(store)

  store.dispatch('toast/create', toast)
}

export default Toast