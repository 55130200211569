<template>
  <Modal
    title="크리에이터 선택"
    ref="modal"
    :isDialog="true"
    :disableConfirm="!isChecked"
    confirmText="등록하기"
    v-on:confirm="requestSetCreator"
  >
    <InputSearch v-model="query" placeholder="크리에이터 ID를 입력해주세요." />
    <ContentUploadCreatorItem
      :creator="creator"
      :isChecked="isChecked"
      v-on:toggleCheck="isChecked = !isChecked"
      v-if="creator"
    />
  </Modal>
</template>

<script>
import { Modal, InputSearch } from "@ogqcorp/v-ui";
import ContentUploadCreatorItem from "./ContentUploadCreatorItem";
import { apiGet } from "../../../api/apiMethods";

export default {
  data() {
    return {
      query: "",
      creator: null,
      isChecked: false,
    };
  },
  mounted() {
    // this.$refs.modal.open()
  },
  watch: {
    query() {
      this.requestCreatorById();
    },
    creator(n) {
      if (!n) {
        this.isChecked = false;
      }
    },
  },
  emits: ["setCreator"],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    requestSetCreator() {
      this.$emit("setCreator", this.creator);
    },
    async requestCreatorById() {
      const query = this.query;

      if (query.length < 1) {
        this.creator = null;
        return;
      }

      try {
        const response = await apiGet(
          `/ptn/v5/admin/creators?query=${query}&searchType=CREATOR_ID`
        );
        // this.creator = response.data.elements[0] || null;
        this.creator =
          response.data.elements.find((v) => v.creatorId === query) || null;
      } catch {
        this.creator = null;
      }
    },
  },
  components: {
    Modal,
    InputSearch,
    ContentUploadCreatorItem,
  },
};
</script>
