<template>
  <PagePanel
    title="휴대폰 인증 데이터"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>이름</template>
        <template v-slot:td>
          <InputText
            v-model="name"
            class="input"
            placeholder="이름을 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>통신사</template>
        <template v-slot:td>
          <dropdown v-model="telecom" :list="telecomArr" placeholder="통신사" />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>휴대폰 번호</template>
        <template v-slot:td>
          <InputText
            v-model="personPhoneNumber"
            class="input"
            placeholder="휴대폰번호를 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>생년월일</template>
        <template v-slot:td>
          <InputText
            v-model="birthday"
            class="input"
            placeholder="생년월일 6자리를 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>성별</template>
        <template v-slot:td>
          <Dropdown v-model="gender" :list="genderArr" placeholder="성별" />
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";

import {InputText, PagePanel, Dropdown} from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      telecomArr: ["SKT", "LGT", "KT"],
      genderArr: [
        { value: "MALE", label: "남" },
        { value: "FEMALE", label: "여" },
      ],
    };
  },
  props: ["creator", "sellerInfo"],
  inheritAttrs: false,
  methods: {},
  computed: {
    birthday: {
      get() {
        return this.sellerInfo.personIdentification.birthday;
      },
      set(value) {
        this.$emit("update:birthday", value);
      },
    },
    personPhoneNumber: {
      get() {
        return this.sellerInfo.personIdentification.personPhoneNumber;
      },
      set(value) {
        this.$emit("update:personPhoneNumber", value);
      },
    },
    name: {
      get() {
        return this.sellerInfo.personIdentification.name;
      },
      set(value) {
        this.$emit("update:name", value);
      },
    },
    telecom: {
      get() {
        return this.sellerInfo.personIdentification.telecom;
      },
      set(value) {
        this.$emit("update:telecom", value);
      },
    },
    gender: {
      get() {
        return this.sellerInfo.personIdentification.gender;
      },
      set(value) {
        this.$emit("update:gender", value);
      },
    },
  },
  components: {
    "table-v": TableV,
    "table-v-row": TableVRow,
    PagePanel,
    Dropdown,
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 340px;
}
</style>
