<template>
  <div class="modal-outer" v-if="isOpen">
    <div class="modal-overlay"></div>
    <div  
      :class="[
        'modal',
        {
          'scrollbar-pushed': isScrollbarPushed
        }
      ]"
      :style="modalStyle"
    >
      <div class="modal-head" v-if="title">
        <div class="title">{{ title }}</div>
        <!-- <button v-on:click="onClose">
          <i-close />
        </button> -->
      </div>
      <div class="modal-body">
        <slot />
        <div
          v-if="$slots.list"
          class="modal-list"
          :class="{
            'can-scroll': canScroll,
            'long-list': isLongList,
          }"
          ref="list"
        >
          <div class="scroll" ref="scroll">
            <slot name="list" />
          </div>
        </div>
      </div>
      <div class="modal-footer-buttons" v-if="isDialog">
        <Button
          class="confirm"
          color="primary"
          :solid="true"
          :disabled="disableConfirm"
          :loading="isLoading"
          v-on:click="onConfirm"
          >{{ confirmText }}</Button
        >
        <Button
          class="cancel"
          :loading="loadCancelButton && isLoading"
          v-on:click="onClose"
          >{{ cancelText }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import svgClose from '@/components/svg/icons/svgClose'
import {Button} from "@ogqcorp/v-ui"
export default {
  data() {
    return {
      canScroll: true,
      unregisterRouterGuard: null,
    };
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLongList: {
      type: Boolean,
      default: false,
    },
    isScrollbarPushed: {
      type: Boolean,
      default: false,
    },
    loadCancelButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "취소",
    },
    confirmText: {
      type: String,
      default: "확인",
    },
    width: {
      type: [Number, String],
      required: false
    }
  },
  emits: ["close", "confirm"],
  created() {},
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, false);
  },
  beforeUnmount() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener("scroll", this.onScroll, false);
    }
    if (this.unregisterRouterGuard) {
      this.unregisterRouterGuard();
    }
    document.removeEventListener("keyup", this.onKeyUp, false);
  },
  watch: {
    isOpen(isOpen, wasOpen) {
      if (!wasOpen && isOpen) {
        this.onOpen();
      }
    },
  },
  methods: {
    onOpen() {
      console.log("Modal onOpen");
      if (this.$slots.list) {
        this.$nextTick(() => {
          this.onScroll();
          this.$refs.scroll.addEventListener("scroll", this.onScroll, false);
        });
      }
      this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
        this.onClose();
        next(false);
      });
    },
    onKeyUp(e) {
      let keyCode = e.keyCode || e.which;
      if (keyCode === 27) {
        if (
          !["textarea", "input"].includes(
            document.activeElement.nodeName.toLowerCase()
          )
        ) {
          this.onClose();
        } else {
          if (document.activeElement.blur != null) {
            document.activeElement.blur();
          }
        }
      }
    },
    onConfirm() {
      if (this.unregisterRouterGuard) {
        this.unregisterRouterGuard();
      }
      this.$emit("confirm");
    },
    onScroll() {
      const scrollEl = this.$refs.scroll;
      this.canScroll =
        scrollEl.scrollHeight - scrollEl.scrollTop !== scrollEl.clientHeight;
    },
    onClose() {
      this.$emit("close");
      if (this.unregisterRouterGuard) {
        this.unregisterRouterGuard();
      }
    },
  },
  components: {
    Button
  },
  computed: {
    modalStyle() {
      return this.width ? `width: ${this.width};` : ''
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-outer {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(10, 10, 10, 0.27);
}

.modal {
  position: relative;
  width: 400px;
  background-color: white;
  box-shadow: 0px 15px 40px rgba(27, 34, 63, 0.08);
  padding: 40px 30px;
  &.scrollbar-pushed {
    padding: 40px 10px 40px 30px
  }
}

.modal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.modal-head .title {
  font-size: 20px;
  font-weight: 500;
  color: var(--mono-900);
}

.modal-head button {
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background: transparent;
  outline: 0;
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  .scrollbar-pushed & {
    padding-right: 16px;
  }
}

.modal-body {
  
}
.modal-list {
  position: relative;
  height: auto;
  max-height: 280px;
  overflow: hidden;
  // mask-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1));
  &.long-list {
    max-height: 500px;
  }
  
  .scroll {
    width: 100%;
    overflow: auto;
    height: 100%;
    max-height: inherit;
    &::-webkit-scrollbar {
      width: 4px;
      height: auto;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
      height: auto;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background-color: var(--mono-200);
    }
    .scrollbar-pushed & {
      padding-right: 12px;
    }
  }
  &.can-scroll:after {
    position: absolute;
    height: 48px;
    left: 0;
    right: 4px;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      var(--mono-000)
    );
    content: "";
    pointer-events: none;
  }
  
}

.modal-footer-buttons button {
  margin-left: 10px;
}
</style>
