<template>
  <div class="tr">
    <div class="td">
      <Checkbox 
        v-on:click="toggleCheck(item.id)" 
        :checked="checked" 
      />
    </div>
    <div class="td">{{ $formatDate(item.createdAt * 1000) }}</div>
    <div class="td">{{ item.title }}</div>
    <div class="td">{{ item.writerName }}</div>
    <div class="td">{{ item.receiverType }}</div>
    <div class="td">{{ $formatDate(item.reservedAt * 1000) }}</div>
    <div class="td">
      <Badge :class="status.color">
        {{ status.label }}
      </Badge>
    </div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"

export default {
  extends: TableListRow,
  computed: {
    status() {
      switch (this.item.status) {
        case "RESERVED":
          return { label: "예약", color: "blue" };
        case "DONE":
          return { label: "미사용", color: "yellow" };
        case "DOING":
          return { label: "사용중", color: "purple" };
        default:
          return { label: this.item.status };
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>
