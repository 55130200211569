<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="발송 대상">
          <div class="group">
            <Radio
              :checked="receiverType === 'MARKETING'"
              v-on:click="setReceiverType('MARKETING')"
              :label="'홍보성 이메일 수신 동의'"
            />
            <Radio
              :checked="receiverType === 'ALL'"
              v-on:click="setReceiverType('ALL')"
              :label="'공지 이메일'"
            />
            <Radio
              :checked="receiverType === 'INDIVIDUAL'"
              v-on:click="setReceiverType('INDIVIDUAL')"
              :label="'개인 이메일'"
            />
          </div>
        </PagePanelFormRow>
        <PagePanelFormRow v-if="emailStatus !== 'DONE'" label="발송 예정일">
          <Datepicker
            v-model="sendAt"
            class="datepicker-item"
            placeholder="발송 예정일"
          />
        </PagePanelFormRow>
        <PagePanelFormRow v-else label="발송 완료일">
          <Datepicker
            v-model="sentAt"
            class="datepicker-item"
            placeholder="발송 완료일"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="수신인" v-if="receiverType === 'INDIVIDUAL'">
          <Button
            v-if="canEdit"
            @click="handleReceiverModal(true)"
            :solid="true"
            size="xsmall"
          >
            추가하기
          </Button>
          <div class="receiver-wrap">
            <template v-if="canEdit">
              <Tag
                v-for="(receiver, i) in receiverList"
                :key="i"
                @clickRemove="removeReceiver(receiver)"
              >
                {{ `${receiver.nickname} (${receiver.creatorId})` }}
              </Tag>
            </template>
            <div v-else>
              {{
                receiverList
                  .map(({ nickname, creatorId }) => `${nickname}(${creatorId})`)
                  .join(", ")
              }}
            </div>
          </div>
        </PagePanelFormRow>
      </PagePanel>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요."
            class="stretch"
            :readonly="!canEdit"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="내용" :alignTop="true">
          <Editor
            v-if="emailStatus !== 'DONE'"
            v-model="body"
            category="NOTICE"
            ref="editor"
          />
          <div v-else v-html="body" />
        </PagePanelFormRow>
      </PagePanel>
    </template>
    <template v-slot:footer>
      <Button
        color="primary"
        v-on:click="handleTestModal"
        :disabled="!canSend || emailStatus === 'DONE'"
        >테스트 메일 보내기</Button
      >
      <Button
        color="primary"
        v-on:click="handleClickSave"
        :loading="isLoading"
        :disabled="!canSend || !canEdit"
      >
        저장
      </Button>
      <Button
        color="primary"
        v-on:click="confirmEmail"
        :loading="isLoading"
        :disabled="!canSend || !canEdit || emailStatus !== 'SAVED'"
      >
        확정
      </Button>
      <Button @click="handleClickCancel">취소</Button>
    </template>
  </admin-layout>
  <MailManualReceiverModal
    :isOpen="isReceiverModalOpen"
    :isLoading="isLoading"
    @close="handleReceiverModal(false)"
    @selectReceiver="selectReceiver"
  />
  <MailManualTestModal
    :isOpen="isTestModalOpen"
    :isLoading="isLoading"
    v-on:close="handleTestModal"
    v-on:sendEmail="sendTestEmail"
  />
</template>

<script setup>
import AdminLayout from "@/layouts/AdminLayout";
import MailManualTestModal from "./MailManualTestModal";
import MailManualReceiverModal from "./MailManualSearchReceiverModal";
import Editor from "@/components/el/inputs/Editor.Prose";
import {
  Button,
  Textarea,
  Radio,
  InputText,
  InputSearch,
  Datepicker,
  PagePanel,
  PagePanelFormRow,
  Tag,
} from "@ogqcorp/v-ui";
import { apiGet, apiPost, apiPut } from "../../../../api/apiMethods";
import { getEmailStatus } from "../../../../utils/index";
import {
  computed,
  getCurrentInstance,
  inject,
  onBeforeMount,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";

const {
  appContext: {
    app: {
      config: {
        globalProperties: { $formatTime },
      },
    },
    provides: { $confirm, $alert, $error },
  },
} = getCurrentInstance();

const route = useRoute();
const router = useRouter();

const editor = ref(null);
const isLoading = ref(false);
const isEdit = ref(route.name === "MailManualEdit");
const title = ref("");
const body = ref("");
const receiverList = ref([]);
const receiverType = ref("");
const emailStatus = ref("");
const sendAt = ref(0); // 예약 발송일
const sentAt = ref(0); // 발송 완료일
const userIds = ref([]);

const isTestModalOpen = ref(false);
const isReceiverModalOpen = ref(false);

onBeforeMount(async () => {
  if (!isEdit.value) return;
  await getEmailDetail();
});

watch(
  async () => await editor?.value?.getHTMLContents(),
  async (newEditorVal) => {
    if (isEdit.value) return;
    body.value = await newEditorVal;
  },
  {
    immediate: true,
  }
);
// watchEffect(
//   () => {
//     console.log("###body", body.value);
//   },
//   {
//     immediate: true,
//   }
// );

const canSend = computed(() => {
  return receiverType.value && title.value && body.value;
});
const canEdit = computed(() => {
  return emailStatus.value !== "REQUESTED" && emailStatus.value !== "DONE";
});

const getEmailDetail = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  try {
    const response = await apiGet(`/admin/emails/${route.params.mailId}`, null);
    title.value = response.data.title;
    body.value = response.data.body;
    receiverList.value = response.data.receiverList || [];
    receiverType.value = response.data.receiverType;
    emailStatus.value = response.data.emailStatus;
    sendAt.value = response.data.sendAt
      ? new Date(response.data.sendAt * 1000)
      : null;
    sentAt.value = response.data.sentAt
      ? new Date(response.data.sentAt * 1000)
      : null;
    editor.value.setContent(body.value);
    if (
      response.data.emailStatus === "DONE" ||
      response.data.emailStatus === "REQUESTED"
    ) {
      const emailStatusLabel = getEmailStatus(response.data.emailStatus).label;
      $confirm({
        msg: `이미 ${emailStatusLabel}된 이메일은 수정이 불가능합니다.`,
        cancelText: "닫기",
      });
    }
  } catch (err) {
    if (err.response.status === 400) {
      router.replace({ name: "PageNotFound" });
    }
  } finally {
    isLoading.value = false;
  }
};

const handleClickSave = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  try {
    const data = {
      title: title.value,
      body: await editor.value.getHTMLContents(),
      receiverType: receiverType.value,
      sendAt: $formatTime(sendAt.value.getTime()),
      userIds: userIds.value || null,
    };
    isEdit.value ? await editEmail(data) : await submitEmail(data);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
const editEmail = async (data) => {
  await apiPut(`/admin/emails/${route.params.mailId}`, data);
  await $confirm({
    msg: `이메일이 수정되었습니다.`,
    cancelText: "닫기",
  });
  await getEmailDetail();
};
const submitEmail = async (data) => {
  await apiPost("/admin/emails", data);
  await $confirm({
    msg: `이메일이 저장되었습니다.`,
    cancelText: "닫기",
  });
  router.push({ name: "MailManual" });
};
const confirmEmail = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  try {
    await apiPut(`/admin/emails/${route.params.mailId}/confirm`, null);
    await $confirm({
      msg: "이메일이 확정 처리되었습니다.",
      cancelText: "닫기",
    });
    await getEmailDetail();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
const sendTestEmail = async (emailAddress) => {
  try {
    await apiPost("/admin/testEmails", {
      title: title.value,
      contents: body.value,
      receiver: emailAddress,
      templateType: "MARKETING",
    });
    $confirm({
      msg: `테스트 이메일을 ${emailAddress}(으)로 성공적으로 발송했습니다.`,
    });
  } catch (err) {
    $error("문제가 발생했습니다.");
  } finally {
    isTestModalOpen.value = false;
    isLoading.value = false;
  }
};

const selectReceiver = (receiver) => {
  if (!receiver.email) {
    $confirm({
      msg: "이메일이 등록되지 않은 계정은 추가할 수 없습니다",
      cancelText: "닫기",
    });
    return;
  }
  const isDuplicatedReceiver = receiverList.value.filter(
    (el) => el.creatorId === receiver.creatorId
  ).length;
  if (isDuplicatedReceiver) {
    $confirm({
      msg: "이미 추가한 수신인 입니다.",
      cancelText: "닫기",
    });
    return;
  }
  receiverList.value = [...receiverList.value, receiver];
  userIds.value = receiverList.value.map((v) => v.creatorId);
  handleReceiverModal(false);
};
const removeReceiver = (receiver) => {
  receiverList.value = receiverList.value.filter(
    (v) => v.creatorId !== receiver.creatorId
  );
};
const setReceiverType = (type) => {
  receiverType.value = type;
};
const handleTestModal = (isOpen) => {
  isTestModalOpen.value = isOpen;
};
const handleReceiverModal = (isOpen) => {
  isReceiverModalOpen.value = isOpen;
};
const handleClickCancel = () => {
  router.push({ name: "MailManual" });
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 30px;
  }
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.buttons button {
  margin-right: 8px;
}

// 수신인 영역
.page-panel-form-row:nth-child(3) {
  align-items: flex-start;
  gap: 8px;

  :deep(.col-content) {
    padding: 24px 0;
  }

  button {
    margin-bottom: 12px;
  }
  .receiver-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
  }
}
</style>
