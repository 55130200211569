<script>
import {
  Tab,
  PagePanel,
  TableEmpty,
  TableAction,
  TableFilter,
  Dropdown,
  PaginationPages,
} from "@ogqcorp/v-ui";
import { apiDelete, apiGet } from "../../api/apiMethods";
export default {
  data() {
    return {
      API_URL: null,
      isLoading: false,
      shallowList: false,
      hasNext: false,
      list: [],
      rowCount: 50,
      checkedRows: [],
      totalListCount: 0,
      queryKeys: [],
      isPaginated: false,
      noPagination: false,
      pagesCountToDisplay: 1,
      filter: null,
      pageKey: "page",
      listKey: "data",
      statusKey: "status",
      filterKey: "position",
      searchKey: "query",
      searchTypeKey: "searchType",
    };
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.query &&
      this.searchQuery != null
    ) {
      this.searchQuery = this.$route.query.query;
    }
    for (let key in this.$route.params) {
      if (this[key] != null) {
        this[key] = this.$route.params[key];
      }
    }
    const numRegex = new RegExp(/^\d+$/gi);
    for (let key in this.$route.query) {
      if (this[key] != null) {
        if (numRegex.test(this[key])) {
          this[key] = parseInt(this.$route.query[key]);
        } else {
          this[key] = this.$route.query[key];
        }
      }
    }
    this.onAfterCreated();
  },
  // watch: {
  //   rowCount() {
  //     this.$nextTick(()=> {
  //       this.loadPage({params: {page: 1}})
  //     })
  //   }
  // },
  watch: {
    $route(n) {
      this.filter = n.query[this.filterKey] || false;
      this.searchQuery = n.query[this.searchKey] || "";
    },
  },
  methods: {
    onAfterCreated() {
      this.loadPage(this.$route.params, this.$route.query, false);
    },
    clearAll() {
      if (
        this.onBeforeClear != null &&
        typeof this.onBeforeClear === "function"
      )
        this.onBeforeClearNoticeTableRow;
      this.loadPage({}, {}, true);
    },
    async deleteSelected() {
      if (this.checkedRows.length < 1) return;
      if (this.isLoading) return;

      try {
        const confirm = await this.$confirm({
          html:
            this.deleteMessageHTML || "선택한 항목들을 <br> 삭제하시겠습니까?",
        });

        if (confirm) {
          await apiDelete(
            `${this.API_URL || this.API_PATH}/${this.checkedRows.join(",")}`
          );
          this.checkedRows = [];
          this.isLoading = false;
          await this.loadPage();
        }
      } catch (err) {
        console.log(err, err?.response);
      } finally {
        this.isLoading = false;
      }
    },
    async loadPageOld(routeOptions, options = {}) {
      this.isLoading = true;
      let params = {
        ...this.$route.params,
        ...(routeOptions && routeOptions.params),
      };

      if (!routeOptions || !routeOptions.params) {
        params = {};
      }

      const numberPage = parseInt(params.page);
      const page = isNaN(numberPage) || numberPage == null ? 1 : numberPage;
      params.page = page;

      let query = {
        ...this.$route.query,
        ...(routeOptions && routeOptions.query),
      };
      if (!routeOptions || !routeOptions.query) {
        query = {};
      }

      let route = {
        params,
        query,
      };

      if (!this.$route.params.page) {
        this.$router.replace(route);
      }

      if (options.pushRoute) {
        await this.$router.push(route);
      }

      let fetchOptions = {
        params: {
          ...params,
          ...query,
        },
      };

      if (fetchOptions.params && fetchOptions.params.page != null) {
        fetchOptions.params.page -= 1;
      }

      for (let key of [...(this.blacklistedKeys || []), "modal"]) {
        delete fetchOptions.params[key];
      }

      this.list = [];
      console.log(`[API REQUEST] ${this.API_URL}`, fetchOptions);

      try {
        const data = await apiGet(this.API_URL || this.API_PATH, fetchOptions);
        if (this.noPagination) {
          this.list = data.data;
          this.totalListCount = this.list.length;
          console.log(`[API RESULT] ${this.API_URL}`, data);
        } else {
          this.list = data[this.listKey] || [];
          this.hasNext = data.hasNext || false;
          this.totalListCount =
            data.total != null ? data.total : this.list.length;
          console.log(`[API RESULT] ${this.API_URL}`, data);
        }
        this.isLoading = false;

        return data;
      } catch (e) {
        console.log(`[API FAILED]`, e.response);
        this.list = [];
        this.hasNext = false;
        this.isLoading = false;
      }
    },

    // LIST SEARCH
    // ---------------------
    async search() {
      await this.loadPage(
        { ...this.$route.params, ...{ page: 1 } },
        {
          ...this.$route.query,
          ...{
            [this.searchKey]: this.searchQuery || undefined,
            [this.searchTypeKey]:
              (this.searchQuery && this.searchType) || undefined,
          },
        },
        true
      );
    },
    // LIST FILTER
    // ---------------------
    openFilterModal() {
      this.$refs.filterModal?.open();
    },
    selectFilter(filter) {
      if (!this.filterKey) return;
      this.filter = this.filter === filter ? null : filter;
    },
    onFilterModalOpen() {
      if (!this.filterKey) return;
      this.filter = this.$route.query[this.filterKey] || undefined;
    },
    onFilterModalConfirm() {
      if (
        this.filter != null &&
        this.$route.query[this.filterKey] === this.filter
      )
        return;
      this.setFilter(this.filter);
    },
    async setFilter(filter) {
      await this.loadPage(
        { ...this.$route.params, ...{ page: 1 } },
        { ...this.$route.query, ...{ [this.filterKey]: filter || undefined } },
        true
      );
    },
    // LIST STATUS
    // ---------------------
    async setStatus(status) {
      if (
        this.$route.page === 1 &&
        status === this.$route.query[this.statusKey]
      )
        return;
      await this.loadPage(
        { ...this.$route.params, ...{ page: 1 } },
        { ...this.$route.query, ...{ [this.statusKey]: status || undefined } },
        true
      );
    },
    // LIST API
    // ---------------------
    async loadPage(params = {}, query = {}, pushRoute = true) {
      if ((!this.API_URL && !this.API_PATH) || this.isLoading) return;

      params.page = params.page || 1;
      console.log("[API/loadPage]", { params, query, pushRoute });

      let pageParams = {
        [this.pageKey]: Math.max(params.page, 1),
      };
      if (this.pageKey !== "page") {
        delete params[this.pageKey];
      }
      if (this.pageSizeKey) {
        pageParams[this.pageSizeKey] = this.rowCount || 40;
      }

      try {
        this.isLoading = true;
        let fetchParams = {
          ...params,
          ...query,
          ...pageParams,
        };
        if (!this.$route.params.page) this.$router.replace({ params, query });
        if (this.pageKey !== "page") delete fetchParams.page;
        if (pushRoute) {
          console.log("API/loadPage/pushRoute]", { query, params });
          await this.$router.push({ params, query });
        }
        const data = await this.fetchData(fetchParams);
        this.list = (this.noPagination ? data : data[this.listKey]) || [];
        this.totalListCount = this.noPagination
          ? this.list.length
          : data.total != null
          ? data.total
          : this.list.length;
        this.hasNext = this.noPagination ? false : data.hasNext || false;
        this.checkedRows = [];
        return data;
      } catch (err) {
        console.log("[API/loadPage Error]", err, err.response);
        this.list = [];
        this.hasNext = false;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchData(params = {}) {
      try {
        params.page = params.page != null ? params.page - 1 : undefined;
        const data = await apiGet(this.API_URL || this.API_PATH, params);
        console.log("[API/fetchData/result]", data.data);
        return data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async gotoPreviousPage() {
      console.log("GOto Prev");
      const numberPage = parseInt(this.$route.params.page) || 1;

      if (numberPage <= 0) return;

      const page = isNaN(numberPage) || numberPage == null ? 1 : numberPage - 1;

      await this.loadPage(
        {
          ...this.$route.params,
          page: page,
        },
        {
          ...this.$route.query,
        },
        true
      );
    },
    async gotoNextPage() {
      const numberPage = parseInt(this.$route.params.page) || 1;

      if (!this.hasNext) return;

      const page = isNaN(numberPage) || numberPage == null ? 1 : numberPage + 1;
      console.log(`[PAGINATION/gotoNextPage]`, `${numberPage} => ${page}`);
      await this.loadPage(
        {
          ...this.$route.params,
          page: page,
        },
        {
          ...this.$route.query,
        },
        true
      );
    },
    toggleCheckCondition() {
      return true;
    },
    toggleAllRows(idKey) {
      console.log("Toggle All Rows", idKey);
      // Dummy Function
      this.checkedRows = this.checkedRows.length
        ? []
        : this.list
            .filter((item) => this.toggleCheckCondition(item))
            .map((item) => item[idKey]);
    },
    toggleCheckRow(id) {
      // Dummy Function
      console.log("checkedRows", this.checkedRows);
      console.log("id", id);
      if (!this.checkedRows.includes(id)) {
        this.checkedRows.push(id);
        return;
      }
      let index = this.checkedRows.findIndex((i) => i === id);
      this.checkedRows.splice(index, 1);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/getCurrentUser"];
    },
    isAnyRowsChecked() {
      return this.checkedRows.length > 0;
    },
    pagesCount() {
      return Math.ceil(this.totalListCount / this.rowCount);
    },
    hasNextPage() {
      if (this.isPaginated) {
        return this.list.length < this.totalListCount;
      } else {
        return this.hasNext;
      }
    },
    currentPage() {
      let page = parseInt(this.$route.params.page);
      return page && !isNaN(page) ? page : 1;
    },
  },
  components: {
    PaginationPages,
    Dropdown,
    TableAction,
    TableFilter,
    TableEmpty,
    Tab,
    PagePanel,
  },
};
</script>
