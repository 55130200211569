<template>
  <div class="editor-wrapper">
    <div class="editor-toolbar" v-if="editor">
      <EditorColorSelector
        :currentColor="editor.getAttributes('textStyle').color"
        v-on:setColor="setColor"
      />
      <EditorHighlightSelector
        :currentColor="editor.getAttributes('highlight').color"
        v-on:setColor="setHighlightColor"
      />
      <EditorButton
        :active="editor.isActive({ textAlign: 'left' })"
        v-on:click="toggleTextAlign('left')"
      >
        <eiAlignLeft />
      </EditorButton>
      <EditorButton
        :active="editor.isActive({ textAlign: 'center' })"
        v-on:click="toggleTextAlign('center')"
      >
        <eiAlignCenter />
      </EditorButton>
      <EditorButton
        :active="editor.isActive({ textAlign: 'right' })"
        v-on:click="toggleTextAlign('right')"
      >
        <eiAlignRight />
      </EditorButton>
      <EditorButton :active="editor.isActive('bold')" v-on:click="toggleBold">
        <eiBold />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('italic')"
        v-on:click="toggleItalic"
      >
        <eiItalic />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('underline')"
        v-on:click="toggleUnderline"
      >
        <eiUnderline />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('heading', { level: 1 })"
        v-on:click="toggleHeading(1)"
      >
        <eiHeading1 />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('heading', { level: 2 })"
        v-on:click="toggleHeading(2)"
      >
        <eiHeading2 />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('heading', { level: 3 })"
        v-on:click="toggleHeading(3)"
      >
        <eiHeading3 />
      </EditorButton>
      <EditorButton
        :active="editor.isActive('link')"
        v-on:click="openLinkModal"
      >
        <eiLink />
      </EditorButton>
      <EditorButton v-on:click="openUploader">
        <eiImage />
      </EditorButton>
      <EditorButton v-on:click="addHorizontalRule">
        <eiHorizontalRule />
      </EditorButton>
      <EditorButton v-on:click="log"> LOG </EditorButton>

      <template v-if="editor.isActive('custom-image')">
        <EditorButton
          v-on:click="setImageSize('default')"
          :active="editor.isActive('custom-image', { size: 'default' })"
        >
          원본
        </EditorButton>
        <EditorButton
          v-on:click="setImageSize('small')"
          :active="editor.isActive('custom-image', { size: 'small' })"
        >
          50%
        </EditorButton>
        <EditorButton
          v-on:click="setImageSize('medium')"
          :active="editor.isActive('custom-image', { size: 'medium' })"
        >
          75%
        </EditorButton>
        <EditorButton
          v-on:click="setImageSize('large')"
          :active="editor.isActive('custom-image', { size: 'large' })"
        >
          100%
        </EditorButton>
      </template>
    </div>
    <div class="editor-body">
      <div class="editor-body-inner">
        <EditorContent :editor="editor" />
      </div>
    </div>

    <EditorUrlModal ref="modal" v-on:confirm="setLink" />
  </div>
</template>

<script>
// import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
// import Image from '@tiptap/extension-image'
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
// import HorizontalRule from '@tiptap/extension-horizontal-rule'

import EditorButton from "./Editor.Prose.Button";

import eiAlignCenter from "./editorIcons/eiAlignCenter";
import eiAlignLeft from "./editorIcons/eiAlignLeft";
import eiAlignRight from "./editorIcons/eiAlignRight";
import eiBold from "./editorIcons/eiBold";
import eiHeading1 from "./editorIcons/eiHeading1";
import eiHeading2 from "./editorIcons/eiHeading2";
import eiHeading3 from "./editorIcons/eiHeading3";
import eiItalic from "./editorIcons/eiItalic";
import eiUnderline from "./editorIcons/eiUnderline";
import eiImage from "./editorIcons/eiImage";
import eiLink from "./editorIcons/eiLink";
import eiHorizontalRule from "./editorIcons/eiHorizontalRule";
import { selectFile, readFileAsDataUrl } from "./Editor.Files";
import CustomImage from "./editorPlugins/custom/Editor.CustomImage";
import EditorColorSelector from "./editorPlugins/EditorColorSelector";
import EditorHighlightSelector from "./editorPlugins/EditorHighlightSelector";
import EditorUrlModal from "./editorPlugins/EditorUrlModal";
import { apiPost } from "@/api/apiMethods";
import { onMounted, ref, onBeforeUnmount } from "vue";
// import ResizableImage from './editorPlugins/Editor.CustomImage'

export default {
  props: {
    category: {
      type: String,
      default: "NOTICE",
    },
  },
  components: {
    EditorContent,
    eiAlignCenter,
    eiAlignLeft,
    eiAlignRight,
    eiBold,
    eiHeading1,
    eiHeading2,
    eiHeading3,
    eiItalic,
    eiUnderline,
    eiImage,
    eiLink,
    eiHorizontalRule,
    EditorButton,
    // BubbleMenu,
    EditorColorSelector,
    EditorHighlightSelector,
    EditorUrlModal,
  },
  setup() {
    const editor = ref(null);

    onMounted(() => {
      editor.value = new Editor({
        content: "",
        extensions: [
          StarterKit.configure({
            heading: {
              levels: [1, 2, 3],
            },
            dropcursor: {
              color: "var(--primary-500)",
            },
          }),
          Underline,
          TextAlign.configure({
            types: ["heading", "paragraph"],
            alignments: ["left", "center", "right"],
          }),
          // Image,
          Color,
          TextStyle,
          // BubbleMenu,
          Link.configure({
            openOnClick: false,
          }),
          Highlight.configure({ multicolor: true }),
          // ResizableImage
          CustomImage,
        ],
      });
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    return {
      editor,
    };
  },
  methods: {
    log() {
      console.log(this.editor.getHTML());
    },
    getJSON() {
      return this.editor.getJSON();
    },
    setContent(htmlString = "") {
      console.log("setContent", { htmlString });
      this.editor.commands.setContent(htmlString);
    },
    async getHTMLContents(options) {
      const isHTMLString =
        options?.isHTMLString != null ? options.isHTMLString : true;
      const parser = new DOMParser();
      const html = parser.parseFromString(this.editor.getHTML(), "text/html");
      let p = html.documentElement.querySelectorAll("p");
      p.forEach((paragraph) => {
        if (paragraph.textContent === "") {
          const br = document.createElement("br");
          paragraph.parentNode.replaceChild(br, paragraph);
        }
      });
      let imgs = Array.from(html.documentElement.querySelectorAll("img")) || [];
      imgs = imgs.map((v) => ({
        src: v.src,
      }));
      console.log("imgs", imgs);

      return isHTMLString
        ? html.documentElement.querySelector("body").innerHTML
        : html.documentElement;
    },
    setImageSize(size = "small") {
      this.editor.chain().focus().setImage({ size }).run();
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleHeading(level = 1) {
      this.editor.chain().focus().toggleHeading({ level }).run();
    },
    toggleTextAlign(align = "left") {
      if (this.editor.isActive({ textAlign: align })) {
        return this.editor.chain().focus().unsetTextAlign().run();
      }
      this.editor.chain().focus().setTextAlign(align).run();
    },
    toggleColor(hexString) {
      if (this.editor.isActive("textStyle", { color: hexString })) {
        return this.editor.chain().focus().unsetColor().run();
      }
      this.editor.chain().focus().setColor(hexString).run();
    },
    setColor(hexString) {
      if (!hexString) {
        return this.editor.chain().focus().unsetColor().run();
      }
      this.editor.chain().focus().setColor(hexString).run();
    },
    setHighlightColor(hexString) {
      if (!hexString) {
        return this.editor.chain().focus().unsetHighlight().run();
      }
      this.editor.chain().focus().toggleHighlight({ color: hexString }).run();
    },
    async getImageCdnUrl(data) {
      return await apiPost(`/admin/service/files/${this.category}`, data);
    },
    async openUploader() {
      const file = await selectFile();
      const formData = new FormData();
      formData.append("file", file);
      const response = await this.getImageCdnUrl(formData);
      this.editor
        .chain()
        .focus()
        .setImage({ src: response.data.preSignedUrl })
        .run();
    },
    openLinkModal() {
      // console.log(  )
      const currentUrl = this.editor.getAttributes("link").href;
      if (currentUrl) {
        this.$refs.modal.url = currentUrl;
      }
      this.$refs.modal.$refs.modal.open();
    },
    setLink() {
      console.log("link", this.$refs.modal.url);
      if (this.$refs.modal.url.trim().length < 1) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: this.$refs.modal.url })
          .run();
      }
      this.$refs.modal.url = "";
    },
    toggleResize() {
      this.editor.chain().focus().toggleResizable().run();
    },
    addHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run();
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-wrapper {
  --input-border-color: var(--mono-100);
  --input-bg-color: var(--mono-000);
  --input-caret-color: var(--primary-500);
  --input-placeholder-color: var(--mono-500);

  border: 1px solid var(--input-border-color);
}
.editor-body {
  overflow: hidden;
}
.editor-body-inner {
  max-height: 640px;
  overflow: auto;
}

:deep .ProseMirror {
  outline: 0;
  caret-color: var(--input-caret-color);
  padding: 8px 20px;
  border: 1px solid transparent;
  transition: 40ms border-color, 120ms box-shadow;

  // &:focus {
  //   box-shadow: 0 0 0 3px var(--primary-100);
  //   border: 1px solid var(--primary-500);
  // }
  mark {
    color: inherit;
  }
}

:deep .ProseMirror img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
  &.ProseMirror-selectednode {
    // outline: 1px solid var(--input-caret-color);
    box-shadow: 0 0 0 3px var(--primary-100);
  }
  &.img-size-small {
    width: 50%;
  }
  &.img-size-medium {
    width: 75%;
  }
  &.img-size-large {
    width: 100%;
  }
}

.editor-toolbar {
  border-bottom: 1px solid var(--input-border-color);
  display: flex;
  align-items: center;
  padding: 4px;
}

.colorPreview {
  display: block;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  // background-color: var(--mono-900);

  color: var(--mono-900);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

:deep .image-bubble {
  display: flex;
  // background-color: red;
  border: 1px solid var(--input-border-color);
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(27, 34, 63, 0.1);
}
</style>
