<template>
  <PagePanel
    title="실명 인증 데이터"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>실명</template>
        <template v-slot:td>
          <InputText v-model='name' class="input" placeholder="이름을 입력하세요." />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>주민등록번호 앞자리</template>
        <template v-slot:td>
          <InputText
            v-model="residentNumber"
            class="input"
            placeholder="주민등록번호 앞자리를 입력하세요."
          />
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";
import {InputText, PagePanel} from "@ogqcorp/v-ui";


export default {
  props: ["creator", "sellerInfo"],
  inheritAttrs: false,
  methods: {},
  components: {
    "table-v": TableV,
    "table-v-row": TableVRow,
    PagePanel,
    InputText,
  },
  computed: {
    name: {
      get() {
        return this.sellerInfo.residentRegistration.name;
      },
      set(value) {
        this.$emit("update:name", value);
      },
    },
    residentNumber: {
      get() {
        return this.sellerInfo.residentRegistration.residentNumber;
      },
      set(value) {
        this.$emit("update:residentNumber", value);
      },
    },
  }
};
</script>

<style lang="scss" scoped>
.input {
  width: 340px;
}
</style>
