<template>
  <div class="panel-sticker-main">
    <PagePanel
      title="스티커 메인 이미지"
      :titleOption="{bold: true}"
      :isRaw="true"
    >
      <template v-slot:titleAction>
        <Button
          :isFile="true"
          v-on:selectFiles="onMainSelected"
        >
          파일 업로드
        </Button>
      </template>

      <div class="inner">
        <div
          class="main-img-wrapper"
          v-on:drop.prevent="onDrop" 
          v-on:dragover.prevent="onDragOver"
          v-on:dragleave.prevent="onDragLeave"
        >
          <img v-if="mainImage" :src="mainImage.dataUrl">
        </div>
        <div class="main-img-guide">
          <ul>
            <li>메인 이미지는 240px * 240px</li>
            <li>깐지나는 이미지로 부탁함.</li>
          </ul>
        </div>
      </div>

    </PagePanel>  
  </div>
</template>

<script>
import {Button, PagePanel} from '@ogqcorp/v-ui'

export default {
  emits: [
    'mainSelected'
  ],
  props: {
    mainImage: Object
  },
  data() {
    return {
      isDragOver: false
    }
  },
  methods: {
    onMainSelected(file) {
      this.$emit('mainSelected', file)
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0]
      if (!file) return
      this.$emit('mainSelected', file)
    },
    onDragOver() {
      this.isDragOver = true
    },
    onDragLeave() {
      this.isDragOver = false
    }
  },
  components: {
    Button,
    PagePanel
  }
}
</script>

<style lang="scss" scoped>

.panel-sticker-main {
  margin: 0 0 50px;
}

.inner {
  display: flex;
  align-items: center;
}

.main-img-wrapper {
  width: 240px;
  height: 240px;
  border: 1px solid var(--mono-400);
  $s: 16px;
  background: repeating-conic-gradient(#e5e5e5 0% 25%, white 0% 50%) 50% / #{$s
    $s};

}

</style>