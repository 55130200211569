<template>
  <form v-on:submit.prevent="pushTag">
    <InputText
      v-model.trim="tag"
      placeholder="태그를 입력해주세요"
      class="input"
      :showIcon="true"
      :stretch="true"
    >
      <template v-slot:icon>
        <i-magnify />
      </template>
    </InputText>
  </form>
</template>

<script>
import {InputText} from "@ogqcorp/v-ui";
import svgMagnify from "@/components/svg/icons/svgMagnify";

export default {
  data() {
    return {
      tag: "",
    };
  },
  methods: {
    pushTag() {
      if (!this.tag) return;
      this.$emit("pushTag", this.tag);
      this.tag = "";
    },
  },
  components: {
    InputText,
    "i-magnify": svgMagnify,
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}
</style>