<template>
  <div class="images-list">
    <div class="main-image">
      <Badge class="image-badge" :solid="true" color="gray">판매 이미지</Badge>
      <div class="image-wrap" @click="openImageViewer()">
        <img :src="$getImgUrl(content.imageUrl, 'w640')" />
      </div>
      <div class="image-fileName">
        {{ mainImageFileName }}
      </div>
    </div>
    <div class="sample-image-list">
      <div
        class="sample-image-item"
        v-for="(image, i) in previewImageList.slice(1)"
        :key="`image-${i}`"
        @click="openImageViewer(i + 1)"
      >
        <Badge
          class="image-badge"
          :color="image.label !== '상품 상세' ? 'gray' : 'blue'"
          solid
        >
          {{ image.label }}
        </Badge>
        <div
          :class="[
            'image-wrap',
            { 'image-wrap-detail': image.label === '상품 상세' },
          ]"
        >
          <img :src="image.imageUrl" />
        </div>
        <div class="image-fileName">
          {{ image.fileName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from "@/api/apiMethods";
import { Badge } from "@ogqcorp/v-ui";

export default {
  data() {
    return {};
  },
  computed: {
    mainImageFileName() {
      return this.content.imageUrl.split("/").slice(-1)[0];
    },
    previewImageList() {
      const mainImage = {
        imageUrl: this.content.imageUrl,
        fileName: this.content?.imageUrl.split("/").slice(-1)[0],
        label: "판매 이미지",
        type: "VIRTUAL_ASSETS",
      };
      const sampleImageList = this.content.sampleImages.map((v) => ({
        imageUrl: this.$getImgUrl(v.imageUrl, "w640"),
        fileName: v.imageUrl.split("/").slice(-1)[0],
        label: "미리보기",
        type: "VIRTUAL_ASSETS_PREVIEW",
      }));
      const descriptionImage = {
        imageUrl: this.$getImgUrl(this.content?.descImageUrl, "w1280"),
        fileName: this.content?.descImageUrl.split("/").slice(-1)[0],
        label: "상품 상세",
        type: "VIRTUAL_ASSETS_DETAIL",
      };
      return [mainImage, ...sampleImageList, descriptionImage];
    },
  },
  methods: {
    openImageViewer(index = 0) {
      const images = this.previewImageList.map((v) => {
        return {
          url: v.imageUrl,
          name: v.imageUrl.split("/").slice(-1)[0],
          replaceUrl: async () => {
            const res = await apiGet(
              `/dam/v5/admin/virtualAssets/${this.content.virtualAssetId}/download`
            );
            if (v.type === "VIRTUAL_ASSETS") return res?.data?.mainImageUrl;
            else if (v.type === "VIRTUAL_ASSETS_PREVIEW")
              return res?.data?.sampleImageUrl;
            else if (v.type === "VIRTUAL_ASSETS_DETAIL")
              return res?.data?.descImageUrl;
            else return v.imageUrl;
          },
        };
      });
      this.$imageViewer.open({
        mode: "virtualAssets",
        images,
        index,
      });
    },
  },
  props: ["content"],
  components: {
    Badge,
  },
};
</script>

<style lang="scss" scoped>
.images-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.main-image {
  position: relative;

  .image-wrap {
    width: 100%;
    height: 320px;
    border: 1px solid var(--primary-200);
  }
  .image-fileName {
    height: 40px;
    font-size: 14px;
  }
}

.sample-image-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
}
.sample-image-item {
  position: relative;

  .image-wrap {
    width: 100%;
    height: 160px;
    border: 1px solid var(--primary-200);
  }
  .image-fileName {
    height: 32px;
    font-size: 12px;
  }
}

.image-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  padding: 0 8px;
}
.image-wrap {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.image-wrap-detail img {
    object-fit: contain;
  }
}
.image-fileName {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
