<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <PagePanel :isForm="true">
        <PagePanelFormRow label="제목">
          <InputText
            v-model.trim="title"
            placeholder="제목을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
        <PagePanelFormRow label="링크 URL">
          <InputText
            v-model.trim="linkUrl"
            placeholder="URL을 입력해주세요."
            class="stretch"
          />
        </PagePanelFormRow>
      </PagePanel>

      <policy-edit-list
        v-on:createRevision="createRevision"
        v-on:selectRevision="selectRevision"
        ref="list"
      />

      <PolicyEditModal
        :title="'개정 내용 작성'"
        :isOpen="isModalOpen"
        :isDialog="true"
        :confirmText="'저장'"
        v-on:confirm="confirmModal"
        v-on:close="closeModal"
        ref="modal"
      />
    </template>

    <template v-slot:footer> </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import { InputText, PagePanel, PagePanelFormRow } from "@ogqcorp/v-ui";

import PolicyEditModal from "./PolicyEditModal";
import PolicyEditList from "./PolicyEditList";
import axios from "axios";
import { apiGet, apiPut } from "../../../api/apiMethods";
export default {
  data() {
    return {
      // View Data
      API_URL: `/admin/terms`,
      isLoading: false,
      isModalOpen: false,
      // Revision Data

      // Terms Data
      originalData: false,
      title: "",
      linkUrl: "",
    };
  },
  created() {
    this.getPolicyData();
  },
  methods: {
    async getPolicyData() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const { data } = await apiGet(
          `/admin/terms/${this.$route.params.policyId}`
        );
        this.originalData = data;
        this.title = data.title;
        this.linkUrl = data.linkUrl;
      } catch (err) {
        console.dir(err);
      } finally {
        this.isLoading = false;
      }
    },
    saveChanges() {
      this.submitPolicy();
    },
    async confirmModal() {
      if (this.isLoading) return;
      this.isLoading = true;
      const modal = this.$refs.modal;
      const updatedDate = this.$formatDate(modal.date).replace(/-/gi, "");
      const title = this.title;
      const revision = modal.revision;
      const contents = modal.contents;

      if (!updatedDate || !revision || !contents) return;

      const data = {
        title: title ? title : this.originalData.title,
        updatedDate,
        revision,
        contents,
      };
      console.log(data);
      try {
        await apiPut(`admin/terms/${this.$route.params.policyId}`, data);
      } catch (error) {
        console.log(error.response);
      } finally {
        this.isModalOpen = false;
        this.isLoading = false;
        this.$refs.list.refresh();
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedRevision = false;
    },
    createRevision() {
      const modal = this.$refs.modal;

      modal.date = false;
      modal.revision = "";
      modal.contents = "";

      this.isModalOpen = true;
    },
    stringToDate(str) {
      return new Date(
        `${str.substring(0, 4)}-${str.substring(4, 6)}-${str.substring(6, 8)}`
      );
    },
    selectRevision(revision) {
      const modal = this.$refs.modal;

      const parse = (str) => {
        if (!/^(\d){8}$/.test(str)) return "invalid date";
        var y = str.substr(0, 4),
          m = str.substr(4, 2),
          d = str.substr(6, 2);
        return new Date(y, m, d);
      };

      modal.date = parse(revision.updatedDate);
      modal.revision = revision.revision;
      modal.contents = revision.contents;

      this.isModalOpen = true;
    },
    async submitPolicy() {
      if (this.isLoading) return;
      this.isLoading = true;
      const policyData = {
        title: this.title.trim(),
        linkUrl: this.linkUrl.trim(),
        ordering: 0,
        updatedDate: this.$formatDate(
          this.revision.revisionDate.getTime(),
          "YYYYMMDD"
        ),
        revision: this.revision.revision.trim(),
        contents: this.revision.contents.trim(),
      };
      console.log(JSON.stringify(policyData));

      try {
        await apiPut(`/admin/terms/${this.$route.params.policyId}`, policyData);
        this.$refs.list.refresh();
        this.createRevision();
      } catch (err) {
        console.dir(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    "admin-layout": AdminLayout,
    PagePanel,
    PagePanelFormRow,
    InputText,
    PolicyEditModal,
    "policy-edit-list": PolicyEditList,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-item {
  width: 364px;
}
.group {
  display: flex;
  align-items: center;
}
.dash {
  margin: 0 12px;
  color: var(--mono-500);
  user-select: none;
}
.buttons button {
  margin-right: 8px;
}

.page-panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;
  margin-top: 28px;

  & > .page-panel {
    margin: 0;
  }
}
</style>
