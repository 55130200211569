<template>
  <Modal
    title="퍼블리시티권 선택"
    ref="modal"
    :isDialog="true"
    :disableConfirm="!isChecked"
    confirmText="등록하기"
    v-on:confirm="requestSetCreator"
  >
    <InputSearch
      v-model="query"
      placeholder="Afreeca TV  BJ 아이디를 입력해주세요."
    />
    <ul class="message">
      <li class="item">
        퍼블리시티권 사용 약관을 동의한 BJ만 검색이 가능합니다.
      </li>
      <li class="item">
        BJ 검색은 아이디로만 가능하며 검색 결과는 BJ의 닉네임으로 보여집니다.
      </li>
      <li class="item">
        원하는 BJ의 아이디를 모를 경우, '<a href="#">BJ 찾기</a>'를 통해
        확인해주세요.
      </li>
      <li class="item">업로드 완료 전까지는 퍼블리시티권 수정이 가능합니다.</li>
    </ul>
    <ContentUploadBJItem
      :partner="partner"
      :isChecked="isChecked"
      v-on:toggleCheck="isChecked = !isChecked"
      v-if="partner"
    />
  </Modal>
</template>

<script>
import { Modal, InputSearch } from "@ogqcorp/v-ui";
import ContentUploadBJItem from "./ContentUploadBJItem";
import { apiGet } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      query: "",
      partner: null,
      isChecked: false,
    };
  },
  mounted() {
    // this.$refs.modal.open()
  },
  watch: {
    query() {
      this.requestCreatorById();
    },
    creator(n) {
      if (!n) {
        this.isChecked = false;
      }
    },
  },
  emits: ["setPartner"],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    requestSetCreator() {
      this.$emit("setPartner", this.partner);
    },
    async requestCreatorById() {
      const query = this.query;

      if (query.length < 1) {
        this.partner = null;
        return;
      }

      try {
        const response = await apiGet(
          `/admin/search/publicity/afreeca/${query}`,
          {},
          true
        );
        console.log(response);
        this.partner = response.data.data || null;
      } catch {
        this.partner = null;
      }
    },
  },
  components: {
    Modal,
    InputSearch,
    ContentUploadBJItem,
  },
};
</script>

<style lang="scss" scoped>
ul {
  // margin: 0;
  padding-left: 24px;
  font-size: 12px;
  margin: 20px 0 0;
  color: var(--mono-500);

  a {
    color: var(--primary-500);
  }
}
</style>
