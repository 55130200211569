<template>
  <div
    :class="[
      'modal-form-control',
      {
        'modal-form-control-horizontal': dir === 'horizontal',
        'modal-form-control-stacked': stacked,
        'modal-form-control-stretch': stretch
      }
    ]"
  >
    <label v-if="$slots.label">
      <slot name="label"></slot>
    </label>
    <div class="control">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    dir: {
      type: String,
    },
    stacked: {
      type: Boolean,
      default: true
    },
    stretch: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-form-control-horizontal {
  display: flex;
  align-items: center;
  gap: 12px;
}
.modal-form-control label {
  display: flex;
  font-weight: 500;
  color: var(--mono-900);
  margin: 0 0 16px;
}
.modal-form-control-horizontal label {
  margin: 0;
}

.modal-form-control + .modal-form-control-stacked {
  margin: 30px 0 0;
}

.modal-form-control-stretch {
  grid-column: 1 / -1;
}

.control {
  width: 100%;
}
</style>
