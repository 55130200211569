<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:body>
      <div class="creator-type-wrapper">
        <label>크리에이터 유형</label>
        <Dropdown v-model="sellerType" :list="sellerTypes" :isForm="true" />
      </div>
      <div class="panels">
        <div class="col">
          <div class="panel">
            <PagePanel :isForm="true" title="회사 정보">
              <div class="control">
                <label for="">회사명</label>
                <div class="inner">
                  <InputText
                    v-model="companyName"
                    class="input"
                    placeholder="회사명을 입력해주세요."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">대표자명</label>
                <div class="inner">
                  <InputText
                    v-model="ceoName"
                    class="input"
                    placeholder="대표자명을 입력해주세요."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">사업자 등록증</label>
                <div class="inner">
                  <creator-image-uploader
                    v-on:imageSelected="setRegistrationImage"
                    v-on:imageDeleted="removeRegistrationImage"
                  />
                </div>
              </div>
              <div class="control">
                <label for="">사업자등록번호</label>
                <div class="inner">
                  <InputText
                    v-model="registrationNumber"
                    class="input"
                    placeholder="-를 제외한 숫자를 입력해주세요."
                    :maxLen="10"
                  />
                </div>
              </div>
              <div class="control">
                <label for="">사업장 주소</label>
                <div class="inner">
                  <InputText
                    v-model="officeAddress"
                    class="input"
                    placeholder="주소를 입력해주세요."
                  />
                </div>
              </div>
            </PagePanel>
          </div>
        </div>

        <div class="col">
          <div class="panel">
            <PagePanel :isForm="true" title="아이디 정보">
              <div class="control">
                <label for="">ID</label>
                <div class="inner">
                  <InputText
                    v-model="username"
                    class="input"
                    placeholder="ID를 입력해주세요."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">닉네임</label>
                <div class="inner">
                  <InputText
                    v-model="nickname"
                    class="input"
                    placeholder="닉네임을 입력해주세요."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">이메일</label>
                <div class="inner">
                  <InputText
                    v-model="email"
                    class="input"
                    placeholder="이메일을 입력해주세요."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">비밀번호</label>
                <div class="inner">
                  <InputText
                    type="password"
                    v-model="password"
                    class="input"
                    placeholder="'비밀번호는 6자~16자 숫자와 문자가 모두 포함되어야 합니다."
                  />
                </div>
              </div>
              <div class="control">
                <label for="">비밀번호 확인</label>
                <div class="inner">
                  <InputText
                    type="password"
                    v-model="passwordConfirm"
                    class="input"
                    placeholder="비밀번호를 한번 더 입력해주세요."
                  />
                </div>
              </div>
            </PagePanel>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <!-- <Button :disabled="true">미리보기</Button> -->
      <Button
        class="Button-save"
        color="primary"
        :disabled="!isValid"
        v-on:click="saveChanges"
        >계정생성</Button
      >
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import CreatorAddImageUploader from "@/views/creator/creator/add/CreatorAddImageUploader";
import { Button, InputText, PagePanel, Dropdown } from "@ogqcorp/v-ui";
import { sellerTypes } from "../creatorUtils";
import { apiPost } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      API_URL: "/admin/v2/creators",
      sellerType: "COMPANY",
      sellerTypes: sellerTypes.filter(({ value }) => value === "COMPANY"),
      companyName: "",
      ceoName: "",
      registrationNumber: "",
      officeAddress: "",
      username: "",
      nickname: "",
      email: "",
      password: "",
      passwordConfirm: "",
      registrationFile: null,
      // registrationFileName: null,
    };
  },
  methods: {
    saveChanges() {
      this.submitUser();
    },
    async submitUser() {
      console.log(JSON.stringify(this.registrationFile.dataUrl));
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.password.length < 1) {
        console.error("Password cannot be empty");
        this.isLoading = false;
        return;
      }
      if (this.password !== this.passwordConfirm) {
        console.error("Match Failed");
        this.isLoading = false;
        return;
      }
      const headers = this.$store.getters["user/getAPIHeaders"];
      const data = {
        sellerType: this.sellerType,
        ceoName: this.ceoName,
        companyName: this.companyName,
        officeAddress: this.officeAddress,
        registrationNumber: this.registrationNumber,
        registrationFile: this.registrationFile
          ? this.registrationFile.dataUrl
          : null,
        registrationFileName: this.registrationFile
          ? this.registrationFile.file.name
          : null,
        // username: this.username,
        nickname: this.nickname,
        email: this.email,
        password: this.password,
      };
      console.log(data);
      try {
        await apiPost(this.API_URL, data, true);
        await this.$confirm({
          msg: "크리에이터를 성공적으로 생성했습니다.",
        });
        setTimeout(() => {
          this.$router.push({ name: "Creators" });
        }, 500);
      } catch (err) {
        console.log("submitUser Error", err.response);
        let message = "문제가 생겼습니다.";
        if (err.response.data.code === 40905) {
          message = "[ID/닉네임] 값이 중복되어 계정을 생성할 수 없습니다.";
        } else if (err.response.data.code === 40903) {
          message = "[이메일] 값이 중복되어 계정을 생성할 수 없습니다.";
        }
        await this.$error(message);
      } finally {
        this.isLoading = false;
      }
    },
    setRegistrationImage(imageData) {
      this.registrationFile = imageData;
    },
    removeRegistrationImage() {
      this.registrationFile = null;
    },
  },
  computed: {
    isValid() {
      return (
        this.companyName &&
        this.ceoName &&
        this.registrationNumber &&
        this.officeAddress &&
        this.username &&
        this.nickname &&
        this.password &&
        this.password.length >= 6 &&
        this.password.length <= 16 &&
        /[a-zA-Z]/g.test(this.password) &&
        /[0-9]/g.test(this.password) &&
        this.passwordConfirm &&
        this.registrationFile &&
        this.password === this.passwordConfirm
      );
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "creator-image-uploader": CreatorAddImageUploader,
    InputText,
    Button,
    PagePanel,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.panel {
  padding: 15px 0;
}

.control {
  padding: 15px 20px;
}

.creator-type-wrapper label,
.control label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin: 0 0 16px;
}

.control .inner {
  display: flex;
  align-items: center;
  .input {
    flex: 1;
  }
}

.Button-save {
  width: 200px;
}

// .dropdown {
//   width: 346px;
// }
</style>
