<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6628 11.5C21.6628 17.1128 17.1128 21.6628 11.5 21.6628C8.81166 21.6628 6.36711 20.619 4.54957 18.9145L20.145 6.15454C21.1073 7.70744 21.6628 9.53885 21.6628 11.5ZM19.3658 5.06434L3.6342 17.9357C2.19863 16.1831 1.33721 13.9422 1.33721 11.5C1.33721 5.88725 5.88725 1.33721 11.5 1.33721C14.6706 1.33721 17.5021 2.78912 19.3658 5.06434ZM23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
      fill="#CED4DA"
    />
  </svg>
</template>
