<template>
  <PagePanel
    title="계좌 인증 데이터"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>예금주</template>
        <template v-slot:td>
          <InputText
            v-model="ownerName"
            class="input"
            placeholder="이름을 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>계좌번호</template>
        <template v-slot:td>
          <InputText
            v-model="accountNumber"
            class="input"
            placeholder="게좌번호를 입력하세요."
          />
        </template>
      </table-v-row>
      <table-v-row>
        <template v-slot:th>은행</template>
        <template v-slot:td>
          <Dropdown
            v-model="bankCode"
            v-on:changed="onBankChanged"
            placeholder="은행사"
            :list="banks"
          />
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";
import { InputText, PagePanel, Dropdown } from "@ogqcorp/v-ui";
import { apiGet } from "@/api/apiMethods";

export default {
  data() {
    return {
      banks: [],
    };
  },
  created() {
    this.getBanks();
  },
  props: ["creator", "sellerInfo"],
  inheritAttrs: false,
  methods: {
    async getBanks() {
      try {
        const res = await apiGet("/ptn/v5/banks");
        this.banks = res.data.map(({ bankCode, bankName }) => ({
          value: bankCode.code,
          label: bankName,
        }));
      } catch (err) {
        console.log(err.response);
      }
    },
    onBankChanged(bankCode) {
      const bank = this.banks.find(({ value }) => value === bankCode);
      if (bank) {
        this.bankName = bank.label;
      }
    },
  },
  computed: {
    ownerName: {
      get() {
        return this.sellerInfo.bankAccount.ownerName;
      },
      set(value) {
        this.$emit("update:ownerName", value);
      },
    },
    accountNumber: {
      get() {
        return this.sellerInfo.bankAccount.accountNumber;
      },
      set(value) {
        this.$emit("update:accountNumber", value);
      },
    },
    bankCode: {
      get() {
        return this.sellerInfo.bankAccount.bankCode;
      },
      set(value) {
        this.$emit("update:bankCode", value);
      },
    },
    bankName: {
      get() {
        return this.sellerInfo.bankAccount.bankName;
      },
      set(value) {
        this.$emit("update:bankName", value);
      },
    },
  },
  components: {
    PagePanel,
    "table-v": TableV,
    "table-v-row": TableVRow,
    Dropdown,
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 340px;
}
</style>
