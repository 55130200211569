<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head> </template>
    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Button
            v-on:click="openModal"
            :color="isFilterApplied ? 'primary' : 'default'"
            :solid="isFilterApplied ? true : false"
          >
            필터
            <template v-slot:icon>
              <svgChevronDown />
            </template>
          </Button>
          <Button v-on:click="clearAll" :disabled="!isFilterApplied">
            초기화
          </Button>
        </template>
        <template v-slot:search>
          <form v-on:submit.prevent="search">
            <Dropdown
              v-model="searchType"
              class="dropdown"
              placeholder="검색 옵션"
              :list="searchTypeArr"
            />
            <InputSearch
              v-model.trim="searchQuery"
              placeholder="닉네임을 검색해주세요."
            />
          </form>
        </template>
      </TableFilter>
    </template>
    <template v-slot:body>
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <div class="table-body" v-else-if="list.length > 0">
          <table-row
            v-for="(item, i) in list"
            :key="i"
            :item="item"
            :id="item.artworkId"
          />
        </div>
      </PagePanel>
      <br />
      <TableAction>
        <template v-slot:actions> </template>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPageOld"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>
    </template>
  </admin-layout>

  <creators-filter-modal
    :isOpen="isFilterOpen"
    v-on:open="$refs.filterModal.reset()"
    v-on:cancel="cancelFilter"
    v-on:confirm="applyFilter"
    ref="filterModal"
  />
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";

// import Tab from '@/components/el/tabs/Tab'

import {Button, InputSearch, svg} from "@ogqcorp/v-ui"
const {svgChevronDown} = svg
import ContentRowHead from "@/views/creator/creator/list/CreatorsTableRowHead";
import ContentRow from "@/views/creator/creator/list/CreatorsTableRow";
import CreatorsFilterModal from "./CreatorsFilterModal";

export default {
  data() {
    return {
      API_URL: `/ptn/v5/admin/creators`,
      searchQuery: "",
      contentType: "sticker",
      listKey: "elements",
      isFilterOpen: false,
      searchType: "NICKNAME",
      searchTypeArr: [
        // {value: 'TITLE', label: '콘텐츠명'},
        { value: "NICKNAME", label: "닉네임" },
        { value: "CREATOR_ID", label: "크리에이터 아이디" },
        { value: "EMAIL", label: "이메일" },
      ],
    };
  },
  extends: TableView,
  methods: {
    onBeforeClear() {
      this.searchQuery = ""
      this.status = false
      this.searchType = "NICKNAME"
      this.$refs.filterModal.reset();
    },
    async setFilters() {
      const query = {
        ...this.$route.query,
        ...{
          status: this.status ? this.status : undefined,
          searchType:
            this.searchType && this.query ? this.searchType : undefined,
          query: this.searchType && this.query ? this.query : undefined,
        },
      };
      await this.loadPage(
        {...this.$route.params, ...{page: 1}},
        query,
        true
      );
    },
    async setContentType(category) {
      if (this.$route.params.category === category) return;
      this.API_URL = `${this.$API_URL}/admin/${category}`;
      this.$router.push({
        params: {
          ...{ page: 1 },
          category,
        },
        query: null,
      });
      await this.loadPageOld();
    },
    isContentType(type) {
      return this.$route.query && this.$route.query.artworkType === type;
    },
    openModal() {
      this.isFilterOpen = true;
    },
    async applyFilter() {
      const query = this.$refs.filterModal.getQuery();
      console.log(query)
      await this.loadPage(
        {
          ...this.$route.params,
          ...{ page: 1 },
        },
        {
          ...this.$route.query,
          ...query,
        },
        true
      );
      this.isFilterOpen = false;
    },
    cancelFilter() {
      this.$refs.filterModal.reset();
      this.isFilterOpen = false;
    },
  },
  computed: {
    isFilterModalOpen() {
      return this.$route.query && this.$route.query.modal === "true";
    },
    isContentSticker() {
      return this.contentType === "sticker";
    },
    isContentImage() {
      return this.contentType === "image";
    },
    isContentColoring() {
      return this.contentType === "coloring";
    },
    isContentAudio() {
      return this.contentType === "audio";
    },
    isContentTemplate() {
      return this.contentType === "template";
    },
    isFilterApplied() {
      return this.$route.query.sellerType != null ||
      this.$route.query.directConnected != null ||
      this.$route.query.grade != null ||
      this.$route.query.brand != null ||
      this.$route.query.streamer != null ||
      this.$route.query.qualityGrade != null ||
      this.$route.query.begunAt != null ||
      this.$route.query.endedAt != null
    },
  },
  components: {
    "admin-layout": AdminLayout,
    "creators-filter-modal": CreatorsFilterModal,
    // 'tab': Tab,
    Button,
    svgChevronDown,
    InputSearch,
    "table-row-head": ContentRowHead,
    "table-row": ContentRow,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 40px 1fr 0.6fr 1fr 1.2fr 1fr 1fr 1fr;
  align-items: center;
}

.dropdown {
  width: 170px;
}
</style>
