<template>
  <card>
    <template v-slot:title> 판매 마켓 관리 </template>
    <div class="list">
      <div class="item" v-on:click="setMode('all')">
        <Radio :checked="mode === 'all'" :disabled="isDisabled" />
        <label>전체마켓에서 판매</label>
      </div>
      <div class="item" v-on:click="setMode('whitelist')">
        <Radio :checked="mode === 'whitelist'" :disabled="isDisabled" />
        <label> 특정 마켓에서 판매 (White List) </label>
      </div>
      <div class="markets" v-if="mode === 'whitelist'">
        <div
          class="market-item"
          v-for="market in markets"
          :key="market.marketId"
        >
          <Checkbox
            size="small"
            :disabled="isDisabled"
            :checked="whitelistMarkets.includes(market.marketId)"
            :label="market.name"
            :data-marketId="market.marketId"
            v-on:click="
              toggleMarketInGroup('whitelistMarkets', market.marketId)
            "
          />
        </div>
      </div>
      <div class="item" v-on:click="setMode('blacklist')">
        <Radio :checked="mode === 'blacklist'" :disabled="isDisabled" />
        <label> 특정 마켓에서 판매금지 (Black List) </label>
      </div>
      <div class="markets" v-if="mode === 'blacklist'">
        <div
          class="market-item"
          v-for="market in markets"
          :key="market.marketId"
        >
          <Checkbox
            size="small"
            :disabled="isDisabled"
            :checked="blacklistMarkets.includes(market.marketId)"
            :label="market.name"
            :data-marketId="market.marketId"
            v-on:click="
              toggleMarketInGroup('blacklistMarkets', market.marketId)
            "
          />
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Checkbox, Radio } from "@ogqcorp/v-ui";
// import markets from '@/fixtures/DATA_MARKETS.js'
import { apiGet } from "@/api/apiMethods";
export default {
  props: {
    _blacklistMarkets: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _whitelistMarkets: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_URL: `/ptn/v5/markets`,
      mode: "all",
      markets: [],
      blacklistMarkets: [],
      whitelistMarkets: [],
    };
  },
  created() {
    this.blacklistMarkets = [...this._blacklistMarkets];
    this.whitelistMarkets = [...this._whitelistMarkets];
    if (this.blacklistMarkets.length > 0) {
      this.mode = "blacklist";
    } else if (this.whitelistMarkets.length > 0) {
      this.mode = "whitelist";
    } else {
      this.mode = "all";
    }
    this.getMarkets();
  },
  methods: {
    async getMarkets() {
      const res = await apiGet(this.API_URL);
      this.markets = res.data;
      console.log("markets", res.data);
    },
    setMode(market) {
      if (this.isDisabled) return;
      this.mode = market;
      if (market === "blacklist") {
        this.whitelistMarkets = [];
      } else if (market === "whitelist") {
        this.blacklistMarkets = [];
      } else {
        this.whitelistMarkets = [];
        this.blacklistMarkets = [];
      }
    },
    toggleMarketInGroup(group, marketId) {
      if (this[group].includes(marketId)) {
        this[group] = this[group].filter((mId) => mId !== marketId);
      } else {
        this[group].push(marketId);
      }
    },
  },
  components: {
    card: SingleContentCard,
    Radio,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-gap: 16px;
}

.item {
  display: flex;
  align-items: center;
}

.item label {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 14px;
}

.item label .dropdown {
  margin-right: 10px;
}

.markets {
  background-color: #f7f9ff;
  margin: 0 -20px;
  padding: 20px 20px 8px;
  display: flex;
  flex-wrap: wrap;
}

.market-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0 12px 12px 0;
}

.market-item label {
  margin-left: 6px;
}
</style>
