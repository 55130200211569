<template>
  <Modal 
    title="링크 추가"
    ref="modal"
    :isDialog="true"
  >
    <ModalFormControl>
      <template v-slot:label>링크 URL</template>
      <InputText v-model="url" />
    </ModalFormControl>
  </Modal>
</template>

<script>
import {Modal, InputText} from '@ogqcorp/v-ui'
import ModalFormControl from "@/components/modal/ModalFormControl";
export default {
  data() {
    return {
      url: ""
    }
  },
  components: {
    Modal,
    ModalFormControl,
    InputText,
  }
}
</script>