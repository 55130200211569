<template>
  <div
    class="tr"
    :class="{ sticker: $route.params.type === 'STICKER' }"
  >
    <div class="td">
      <Checkbox />
    </div>
    <div class="td">등록일시</div>
    <div class="td">미리보기</div>
    <div class="td">콘텐츠명</div>
    <div class="td">콘텐츠 타입</div>
    <div v-if="$route.params.type === 'STICKER'" class="td">판매 형태</div>
    <div class="td">유저 ID<br />(닉네임)</div>
    <div class="td">콘텐츠 ID</div>
    <div class="td">작가등급</div>
    <div class="td">콘텐츠 상태</div>
  </div>
</template>

<script>
import { TableListRow } from "@ogqcorp/v-ui";
export default {
  extends: TableListRow,
  components: {},
};
</script>
