<template>
  <div class="tr">
    <div class="td">
      <Checkbox v-on:click="toggleCheck" :checked="checked" />
    </div>
    <div class="td">NO</div>
    <div class="td">개정일</div>
    <div class="td">개정내용</div>
  </div>
</template>

<script>
import {TableListRow} from "@ogqcorp/v-ui"
export default {
  extends: TableListRow,
  components: {},
};
</script>
