<template>
  <form v-on:submit.prevent class="image-uploader" ref="form">
    <InputText
      v-model="imageName"
      class="input"
      placeholder="파일을 등록해주세요. (png/pdf)"
      :readonly="true"
    />
    <div class="file-btn">
      파일첨부
      <input type="file" accept=".jpg, .jpeg, .png, .pdf" v-on:change="onChange" />
    </div>
  </form>
</template>

<script>
import ImageUploadBase from "@/components/uploader/ImageUploadBase";
import {InputText} from "@ogqcorp/v-ui"

export default {
  extends: ImageUploadBase,
  components: {
    InputText,
  },
};
</script>

<style lang="scss" scoped>
.image-uploader {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.file-btn {
  position: relative;
  cursor: pointer;
  width: 72px;
  height: 32px;
  margin: 0 0 0 20px;
  background-color: var(--mono-100);
  font-size: 14px;
  line-height: 32px;
  color: var(--mono-900);
  text-align: center;
}

input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.input {
  flex: 1;
}
</style>
