<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:bodyHead>
      <TableAction>
        <template v-slot:actions>
          <Button :disabled="true">약관 작성</Button>
          <!-- <Button :disabled="!isAnyRowsChecked" v-on:click="deleteSelected" -->
          <Button :disabled="!isAnyRowsChecked" v-on:click="$error('약관은 삭제가 불가능합니다.')"
            >선택 삭제</Button
          >
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
      </TableAction>
    </template>

    <template v-slot:body>
      <TableAction>
        <template v-slot:pagination>
          <PaginationPages
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
            v-on:nextPage="gotoNextPage"
            v-on:previousPage="gotoPreviousPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <template v-if="list.length > 0">
          <div class="table-head">
            <table-row-head
              :checked="checkedRows.length > 0"
            />
          </div>
          <div class="table-body">
            <table-row
              v-for="item in list"
              :item="item"
              :checked="checkedRows.includes(item.termId)"
              :key="item.termId"
              :id="item.termId"
            />
          </div>
        </template>
        <template v-if="!isLoading && list.length < 1">
          <div class="table-empty-list-message">데이터가 없습니다.</div>
        </template>
      </PagePanel>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import TableView from "@/components/table/TableView";
import {Button} from "@ogqcorp/v-ui";
import PolicyTableRow from "@/views/service/policy/PolicyTableRow";
import PolicyTableRowHead from "@/views/service/policy/PolicyTableRowHead";

export default {
  data() {
    return {
      API_URL: `${this.$API_URL}/admin/terms`,
      deleteMessageHTML: `선택하신 약관을 <br> 삭제하시겠습니까?`,
      searchQuery: "",
      listKey: "elements",
      idKey: "termId",
    };
  },

  extends: TableView,
  components: {
    "admin-layout": AdminLayout,
    Button,
    "table-row": PolicyTableRow,
    "table-row-head": PolicyTableRowHead,
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.65fr 1.4fr 1.25fr 0.65fr;
  align-items: center;
}
</style>
