<template>
  <card>
    <div class="d">
      <div class="file-upload-wrap">
        <div class="label file-upload">
          <span>AI 파일</span>
          <span class="file-name">
            {{ AIFileName }}
          </span>
          <label>
            <input
              type="file"
              id="AIfileInput"
              ref="AIFile"
              accept=".ai"
              v-on:change="uploadAIFile"
            />
            <div class="business-license-file">
              <span class="file-upload-btn"> 파일 첨부하기 </span>
            </div>
          </label>
        </div>
        <div class="label file-upload">
          <span>EPS 파일</span>
          <span class="file-name">
            {{ EPSFileName }}
          </span>
          <label>
            <input
              type="file"
              id="EPSfileInput"
              ref="EPSFile"
              accept=".eps"
              v-on:change="uploadEPSFile"
            />
            <div class="business-license-file">
              <span class="file-upload-btn"> 파일 첨부하기 </span>
            </div>
          </label>
        </div>
        <div class="label file-upload">
          <span>PSD 파일</span>
          <span class="file-name">
            {{ PSDFileName }}
          </span>
          <label>
            <input
              type="file"
              id="PSDfileInput"
              ref="PSDFile"
              accept=".psd"
              v-on:change="uploadPSDFile"
            />
            <div class="business-license-file">
              <span class="file-upload-btn"> 파일 첨부하기 </span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { readFileAsDataUrl } from "@/utils/files";
import Toast from "@/components/toast";
import { apiPost } from "../../../api/apiMethods";

export default {
  props: {
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      newFiles: [],
      AIFileName: "",
      EPSFileName: "",
      PSDFileName: "",
    };
  },
  created() {
    this.files.map((file) => {
      if (file.format === "AI") {
        this.AIFileName = "파일 등록 완료";
      } else if (file.format === "EPS") {
        this.EPSFileName = "파일 등록 완료";
      } else if (file.format === "PSD") {
        this.PSDFileName = "파일 등록 완료";
      }
    });
  },
  methods: {
    uploadAIFile() {
      const file = this.$refs.AIFile.files[0];
      this.AIFileName = file.name;
      this.onResourceSelected(file);
    },
    uploadEPSFile() {
      const file = this.$refs.EPSFile.files[0];
      this.EPSFileName = file.name;
      this.onResourceSelected(file);
    },
    uploadPSDFile() {
      const file = this.$refs.PSDFile.files[0];
      this.PSDFileName = file.name;
      this.onResourceSelected(file);
    },
    uploadFile(fileType, fileData, fileName) {
      const API_URL = `/admin/file/${fileType}/upload`;
      const formData = new FormData();
      const file = new File([fileData], fileName);
      formData.append("userId", this.creator?.creatorId);
      formData.append("file", file);
      return apiPost(API_URL, formData, true);
    },
    async onResourceSelected(file) {
      const format = file?.name
        ?.substring(file?.name?.lastIndexOf(".") + 1)
        .toUpperCase();
      const data = await readFileAsDataUrl(file);

      if (format === "AI" && data.type !== "application/postscript") {
        return Toast("ai 형식이어야 합니다.", { type: "error" });
      }

      if (format === "EPS" && data.type !== "application/postscript") {
        return Toast("eps 형식이어야 합니다.", { type: "error" });
      }

      if (format === "PSD" && data.type !== "image/vnd.adobe.photoshop") {
        return Toast("psd 형식이어야 합니다.", { type: "error" });
      }

      const fileData = await this.uploadFile("RAW", file, file.name);

      this.newFiles = this.files.filter((el) => el.format !== format);

      this.newFiles.push({
        fileSize: data.file.size,
        filePath: fileData.data.path,
        fileName: data.file.name,
        format,
        width: fileData.data.width,
        height: fileData.data.height,
      });

      Toast(`${format} 파일을 등록했습니다.`, { type: "success" });
    },
  },
  components: {
    card: SingleContentCard,
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.file-upload-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.file-upload {
  display: flex;
  width: 100%;
}
.file-upload label {
  margin: 0;
  cursor: pointer;
}
.file-upload input[type="file"] {
  display: none;
}
.file-upload-btn {
  text-decoration: underline;
  font-size: 14px;
}
.business-license-file {
  display: flex;
  gap: 20px;
}
.file-name {
  margin: 0 20px 0 auto;
  color: var(--mono-500);
}
</style>
