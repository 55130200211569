<template>
  <Modal
    :title="`카테고리 ${isEdit ? '수정' : '추가'}`"
    :isDialog="true"
    :confirmText="isEdit ? '저장' : '추가'"
    :disableConfirm="!isValid"
    :isLoading="isLoading"
  >
    <control>
      <template v-slot:label>카테고리(KR)</template>
      <InputText
        v-model="krName"
        class="input"
        placeholder="카테고리명을 입력하세요"
      />
    </control>
    <control>
      <template v-slot:label>카테고리(EN)</template>
      <InputText
        v-model="enName"
        class="input"
        placeholder="카테고리명을 입력하세요"
      />
    </control>
    <control>
      <template v-slot:label>카테고리로 지정할 태그</template>
      <Textarea
        v-model="tags"
        placeholder="카테고리로 지정될 태그명을 입력하세요(, 로 구분)"
        class="input ta"
        :minHeight="'120px'"
      />
    </control>
    <control>
      <div class="radios">
        <div class="radio-item">
          <Radio
            :checked="activated"
            label="활성화"
            v-on:click="activated = true"
          />
        </div>
        <div class="radio-item">
          <Radio
            :checked="!activated"
            label="비활성화"
            v-on:click="activated = false"
          />
        </div>
      </div>
    </control>
  </Modal>
</template>

<script>
import ModalFormControl from "@/components/modal/ModalFormControl";
import ModalSimple from "@/components/modal/simple/ModalSimple";
import { Radio, InputText, Textarea } from "@ogqcorp/v-ui";
import { apiPost, apiPut } from "../../../../api/apiMethods";

export default {
  data() {
    return {
      categoryId: false,
      isEdit: false,
      krName: "",
      enName: "",
      activated: false,
      isLoading: false,
      tags: [],
    };
  },
  methods: {
    save() {
      if (!this.isEdit) {
        this.add();
      } else {
        this.edit();
      }
    },
    async edit() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const response = await apiPut(`/dam/v5/categories/${this.categoryId}`, {
          krName: this.krName,
          enName: this.enName,
          activated: this.activated,
          tags: this.tagList,
        });
        console.log(response);
        this.$emit("refresh");
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("close");
        }, 500);
      } catch (err) {
        this.$alert({ msg: err.response.data.message });
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
    setCategory({ krName, enName, activated, tags }) {
      this.krName = krName;
      this.enName = enName;
      this.activated = activated;
      this.tags = tags;
    },
    clear() {
      this.categoryId = false;
      this.krName = "";
      this.enName = "";
      this.activated = false;
      this.tags = [];
    },
    async add() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        await apiPost("/dam/v5/categories", {
          krName: this.krName,
          enName: this.enName,
          activated: this.activated,
          type: this.$route.params.type,
          tags: this.tagList,
          ordering: 0,
        });
        this.$emit("refresh");
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("close");
        }, 500);
      } catch (err) {
        this.$alert({ msg: err.response.data.message });
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    },
  },
  computed: {
    isValid() {
      return this.krName && this.enName;
    },
    tagList() {
      if (typeof this.tags === "object") return this.tags;

      return this.tags
        .replace(/\s/g, ",")
        .split(",")
        .map((v) => v.trim());
    },
  },
  components: {
    Modal: ModalSimple,
    InputText,
    Textarea,
    control: ModalFormControl,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
}

.radios {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -15px;
}

.radios .radio-item {
  margin: 0 20px 15px 0;
}
</style>
