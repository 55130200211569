<template>
  <form
    class="uploader"
    v-on:submit.prevent
    v-on:drop="onDrop"
    ref="form"
    :class="{
      'has-image':
        hasImage || (!hasImage && defaultImage && !isDefaultImageHidden),
    }"
  >
    <div class="uploader-message">
      파일을 여기에 놓거나 클릭하여 업로드하세요.
    </div>
    <div class="uploader-icon">
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(.5)" fill="none" fill-rule="evenodd">
          <circle fill="currentColor" cx="22" cy="22" r="22" />
          <path
            stroke="#FFF"
            stroke-width="1.8"
            d="M22 17.519V44M12 26l10-9 10 9"
          />
        </g>
      </svg>
    </div>
    <div class="uploader-label">
      JPG / PNG 파일<br />
      10MB 이하 이미지 업로드 가능
    </div>
    <input type="file" accept=".jpg, .jpeg, .png" v-on:change="onChange" />
    <div class="img-preview-wrapper" v-if="hasImage">
      <div class="img-preview">
        <img :src="imageData.dataUrl" />
      </div>
      <button type="button" v-on:click="deleteImage"><i-close /></button>
    </div>
    <div
      class="img-preview-wrapper"
      v-else-if="!hasImage && defaultImage && !isDefaultImageHidden"
    >
      <div class="img-preview">
        <img :src="defaultImage" />
      </div>
      <button type="button" v-on:click="hideDefaultImage"><i-close /></button>
    </div>
  </form>
</template>

<script>
import svgClose from "@/components/svg/icons/svgClose";

export default {
  data() {
    return {
      isLoading: false,
      imageData: false,
      isDefaultImageHidden: false,
    };
  },
  props: ["defaultImage"],
  methods: {
    hideDefaultImage() {
      this.isDefaultImageHidden = true;
    },
    async onChange(e) {
      if (this.isLoading) return;
      this.isLoading = true;
      let file = e.target.files[0];
      let imageData = await this.readAsDataURL(file);
      console.log(imageData);
      this.$refs.form.reset();
      this.imageData = imageData;
      this.$emit("imageSelected", imageData);
      this.isLoading = false;
    },
    async onDrop(e) {
      if (this.isLoading) return;
      this.isLoading = true;
      let file = e.dataTransfer.files[0];
      let imageData = await this.readAsDataURL(file);
      this.$refs.form.reset();
      console.log(imageData);
      this.imageData = imageData;
      this.$emit("imageSelected", imageData);
      this.isLoading = false;
    },
    async readAsDataURL(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = async () => {
          let dimensions = await this.getImageDimensions(fileReader.result);
          return resolve({
            dataUrl: fileReader.result,
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
            dimensions: dimensions,
          });
        };
        fileReader.readAsDataURL(file);
      });
    },
    getImageDimensions(dataUrl) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          return resolve({
            height: img.height,
            width: img.width,
          });
        };
        img.src = dataUrl;
      });
    },
    deleteImage(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      this.imageData = false;
      this.$emit("imageDeleted");
    },
  },
  computed: {
    hasImage() {
      return !this.isLoading && this.imageData && this.imageData.dataUrl;
    },
  },
  components: {
    "i-close": svgClose,
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  position: relative;
  width: 100%;
  max-width: 760px;
  min-height: 203px;
  border: 1px dashed var(--mono-400);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mono-500);
  &:hover:not(.has-image) {
    color: var(--mono-400);
    border-color: var(--primary-500);
  }
}
.uploader-message {
  font-weight: normal;
  font-size: 0.875rem;
  margin-bottom: 32px;
  user-select: none;
  .has-image > & {
    opacity: 0;
  }
}
.uploader-icon {
  width: 44px;
  height: 44px;
  margin-bottom: 12px;
  user-select: none;
  .has-image > & {
    opacity: 0;
  }
}

.uploader-label {
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  user-select: none;
  .has-image > & {
    opacity: 0;
  }
}

.uploader input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
}

.img-preview-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
}

.img-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  padding: 8px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.has-image:hover .img-preview img {
  opacity: 0.5;
}

.img-preview-wrapper button {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  padding: 0;
  z-index: 7;
  background-color: transparent;
  border: 0;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
.has-image:hover .img-preview-wrapper button {
  display: block;
}
</style>
