<template>
  <card>
    <template v-slot:title> 콘텐츠 품질 점수 </template>
    <div class="list">
      <div
        class="item"
        v-for="({ value, label }, i) in grades"
        :key="`item-${i}`"
      >
        <Radio
          :checked="grade === value"
          :label="label"
          @click="setGrade(value)"
          size="small"
        />
      </div>
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import { Radio } from "@ogqcorp/v-ui";

export default {
  props: {
    _grade: String,
  },
  data() {
    return {
      grades: [
        {
          value: "PREMIUM",
          label: "상",
        },
        {
          value: "NORMAL",
          label: "중",
        },
        {
          value: "LOW",
          label: "하",
        },
      ],
      grade: null,
    };
  },
  created() {
    this.grade = this._grade;
  },
  methods: {
    setGrade(grade) {
      this.grade = this.grade === grade ? null : grade;
    },
  },
  components: {
    card: SingleContentCard,
    Radio,
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

.item {
  display: flex;
  align-items: center;
}

.item label {
  margin-left: 6px;
  font-size: 14px;
  color: var(--mono-900);
}
</style>
