<template>
  <admin-layout :title="$route.meta.title">
    <template v-slot:head>
      <div class="tabs">
        <Tab label="자동" :active="true" />
        <Tab
          label="수동"
          :active="false"
          v-on:click="$router.push({ name: 'NotificationsManual' })"
        />
      </div>
    </template>

    <template v-slot:bodyHead>
      <TableFilter>
        <template v-slot:buttons>
          <Button>
            전체
            <template v-slot:icon>
              <svgChevronDown />
            </template>
          </Button>
        </template>
        <template v-slot:search> </template>
      </TableFilter>
    </template>

    <template v-slot:body>
      <TableAction>
        <template v-slot:actions>
          <Button :to="{ name: 'NotificationsAutoAdd' }">알림 작성</Button>
          <Button :disabled="!isAnyRowsChecked">선택 삭제</Button>
          <div class="action-status">
            검색결과 총 {{ totalListCount }}개 ({{ checkedRows.length }}개
            선택됨)
          </div>
        </template>
        <template v-slot:pagination>
          <PaginationPages 
            :pagesCount="pagesCountToDisplay"
            :currentPage="currentPage"
            :hasNextPage="hasNextPage"
            v-on:requestPage="loadPage"
          />
        </template>
      </TableAction>

      <PagePanel>
        <div class="table-head">
          <table-row-head
            v-on:toggleCheck="toggleAllRows('id')"
            :checked="checkedRows.length > 0"
          />
        </div>
        <TableEmpty v-if="!isLoading && list.length < 1" />
        <template v-else-if="list.length">
          <div class="table-body">
            <table-row
              v-for="item in list"
              v-on:toggleCheck="toggleCheckRow"
              :item="item"
              :checked="checkedRows.includes(item.id)"
              :key="item.id"
              :id="item.id"
            />
          </div>
        </template>
      </PagePanel>
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";

import TableView from "@/components/table/TableView";
import NotificationsTableRowHead from "@/views/service/notifications/manual/NotificationsManualTableRowHead";
import NotificationsTableRow from "@/views/service/notifications/manual/NotificationsManualTableRow";
import {Button, svg} from "@ogqcorp/v-ui";
const {svgChevronDown} = svg
export default {
  data() {
    return {
      // API_URL: `${this.$API_URL}/admin/reservedENotificationss`,

      listKey: "elements",
    };
  },
  extends: TableView,
  components: {
    "admin-layout": AdminLayout,
    Button,
    "table-row-head": NotificationsTableRowHead,
    "table-row": NotificationsTableRow,
    svgChevronDown
  },
};
</script>

<style lang="scss" scoped>
.table-head::v-deep .tr,
.table-body::v-deep .tr {
  display: grid;
  grid-template-columns: 48px 0.5fr 2fr 0.75fr 0.75fr 0.5fr 0.5fr;
  align-items: center;
}
</style>
